import OvertimeActionTypes from "./overtime.types";

interface OvertimeState {
  overtimeList: Array<any>;
}

const INITIAL_STATE: OvertimeState = {
  overtimeList: [],
};

const OvertimeReducer = (state = INITIAL_STATE, action: any): OvertimeState => {
  switch (action.type) {
    case OvertimeActionTypes.GET_OVERTIME:
      return {
        ...state,
        overtimeList: [],
      };
    case OvertimeActionTypes.GET_OVERTIME_SUCCESS:
      return {
        ...state,
        overtimeList: action.payload,
      };
    case OvertimeActionTypes.GET_OVERTIME_FAILURE:
      return {
        ...state,
        overtimeList: [],
      };
    case OvertimeActionTypes.CREATE_OVERTIME:
      return {
        ...state,
      };
    case OvertimeActionTypes.CREATE_OVERTIME_SUCCESS:
      return {
        ...state,
      };
    case OvertimeActionTypes.CREATE_OVERTIME_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default OvertimeReducer;
