import { Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../redux/global/global.actions";
import { HistoryServices } from "../../../../_services";

const useListHistory = (): [Array<any>, (page: number) => Promise<void>] => {
  const dispatch = useDispatch();
  const [listHistory, setListHistory] = useState<Array<any>>([]);

  const getHistory = async (page: number) => {
    try {
      dispatch(showLoading());
      const get = await HistoryServices.get(page);
      setListHistory(get.data.wageRequests);
      dispatch(hideLoading());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  return [listHistory, getHistory];
};

export default useListHistory;
