import React, { useEffect, useRef, useState } from "react";
import { PageComponent } from "../../lib";
import "./index.scss";
import { Icon } from "@blueprintjs/core";
import { LoanRequestForm } from "./LoanRequestForm";
import { LoanHistory } from "./LoanHistory";
import DownloadingForm from "./DownloadingForm";
import CardDetail from "./CardDetail";
import {
  LoanHistoryInterface,
  LoanRender,
  LoanRenderForm,
  ResponseFetch,
  SpawnRender,
  Token,
} from "./interface";
import { createStore } from "./util";

export const [useSpawnRender, updateSpawnRender] = createStore<SpawnRender>({
  spawn: LoanRender.HOME,
});
export const [useTokens] = createStore<Partial<Token>>({});

const Loan: PageComponent = () => {
  const [render, setRender] = useSpawnRender({
    shouldCompare() {
      return false;
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setLoading(!loading);
    }
  }, [loading]);

  useEffect(() => {
    setRender({
      spawn: LoanRender.HOME,
    });
  }, []);

  return (
    <div>
      <div
        className="header"
        style={{
          display: "flex",
        }}
      >
        <div
          style={{ marginBottom: "0.5rem", padding: "1rem" }}
          onClick={() => {
            render.spawn === LoanRenderForm.CONFIRM &&
              setRender({ spawn: LoanRenderForm.FILL });
            render.spawn === LoanRenderForm.FILL &&
              setRender({ spawn: LoanRender.HOME });
            render.spawn === LoanRender.DETAIL &&
              setRender({ spawn: LoanRender.HOME });
            render.spawn === LoanRender.HOME && window.history.back();
          }}
        >
          <Icon icon="arrow-left" style={{ color: "#205072" }} />
        </div>
        {render.spawn === LoanRender.HOME && (
          <div
            style={{
              fontWeight: "bold",
              width: "100%",
              color: "#205072",
              textAlign: "center",
            }}
          >
            Riwayat Koperasi
          </div>
        )}
        {(
          [
            LoanRender.FORM,
            ...Object.values(LoanRenderForm).filter((k) => true),
          ] as string[]
        ).includes(render.spawn) && (
          <div
            style={{
              fontWeight: "bold",
              width: "100%",
              color: "#205072",
              textAlign: "center",
            }}
          >
            Pinjaman Koperasi
          </div>
        )}
        {render.spawn === LoanRender.DETAIL && (
          <div
            style={{
              fontWeight: "bold",
              width: "100%",
              color: "#205072",
              textAlign: "center",
            }}
          >
            Detail Pinjaman
          </div>
        )}
      </div>
      <div className="white-space" />
      {loading && (
        <h3 style={{ position: "absolute", left: "40%", top: "50%" }}>
          Loading...
        </h3>
      )}
      {!loading && render.spawn === LoanRender.HOME && <LoanHistory />}
      {!loading &&
        (
          [
            LoanRender.FORM,
            ...Object.values(LoanRenderForm).filter(() => true),
          ] as string[]
        ).includes(render.spawn) && <LoanRequestForm />}
      {!loading && render.spawn === LoanRender.DOWNLOAD && <DownloadingForm />}
      {!loading && render.spawn === LoanRender.DETAIL && <CardDetail />}
    </div>
  );
};
export default Loan;
