import { Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import { PermissionI } from "../../../../../../_interfaces";
import { PresenceServices } from "../../../../../../_services";

const usePermit = (): [
  (payload: PermissionI) => void,
  () => void,
  Array<any>,
  (id: number) => void,
] => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const doPermit = async (payload: PermissionI) => {
    try {
      dispatch(showLoading());
      const permission = await PresenceServices.permission(payload);
      dispatch(hideLoading());
      dispatch(showAlert("Create permission success", Intent.SUCCESS));
      window.history.back();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const getData = async () => {
    try {
      dispatch(showLoading());
      const permission = await PresenceServices.permissionList();
      setData(permission.data.permissions);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      dispatch(
        showAlert("Terjadi kesalahan saat mengambil data", Intent.DANGER),
      );
    }
  };

  const cancelPermission = async (id: number) => {
    try {
      dispatch(showLoading());
      const cancel = await PresenceServices.cancelPermission(id);
      getData();
      dispatch(hideLoading());
      dispatch(showAlert("Izin berhasil di cancel", Intent.SUCCESS));
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("Terjadi kesalahan", Intent.DANGER));
    }
  };

  return [doPermit, getData, data, cancelPermission];
};

export default usePermit;
