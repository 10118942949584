/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { useAppSelector } from "../../../../../redux/hooks";
import CreateProduct from "../pages/CreateProduct";
import Home from "../pages/Home";

const UserRoutesProducts = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { title, hasBack } = useAppSelector((state) => state.globalState);

  useEffect(() => {
    // dispatch(getMasterData());
  }, []);

  return (
    <div>
      <div className="header kpi">
        <div
          className="back-button"
          onClick={() => {
            if (
              history.location.pathname === "/production-tracking" ||
              history.location.pathname === "/production-tracking/"
            )
              history.replace("/dashboard/0");
            else history.goBack();
          }}
        >
          <Icon icon="arrow-left" />
        </div>
        <div className="title">Production Tracking</div>
      </div>
      <div className="white-space" />
      <div>
        <Switch>
          <Route exact component={Home} path={`/production-tracking/`} />
          <Route
            exact
            component={CreateProduct}
            path={`/production-tracking/form`}
          />
          <Redirect exact from="**" to={`/production-tracking/`} />
        </Switch>
      </div>
    </div>
  );
};

export default UserRoutesProducts;
