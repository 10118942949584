const ShiftActionTypes = {
  GET_INCOMING_SHIFT: "GET_INCOMING_SHIFT",
  GET_INCOMING_SHIFT_SUCCESS: "GET_INCOMING_SHIFT_SUCCESS",
  GET_INCOMING_SHIFT_FAILURE: "GET_INCOMING_SHIFT_FAILURE",
  GET_CURRENT_SHIFT: "GET_CURRENT_SHIFT",
  GET_CURRENT_SHIFT_SUCCESS: "GET_CURRENT_SHIFT_SUCCESS",
  GET_CURRENT_SHIFT_FAILURE: "GET_CURRENT_SHIFT_FAILURE",
  DEFAULT_CURRENT_SHIFT: "DEFAULT_CURRENT_SHIFT",
};

export default ShiftActionTypes;
