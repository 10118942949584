/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../../../redux/hooks";
import "./shiftrequest.style.scss";
import { DateInput } from "@blueprintjs/datetime";
import Select, { OptionsOrGroups } from "react-select";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { getAttendanceData } from "../../../../../../redux/clock-in/clockin.actions";
import { ShiftInterface } from "../../_interfaces";
import {
  createShiftRequest,
  getShiftRequest,
} from "../../../../../../redux/shiftrequest/shiftrequest.action";
import { useDispatch } from "react-redux";

const RequestShift = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [data, setData] = useState<ShiftInterface>({
    masterShiftId: "",
    desc: "",
    approvalId: "",
    date: new Date(),
  });

  const [activity, setActivity] = useState<Array<any>>([]);

  const { masterSchedule, masterScheduleList, dataShift } = useAppSelector(
    (state) => ({
      ...state.clockin,
      ...state.shiftRequest,
    }),
  );

  const options: any = [{ value: "", label: "Pilih Shift" }];

  useEffect(() => {
    dispatch(setHeader({ title: "Shift Request", hasBack: true }));
    // dispatch(getShiftRequest());
  }, []);

  useEffect(() => {
    const dataView: any[] = [];
    dataShift?.map((item: any, idx: number) => {
      masterScheduleList?.map((value: any, idx: number) => {
        value?.detail?.timeRange?.map((x: any, i: number) => {
          if (
            x.days.includes(new Date(item.date).getDay()) &&
            value.id == item.masterShiftId
          )
            dataView.push({
              ...item,
              time: `Clock in ${x.startTime} - Clock out ${x.endTime}`,
              name: value.shiftName,
            });
        });
      });
    });

    setActivity(dataView);
  }, [dataShift]);

  useEffect(() => {
    masterScheduleList?.map((item: any, idx: number) => {
      const dataSchedule = {
        value: item.id,
        label: item.shiftName,
      };

      options.push(dataSchedule);
    });
  }, [options]);

  const handleDateChange = (e: any) => {
    setData((item: any) => ({
      ...item,
      date: e,
    }));
  };

  const handleChange = (e: any) => {
    setData((item: any) => ({
      ...item,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectChange = (val: any) => {
    setData((item: any) => ({
      ...item,
      masterShiftId: val.value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(createShiftRequest({ payload: data }));
  };

  return (
    <div className="shift-request-page">
      <div className="card mb-3 current-shift">
        <div className="card-body">
          <p className="text-info">Current Shift</p>
          <h3>{masterSchedule?.shiftName ?? "-"}</h3>
          <div className="row-custom mt-2">
            <div className="flex">
              <p className="text-secondary">Weekday Start Time</p>
              <h3 className="text-info">08:00</h3>
            </div>
            <div className="flex">
              <p className="text-secondary">Weekday End Time</p>
              <h3 className="text-info">18:00</h3>
            </div>
            <div className="flex">
              <p className="text-secondary">Saturday Start Time </p>
              <h3 className="text-info">08:00</h3>
            </div>
            <div className="flex">
              <p className="text-secondary">Saturday End Time</p>
              <h3 className="text-info">18:00</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="date-box">
        <label htmlFor="date">Masukan tanggal shift</label>
        <DateInput
          formatDate={(date) => date.toLocaleDateString()}
          onChange={(e) => handleDateChange(e)}
          parseDate={(str) => new Date(str)}
          placeholder={"M/D/YYYY"}
        />
      </div>
      <div className="mt-2">
        <label htmlFor="select-shift">Pilih Shift</label>
        <Select
          options={options}
          className="mt-2"
          name="masterShiftId"
          onChange={(e: any) => handleSelectChange(e)}
        />
      </div>
      <div className="mt-2">
        <label htmlFor="caption">Keterangan</label>
        <div>
          <textarea
            placeholder="Tulis Keterangan"
            className="form-control w-100"
            name="desc"
            onChange={handleChange}
          ></textarea>
        </div>
      </div>
      <div className="mt-2">
        <button className="btn btn-info" onClick={(e: any) => handleSubmit(e)}>
          Request Shift
        </button>
      </div>
      <div className="log-actity-box">
        <div className="row-custom">
          <div>
            <h4>Request Shift Activty</h4>
          </div>
          <div>
            <h5 className="text-secondary">View All</h5>
          </div>
        </div>
        <div>
          <ul className="list-custom">
            {activity.map((item: any, idx: number) => (
              <li key={idx}>
                <div className="row-custom">
                  <div>
                    <p>{item.time}</p>
                    <p>{item?.name ?? "-"}</p>
                  </div>
                  <div>
                    <p>Pending</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RequestShift;
