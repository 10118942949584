import { Intent } from "@blueprintjs/core";
import { batch } from "react-redux";
import {
  CreateLog,
  GenerateProduction,
  UpdateLog,
} from "../../_interfaces/productionTracking.interfaces";
import { ProductionTrackingServices } from "../../_services";
import GlobalActionTypes from "../global/global.types";
import ProductionTrackingActionTypes from "./productionTracking.types";

export const getMyProjects = () => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ProductionTrackingActionTypes.GET_MY_PROJECT });
      });

      const get = await ProductionTrackingServices.getMyProjects();

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.GET_MY_PROJECT_SUCCESS,
          payload: get.data.projects,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.GET_MY_PROJECT_FAILURE,
        });
      });
    }
  };
};

export const getGroups = (id: number, date: string, status?: string) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ProductionTrackingActionTypes.GET_GROUP_BY_PROJECT });
      });

      const get = await ProductionTrackingServices.getGroups(id, date, status);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.GET_GROUP_BY_PROJECT_SUCCESS,
          payload: get.data,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.GET_GROUP_BY_PROJECT_FAILURE,
        });
      });
    }
  };
};

export const generateProduction = (payload: GenerateProduction) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING, payload: payload });
        dispatch({ type: ProductionTrackingActionTypes.GENERATE_PRODUCTION });
      });

      const create = await ProductionTrackingServices.generateProduction(
        payload,
      );

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.GENERATE_PRODUCTION_SUCCESS,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Production berhasil dibuat",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.GENERATE_PRODUCTION_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const getLogItem = (
  projectId: any,
  date: any,
  itemId: any,
  groupName: any,
) => {
  return async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        batch(() => {
          dispatch({ type: GlobalActionTypes.SHOW_LOADING });
          dispatch({ type: ProductionTrackingActionTypes.GET_LOG_ITEM });
        });

        const get = await ProductionTrackingServices.getLogItem(
          projectId,
          date,
          itemId,
          groupName,
        );

        batch(() => {
          dispatch({ type: GlobalActionTypes.HIDE_LOADING });
          dispatch({
            type: ProductionTrackingActionTypes.GET_LOG_ITEM_SUCCESS,
            payload: get.data,
          });
        });
        return resolve(get.data);
      } catch (error) {
        batch(() => {
          dispatch({ type: GlobalActionTypes.HIDE_LOADING });
          dispatch({
            type: ProductionTrackingActionTypes.GET_LOG_ITEM_FAILURE,
          });
          dispatch({
            type: GlobalActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Terjadi kesalahan saat pengambilan data",
              alertType: Intent.DANGER,
            },
          });
        });
      }
    });
  };
};

export const createLog = (payload: CreateLog) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING, payload: payload });
        dispatch({ type: ProductionTrackingActionTypes.CREATE_LOG_ITEM });
      });

      const create = await ProductionTrackingServices.createLog(payload);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.CREATE_LOG_ITEM_SUCCESS,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Production Log berhasil dibuat",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.CREATE_LOG_ITEM_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const updateLog = (id: number, payload: UpdateLog) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING, payload: payload });
        dispatch({ type: ProductionTrackingActionTypes.UPDATE_LOG_ITEM });
      });

      const create = await ProductionTrackingServices.updateLog(id, payload);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.UPDATE_LOG_ITEM_SUCCESS,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Production Log berhasil di update",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.UPDATE_LOG_ITEM_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const sendToChecker = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ProductionTrackingActionTypes.SEND_TO_CHECKER });
      });

      const create = await ProductionTrackingServices.sendToChecker(id);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.SEND_TO_CHECKER_SUCCESS,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Data berhasil di kirim",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.SEND_TO_CHECKER_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const approveInput = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ProductionTrackingActionTypes.APPROVE_INPUT });
      });

      const create = await ProductionTrackingServices.approveInput(id);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.APPROVE_INPUT_SUCCESS,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Berhasil di approve",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.APPROVE_INPUT_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const rejectInput = (id: number, rejectReason: string) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ProductionTrackingActionTypes.REJECT_INPUT });
      });

      const create = await ProductionTrackingServices.rejectInput(
        id,
        rejectReason,
      );

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.REJECT_INPUT_SUCCESS,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Data berhasil di reject",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.REJECT_INPUT_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const sendToManager = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ProductionTrackingActionTypes.SEND_TO_MANAGER });
      });

      const create = await ProductionTrackingServices.sendToManager(id);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.SEND_TO_MANAGER_SUCCESS,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Data berhasil di kirim",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.SEND_TO_MANAGER_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const deleteInput = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ProductionTrackingActionTypes.DELETE_INPUT });
      });

      const create = await ProductionTrackingServices.deleteInput(id);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.DELETE_INPUT_SUCCESS,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Berhasil hapus data input",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.DELETE_INPUT_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const managerSubmit = (id: number) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ProductionTrackingActionTypes.MANAGER_SUBMIT });
      });

      const create = await ProductionTrackingServices.managerSubmit(id);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.MANAGER_SUBMIT_SUCCESS,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Data berhasil di submit",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ProductionTrackingActionTypes.MANAGER_SUBMIT_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const setFilter = (data: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ProductionTrackingActionTypes.SET_FILTER,
      payload: data,
    });
  };
};

export const resetFilter = () => {
  return async (dispatch: any) => {
    dispatch({
      type: ProductionTrackingActionTypes.RESET_FILTER,
    });
  };
};
