import { Intent } from "@blueprintjs/core";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import { ClockInBySpvI } from "../../../../../../_interfaces";
import { PresenceServices } from "../../../../../../_services";

const usePresence = () => {
  const dispatch = useDispatch();

  const doPresence = async (
    payload: ClockInBySpvI,
    type: string,
    presenceAt: string,
  ) => {
    try {
      dispatch(showLoading());
      const presence = await PresenceServices.presenceBySpv(
        payload,
        type,
        presenceAt,
      );
      dispatch(hideLoading());
      dispatch(showAlert("Absen karyawan success", Intent.SUCCESS));
      window.history.back();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  return [doPresence];
};

export default usePresence;
