import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import CookieStorage from "./cookieStore";
import Cookies from "cookies-js";
import storage from "redux-persist/lib/storage";
import AuthReducer from "./auth/auth.reducer";
import GlobalReducer from "./global/global.reducer";
import RequestReducer from "./request/request.reducer";
import ChangePasswordReducer from "./changePassword/changePassword.reducer";
import ClockinReducer from "./clock-in/clockin.reducer";
import PermitReducer from "./permit/permit.reducer";
import OutstationReducer from "./outstation/outstation.reducer";
import ApprovalReducer from "./approval/approval.reducer";
import ShiftRequestReducer from "./shiftrequest/shiftrequest.reducer";
import OvertimeReducer from "./overtime/overtime.reducer";
import ShiftReducer from "./shift/shift.reducer";
import PresenceReducer from "./presence/presence.reducer";
import HistoryReducer from "./history/history.reducer";
import LoanReducer from "./loan/loan.reducer";
import CompanySettingReducer from "./companySetting/companySetting.reducer";
import ProductionTrackingReducer from "./productionTracking/productionTracking.reducer";

const persistConfig = {
  key: "newgajikureduxpersistconfig",
  storage: storage,
  blacklist: ["auth"],
};
const authPersistConfig = {
  key: "authonly",
  storage: new CookieStorage(Cookies, {}),
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthReducer),
  globalState: GlobalReducer,
  requestState: RequestReducer,
  changePasswordState: ChangePasswordReducer,
  clockin: ClockinReducer,
  permit: PermitReducer,
  outsource: OutstationReducer,
  approval: ApprovalReducer,
  shiftRequest: ShiftRequestReducer,
  overtime: OvertimeReducer,
  shift: ShiftReducer,
  presence: PresenceReducer,
  historyState: HistoryReducer,
  loanState: LoanReducer,
  companySettingState: CompanySettingReducer,
  productionTrackingState: ProductionTrackingReducer,
});

export default persistReducer(persistConfig, rootReducer);
