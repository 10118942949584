/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import { createRemoteRequest } from "../../../../../../redux/presence/presence.actions";
import { getCurrentShift } from "../../../../../../redux/shift/shift.actions";
import usePermissions from "../../../../../../_helper/usePermissions";
import useUpload from "../../../../../../_helper/useUpload";
import { OvertimeParam } from "../../../../../../_interfaces";
import "./outstation.style.scss";

const OutstationForm = () => {
  const dispatch = useDispatch();
  const { currentShift, filePermission, locationPermission } = useAppSelector(
    (state) => ({
      ...state.shift,
      ...state.globalState,
    }),
  );
  const { reqFilePermission, reqLocationPermission } = usePermissions();
  const defaultForm: OvertimeParam = {
    scheduleId: 0,
    description: "",
    latitude: 0,
    longitude: 0,
    photo: "",
  };
  const currentDate = new Date().toISOString().split("T")[0];
  const [form, setForm] = useState(defaultForm);
  const [date, setDate] = useState(currentDate);
  const [fileUrl, upload] = useUpload();

  useEffect(() => {
    dispatch(setHeader({ title: "Request Remote", hasBack: true }));
    // reqFilePermission();
    // reqLocationPermission();
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setForm((state) => ({
          ...state,
          longitude: pos.coords.longitude,
          latitude: pos.coords.latitude,
        }));
      },
      () => {},
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  }, [locationPermission]);

  useEffect(() => {
    setForm((temp) => ({ ...temp, photo: fileUrl }));
  }, [fileUrl]);

  useEffect(() => {
    setForm((temp) => ({
      ...temp,
      scheduleId: currentShift?.id,
    }));
  }, [currentShift]);

  useEffect(() => {
    if (date !== "") dispatch(getCurrentShift(date));
  }, [date]);

  const handleChange = (e: any) => {
    setForm((temp) => {
      return { ...temp, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(createRemoteRequest(form));
  };

  const handleUploadFiles = (files: any) => {
    if (files.length > 0) {
      upload(files[0], "remote");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="outstation">
      <label>Tanggal Remote</label>
      <input
        type="date"
        name="requestedAt"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        required
      />
      {currentShift?.startTime ? (
        <div className="schedule">
          <label>Shift Anda</label>
          <div>
            {new Date(currentShift?.startTime).toLocaleDateString("id-ID", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZone: "Asia/Jakarta",
            })}{" "}
            -{" "}
            {new Date(currentShift?.endTime).toLocaleDateString("id-ID", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZone: "Asia/Jakarta",
            })}
          </div>
        </div>
      ) : (
        date !== "" && (
          <div className="schedule error">
            Tidak ada shift di tanggal {date}
          </div>
        )
      )}
      <div className="upload-photo">
        <input
          type="file"
          id="file-izin"
          accept="*"
          onChange={(e: any) => handleUploadFiles(e.target.files)}
        />
        <div className="label">Unggah File</div>
        <label htmlFor="file-izin" className="file-box">
          <p className="text">{fileUrl !== "" ? fileUrl : "Pilih File"}</p>
          <div className="btn">Browse</div>
        </label>
      </div>
      <label>Deskripsi</label>
      <textarea
        placeholder="Keterangan"
        name="description"
        value={form?.description}
        onChange={handleChange}
      />
      {(!filePermission || !locationPermission) && (
        <p className="error">Izinkan lokasi &amp; file untuk melanjutkan</p>
      )}
      <button
        type="submit"
        className="submit-btn"
        disabled={
          form?.description === "" || !filePermission || !locationPermission
        }
      >
        Submit
      </button>
    </form>
  );
};

export default OutstationForm;
