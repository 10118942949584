import { Intent } from "@blueprintjs/core";
import GlobalActionTypes from "./global.types";

interface PermissionsI {
  camera: boolean;
  location: boolean;
}

export const setHeader = ({ title = "", hasBack = false }) => {
  return (dispatch: any) =>
    dispatch({
      type: GlobalActionTypes.SET_HEADER,
      payload: { title, hasBack },
    });
};

export const hideSuccess = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.HIDE_SUCCESS });
};

export const showAlert =
  (alertMessage: string, alertType: Intent) => async (dispatch: any) => {
    dispatch({
      type: GlobalActionTypes.SHOW_ALERT,
      payload: { alertMessage, alertType },
    });
  };

export const hideAlert = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.HIDE_ALERT });
};

export const setCaptureImage = (image: string) => {
  return async (dispatch: any) => {
    dispatch({ type: GlobalActionTypes.SET_IMAGE, payload: image });
  };
};

export const unsetCaptureImage = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.UNSET_IMAGE });
};

export const setLocation =
  (lat: string, long: string, address: string, isNearby: boolean) =>
  async (dispatch: any) => {
    dispatch({
      type: GlobalActionTypes.SET_LOCATION,
      payload: { lat, long, address, isNearby },
    });
  };

export const unsetLocation = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.UNSET_LOCATION });
};

export const showLoading = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.SHOW_LOADING });
};

export const hideLoading = () => async (dispatch: any) => {
  dispatch({ type: GlobalActionTypes.HIDE_LOADING });
};

export const setPermissions =
  (permissions: PermissionsI) => async (dispatch: any) => {
    dispatch({
      type: GlobalActionTypes.SET_PERMISSION,
      payload: {
        camera: permissions.camera,
        location: permissions.location,
      },
    });
  };

export const setFCMToken = (token: string) => async (dispatch: any) => {
  dispatch({
    type: GlobalActionTypes.SET_FCM_TOKEN,
    payload: token,
  });
};

export const setLocationPermission =
  (status: boolean) => async (dispatch: any) => {
    dispatch({
      type: GlobalActionTypes.SET_LOCATION_PERMISSION,
      payload: status,
    });
  };

export const setCameraPermission =
  (status: boolean) => async (dispatch: any) => {
    dispatch({
      type: GlobalActionTypes.SET_CAMERA_PERMISSION,
      payload: status,
    });
  };
