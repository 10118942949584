import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./index.css";
import { useLogin } from "../../../../data/login/api";
import { RegistrationComponentProps } from "../../lib";
import { usePasswordResetMutation } from "../../../../backend/app";

export const ResetSecurityCode: FunctionComponent<RegistrationComponentProps> =
  ({ setNextIsDisabled, registrationToken, onNextClickRef }) => {
    const [initialSecurityCode, setInitialSecurityCode] = useState<string>("");
    const [securityCode, setSecurityCode] = useState<string>("");

    const [login] = useLogin();

    useEffect(() => {
      setNextIsDisabled(
        securityCode === "" ||
          securityCode.length < 4 ||
          securityCode !== initialSecurityCode,
      );
    }, [securityCode, setNextIsDisabled]);

    const [registerSecurityCode] = usePasswordResetMutation();

    onNextClickRef.current = useCallback(async () => {
      const loginAuthBackendResponse = await registerSecurityCode({
        variables: {
          newPassword: securityCode,
          token: registrationToken,
        },
        fetchPolicy: "no-cache",
      });
      await login({
        username: loginAuthBackendResponse.data!.PasswordReset,
        password: securityCode,
      });
    }, [registerSecurityCode, registrationToken, securityCode]);

    return (
      <div className="hp">
        <div className="company-tag">Gajiku Inc</div>
        <div className="header">
          <div>Harap Setel Ulang Kata Sandimu</div>
        </div>
        <div className="container password">
          <div className="password-field">
            <input
              type="password"
              value={initialSecurityCode}
              onChange={(e) => setInitialSecurityCode(e.target.value)}
              placeholder="Masukan Kata Sandi Baru..."
            />
            <input
              type="password"
              value={securityCode}
              onChange={(e) => setSecurityCode(e.target.value)}
              placeholder="Masukan Kata Sandi Baru Lagi..."
            />
          </div>
        </div>
      </div>
    );
  };
