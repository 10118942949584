import { Icon } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import { getOvertimeList } from "../../../../../../redux/overtime/overtime.actions";
import "./overtime.style.scss";
import useOvertime from "./useOvertime";

const Overtime = () => {
  const dispatch = useDispatch();
  const { overtimeList } = useAppSelector((state) => ({
    ...state.overtime,
    ...state.clockin.masterData,
    ...state.globalState,
  }));
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [cancelOvertime, _] = useOvertime();
  const history = useHistory();

  useEffect(() => {
    dispatch(setHeader({ title: "Lembur", hasBack: true }));
    dispatch(getOvertimeList());
  }, []);

  const generateIcon = (status: string) => {
    switch (status) {
      case "pending":
        return <Icon color="#979757" icon="time" iconSize={20} />;
      case "approved":
        return <Icon color="#6eb96e" icon="tick-circle" iconSize={20} />;
      case "rejected":
        return <Icon color="#c06262" icon="delete" iconSize={20} />;
      case "canceled":
        return <Icon color="#808080" icon="ban-circle" iconSize={20} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="overtime-page">
      <button
        className="submit-btn"
        onClick={() => {
          history.push(`/attendance/overtime-request/${date}`);
        }}
      >
        Request Lembur
      </button>
      <div className="overtime-list">
        <div className="header-list">Lembur Activity</div>
        {overtimeList?.map((item: any) => (
          <div className="list-group" key={item.id}>
            <div className="left">
              <div>
                {`${new Date(item.startAt).toLocaleDateString("id-ID", {
                  day: "numeric",
                  year: "numeric",
                  month: "short",
                  hour: "numeric",
                  minute: "numeric",
                  timeZone: "Asia/Jakarta",
                })} - 
              ${new Date(item.endAt).toLocaleDateString("id-ID", {
                day: "numeric",
                year: "numeric",
                month: "short",
                hour: "numeric",
                minute: "numeric",
                timeZone: "Asia/Jakarta",
              })}`}
              </div>
              <div>{item.description}</div>
              {item.status === "rejected" && (
                <div>Reject reason: {item.reject_reason}</div>
              )}
              <div></div>
            </div>
            <div className="right">
              {generateIcon(item.status)}
              {item.status === "pending" && (
                <button className="btn" onClick={() => cancelOvertime(item.id)}>
                  Cancel
                </button>
              )}
            </div>
          </div>
        ))}
        {overtimeList?.length === 0 && (
          <div style={{ textAlign: "center" }}>Belum ada data</div>
        )}
      </div>
    </div>
  );
};

export default Overtime;
