import React, { FunctionComponent, useEffect, useRef, useState } from "react";

const TncEnglish = () => {
  return (
    <div>
      Welcome to Gajiku!
      <br />
      <br />
      <p>
        The Terms & Conditions as set out below govern the use of services
        offered by PT Vara Technologies Inc in connection with the use of the
        website www.gajikuapp.com and Gajiku application. User are advised to
        read carefully because it might impact the rights and obligations of
        User under the applicable laws. By downloading, installing, using and/or
        enjoying our service on the Gajiku Platform, User have read, understood,
        acknowledged, agreed, and accepted all terms, conditions, and all
        information of use Gajiku Platform. These Terms and Conditions
        constitute valid agreement as to the procedure and requirement for
        Gajiku Platform between User and the administrator of Gajiku Platform,
        namely PT Vara Technologies Inc.
      </p>
      <br />
      <p>
        Term of use and privacy policy may be renewed by Platform Administrator
        from time to time, to modify, delete and/or apply new terms and
        conditions. Further use of Gajiku Platform by User will be considered as
        an agreement to be subject to changes to this Terms and Conditions.
        GENERAL CONDITIONS Gajiku is a technology-based service application
        platform that provides advance salary payment facilities for User
        employees.
      </p>
      <br />
      <p>
        Gajiku is owned by PT Vara Technologies Inc. Gajiku platform is an
        application Android version under the name “Gajiku” and website address
        at www.gajikuapp.com. In order to use Gajiku Platform, an internet
        connection and an Android device is required, and all costs arising from
        using the electronic device connection are the sole responsibility of
        User. User is the party who uses and/or enjoys Gajiku services,
        including, but not limited to, the partner company and employee.
      </p>
      <br />
      <p>
        Gajiku has the rights to not process, limit, suspend or terminate User
        to use Gajiku Platform, in the event there is a reason that User have
        violated this Terms and Condition and/or applicable laws. Terms &
        Conditions is an agreement between User and Gajiku which contains a set
        of rules governing the rights, obligations, responsibilities of User and
        Gajiku, as well as procedures for using the Gajiku service system.
      </p>
      <br />
      <p>
        TERMS OF USER Employee who want to use Gajiku service and to access
        Gajiku Platform need to register, accompanied by submitting information
        in form of a cellphone number owned by the employee. Company that have
        collaborated with Gajiku can use Gajiku service by getting online access
        to the Gajiku Platform dashboard to be able to see employees who have
        made advance salary withdrawals.
      </p>
      <br />
      <p>
        After registering in Gajiku Platform, User will receive a personal
        account in Gajiku accessible with One Time Password (OTP) that will sent
        to the registered User mobile phone number and verified by Gajiku. The
        password of User account provided by One Time Password (OTP) shall keep
        confidential.
      </p>
      <br />
      <p>
        Users can then register a Personal Identification Number (PIN) code to
        login going forward. Any changes in of User data and information must be
        notified to Gajiku. Information relating personal data provided by User
        will only be used by Gajiku to render the service in Gajiku Platform and
        for other purpose in accordance with privacy policy that has been agreed
        by User and in accordance with the applicable laws.
      </p>
      <br />
      <p>
        User must provide true, accurate, complete, and up to date information
        and agree to provide any proof identity or another proof as reasonably
        requested for registration of Gajiku Platform. In any changes to
        User&#39;s bank account information listed in Gajiku Platform database,
        Gajiku will take double verification, the first is verification to User
        company and verification through a verified mobile number by sending a
        one-time password (OTP). User employee must ensure that the name on the
        bank account is match with the name as provided by the User company to
        Gajiku.
      </p>
      <br />
      <p>
        Any changes to the bank account information will result in a subsequent
        verification test and may delay the timings process. In the event that
        disclosure of passwords or User account has been compromised which can
        result in losses for User due to the unauthorized use (including hacking
        of User account), without authority and against the law on User account
        that occurs not because of Gajiku&#39;s fault, the salary withdrawal
        transaction services performed through Gajiku Platform is considered as
        valid transactions, unless User has notified Gajiku regarding this
        matter in advance. User must report to Gajiku for any whatsoever misuse
        account User in Gajiku Platform, User is responsible for the use of User
        account, even though the account has been misused by someone else.
      </p>
      <br />
      <p>
        User are not permitted to endanger, manipulate, misuse or modify Gajiku
        Platform whatsoever, Gajiku shall have the right to close, cancel and
        prohibit User using the Gajiku Platform due to the violation of this
        Terms and Conditions. User has agreed and understand that User will use
        Gajiku Platform only for the purposes as referred to in the service, and
        is not allowed to misuse or use Gajiku Platform for activities that are
        against the law, including but not limited to criminal acts of
        embezzlement or fraud.
      </p>
      <br />
      <p>
        User company is obliged to update employee data on time, for any change
        in employee salary, employee resignation, and/or additional employee
        through Gajiku Platform. By providing personal data information to
        Gajiku, User understands and agreed to Terms and Conditions of Gajiku
        Platform. ACCOUNT SECURITY Gajiku is committed to take all reasonable
        efforts to keep Gajiku Platform is able to function properly, safe,
        comfortably and smoothly as intended, but Gajiku not guarantee
        continuous access to the service we provide may always be perfect.
      </p>
      <br />
      <p>
        User has agreed and acknowledged that the services provided by Gajiku to
        User are AS IS and AS AVAILABLE in the sense that Gajiku Platform User
        has agreed that the use of Gajiku services at User&#39;s own risk and
        Gajiku cannot guarantee as follows: Gajiku do not guarantee that the
        service provided will be free from error and/or defects when operating
        in combination with hardware, software, system or other data.
      </p>
      <br />
      <p>
        Whereas the availability and reliability, as well as the supporting
        servers that provide by Gajiku Platform will be free from viruses and
        other dangerous components. Whereas quality of products, features and
        available information on the Gajiku Platform will fulfill User
        expectation and needs.
      </p>
      <br />
      <p>
        Whereas User acknowledges and agrees that the use of internet access
        cannot continuously create something safe and private, and therefore any
        messages or information that User sent or used on Gajiku Platform may be
        hacked or taken by unauthorized Third Parties. PRIVACY POLICY The
        privacy policy as set out in this Terms and Conditions will explain how
        Gajiku manages, processes, obtains, collects, uses, discloses and
        processes, opens access, and protects information and personal data that
        has been provided by User when registering to use Gajiku Platform.
      </p>
      <br />
      <p>
        Please note that the personal data is excluding the personal data that
        is already available in the public domain. Gajiku Platform User need to
        read this privacy policy thoroughly before registering and/or accessing
        Gajiku Platform service, because the privacy policy is part of Terms and
        Condition of Gajiku Platform.
      </p>
      <br />
      <p>
        By using Gajiku Platform, User is deemed to have read and agreed to the
        privacy policy as well as using personal data to be used for the
        purposes of the rendering the service in accordance with this privacy
        policy.
      </p>
      <br />
      <p>
        Registration Information provided regarding personal identity that must
        be filled in by account User on the Platform includes among other: Full
        name as printed in the valid Identity Card (ID card or Passport); Mobile
        phone number that will be used to send one-time password (OTP); and
        Monthly basic salary. User acknowledges and agrees that Gajiku can
        receive User information, including but not limited to GPS
        location/coordinates (if User activates this feature), internet protocol
        (IP) address (if User using a browser), operating system, browser type,
        platform address. User agrees that Gajiku will verify directly to User,
        including to company where User works in regard to the personal data
        that has been submitted by User through Gajiku Platform.
      </p>
      <br />
      <p>
        User guarantee that the information provided is true, accurate, and up
        to date. In case that the information provided is incorrect, Gajiku is
        not responsible for all consequences that may arise in connection with
        the provision and use of such incorrect information.
      </p>
      <br />
      <p>
        Use of Personal Data User hereby gives express consent to Gajiku to make
        all use of User personal data for business and operational activities as
        well as for other purposes required in accordance with the purpose for
        which Personal Data is provided, including but not limited to as
        follows: Gajiku will keep User personal information as long as the
        information is related to the purposes required for the Gajiku service
        interests.
      </p>
      <br />
      <p>
        Confidentiality of personal information that user provides will not
        distributed unless agreed by User based privacy policy on this Terms and
        Conditions or permitted by applicable laws. That by using the Gajiku
        Platform, User agrees and allows certain third parties to use User
        Personal Data required in connection with Gajiku services, including but
        not limited to: Service provider company for information technology (IT)
        such as infrastructure service, cloud computing, software, analysis
        development of “Big Data” and “Machine Learning”, including “Data
        Cleansing”, “Data Insight”, “Credit Scoring” and others. Transaction
        processing service providers of funds transfer payments from Gajiku to
        Users.
      </p>
      <br />
      <p>
        Government agencies or institutions (if needed), Other parties to the
        extent permitted by regulation and applicable laws. Security Measure
        Gajiku is committed to provide the best service by ensuring that User
        information provided to Gajiku is safe and cannot be used by
        unauthorized parties. By using Gajiku Platform, User allow Gajiku to be
        able to take all necessary security measures to protect User
        information.
      </p>
      <br />
      <p>
        However, Gajiku cannot guarantee this perfectly and is not responsible
        for any loss, loss of data that occurs from incidents beyond control of
        Gajiku. Therefore, Gajiku strongly recommends that User keep passwords
        confidential to any party and always make updates to Gajiku Platform.
        User hereby gives consent to Gajiku to be able send, store, use and
        treat personal data on the server that has been determined by Gajiku.
        Server can be managed by Gajiku in collaboration with other third
        parties.
      </p>
      <br />
      <p>
        However, Gajiku, will regulate the use and utilization of the data
        information will be governed by this privacy policy in accordance with
        the prevailing laws and regulations. SERVICE FEATURE Gajiku is a
        technology-based platform that offer facilities for its User employees
        to be able to get a salary earned before the payday period provided that
        the salary that can be withdrawn by User is a maximum of 15 (fifteen)
        working days.
      </p>
      <br />
      <p>
        By using Gajiku Platform services, User can make salary withdrawals
        anytime and anywhere without having to wait for payday. User employees
        have acknowledged and agreed that the User’s company will deduct the
        salary that will be received by User employees during the payroll period
        against the amount of advance salary withdrawals made by User employees
        through Gajiku Platform.
      </p>
      <br />
      <p>
        User employees have agreed and allowed User’s company to make salary
        deductions on the payday for the advance salary withdrawal made by User
        employees.
      </p>
      <br />
      <p>
        In accordance with the operational standard of Gajiku, the process of
        disbursing funds only required a maximum of 1 (one) hour after
        withdrawal of funds requested by User employees. By making a salary
        withdrawal through Gajiku Platform, User employees has agreed to be
        charged for transaction fee for the advance salary withdrawal. If User
        employee does not agree to the amount of transaction fee that being
        charged, User employee is required to stop using Gajiku Platform
        services.
      </p>
      <br />
      <p>
        Gajiku will issue a report to User company and/or User employees in
        connection with the salary withdrawal made by employees, which includes
        details of advance salary withdrawal made by User employees. TRANSACTION
        FEE Gajiku will not charge any fees and/or interest except for the
        transaction fee to User employees for any advance salary withdrawals
        made by User employees.
      </p>
      <br />
      <p>
        Transaction fee is a fee that is charged to User employee in the amount
        of 3% (three percent) of the total amount of advance salary withdrawn
        for the purpose of maintenance the system and improve Gajiku services.
        Example: Salary = Rp. 1.000.000,- reduce by transaction fee 3% = then
        the salary that will be received by User employee is Rp. 970.000
        Transaction fee charged by Gajiku is flat, regardless of the advance
        salary withdrawn by User.
      </p>
      <br />
      <p>
        Gajiku has the right at any time without prior notice to make changes to
        the transaction fee contained in this Terms and Conditions. By using the
        Gajiku Platform, User is deemed to understand and agree to the Terms and
        Conditions of the transaction fee that apply. LIMITATION OF LIABILITY In
        providing services, Gajiku will carry out prudence, however Gajiku is
        not responsible for all actions taken by User in connection with the use
        of Gajiku services which are carried out in bad faith, willful
        misconduct, user negligence.
      </p>
      <br />
      <p>
        CLAIM User can make notifications and complaints through Gajiku Platform
        by filling out the form application or via electronic mail (e-mail) at
        nadia@gajikuapp.com, since it has been observed there have been
        suspicious actions outside of the activities carried out by User on the
        Gajiku Platform.
      </p>
      <br />
      <p>
        Gajiku will immediately process the complaint or notification within
        period 3 (three) days after the complaint is received by Gajiku and will
        immediately confirm to User. In the event a fraud claim occurs in
        connection with the disbursement of User employee salary, Gajiku will
        investigate such claim according to the applicable standard operating
        procedure in Gajiku internally.
      </p>
      <br />
      <p>
        Gajiku will exercise due care and diligence and use the same skill and
        care in providing its services hereunder, but shall not be liable for
        any action taken or omitted by it in the performance of services
        rendered hereunder in the absence of its bad faith, willful misconduct,
        gross negligence or reckless disregard of its duties.
      </p>
      <br />
      <p>
        Gajiku is not responsible for fraudulent claims submitted by User as a
        result of their own negligence by not maintaining their security of User
        account which results in unauthorized use. User hereby disclaims any
        liability of Gajiku for compensation for any claims caused by User
        negligence.
      </p>
      <br />
      <p>
        TAXES All taxes that may arise in connection with the use of Gajiku
        Platform services shall be borne by each party in accordance with the
        prevailing laws. INTELLECTUAL PROPERTY RIGHTS Gajiku is the sole owner
        of the name, icon and logo, and all intellectual property rights related
        to the website, system, and interface are fully owned by Gajiku, which
        is copyright and trademark is protected by law.
      </p>
      <br />
      <p>
        It is strictly forbidden without Gajiku&#39;s written consent to use,
        modify, or post the name, icon, logo or brand. All intellectual property
        rights contained in Gajiku Platform, including but not limited to the
        platform source code, are strictly prohibited from modifying, derivative
        works, adapting, duplicating, copying, selling, reproducing, hacking,
        selling, and/or exploiting Gajiku Platform, the use of Gajiku Platform
        for unauthorized access, launching automatic programs or scripts, or any
        program that may hinder the operation and/or performance of Gajiku
        Platform, or in any way reproduce or avoid the navigation structure or
        presentation of Gajiku Platform or its contents.
      </p>
      <br />
      <p>
        User is only allowed to use Gajiku Platform for non-commercial personal
        needs, non-transferable, and non-sublicensable. Gajiku has the full
        right to take all legal actions against User for any violation of
        Intellectual Property Rights related to Gajiku Platform.
      </p>
      <br />
      <p>
        NOTICE All notifications or requests for information to and/or about the
        Gajiku Platform will be processed if made in writing and sent to
        Gajiku&#39;s address on Jl Mandor Demong No. 07 Kel. Mustikasari, Kec.
        Mustika Jaya Bekasi 17157, or can be sent via electronic mail (e-mail)
        at nadia@gajikuapp.com, or contact our call center at +6281231747236.
        Any notification given by Gajiku will be deemed to have been received by
        User, if: Gajiku can show that the notification, both physically and
        electronically has been sent to User; or; Gajiku has made the
        notification on Gajiku Platform which can be accessed by public without
        being charged for any fees.
      </p>
      <br />
      <p>
        DISPUTE RESOLUTION All disputes and differences of opinion that may
        arise in the implementation of this Terms and Conditions will be
        resolved amicably, and if the dispute that arise that cannot be resolved
        amicably, within 30 (thirty) days after the dispute, User complaint
        dispute must be resolved first through a forum with Gajiku.
      </p>
      <br />
      <p>
        In the event the settlement of User complaints cannot be resolved after
        30 (thirty) days, User and Gajiku agree to resolve the dispute through
        the South Jakarta District Court.
      </p>
      <br />
      <p>
        GOVERNING LAW This Terms and Conditions shall be governed by and
        construed in accordance with the laws of the Republic of Indonesia.
        FORCE MAJEURE Gajiku shall not be liable for any delay or failure to
        perform any of its obligations under this Terms and Conditions, if the
        delay or failure results from events or circumstances beyond its
        reasonable control, including but not limited to, incidents of fire,
        natural disasters (such as earthquakes, floods, hurricanes, lightning),
        mass strikes, riots, wars, changes to laws and regulations, and
        conditions in the economic, financial sector, damage to Gajiku
        Platform&#39;s transaction system, and disruption of the transaction
        settlement system on Gajiku Platform.
      </p>
      <br />
      <p>
        MISCELLANEOUS User expressly agree that the provisions of article 1266
        of Indonesian Civil Code that requiring approval or authorization of the
        court to terminate the Terms and Conditions shall not apply.
      </p>
      <br />
      <p>
        Gajiku without prior notice have the right, to take any actions required
        in the case of fraud/manipulation, money laundering or violated
        committed by User and any violation made by User money will be processed
        under the applicable laws in Indonesia. Gajiku has the right to
        investigate and claim the right of Gajiku for any violation of this
        Terms and Conditions as long as it is possible and permitted by the
        applicable laws in Indonesia.
      </p>
      <br />
      <p>
        User hereby acknowledges and agrees that Gajiku has the right to monitor
        every access to Gajiku Platform, to ensure that User comply with this
        Terms and Conditions, and comply with the applicable laws and
        regulations in Indonesia. This Terms and Conditions are made in
        Indonesian and English. If there is any inconsistency or difference in
        interpretation between Indonesian and English, the Indonesian version
        shall prevail.
      </p>
      <br />
      <p>
        Hereby, Gajiku Platform User represents and warrants that all
        information provided by User to Gajiku is correct and accurate, in the
        event that User submits incorrect information, then Gajiku has the right
        to suspend, close User access to Gajiku Platform, close and/or cancel
        User&#39;s account on Gajiku Platform. Gajiku reserves all rights that
        not expressly granted herein.
      </p>
      <br />
      <p>
        Gajiku may change this Terms and Conditions at any time by posting the
        revised Terms and Conditions on the Gajiku Platform. The use of Gajiku
        Platform by User after the change is posted shall be deemed as User
        acceptance of the revised Terms and Conditions. If User disagree with
        any of this Terms and Conditions, please do not access, browse or use
        Gajiku Platform. Last update of this Terms and Conditions: 4th November,
        2020.
      </p>
    </div>
  );
};

export default TncEnglish;
