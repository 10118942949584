import { Intent } from "@blueprintjs/core";
import { batch } from "react-redux";
import { CreateLoan } from "../../_interfaces";
import { LoanServices } from "../../_services/loan.services";
import GlobalActionTypes from "../global/global.types";
import LoanActionTypes from "./loan.types";

export const getLoanRequest = (
  type: string,
  amount: number,
  mentLength: number,
) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: LoanActionTypes.GET_LOAN_REQUEST });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const get = await LoanServices.getLoanRequest(type, amount, mentLength);

      batch(() => {
        dispatch({
          type: LoanActionTypes.GET_LOAN_REQUEST_SUCCESS,
          payload: get.data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({
          type: LoanActionTypes.GET_LOAN_REQUEST_FAILURE,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const createLoanRequest = (data: CreateLoan) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: LoanActionTypes.CREATE_LOAN_REQUEST });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const post = await LoanServices.createLoanRequest(data);

      batch(() => {
        dispatch({ type: LoanActionTypes.CREATE_LOAN_REQUEST_SUCCESS });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Berhasil mengajukan pinjaman",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({ type: LoanActionTypes.CREATE_LOAN_REQUEST_FAILURE });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const getLoanHistory = () => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: LoanActionTypes.GET_LOAN_HISTORY });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const get = await LoanServices.getLoanHistory();

      batch(() => {
        dispatch({
          type: LoanActionTypes.GET_LOAN_HISTORY_SUCCESS,
          payload: get.data.loanRequests,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({
          type: LoanActionTypes.GET_LOAN_HISTORY_FAILURE,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const getLoanHDetail = (id: any) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: LoanActionTypes.GET_LOAN_DETAIL });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });
      const get = await LoanServices.getLoanDetail(id);

      batch(() => {
        dispatch({
          type: LoanActionTypes.GET_LOAN_DETAIL_SUCCESS,
          payload: get.data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({
          type: LoanActionTypes.GET_LOAN_DETAIL_FAILURE,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const updateLoanAttachment = (id: number | undefined, url: string) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: LoanActionTypes.UPDATE_LOAN_ATTACHMENT });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const post = await LoanServices.updateLoanAttachment(id, url);

      batch(() => {
        dispatch({ type: LoanActionTypes.UPDATE_LOAN_ATTACHMENT_SUCCESS });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Berhasil mengirim file",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({ type: LoanActionTypes.UPDATE_LOAN_ATTACHMENT_FAILURE });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const cancelLoanRequest = (id: number | undefined) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: LoanActionTypes.CANCEL_LOAN_REQUEST });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const post = await LoanServices.cancelLoanRequest(id);

      batch(() => {
        dispatch({ type: LoanActionTypes.CANCEL_LOAN_REQUEST_SUCCESS });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Berhasil membatalkan pinjaman",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({ type: LoanActionTypes.CANCEL_LOAN_REQUEST_FAILURE });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};
