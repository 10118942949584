import { Icon } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import { getCutiList } from "../../../../../../redux/permit/permit.actions";
import "./cuti.style.scss";
import useCuti from "./useCuti";

const CutiPage = () => {
  const dispatch = useDispatch();
  const [cancelCuti] = useCuti();
  const { leaveList } = useAppSelector((state) => ({
    ...state.permit,
    ...state.clockin.masterData,
    ...state.globalState,
  }));
  const history = useHistory();

  useEffect(() => {
    dispatch(setHeader({ title: "Cuti", hasBack: true }));
    dispatch(getCutiList());
  }, []);

  const generateIcon = (status: string) => {
    switch (status) {
      case "pending":
        return <Icon color="#979757" icon="time" iconSize={20} />;
      case "approved":
        return <Icon color="#6eb96e" icon="tick-circle" iconSize={20} />;
      case "rejected":
        return <Icon color="#c06262" icon="delete" iconSize={20} />;
      case "canceled":
        return <Icon color="#808080" icon="ban-circle" iconSize={20} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="cuti">
      <button
        className="submit-btn"
        onClick={() => {
          history.push(`/attendance/cuti-request`);
        }}
      >
        Request Cuti
      </button>
      <div className="cuti-list">
        <div className="header-list">History Cuti</div>
        {leaveList?.map((item: any) => (
          <div className="list-group" key={item.id}>
            <div className="left">
              <div>{item.leaveCategoryName}</div>
              <div>{`${item.startAt} - ${item.endAt}`}</div>
              <div>{item.description}</div>
            </div>
            <div className="right">
              {generateIcon(item.status)}
              {item.status === "pending" && (
                <button className="btn" onClick={() => cancelCuti(item.id)}>
                  Cancel
                </button>
              )}
            </div>
          </div>
        ))}
        {leaveList?.length === 0 && (
          <div style={{ textAlign: "center" }}>Belum ada data</div>
        )}
      </div>
    </div>
  );
};

export default CutiPage;
