const PermitActionTypes = {
  GET_CUTI_LIST: "GET_CUTI_LIST",
  GET_CUTI_LIST_SUCCESS: "GET_CUTI_LIST_SUCCESS",
  GET_CUTI_LIST_FAILURE: "GET_CUTI_LIST_FAILURE",
  GET_OPTIONS: "GET_OPTIONS",
  GET_OPTIONS_SUCCESS: "GET_OPTIONS_SUCCESS",
  GET_OPTIONS_FAILURE: "GET_OPTIONS_FAILURE",
  SUBMIT_LEAVE: "SUBMIT_LEAVE",
  SUBMIT_LEAVE_SUCCESS: "SUBMIT_LEAVE_SUCCESS",
  SUBMIT_LEAVE_FAILURE: "SUBMIT_LEAVE_FAILURE",
};

export default PermitActionTypes;
