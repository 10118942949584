const RequestsActionTypes = {
  REQUEST: "REQUEST",
  REQUEST_SUCCESS: "REQUEST_SUCCESS",
  REQUEST_FAILURE: "REQUEST_FAILURE",
  BANK_UPDATE: "BANK_UPDATE",
  BANK_UPDATE_SUCCESS: "BANK_UPDATE_SUCCESS",
  BANK_UPDATE_FAILURE: "BANK_UPDATE_FAILURE",
  WAGE_REQUEST: "WAGE_REQUEST",
  WAGE_REQUEST_SUCCESS: "WAGE_REQUEST_SUCCESS",
  WAGE_REQUEST_FAILURE: "WAGE_REQUEST_FAILURE",
  WAGE_CHECK: "WAGE_CHECK",
  WAGE_CHECK_SUCCESS: "WAGE_CHECK_SUCCESS",
  WAGE_CHECK_FAILURE: "WAGE_CHECK_FAILURE",
};

export default RequestsActionTypes;
