import { batch } from "react-redux";
import { PresenceServices, ShiftServices } from "../../_services";
import GlobalActionTypes from "../global/global.types";
import ShiftActionTypes from "./shift.types";

export const getIncomingShift = () => {
  return async (dispatch: any, getState: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ShiftActionTypes.GET_INCOMING_SHIFT });
      });

      const todaySchedule = await ShiftServices.getTodaySchedule();

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ShiftActionTypes.GET_INCOMING_SHIFT_SUCCESS,
          payload: todaySchedule.data,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ShiftActionTypes.GET_INCOMING_SHIFT_FAILURE,
        });
      });
    }
  };
};

export const getCurrentShift = (date: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ShiftActionTypes.GET_CURRENT_SHIFT });
      });

      const currentShift = await ShiftServices.getCurrentShift(date);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ShiftActionTypes.GET_CURRENT_SHIFT_SUCCESS,
          payload: currentShift.data,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ShiftActionTypes.GET_CURRENT_SHIFT_FAILURE,
        });
      });
    }
  };
};

export const unsetCurrentShift = () => {
  return (dispatch: any) => {
    dispatch({ type: ShiftActionTypes.DEFAULT_CURRENT_SHIFT });
  };
};
