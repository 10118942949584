import PresenceActionTypes from "./presence.types";

interface EmployeeI {
  companyID: string;
  id: string;
  name: string;
  pkID: number;
  role: string;
  userID: string;
}

interface PresenceState {
  statusRequestRemote: string;
  employees: Array<EmployeeI>;
  presences: Array<any>;
}

const INITIAL_STATE: PresenceState = {
  statusRequestRemote: "",
  employees: [],
  presences: [],
};

const PresenceReducer = (state = INITIAL_STATE, action: any): PresenceState => {
  switch (action.type) {
    case PresenceActionTypes.GET_PRESENCE_HISTORY:
      return {
        ...state,
        presences: [],
      };
    case PresenceActionTypes.GET_PRESENCE_HISTORY_SUCCESS:
      return {
        ...state,
        presences: action.payload,
      };
    case PresenceActionTypes.GET_PRESENCE_HISTORY_FAILURE:
      return {
        ...state,
        presences: [],
      };
    case PresenceActionTypes.REQUEST_REMOTE:
      return {
        ...state,
        statusRequestRemote: "req",
      };
    case PresenceActionTypes.REQUEST_REMOTE_SUCCESS:
      return {
        ...state,
        statusRequestRemote: "success",
      };
    case PresenceActionTypes.REQUEST_REMOTE_FAILURE:
      return {
        ...state,
        statusRequestRemote: "failure",
      };
    case PresenceActionTypes.GET_SUBORDINATES:
      return {
        ...state,
        employees: [],
      };
    case PresenceActionTypes.GET_SUBORDINATES_SUCCESS:
      return {
        ...state,
        employees: action.payload,
      };
    case PresenceActionTypes.GET_SUBORDINATES_FAILURE:
      return {
        ...state,
        employees: [],
      };
    default:
      return state;
  }
};

export default PresenceReducer;
