import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RegistrationComponentProps } from "../../lib";
import "./index.css";
import { useRegistrationVerifyOtpMutation } from "../../../../backend/app";
import { AuthServices } from "../../../../_services";

export const VerifyOTP: FunctionComponent<RegistrationComponentProps> = ({
  setNextIsDisabled,
  registrationToken,
  setRegistrationToken,
  onNextClickRef,
  invitationPhone,
  setInvitationName,
  setInvitationCompanyName,
}) => {
  const [otpCode, setOtpCode] = useState<string>("");

  useEffect(() => {
    setNextIsDisabled(otpCode.length < 4);
  }, [otpCode, setNextIsDisabled]);

  const [verifyOtp] = useRegistrationVerifyOtpMutation();

  onNextClickRef.current = useCallback(async () => {
    const resp = await AuthServices.claimAccountVerifyOTP(
      invitationPhone,
      otpCode,
    );

    setRegistrationToken(resp.data?.forgetPasswordToken);
    setInvitationName(resp.data?.name);
    setInvitationCompanyName(resp.data?.companyName);
  }, [setRegistrationToken, verifyOtp, registrationToken, otpCode]);

  return (
    <div className="hp">
      <div className="company-tag">Gajiku Inc</div>
      <div className="header">
        <div>Harap masukan Nomor Kode OTP.</div>
      </div>
      <div className="container otp-number">
        <div>Nomor Kode</div>
        <div className="otp-field">
          <input
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            placeholder="Masukan OTP"
          />
        </div>
      </div>
    </div>
  );
};
