import request from "../_network/request";
const attendanceUrl = "attendance";

const getIncomingShift = () => {
  return request({
    url: `${attendanceUrl}/shift/employee/incoming`,
    method: "GET",
  });
};

const getCurrentShift = (currentDate: string) => {
  return request({
    url: `${attendanceUrl}/schedule/employee/date?requestedDate=${currentDate}`,
    method: "GET",
  });
};

const getTodaySchedule = () => {
  return request({
    url: `${attendanceUrl}/schedule/employee/today`,
    method: "GET",
  });
};

export const ShiftServices = {
  getIncomingShift,
  getCurrentShift,
  getTodaySchedule,
};
