import request from "../_network/request";

const uploadImage = (image: string, location: string) => {
  const data = new FormData();
  data.append("file", image);
  data.append("location", location);
  data.append("random-filename", "true");

  // if (image !== "") {
  //   const blobBin = atob(image.split(",")[1]);
  //   const array = [];
  //   for (let i = 0; i < blobBin.length; i++) {
  //     array.push(blobBin.charCodeAt(i));
  //   }
  //   const file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  //   data.append("file", file, `random.jpeg`);
  // }

  return request({
    url: "gajiku-upload/upload",
    method: "POST",
    data: data,
    headers: {
      apikey: process.env.REACT_APP_API_KEY_FILEUPLOAD,
    },
  });
};

const uploadImageFromB64 = (image: string, location: string) => {
  const data = new FormData();
  // data.append("file", image);
  data.append("location", location);
  data.append("random-filename", "true");

  if (image !== "") {
    const blobBin = atob(image.split(",")[1]);
    const array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    const file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    data.append("file", file, `random.jpeg`);
  }

  return request({
    url: "gajiku-upload/upload",
    method: "POST",
    data: data,
    headers: {
      apikey: process.env.REACT_APP_API_KEY_FILEUPLOAD,
    },
  });
};

export const uploadServices = {
  uploadImage,
  uploadImageFromB64,
};
