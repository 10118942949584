import React, { FunctionComponent, useEffect, useState } from "react";
import Select from "react-select";

import { Link, useHistory } from "react-router-dom";
import "./styles.scss";
import { Icon, Dialog, Card, Button } from "@blueprintjs/core";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { getCompanySettings } from "../../../../../../redux/companySetting/companySetting.action";
import {
  generateProduction,
  getGroups,
  getMyProjects,
  setFilter,
} from "../../../../../../redux/productionTracking/productionTracking.actions";
import noDataImg from "../../../../../../assets/no-data.png";

const Home = () => {
  const { title, hasBack } = useAppSelector((state) => state.globalState);
  const [filterProject, setFilterProject] = useState<any>();
  const [projectOptions, setProjectOptions] = useState<Array<any>>([]);
  const [selectedProject, setSelectedProject] = useState<any>();
  const [filterDate, setFilterDate] = useState("");
  const [tempDate, setTempDate] = useState("");
  const [isDialogHistoryOpen, setIsDialogHistoryOpen] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();
  const date = new Date();
  let day: any = date.getDate();
  let month: any = date.getMonth() + 1;
  const year: any = date.getFullYear();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;

  const today = year + "-" + month + "-" + day;

  useEffect(() => {
    dispatch(getCompanySettings());
    dispatch(getMyProjects());

    if (filterData?.date) {
      setFilterDate(filterData?.date);
      dispatch(getGroups(filterData?.project?.value, filterData?.date));
    } else {
      setFilterDate(today);
      dispatch(getGroups(projects[0]?.id, today));
    }
  }, []);

  const { projects, groups, loading, statusRequest, filterData } =
    useAppSelector((state) => state.productionTrackingState);

  useEffect(() => {
    let listProject = {};
    const arrProjectOptions: any = [];

    projects?.map((item: any) => {
      listProject = {
        value: item.id,
        label: item.name,
        role: item.role,
      };

      arrProjectOptions.push(listProject);
    });

    setProjectOptions(arrProjectOptions);
    if (filterData?.project) {
      setSelectedProject(filterData?.project);
      dispatch(getGroups(filterData?.project?.value, filterData?.date));
      setFilterProject(filterData?.project);
    } else {
      setSelectedProject(arrProjectOptions[0]);
      setFilterProject(arrProjectOptions[0]);
    }
  }, [projects]);

  const handleFilterProject = (e: any) => {
    setSelectedProject(e);
    setTempDate(filterDate);
  };

  const handleFilterDate = (e: any) => {
    setFilterDate(e.target.value);
  };

  useEffect(() => {
    if (statusRequest == "successfull") {
      dispatch(getGroups(selectedProject?.value, filterDate));
    }
  }, [statusRequest]);

  const handleSearch = () => {
    setFilterProject(selectedProject);
    dispatch(getGroups(selectedProject?.value, filterDate));
    dispatch(
      setFilter({
        date: filterDate,
        project: selectedProject,
      }),
    );
  };

  const handleGenerateProduction = () => {
    dispatch(
      generateProduction({
        projectId: selectedProject.value,
        productionDate: filterDate,
      }),
    );
  };

  const status = 404;

  return (
    <>
      <div className="homepage-tracking body-content">
        <div className="filter-production">
          <div className="select-product-filter mb-2">
            <label className="mb-2">Pilih Project</label>
            <Select
              options={projectOptions}
              isClearable={true}
              onChange={(e) => handleFilterProject(e)}
              value={selectedProject}
              className="mt-2"
            />
          </div>
          <div className="mb-2">
            <label>Pilih Tanggal</label>
            <input
              type="date"
              className="w-100 mt-2"
              onChange={(e) => handleFilterDate(e)}
              value={filterDate}
            />
          </div>
        </div>
        <Button className="w-100 btn-info btn-lg mb-2" onClick={handleSearch}>
          Cari
        </Button>
        <div className="data-info-wrap">
          {(filterProject?.role == "operator" ||
            filterProject?.role == "worker") && (
            <>
              <div
                className="data-box"
                onClick={() =>
                  groups?.totalWaitForApproval +
                    groups?.totalChecking +
                    groups?.totalApproved >
                  0
                    ? dispatch(
                        getGroups(
                          selectedProject?.value,
                          filterDate,
                          "submitted",
                        ),
                      )
                    : ""
                }
              >
                <h3>
                  {groups
                    ? groups?.totalWaitForApproval +
                      groups?.totalChecking +
                      groups?.totalApproved
                    : "-"}
                </h3>
                <small>Submitted data</small>
              </div>
              <div
                className="data-box"
                onClick={() =>
                  groups?.totalPending > 0
                    ? dispatch(
                        getGroups(
                          selectedProject?.value,
                          filterDate,
                          "pending",
                        ),
                      )
                    : ""
                }
              >
                <h3>{groups ? groups?.totalPending : "-"}</h3>
                <small>Pending data</small>
              </div>
              <div
                className="data-box"
                onClick={() =>
                  groups?.totalNeedRevision > 0
                    ? dispatch(
                        getGroups(
                          selectedProject?.value,
                          filterDate,
                          "need_revision",
                        ),
                      )
                    : ""
                }
              >
                <h3>{groups ? groups?.totalNeedRevision : "-"}</h3>
                <small>Revision data</small>
              </div>
            </>
          )}
          {filterProject?.role == "checker" && (
            <>
              <div
                className="data-box"
                onClick={() =>
                  groups?.totalPending > 0
                    ? dispatch(
                        getGroups(
                          selectedProject?.value,
                          filterDate,
                          "pending_checker",
                        ),
                      )
                    : ""
                }
              >
                <h3>
                  {groups
                    ? groups?.totalPending + groups?.totalNeedRevision
                    : "-"}
                </h3>
                <small>Pending data</small>
              </div>
              <div
                className="data-box"
                onClick={() =>
                  groups?.totalChecking > 0
                    ? dispatch(
                        getGroups(
                          selectedProject?.value,
                          filterDate,
                          "checking",
                        ),
                      )
                    : ""
                }
              >
                <h3>{groups ? groups?.totalChecking : "-"}</h3>
                <small>Uncheck data</small>
              </div>
              <div
                className="data-box"
                onClick={() =>
                  groups?.totalApproved + groups?.totalWaitForApproval > 0
                    ? dispatch(
                        getGroups(
                          selectedProject?.value,
                          filterDate,
                          "checked",
                        ),
                      )
                    : ""
                }
              >
                <h3>
                  {groups
                    ? groups?.totalApproved + groups?.totalWaitForApproval
                    : "-"}
                </h3>
                <small>Checked data</small>
              </div>
            </>
          )}
          {filterProject?.role == "manager" && (
            <>
              <div
                className="data-box"
                onClick={() =>
                  groups?.totalPending +
                    groups?.totalChecking +
                    groups.totalNeedRevision >
                  0
                    ? dispatch(
                        getGroups(
                          selectedProject?.value,
                          filterDate,
                          "pending_manager",
                        ),
                      )
                    : ""
                }
              >
                <h3>
                  {groups
                    ? groups?.totalPending +
                      groups?.totalChecking +
                      groups.totalNeedRevision
                    : "-"}
                </h3>
                <small>Pending data</small>
              </div>
              <div
                className="data-box"
                onClick={() =>
                  groups?.totalWaitForApproval > 0
                    ? dispatch(
                        getGroups(
                          selectedProject?.value,
                          filterDate,
                          "wait_for_approval",
                        ),
                      )
                    : ""
                }
              >
                <h3>{groups ? groups?.totalWaitForApproval : "-"}</h3>
                <small>Need Approval</small>
              </div>
              <div
                className="data-box"
                onClick={() =>
                  groups?.totalApproved > 0
                    ? dispatch(
                        getGroups(
                          selectedProject?.value,
                          filterDate,
                          "APPROVED",
                        ),
                      )
                    : ""
                }
              >
                <h3>{groups ? groups?.totalApproved : "-"}</h3>
                <small>Approved Data</small>
              </div>
            </>
          )}
        </div>
        {groups?.productions?.length === 0 ? (
          <>
            <Button
              className="btn-info btn-lg w-100 mt-2"
              loading={loading}
              onClick={handleGenerateProduction}
            >
              Mulai Produksi
            </Button>
            <div className="operator-container mt-2">
              <div className="text-center">
                <p>Tidak ada data</p>
                <div>
                  <img src={noDataImg} className="w-100" alt="" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="list-data mt-2">
            <div className="operator-container">
              {groups?.productions?.map((item: any, idx: number) => {
                return (
                  <div className="card-custom mt-2 mb-2" key={idx}>
                    <div className="card-custom-header">
                      <div className="d-flex">
                        <h4 className="w-100">{item.group}</h4>
                        {item.status == "CHECKING" && (
                          <small className="text-right w-100 text-warning">
                            Operator Submitted <Icon icon="chevron-down" />
                          </small>
                        )}
                        {item.status == "WAIT_FOR_APPROVAL" && (
                          <small className="text-right w-100 text-warning">
                            Checker Submitted <Icon icon="chevron-down" />
                          </small>
                        )}
                        {item.status == "APPROVED" && (
                          <small className="text-right w-100 text-info">
                            Approved <Icon icon="chevron-down" />
                          </small>
                        )}
                        {item.status == "PENDING" && (
                          <small className="text-right w-100 text-warning">
                            Operator on Input <Icon icon="chevron-down" />
                          </small>
                        )}
                        {item.status == "NEED_REVISION" && (
                          <small className="text-right w-100 text-warning">
                            {filterProject?.role == "checker" &&
                              "Menunggu revisi di operator"}{" "}
                            {(filterProject?.role == "operator" ||
                              (filterProject?.role == "worker" &&
                                "Butuh revisi")) &&
                              "Menunggu revisi di operator"}{" "}
                            <Icon icon="chevron-down" />
                          </small>
                        )}
                        {/* {
                            (filterProject?.role == "checker" && item.status == "PENDING") && (
                              <small className="text-right w-100 text-warning">Operator Submitted <Icon icon="chevron-down" /></small>
                            )
                          } */}
                      </div>
                    </div>
                    <div className="card-custom-body">
                      <div>
                        <p className="text-secondary">Project</p>
                        <h4>{item.itemName}</h4>
                      </div>
                      <p className="text-secondary">Member</p>
                      <ul className="list-member">
                        {item?.members?.map((data: any, idx: number) => {
                          return <li key={idx}>{data.name}</li>;
                        })}
                      </ul>
                      {(filterProject?.role == "checker" ||
                        filterProject?.role == "manager") && (
                        <>
                          <hr />
                          <div className="total-data d-flex">
                            <p className="text-secondary w-100 mb-0">
                              Total Data
                            </p>
                            <h4 className="text-info mb-0">
                              {item.totalProductions}
                            </h4>
                          </div>
                          <div className="total-data d-flex">
                            <p className="text-secondary w-100">
                              Total Produksi
                            </p>
                            <h4 className="text-info text-right w-100">
                              {item.totalValues} {item.uomName}
                            </h4>
                          </div>
                          <hr />
                          {filterProject?.role === "checker" && (
                            <>
                              <div className="total-data d-flex">
                                <p className="text-secondary w-100 mb-0">
                                  Data Approved
                                </p>
                                <h4 className="text-info mb-0">
                                  {item?.totalApproved}
                                </h4>
                              </div>
                              <div className="total-data d-flex">
                                <p className="text-secondary w-100 mb-0">
                                  Data Revision
                                </p>
                                <h4 className="text-info text-right w-100 mb-0">
                                  {item?.totalNeedRevision}
                                </h4>
                              </div>
                              <div className="total-data d-flex">
                                <p className="text-secondary w-100">
                                  Data Uncheck
                                </p>
                                <h4 className="text-info text-right w-100">
                                  {item.totalPending}
                                </h4>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="card-custom-footer">
                      <div className="w-100 text-right">
                        <Button
                          className="btn-warning-outline btn-sm"
                          onClick={() =>
                            history.push(
                              `/production-tracking/form?projectId=${item.projectId}&date=${filterDate}&itemId=${item.itemId}&groupName=${item.group}`,
                            )
                          }
                        >
                          {item?.status != "APPROVED" &&
                            (filterProject?.role == "operator" ||
                              filterProject?.role == "worker") &&
                            "Isi Data"}
                          {item?.status != "APPROVED" &&
                            filterProject?.role == "checker" &&
                            "Detail"}
                          {item?.status != "APPROVED" &&
                            filterProject?.role == "manager" &&
                            "Detail"}
                          {item?.status == "APPROVED" && "Detail"}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <Dialog isOpen={isDialogHistoryOpen}>
        <div
          className="text-right close-icon-modal"
          onClick={() => setIsDialogHistoryOpen(false)}
        >
          &times;
        </div>
        <h4 className="mt-0">Status History</h4>
        <div className="history-list">
          <div className="history-box">
            <div className="history-header">
              <h4>14 April 2022</h4>
            </div>
            <div className="history-body">
              <ul>
                <li>Worker: Submitted</li>
                <li>Operator 2: Revision</li>
                <li>
                  <p>Checker: Revision</p>
                  <small className="text-secondary">
                    Salah isi nama project tolong perbaiki
                  </small>
                </li>
              </ul>
            </div>
          </div>
          <div className="history-box">
            <div className="history-header">
              <h4>13 April 2022</h4>
            </div>
            <div className="history-body">
              <ul>
                <li>Worker: Submitted</li>
                <li>Operator 2: Revision</li>
                <li>
                  <p>Checker: Revision</p>
                  <small className="text-secondary">
                    Salah isi nama project tolong perbaiki
                  </small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Home;
