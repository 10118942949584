import React, { FunctionComponent, useEffect, useState } from "react";
import box_package from "../../../../assets/box_package.svg";
// import box_package from "../../../../assets/box_package.svg";
import list_checked from "../../../../assets/list_checked.svg";
import wallet from "../../../../assets/wallet.svg";
import user_checked from "../../../../assets/user_checked.svg";
import {
  useCompanyEmployeeRetrieveQuery,
  CompanyEmployee,
} from "../../../../backend/app";

import { Link } from "react-router-dom";
import "./styles.scss";
import { Icon, Dialog } from "@blueprintjs/core";
import { useLogin } from "../../../../data/login/api";
import { useAppSelector } from "../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { getCompanySettings } from "../../../../redux/companySetting/companySetting.action";
import { resetFilter } from "../../../../redux/productionTracking/productionTracking.actions";

export const HomePage: FunctionComponent = () => {
  const [, loginAuth] = useLogin();

  const [isOpen, setIsOpen] = useState(false);
  const [permission, setPermission] = useState(false);
  const [text, setText] = useState<string>("");
  const {
    name,
    attendanceAllowed,
    loan_management_enabled,
    production_tracking_enabled,
  } = useAppSelector((state) => ({
    ...state.requestState,
    ...state.companySettingState,
  }));

  const toggleCloseDialog = (type: boolean, text: string) => {
    setIsOpen(type);
    setText(text);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanySettings());
  }, []);

  return (
    <>
      <Dialog isOpen={isOpen} className="dialog-forbidden">
        <div className="forbidden-box">
          <h2>Oops!</h2>
          <Icon icon="warning-sign" />
          <p>{text}</p>
          <button onClick={() => toggleCloseDialog(false, "")}>Tutup</button>
        </div>
      </Dialog>
      <div className="jumbotron"></div>
      <div className="homepage-wrapper">
        <div className="user">
          <div className="user-pict">
            {/* <img
              src="https://i.kym-cdn.com/photos/images/newsfeed/000/110/672/1301700505625.jpg"
              alt=""
            /> */}
          </div>
          <div className="bell-icon">
            <Icon icon="notifications" />
          </div>
        </div>
        <div className="name">
          <h2>Hi, {name}</h2>
        </div>
        <div className="carding-wrap">
          <Link to={"/dashboard/2"}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-between">
                  <div className="desc">
                    <strong>
                      <h3>Gaji On-Demand</h3>
                    </strong>
                    <small className="text-secondary">
                      Tarik gaji Anda hari ini.
                    </small>
                  </div>
                  <div className="icon-card">
                    <img src={wallet} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          {loan_management_enabled && (
            <Link to={"/loan/"}>
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-between">
                    <div className="desc">
                      <strong>
                        <h3>Koperasi Digital</h3>
                      </strong>
                      <small className="text-secondary">
                        Lacak dan catat KPI Anda.
                      </small>
                    </div>
                    <div className="icon-card">
                      <img src={list_checked} />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
          {attendanceAllowed && (
            <Link
              to={
                // false || loginAuth.level === "COMPANY_MANAGER"
                // ?
                "/attendance/"
                // : "#"
              }
              onClick={() =>
                toggleCloseDialog(
                  false,
                  "Kontak gajiku untuk daftar fitur Attendance.",
                )
              }
            >
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-between">
                    <div className="desc">
                      <strong>
                        <h3>Attendance</h3>
                      </strong>
                      <small className="text-secondary">
                        Absen masuk di jam Anda.
                      </small>
                    </div>
                    <div className="icon-card">
                      <img src={user_checked} />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
          {production_tracking_enabled && (
            <Link
              to={"/production-tracking/"}
              onClick={() => dispatch(resetFilter())}
            >
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-between">
                    <div className="desc">
                      <strong>
                        <h3>Input Produksi</h3>
                      </strong>
                      <small className="text-secondary">
                        Input & track produksi
                      </small>
                    </div>
                    <div className="icon-card">
                      <img src={list_checked} />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
