import { Intent } from "@blueprintjs/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import { ClockInBySpvI, ClockInSelfieI } from "../../../../../../_interfaces";
import { PresenceServices } from "../../../../../../_services";

const useClockIn = () => {
  const dispatch = useDispatch();
  const hist = useHistory();

  const doPresence = async (payload: ClockInSelfieI) => {
    try {
      dispatch(showLoading());
      const presence = await PresenceServices.clockInSelfie(payload);
      dispatch(hideLoading());
      dispatch(showAlert("Absen masuk berhasil", Intent.SUCCESS));
      hist.push("/attendance");
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  return [doPresence];
};

export default useClockIn;
