/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {
  setHeader,
  unsetCaptureImage,
  unsetLocation,
} from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import {
  getIncomingShift,
  unsetCurrentShift,
} from "../../../../../../redux/shift/shift.actions";
import "./home.style.scss";

const Home = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [greetings, setGreetings] = useState("");

  const { todaySchedule, isSupervisor, name } = useAppSelector((state) => ({
    ...state.shift,
    ...state.requestState,
    ...state.globalState,
  }));

  useEffect(() => {
    dispatch(setHeader({ title: "Attendance", hasBack: true }));
    dispatch(unsetCaptureImage());
    dispatch(unsetLocation());
    dispatch(getIncomingShift());
    dispatch(unsetCurrentShift());
    const today = new Date();
    if (today.getHours() < 12) {
      setGreetings("Good Morning");
    } else if (today.getHours() < 18) {
      setGreetings("Good Afternoon");
    } else {
      setGreetings("Good Evening");
    }
  }, []);

  const getTime = (date: Date): string => {
    const time = date.toLocaleDateString("id-ID", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "Asia/Jakarta",
    });
    return time.split(" ")[1].replaceAll(".", ":");
  };

  const generateFromTo = (
    start: string | undefined,
    end: string | undefined,
  ): string => {
    if (start && end) {
      const startTime = getTime(new Date(start));
      const endTime = getTime(new Date(end));
      return `${startTime} - ${endTime}`;
    }

    return "";
  };

  return (
    <div className="home">
      <div className="profile-container">
        <div className="greeting">{greetings}</div>
        <div className="name">{name}</div>
      </div>
      <div className="upcoming-trip">
        <div className="card">
          <div className="trip-detail">
            <div className="detail">
              <div className="desc">Your Schedule</div>
              <div className="time">{todaySchedule?.shiftName || "------"}</div>
              <div className="from-to">
                {generateFromTo(
                  todaySchedule?.startTime,
                  todaySchedule?.endTime,
                )}
              </div>
            </div>
          </div>
          {(!todaySchedule?.hasClockIn || !todaySchedule?.hasClockOut) && (
            <button
              className="button"
              onClick={() => {
                // if (locationPermission) {
                history.push(`${path}choose-option`);
                // } else {
                //   window.parent.postMessage("reqLocationPermission", "*");
                // }
              }}
            >
              {todaySchedule?.hasClockIn
                ? "Absen Keluar dengan selfie"
                : "Absen Masuk dengan selfie"}
            </button>
          )}
          {(todaySchedule?.hasPermission ||
            todaySchedule?.hasClockIn ||
            todaySchedule?.hasClockOut) && (
            <table className="presence-info">
              {todaySchedule.hasPermission && (
                <tr>
                  <td>Izin</td>
                  <td>
                    {generateFromTo(
                      todaySchedule.permissionStartTime,
                      todaySchedule.permissionEndTime,
                    )}
                  </td>
                  <td>
                    {todaySchedule.permissionApproved ? "Approved" : "Pending"}
                  </td>
                </tr>
              )}
              <tr>
                <td>Jam Presensi</td>
                <td>
                  {`${getTime(new Date(todaySchedule.presenceClockIn))}
                   - 
                  ${
                    todaySchedule.presenceClockOut !== ""
                      ? getTime(new Date(todaySchedule.presenceClockOut))
                      : "__:__:__"
                  }`}
                </td>
                <td className="flex">
                  <div>{todaySchedule.presenceType.toUpperCase()}</div>
                  {todaySchedule.presenceType.toUpperCase() === "PENDING" && (
                    <div className="status">
                      {todaySchedule.presenceApproved}
                    </div>
                  )}
                </td>
              </tr>
            </table>
          )}
        </div>
      </div>
      <div className="card-presence">
        <div className="presence">
          <div
            className="card-icon-text"
            onClick={() => {
              history.push(`${path}outstation`);
            }}
          >
            <div className="icon">
              <Icon color="#fff" icon="time" iconSize={30} />
            </div>
            <label>Remote</label>
          </div>
          <div
            className="card-icon-text"
            onClick={() => history.push(`${path}permit`)}
          >
            <div className="icon">
              <Icon color="#fff" icon="list-detail-view" iconSize={30} />
            </div>
            <label>Izin</label>
          </div>
          <div
            className="card-icon-text"
            onClick={() => history.push(`${path}presence-history`)}
          >
            <div className="icon">
              <Icon color="#fff" icon="history" iconSize={30} />
            </div>
            <label>Presensi</label>
          </div>
          {isSupervisor && (
            <>
              <div
                className="card-icon-text"
                onClick={() => history.push(`${path}employee-presence`)}
              >
                <div className="icon">
                  <Icon color="#fff" icon="exchange" iconSize={30} />
                </div>
                <label>Presensi Bawahan</label>
              </div>
              <div
                className="card-icon-text"
                onClick={() => history.push(`${path}scan-qr`)}
              >
                <div className="icon">
                  <Icon color="#fff" icon="array-timestamp" iconSize={30} />
                </div>
                <label>Scan QR</label>
              </div>
              <div className="card-icon-text empty" />
            </>
          )}
        </div>
      </div>
      <div className="button-container">
        {isSupervisor && (
          <div className="btn" onClick={() => history.push(`${path}approval`)}>
            <div className="text-section">
              <div className="title">Approval</div>
              {/* <div className="desc">Lorem ipsum dolor sit amet</div> */}
            </div>
            <Icon color="#fff" icon="form" iconSize={30} />
          </div>
        )}
        <div className="btn" onClick={() => history.push(`${path}overtime`)}>
          <div className="text-section">
            <div className="title">Pengajuan Lembur</div>
            {/* <div className="desc">Lorem ipsum dolor sit amet</div> */}
          </div>
          <Icon color="#fff" icon="time" iconSize={30} />
        </div>
        <div className="btn" onClick={() => history.push(`${path}cuti`)}>
          <div className="text-section">
            <div className="title">Pengajuan Cuti</div>
            {/* <div className="desc">Lorem ipsum dolor sit amet</div> */}
          </div>
          <Icon color="#fff" icon="list-detail-view" iconSize={30} />
        </div>
      </div>
    </div>
  );
};

export default Home;
