import { TodayScheduleI } from "../../_interfaces";
import ProductionTrackingActionTypes from "./productionTracking.types";

interface ProductionTrackingState {
  projects: any[];
  groups: any;
  loading: boolean;
  failure: boolean;
  statusRequest: string;
  logs: any;
  productionLogs: any;
  filterData: any;
}

const INITIAL_STATE: ProductionTrackingState = {
  projects: [],
  groups: {},
  loading: false,
  failure: false,
  statusRequest: "",
  logs: {},
  productionLogs: {},
  filterData: {},
};

const ProductionTrackingReducer = (
  state = INITIAL_STATE,
  action: any,
): ProductionTrackingState => {
  switch (action.type) {
    case ProductionTrackingActionTypes.GET_MY_PROJECT:
      return {
        ...state,
        loading: true,
      };
    case ProductionTrackingActionTypes.GET_MY_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        projects: action.payload,
      };
    case ProductionTrackingActionTypes.GET_MY_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
      };
    case ProductionTrackingActionTypes.GET_GROUP_BY_PROJECT:
      return {
        ...state,
        loading: true,
      };
    case ProductionTrackingActionTypes.GET_GROUP_BY_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        groups: action.payload,
      };
    case ProductionTrackingActionTypes.GET_GROUP_BY_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
      };
    case ProductionTrackingActionTypes.GENERATE_PRODUCTION:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.GENERATE_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "successfull",
      };
    case ProductionTrackingActionTypes.GENERATE_PRODUCTION_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.GET_LOG_ITEM:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.GET_LOG_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "success",
        logs: action.payload,
        productionLogs: action.payload,
      };
    case ProductionTrackingActionTypes.GET_LOG_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.CREATE_LOG_ITEM:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.CREATE_LOG_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "successfull",
      };
    case ProductionTrackingActionTypes.CREATE_LOG_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.UPDATE_LOG_ITEM:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.UPDATE_LOG_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "successfull",
      };
    case ProductionTrackingActionTypes.UPDATE_LOG_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.SEND_TO_CHECKER:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.SEND_TO_CHECKER_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "successfull",
      };
    case ProductionTrackingActionTypes.SEND_TO_CHECKER_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.APPROVE_INPUT:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.APPROVE_INPUT_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "successfull",
      };
    case ProductionTrackingActionTypes.APPROVE_INPUT_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.REJECT_INPUT:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.REJECT_INPUT_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "successfull",
      };
    case ProductionTrackingActionTypes.REJECT_INPUT_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.SEND_TO_MANAGER:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.SEND_TO_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "successfull",
      };
    case ProductionTrackingActionTypes.SEND_TO_MANAGER_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.DELETE_INPUT:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.DELETE_INPUT_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "successfull",
      };
    case ProductionTrackingActionTypes.DELETE_INPUT_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.MANAGER_SUBMIT:
      return {
        ...state,
        loading: true,
        statusRequest: "",
      };
    case ProductionTrackingActionTypes.MANAGER_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        statusRequest: "successfull",
      };
    case ProductionTrackingActionTypes.MANAGER_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        statusRequest: "failed",
      };
    case ProductionTrackingActionTypes.SET_FILTER:
      return {
        ...state,
        filterData: action.payload,
      };
    case ProductionTrackingActionTypes.RESET_FILTER:
      return {
        ...state,
        filterData: {},
      };
    default:
      return state;
  }
};

export default ProductionTrackingReducer;
