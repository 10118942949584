/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Icon } from "@blueprintjs/core";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Webcam from "react-webcam";
import {
  setCaptureImage,
  setHeader,
} from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import usePermissions from "../../../../../../_helper/usePermissions";
import "./take-picture.style.scss";

const TakePicture = () => {
  const dispatch = useDispatch();
  const cameraRef = useRef<any>(null);
  const history = useHistory();
  const { cameraPermission } = useAppSelector((state) => state.globalState);
  const { reqCameraPermission } = usePermissions();

  useEffect(() => {
    dispatch(setHeader({ title: "Ambil Foto Selfie", hasBack: true }));
    reqCameraPermission();
  }, []);

  const capture = useCallback(() => {
    const image = cameraRef.current.getScreenshot();
    dispatch(setCaptureImage(image));
    history.goBack();
  }, [cameraRef]);

  return (
    <div className="selfie-page">
      {cameraPermission ? (
        <Webcam
          className="camera-container"
          screenshotFormat="image/jpeg"
          ref={cameraRef}
        />
      ) : (
        <div className="camera-deny">
          Izinkan kamera untuk melanjutkan absen
        </div>
      )}
      <Button
        className="take-picture-btn"
        onClick={capture}
        disabled={!cameraPermission}
      >
        <Icon icon="camera" color="#fff" />
        {`Ambil foto`}
      </Button>
    </div>
  );
};

export default TakePicture;
