const LoanActionTypes = {
  GET_LOAN_REQUEST: "GET_LOAN_REQUEST",
  GET_LOAN_REQUEST_SUCCESS: "GET_LOAN_REQUEST_SUCCESS",
  GET_LOAN_REQUEST_FAILURE: "GET_LOAN_REQUEST_FAILURE",

  CREATE_LOAN_REQUEST: "CREATE_LOAN_REQUEST",
  CREATE_LOAN_REQUEST_SUCCESS: "CREATE_LOAN_REQUEST_SUCCESS",
  CREATE_LOAN_REQUEST_FAILURE: "CREATE_LOAN_REQUEST_FAILURE",

  GET_LOAN_HISTORY: "GET_LOAN_HISTORY",
  GET_LOAN_HISTORY_SUCCESS: "GET_LOAN_HISTORY_SUCCESS",
  GET_LOAN_HISTORY_FAILURE: "GET_LOAN_HISTORY_FAILURE",

  GET_LOAN_DETAIL: "GET_LOAN_DETAIL",
  GET_LOAN_DETAIL_SUCCESS: "GET_LOAN_DETAIL_SUCCESS",
  GET_LOAN_DETAIL_FAILURE: "GET_LOAN_DETAIL_FAILURE",

  UPDATE_LOAN_ATTACHMENT: "UPDATE_LOAN_ATTACHMENT",
  UPDATE_LOAN_ATTACHMENT_SUCCESS: "UPDATE_LOAN_ATTACHMENT_SUCCESS",
  UPDATE_LOAN_ATTACHMENT_FAILURE: "UPDATE_LOAN_ATTACHMENT_FAILURE",

  CANCEL_LOAN_REQUEST: "CANCEL_LOAN_REQUEST",
  CANCEL_LOAN_REQUEST_SUCCESS: "CANCEL_LOAN_REQUEST_SUCCESS",
  CANCEL_LOAN_REQUEST_FAILURE: "CANCEL_LOAN_REQUEST_FAILURE",
};

export default LoanActionTypes;
