import { Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import {
  LeaveDataFormatted,
  LeaveDataRes,
} from "../../../../../../_interfaces";
import { LeaveServices } from "../../../../../../_services/leave.services";

const useLeave = (
  initialValue: Array<any>,
): [
  Array<any>,
  () => void,
  (ids: Array<number>) => void,
  (id: number) => void,
  (id: number, reason: string) => void,
] => {
  const dispatch = useDispatch();
  const [data, setData] = useState(initialValue);

  const getData = async () => {
    try {
      dispatch(showLoading());
      const leaves = await LeaveServices.getSubordinateLeave();
      const formatted: Array<LeaveDataFormatted> =
        leaves.data.leaveRequests.map(
          (item: LeaveDataRes): LeaveDataFormatted => {
            const startAt = new Date(item.startAt);
            const endAt = new Date(item.endAt);
            return {
              ...item,
              type: item.leaveCategoryName,
              date: item.startAt,
              description: item.description,
              startAt: startAt.toLocaleDateString("id-ID", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }),
              endAt: endAt.toLocaleDateString("id-ID", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }),
            };
          },
        );
      setData(formatted);
      dispatch(hideLoading());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const bulk = async (ids: Array<number>) => {
    try {
      dispatch(showLoading());
      const bulk = await LeaveServices.approveBulkLeave(ids);
      dispatch(hideLoading());
      dispatch(showAlert("Success approve", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const approve = async (id: number) => {
    try {
      dispatch(showLoading());
      const action = await LeaveServices.approveLeave(id);
      dispatch(hideLoading());
      dispatch(showAlert("Success approve", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const reject = async (id: number, reason: string) => {
    try {
      dispatch(showLoading());
      const action = await LeaveServices.rejectLeave(id, reason);
      dispatch(hideLoading());
      dispatch(showAlert("Success reject", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  return [data, getData, bulk, approve, reject];
};

export default useLeave;
