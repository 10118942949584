/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Datepicker from "../../components/date-picker";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import "./presence.style.scss";
import { useDispatch } from "react-redux";
import { getSubordinates } from "../../../../../../redux/presence/presence.actions";
import { ClockInBySpvI } from "../../../../../../_interfaces";
import { getCurrentShift } from "../../../../../../redux/shift/shift.actions";
import usePresence from "./usePresence";

const EmployeePresencePage = () => {
  const dispatch = useDispatch();
  const { employees, currentShift } = useAppSelector((state) => ({
    ...state.presence,
    ...state.shift,
  }));
  const [form, setForm] = useState<ClockInBySpvI>({
    subordinateId: "",
    scheduleId: 0,
    latitude: 0,
    longitude: 0,
    photo: "",
  });
  const [doPresence] = usePresence();
  const [selectedDate, setSelectedDate] = useState("");
  const [presenceType, setPresenceType] = useState("clock-in");
  const [presenceAt, setPresenceAt] = useState("");

  useEffect(() => {
    dispatch(setHeader({ title: "Supervisor Absen", hasBack: true }));
    dispatch(getSubordinates());
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setForm((state) => ({
          ...state,
          longitude: pos.coords.longitude,
          latitude: pos.coords.latitude,
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  }, []);

  useEffect(() => {
    if (selectedDate !== "") {
      dispatch(getCurrentShift(selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    setForm((temp) => ({
      ...temp,
      scheduleId: currentShift?.id || -1,
    }));
  }, [currentShift]);

  const handleChange = (e: any) => {
    let value = e.target.value;
    if (e.target.name === "shiftDetailId") value = parseInt(e.target.value);
    setForm((temp) => ({ ...temp, [e.target.name]: value }));
  };

  const handleSubmit = () => {
    doPresence(
      form,
      presenceType,
      `${selectedDate}T${presenceAt}:00.000+07:00`,
    );
  };

  return (
    <div className="permit">
      <div className="form-group">
        <label>Karyawan</label>
        <select className="select" name="subordinateId" onChange={handleChange}>
          <option hidden value="">
            Pilih Karyawan
          </option>
          {employees?.map((opt, i: number) => (
            <option key={i} value={opt.id}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Tanggal Hadir</label>
        <input
          type="date"
          name="date_from"
          id="datefrom"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Type</label>
        <select
          className="select"
          value={presenceType}
          onChange={(e) => setPresenceType(e.target.value)}
        >
          <option value="clock-in">Clock In</option>
          <option value="clock-out">Clock Out</option>
        </select>
      </div>
      <div className="form-group">
        <label>Jam</label>
        <input
          type="time"
          placeholder="Jam keluar"
          value={presenceAt}
          onChange={(e) => setPresenceAt(e.target.value)}
        />
      </div>
      <textarea placeholder="Keterangan" name="desc" />
      <button
        onClick={() => handleSubmit()}
        className="submit-btn"
        disabled={presenceAt === ""}
      >
        Submit
      </button>
    </div>
  );
};

export default EmployeePresencePage;
