import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { createOvertime } from "../../../../../../redux/overtime/overtime.actions";
import ButtonImage from "../../components/button-selfie";
import { OverTimeInterface } from "../../../../../../_interfaces";
import { Button } from "@blueprintjs/core";
import useUpload from "../../../../../../_helper/useUpload";
import { getCurrentShift } from "../../../../../../redux/shift/shift.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import useOvertime from "./useOvertime";
import usePermissions from "../../../../../../_helper/usePermissions";

const OvertimeRequest = () => {
  const params: any = useParams();
  const [formData, setFormData] = useState<OverTimeInterface>({
    overtimeType: "workday",
    description: "",
    startAt: "",
    endAt: "",
    photos: "",
    scheduleId: 0,
  });
  const dispatch = useDispatch();
  const [fileUrl, upload] = useUpload();
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const { currentShift, filePermission } = useAppSelector((state) => ({
    ...state.shift,
    ...state.globalState,
  }));
  const { reqFilePermission } = usePermissions();
  const [, submitOvertime] = useOvertime();

  useEffect(() => {
    dispatch(setHeader({ title: "Lembur", hasBack: true }));
    const currentDate = new Date().toISOString().split("T")[0];
    setFormData((temp) => ({
      ...temp,
      startAt: `${currentDate}T15:00:00`,
      endAt: `${currentDate}T16:00:00`,
    }));
    // reqFilePermission();
  }, []);

  useEffect(() => {
    setFormData((temp) => ({ ...temp, photos: fileUrl }));
  }, [fileUrl]);

  useEffect(() => {
    if (date !== "") dispatch(getCurrentShift(date));
  }, [date]);

  useEffect(() => {
    setFormData((temp) => ({
      ...temp,
      scheduleId: currentShift?.id,
    }));
  }, [currentShift]);

  const handleChange = (e: any) => {
    setFormData((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  const handleUploadFiles = (files: any) => {
    if (files.length > 0) {
      upload(files[0], "overtime");
    }
  };

  return (
    <div className="overtime-page">
      <div className="form-group">
        <label htmlFor="dateRequest">Type lembur</label>
        <select
          className="select"
          name="overtimeType"
          value={formData.overtimeType}
          onChange={handleChange}
        >
          <option value="workday">Work Day</option>
          <option value="offday">Holiday</option>
        </select>
      </div>
      {formData.overtimeType === "workday" && (
        <>
          <div className="form-group">
            <label htmlFor="startAt">Tanggal Lembur</label>
            <input
              type="date"
              id="date"
              name="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          {currentShift?.startTime ? (
            <div className="schedule">
              <label>Shift Anda</label>
              <div>
                {new Date(currentShift?.startTime).toLocaleDateString("id-ID", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  timeZone: "Asia/Jakarta",
                })}{" "}
                -{" "}
                {new Date(currentShift?.endTime).toLocaleDateString("id-ID", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  timeZone: "Asia/Jakarta",
                })}
              </div>
            </div>
          ) : (
            date !== "" && (
              <div className="schedule error">
                Tidak ada shift di tanggal {date}
              </div>
            )
          )}
        </>
      )}
      <div className="form-group">
        <label htmlFor="startAt">Jam masuk</label>
        <input
          type="datetime-local"
          id="startAt"
          name="startAt"
          value={formData?.startAt}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="endTime">Jam keluar</label>
        <input
          type="datetime-local"
          id="endAt"
          name="endAt"
          value={formData?.endAt}
          min={formData?.startAt}
          onChange={handleChange}
        />
      </div>
      <div className="upload-photo">
        <input
          type="file"
          id="file-izin"
          accept="*"
          onChange={(e: any) => handleUploadFiles(e.target.files)}
        />
        <div className="label">Unggah File</div>
        <label htmlFor="file-izin" className="file-box">
          <p className="text">
            {formData.photos !== "" ? formData.photos : "Pilih File"}
          </p>
          <div className="btn">Browse</div>
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="description">Alasan lembur</label>
        <textarea
          id="description"
          name="description"
          value={formData?.description}
          onChange={handleChange}
        />
      </div>
      {!filePermission && (
        <p className="error">Izinkan akses file untuk melanjutkan</p>
      )}
      <button
        className="submit-btn"
        onClick={() => submitOvertime(formData)}
        disabled={formData.description === "" || !filePermission}
      >
        Request
      </button>
    </div>
  );
};

export default OvertimeRequest;
