import { LeaveRecord } from "../_interfaces";
import request from "../_network/request";
const leaveUrl = "leave";
const leaveRecordUrl = "leave_record";
const approvalUrl = "approval";

const getLeaveOptions = () => {
  return request({
    url: `${leaveUrl}/categories`,
    method: "GET",
  });
};

const getCutiList = () => {
  return request({
    url: `${leaveUrl}/history/my`,
    method: "GET",
  });
};

const getApprovalLeave = () => {
  return request({
    url: `${leaveRecordUrl}/by_user`,
    method: "GET",
  });
};

const submitLeave = ({ payload }: { payload: LeaveRecord }) => {
  // const data = new FormData();

  // data.append("approval_id", payload.approval_id);
  return request({
    url: `${leaveUrl}/request/create`,
    method: "POST",
    data: payload,
  });
};

const createApproval = () => {
  return request({
    url: approvalUrl,
    method: "GET",
  });
};

const cancelCuti = (id: number) => {
  return request({
    url: `${leaveUrl}/request/cancel/${id}`,
    method: "POST",
  });
};

const getSubordinateLeave = () => {
  return request({
    url: `${leaveUrl}/history/subordinate`,
    method: "GET",
  });
};

const approveLeave = (id: number) => {
  return request({
    url: `${leaveUrl}/request/approve/${id}`,
    method: "POST",
  });
};

const rejectLeave = (id: number, rejectReason: string) => {
  return request({
    url: `${leaveUrl}/request/reject/${id}`,
    method: "POST",
    data: { rejectReason },
  });
};

const approveBulkLeave = (ids: Array<number>) => {
  return request({
    url: `${leaveUrl}/request/approve-bulk`,
    method: "POST",
    data: { requestIds: ids },
  });
};

export const LeaveServices = {
  getLeaveOptions,
  submitLeave,
  createApproval,
  getApprovalLeave,
  getCutiList,
  cancelCuti,
  getSubordinateLeave,
  approveLeave,
  rejectLeave,
  approveBulkLeave,
};
