import { Intent } from "@blueprintjs/core";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import { getPresenceHistory } from "../../../../../../redux/presence/presence.actions";
import { PresenceServices } from "../../../../../../_services";

const usePresence = () => {
  const dispatch = useDispatch();

  const cancelRemote = async (id: number) => {
    try {
      dispatch(showLoading());
      const presence = await PresenceServices.cancelRemote(id);
      dispatch(showAlert("Cancel remote success", Intent.SUCCESS));
      dispatch(getPresenceHistory());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  return [cancelRemote];
};

export default usePresence;
