const OvertimeActionTypes = {
  GET_OVERTIME: "GET_OVERTIME",
  GET_OVERTIME_SUCCESS: "GET_OVERTIME_SUCCESS",
  GET_OVERTIME_FAILURE: "GET_OVERTIME_FAILURE",
  CREATE_OVERTIME: "CREATE_OVERTIME",
  CREATE_OVERTIME_SUCCESS: "CREATE_OVERTIME_SUCCESS",
  CREATE_OVERTIME_FAILURE: "CREATE_OVERTIME_FAILURE",
};

export default OvertimeActionTypes;
