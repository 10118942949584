import { Intent } from "@blueprintjs/core";
import { batch } from "react-redux";
import { LeaveRecord } from "../../_interfaces";
import { LeaveServices } from "../../_services/leave.services";
import GlobalActionTypes from "../global/global.types";
import PermitActionTypes from "./permit.types";

export const getCutiList = () => {
  return async (dispatch: any, getState: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: PermitActionTypes.GET_CUTI_LIST });
      });

      const cutiList = await LeaveServices.getCutiList();

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PermitActionTypes.GET_CUTI_LIST_SUCCESS,
          payload: cutiList.data.leaveRequests,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({ type: PermitActionTypes.GET_CUTI_LIST_FAILURE });
      });
    }
  };
};

export const getOptions = () => {
  return async (dispatch: any, getState: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: PermitActionTypes.GET_OPTIONS });
      });

      const options = await LeaveServices.getLeaveOptions();

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PermitActionTypes.GET_OPTIONS_SUCCESS,
          payload: options.data.categories,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({ type: PermitActionTypes.GET_OPTIONS_FAILURE });
      });
    }
  };
};

export const createLeaveRecord = (payload: LeaveRecord) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING, payload: payload });
        dispatch({ type: PermitActionTypes.SUBMIT_LEAVE });
      });

      const create = await LeaveServices.submitLeave({
        payload,
      });

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PermitActionTypes.SUBMIT_LEAVE_SUCCESS,
          payload: create,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Pengajuan cuti berhasil",
            alertType: Intent.SUCCESS,
          },
        });
      });
      window.history.back();
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PermitActionTypes.SUBMIT_LEAVE_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};
