import RequestActionTypes from "./request.types";
import { batch } from "react-redux";
import GlobalActionTypes from "../global/global.types";
import { RequestServices } from "../../_services";
import { Intent } from "@blueprintjs/core";

export const requestGet = () => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: RequestActionTypes.REQUEST });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const request = await RequestServices.get();
      batch(() => {
        dispatch({
          type: RequestActionTypes.REQUEST_SUCCESS,
          payload: request.data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({ type: RequestActionTypes.REQUEST_FAILURE, payload: error });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const updateBank = (bankCode: string, bankAccountNumber: string) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: RequestActionTypes.BANK_UPDATE });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      await RequestServices.updateBank({
        bankCode,
        bankAccountNumber,
      });
      batch(() => {
        dispatch({
          type: RequestActionTypes.BANK_UPDATE_SUCCESS,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: "Update Success",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({
          type: RequestActionTypes.BANK_UPDATE_FAILURE,
          payload: error,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const wageRequest = (
  amount: number,
  description: string,
  type: string,
) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: RequestActionTypes.WAGE_REQUEST });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      if (type == "gajiku-ewa") {
        await RequestServices.wageRequest({ amount, description });
      } else {
        await RequestServices.wageRequestWallet({ amount, description });
      }

      batch(() => {
        dispatch({
          type: RequestActionTypes.WAGE_REQUEST_SUCCESS,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: "Request Success",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({
          type: RequestActionTypes.WAGE_REQUEST_FAILURE,
          payload: error,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const wageCheck = (amount: number) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: RequestActionTypes.WAGE_CHECK });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const check = await RequestServices.wageCheck({ amount });

      batch(() => {
        dispatch({
          type: RequestActionTypes.WAGE_CHECK_SUCCESS,
          payload: check.data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({
          type: RequestActionTypes.WAGE_CHECK_FAILURE,
          payload: error,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};
