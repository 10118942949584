/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeader } from "../../../../../../redux/global/global.actions";
import usePresence from "./usePresence";
import "./approval.style.scss";
import useLeave from "./useLeave";
import useOvertime from "./useOvertime";
import CardContent from "./CardContent";
import usePermission from "./usePermission";

const Approval = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [listData, setListData] = useState<Array<any>>([]);
  const [form, setForm] = useState<Array<number>>([]);
  const [
    overtimeData,
    getOvertime,
    bulkOvertime,
    approveOvertime,
    rejectOvertime,
  ] = useOvertime([]);
  const [
    presenceData,
    getPresence,
    bulkPresence,
    approvePresence,
    rejectPresence,
  ] = usePresence([]);
  const [
    permissionData,
    getPermission,
    bulkPermission,
    approvePermission,
    rejectPermission,
  ] = usePermission([]);
  const [leaveData, getLeave, bulkLeave, approveLeave, rejectLeave] = useLeave(
    [],
  );

  useEffect(() => {
    dispatch(setHeader({ title: "Approval", hasBack: true }));
  }, []);

  useEffect(() => {
    switch (tab) {
      case 0:
        setListData(presenceData);
        break;
      case 1:
        setListData(leaveData);
        break;
      case 2:
        setListData(overtimeData);
        break;
      case 3:
        setListData(permissionData);
        break;
      default:
        setListData(presenceData);
        break;
    }
    setForm([]);
  }, [overtimeData, leaveData, presenceData, permissionData]);

  useEffect(() => {
    switch (tab) {
      case 0:
        getPresence();
        break;
      case 1:
        getLeave();
        break;
      case 2:
        getOvertime();
        break;
      case 3:
        getPermission();
        break;
      default:
        getPresence();
        break;
    }
    setForm([]);
  }, [tab]);

  const handleCheck = (e: any) => {
    if (e.target.checked) setForm((temp) => [...temp, e.target.value]);
    else
      setForm((temp) => {
        return temp.filter((item) => item !== e.target.value);
      });
  };

  const handleCheckAll = (e: any) => {
    let all: Array<any> = [];
    if (e.target.checked) {
      all = listData.map((item) => {
        return item.date;
      });
    }
    setForm(all);
  };

  const bulk = () => {
    switch (tab) {
      case 0:
        bulkPresence(form);
        break;
      case 1:
        bulkLeave(form);
        break;
      case 2:
        bulkOvertime(form);
        break;
      case 3:
        bulkPermission(form);
        break;
      default:
        break;
    }
  };

  return (
    <div className="approval">
      <div className="tabs">
        <button
          onClick={() => setTab(0)}
          className={`${tab === 0 && "active"}`}
        >
          Presensi
        </button>
        <button
          onClick={() => setTab(1)}
          className={`${tab === 1 && "active"}`}
        >
          Cuti
        </button>
        <button
          onClick={() => setTab(2)}
          className={`${tab === 2 && "active"}`}
        >
          Lembur
        </button>
        <button
          onClick={() => setTab(3)}
          className={`${tab === 3 && "active"}`}
        >
          Izin
        </button>
      </div>
      <div className="list-view">
        <div className="action-group">
          <div className="choose-all">
            <input
              type="checkbox"
              name="all"
              id="all"
              checked={form.length === listData.length}
              onClick={handleCheckAll}
            />
            <label htmlFor="all">Pilih semua</label>
          </div>
          <button className="btn" onClick={bulk}>
            Approve ({form.length})
          </button>
        </div>
        {listData.map((item, i) => (
          <div className="list" key={i}>
            <input
              type="checkbox"
              name="form"
              id={`${i}`}
              value={item.id}
              checked={form.find((c) => c == item.id) ? true : false}
              onChange={handleCheck}
            />
            <CardContent
              item={item}
              i={i}
              tab={tab}
              approveOvertime={approveOvertime}
              rejectOvertime={rejectOvertime}
              approveLeave={approveLeave}
              rejectLeave={rejectLeave}
              approvePresence={approvePresence}
              rejectPresence={rejectPresence}
              approvePermission={approvePermission}
              rejectPermission={rejectPermission}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Approval;
