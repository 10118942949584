import React, { FunctionComponent, useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import "./styles.scss";
import { Icon, Dialog, Card, Button, Collapse } from "@blueprintjs/core";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch, useSelector } from "react-redux";
import { getCompanySettings } from "../../../../../../redux/companySetting/companySetting.action";
import {
  approveInput,
  managerSubmit,
  createLog,
  deleteInput,
  getLogItem,
  getMyProjects,
  rejectInput,
  sendToChecker,
  sendToManager,
  updateLog,
} from "../../../../../../redux/productionTracking/productionTracking.actions";
import Select from "react-select";
import GenerateForm from "./generateForm";

interface CollapsedData {
  item: any;
  collapsibleData: Array<any>;
  role: string;
  logs: any;
  onClickEdit: VoidFunction;
  onClickRevision: VoidFunction;
  loading: boolean;
  index: number;
}

const RenderCollapsedItem = ({
  item,
  collapsibleData,
  role,
  logs,
  onClickRevision,
  onClickEdit,
  loading,
  index,
}: CollapsedData) => {
  const dispatch = useDispatch();
  return (
    <Collapse isOpen={collapsibleData[index]}>
      <h4>ADDITIONAL INFORMATION</h4>
      <GenerateForm fromModal={false} data={item?.additionalInfo} />
      <h4>JUMLAH PRODUKSI</h4>
      {item?.memberValue?.map((d: any, i: number) => {
        return (
          <div key={i}>
            <label>{d.name}</label>
            <div className="d-flex">
              <div className="mr-2 w-100">
                <input
                  type="text"
                  value={d.value}
                  disabled
                  className="form-control w-100"
                />
              </div>
              <div className="text-right w-50">
                <input
                  type="text"
                  value={logs.uom}
                  disabled
                  className="form-control w-100"
                />
              </div>
            </div>
          </div>
        );
      })}
      {(role == "operator" || role == "worker") &&
        logs?.status != "CHECKING" &&
        logs?.status != "WAIT_FOR_APPROVAL" &&
        item.status != "APPROVED" && (
          <Button className="btn-warning-outline w-100" onClick={onClickEdit}>
            Edit
          </Button>
        )}
      {role == "checker" &&
        item.status == "PENDING" &&
        logs?.status != "PENDING" && (
          <div className="d-flex">
            <Button
              className="btn-info w-100 mr-2"
              loading={loading}
              onClick={() => dispatch(approveInput(item.id))}
            >
              Approve
            </Button>
            <Button
              className="btn-warning-outline w-100"
              onClick={onClickRevision}
            >
              Revisi
            </Button>
          </div>
        )}
      {role == "manager" &&
        item.status == "APPROVED" &&
        logs?.status == "WAIT_FOR_APPROVAL" && (
          <div>
            <Button
              className="btn-warning-outline w-100"
              onClick={onClickRevision}
            >
              Revisi
            </Button>
          </div>
        )}
      {(item?.status == "NEED_REVISION" || item?.status == "APPROVED") && (
        <>
          <hr />
          <div className="total-data d-flex">
            <p className="text-secondary w-100">Status</p>
            <h4
              className={`${
                item?.status == "NEED_REVISION"
                  ? "text-danger text-right w-100"
                  : "text-info text-right w-100"
              }`}
            >
              {item?.status == "NEED_REVISION" && "Rejected"}
              {item?.status == "APPROVED" && "Approved"}
            </h4>
          </div>
          {item?.status == "NEED_REVISION" && (
            <div className="total-data d-flex">
              <p className="text-secondary w-100 mt-0">Alasan</p>
              <h4 className="text-info text-right mt-0 w-100 text-danger">
                {item?.rejectReason}
              </h4>
            </div>
          )}
        </>
      )}
    </Collapse>
  );
};

const ListInput = (props: any) => {
  return (
    <div className="card-custom mt-2" key={1}>
      <div className="card-custom-body">
        <div className="d-flex">
          <div className="d-flex w-100">
            <div className="w-100 d-flex" onClick={props.onCollapsed}>
              <h4 className="mr-2 mb-0 mt-0">Input {props.idx + 1}</h4>
              {props.item.status == "NEED_REVISION" && (
                <small className="text-warning">
                  Checker: Request Revision
                </small>
              )}
              {props.item.status == "APPROVED" &&
                props?.logs?.status != "APPROVED" && (
                  <small className="text-info">Checker: Approve</small>
                )}

              {props?.logs?.status == "APPROVED" && (
                <small className="text-info">Data Aproved</small>
              )}
            </div>
            <Icon
              icon={"chevron-down"}
              // icon={"chevron-down"}
              // onClick={() => handleCollapse(props.idx)} />
            />
          </div>
          {props.role == "operator" &&
            props.item?.status != "APPROVED" &&
            (props?.logs?.status == "PENDING" ||
              props?.logs?.status == "NEED_REVISION") && (
              <Icon
                icon={"trash"}
                className="text-danger ml-2"
                onClick={props.modalRemove}
              />
            )}
        </div>
        {props.renderCollapsedItem}
      </div>
    </div>
  );
};

const ModalEdit = React.forwardRef(
  (
    {
      initData,
      onValueChange,
      onTotalChange,
      totalForm,
      data,
      handleSubmit,
      onClose,
      initialId,
      parentData,
      loading,
    }: {
      initData: any[];
      onValueChange?: (param: any) => void;
      onTotalChange?: (param: any) => void;
      totalForm?: any;
      data?: any;
      handleSubmit?: () => void;
      onClose?: () => void;
      initialId: number | null | undefined;
      parentData?: any;
      loading: boolean;
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [tempData, setTempData] = useState({});

    React.useImperativeHandle(ref, () => ({
      show: () => _onShow(),
      hide: () => _onClose(),
    }));

    return (
      <Dialog isOpen={isOpen} className="h-auto form-generate-kpi">
        <h4>Form Data</h4>
        <form onSubmit={handleSubmit}>
          <GenerateForm
            initialValue={initData}
            onValueChange={(value) => {
              if (onValueChange) {
                onValueChange(value);
              }
            }}
            onTotalUomChange={(value) => {
              if (onTotalChange) {
                onTotalChange(value);
              }
            }}
            data={data}
            totalForm={totalForm}
            fromModal={true}
            initialId={initialId}
            parentData={parentData}
          />
          <div className="d-flex mt-2">
            <Button className="mr-2" onClick={_onClose}>
              Tutup
            </Button>
            <Button type="submit" className="btn-info" loading={loading}>
              Simpan
            </Button>
          </div>
        </form>
      </Dialog>
    );

    function _onShow() {
      setIsOpen(true);
    }

    function _onClose() {
      if (onClose) {
        onClose();
      }
      setIsOpen(false);
    }
  },
);

ModalEdit.displayName = "ModalEdit";

const CreateProduct = () => {
  const modalRef: any = React.useRef();

  const { title, hasBack } = useAppSelector((state) => state.globalState);
  const [filterDate, setFilterDate] = useState("");
  const [formModal, setFormModal] = useState(false);
  const [memberModal, setMemberModal] = useState(false);
  const history = useHistory();
  const [listProduction, setListProduction] = useState<Array<any>>([]);
  const [editableData, setEditableData] = useState<Array<any>>([]);
  const [collapsibleData, setCollapsibleData] = useState<Array<any>>([]);

  const [additionalPayload, setAdditionalPayload] = useState<Array<any>>([]);
  const [totalUom, setTotalUom] = useState<Array<any>>([]);
  const [selectedItem, setSelectedItem] = useState<any | null>();
  const [idList, setIdList] = useState<number | null>();
  const dispatch = useDispatch();

  const [list, setList] = useState<any>();
  const [logs, setLogs] = useState<any>();
  const { statusRequest, productionLogs, projects, loading } = useAppSelector(
    (state) => state.productionTrackingState,
  );
  const [isEdit, setIsEdit] = useState(false);
  const [raw, setRaw] = useState<any>();
  const [role, setRole] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [isAllApprove, setIsAllApprove] = useState(false);
  const [isHavePending, setIsHavePending] = useState(false);
  const [isHaveRevisionData, setIsHaveRevisionData] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [idInput, setIdInput] = useState<any | null>();

  const searchParams = new URLSearchParams(window.location.search);
  const projectId = searchParams.get("projectId");
  const dateProject = searchParams.get("date");
  const itemIdProject = searchParams.get("itemId");
  const groupName = searchParams.get("groupName");

  useEffect(() => {
    getData();
    setFilterDate(new Date().toLocaleDateString("id-ID"));
    setListProduction([]);
    dispatch(getMyProjects());
  }, []);

  const getData = async () => {
    try {
      const res: any = await dispatch(
        getLogItem(projectId, dateProject, itemIdProject, groupName),
      );
      const cloneListProd = { ...res, productions: res?.productions };
      const cloneList = { ...res };
      setLogs(cloneListProd);
      setupData(cloneListProd, cloneList);
      setRaw({ ...res });
    } catch (error) {}
  };

  useEffect(() => {
    const approveData = logs?.productions?.some(
      (item: any) => item.status == "APPROVE",
    );
    const pendingData = logs?.productions?.some(
      (item: any) => item.status == "PENDING",
    );
    const revisionData = logs?.productions?.some(
      (item: any) => item.status == "NEED_REVISION",
    );
    setIsAllApprove(approveData);
    setIsHavePending(pendingData);
    setIsHaveRevisionData(revisionData);
  }, [logs]);

  useEffect(() => {
    const getRole: any = projects?.filter((item: any) => item.id == projectId);

    setRole(getRole[0]?.role);
  }, [projects]);

  const arr: any = [];

  const setupData = (data: any, listData: any) => {
    const arrListProd = data?.productions?.map((item: any) => {
      const newListProduction = {
        data: item,
        isCollapse: false,
      };
      return newListProduction;
    });
    setEditableData(arrListProd);

    const collapsed = arrListProd?.map((i: any, idx: number) => {
      if (idx === selectedItem) {
        return true;
      }
      return false;
    });
    setCollapsibleData(collapsed);
  };

  useEffect(() => {
    if (statusRequest == "successfull") {
      modalRef.current.hide();
      setRejectModal(false);
      setModalConfirm(false);
      setRejectReason("");
    }
  }, [statusRequest]);

  const handleSubmit = (e?: any) => {
    e.preventDefault();
    const arrMember: any = [];
    const memberPayload = totalUom?.map((item: any) => {
      const newObj = {
        memberId: item.memberId,
        value: item.value,
      };

      arrMember.push(newObj);
    });

    const data = {
      productionLogId: productionLogs.id,
      additionalInformation: additionalPayload,
      memberValues: arrMember,
    };

    if (isEdit) {
      dispatch(
        updateLog(logs?.productions[selectedItem].id, {
          additionalInformation: additionalPayload,
          memberValues: arrMember,
        }),
      );
    } else {
      dispatch(createLog(data));
    }
  };

  const handleReject = () => {
    dispatch(rejectInput(logs?.productions[selectedItem].id, rejectReason));
  };

  return (
    <>
      <div className="homepage-tracking body-content">
        <div className="card-custom">
          <div className="card-custom-body">
            <div className="form-group">
              <label>Plih Tanggal</label>
              <div>
                <input
                  type="date"
                  value={dateProject ?? ""}
                  disabled
                  className="w-100"
                />
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="w-100">
                <label className="text-secondary">Nama Project</label>
                <h4 className="text-info mt-sm-1 mb-0">{logs?.projectName}</h4>
              </div>
              <div className="w-50">
                <label className="text-secondary">Team</label>
                <h4 className="mt-sm-1 mb-1">{logs?.group}</h4>
              </div>
            </div>
            <hr />
            <div className="d-flex mt-2">
              <div className="w-100">
                <label className="text-secondary">Nama Item</label>
                <h4 className="text-info mt-sm-1 mb-0">{logs?.itemName}</h4>
              </div>
              <div className="w-50">
                <label className="text-secondary">Total</label>
                <h4
                  className="mt-sm-1 mb-1 text-info"
                  onClick={() => setMemberModal(true)}
                >
                  {logs?.totalMemberValue?.reduce(
                    (total: number, item: any) => total + Number(item.value),
                    0,
                  )}
                </h4>
              </div>
            </div>
            <hr />
            <label className="text-secondary">Status</label>
            {logs?.status == "CHECKING" && (
              <>
                <div>
                  <h4 className="mt-sm-1 text-warning mb-0">
                    Sedang di review oleh Checker
                  </h4>
                </div>
              </>
            )}
            {logs?.status == "CHECKING" &&
              (role == "checker" || role == "manager") && (
                <>
                  <div>
                    <h4 className="mt-sm-1 text-warning mb-0">
                      {logs?.status == "NEED_REVISION" && "Butuh Revisi"}
                    </h4>
                  </div>
                </>
              )}
            {logs?.status == "PENDING" && (
              <>
                <div>
                  <h4 className="mt-sm-1 text-warning mb-0">
                    Sedang di input operator
                  </h4>
                </div>
              </>
            )}
            {logs?.status == "WAIT_FOR_APPROVAL" && (
              <>
                <div>
                  <h4 className="mt-sm-1 text-warning mb-0">
                    Menunggu Approval Manager
                  </h4>
                </div>
              </>
            )}
            {logs?.status == "APPROVED" && (
              <>
                <div>
                  <h4 className="mt-sm-1 text-info mb-0">
                    Data sudah di Approve
                  </h4>
                </div>
              </>
            )}
            {logs?.status == "NEED_REVISION" && (
              <>
                <div>
                  <h4 className="mt-sm-1 text-warning mb-0">Butuh Revisi</h4>
                </div>
              </>
            )}
          </div>
        </div>
        {((logs?.status == "PENDING" &&
          (role == "operator" || role == "worker")) ||
          (logs?.status == "NEED_REVISION" &&
            (role == "operator" || role == "worker"))) && (
          <Button
            className="btn-sm btn-info mt-2 w-100"
            onClick={() => {
              modalRef.current.show();
              setSelectedItem(null);
              setIsEdit(false);
            }}
          >
            + Tambah Data
          </Button>
        )}
        {logs?.productions.map((item: any, idx: number) => {
          return (
            <ListInput
              modalRemove={() => {
                setModalConfirm(true);
                setIdInput(item.id);
              }}
              logs={logs}
              onCollapsed={() => onCollapsed(idx)}
              role={role}
              item={item}
              key={idx}
              idx={idx}
              renderCollapsedItem={
                <RenderCollapsedItem
                  item={item}
                  index={idx}
                  collapsibleData={collapsibleData}
                  role={role}
                  logs={logs}
                  onClickEdit={() => {
                    // setFormModal(true)
                    // handleEdit(item)
                    setSelectedItem(idx);
                    setIdList(item.id);
                    setIsEdit(true);
                    setTimeout(() => {
                      modalRef?.current?.show();
                    }, 500);
                  }}
                  onClickRevision={() => {
                    setRejectModal(true);
                    setSelectedItem(idx);
                  }}
                  loading={loading}
                />
              }
            />
          );
        })}
        {logs?.productions?.length === 0 && <h4>Tidak ada data</h4>}
        {logs?.productions?.length > 0 &&
          (logs.status == "PENDING" || logs?.status == "NEED_REVISION") &&
          (role == "operator" || role == "worker") && (
            <div className="mt-2 submit-box">
              <Button
                className="btn-info w-100"
                loading={loading}
                onClick={() => dispatch(sendToChecker(logs.id))}
              >
                Kirim Ke Checker
              </Button>
            </div>
          )}

        {role == "checker" &&
          logs?.status == "CHECKING" &&
          logs?.productions?.length > 0 && (
            <div className="mt-2 submit-box">
              <Button
                onClick={() => dispatch(sendToManager(logs.id))}
                loading={loading}
                disabled={
                  logs?.status == "NEED_REVISION" ||
                  (role == "checker" && isAllApprove) ||
                  (role == "checker" && isHavePending) ||
                  (role == "checker" && isHaveRevisionData && isHavePending)
                }
                className="btn-info w-100"
              >
                {((role == "checker" &&
                  isHaveRevisionData &&
                  !isHavePending &&
                  logs?.status != "PENDING") ||
                  (role == "checker" && isHavePending) ||
                  (role == "checker" && isHaveRevisionData)) &&
                  "Kirim Ke Operator / Worker"}
                {role == "checker" &&
                  !isHaveRevisionData &&
                  !isHavePending &&
                  "Kirim Ke Manager"}
              </Button>
            </div>
          )}
        {role == "manager" &&
          logs?.productions?.length > 0 &&
          logs?.status == "WAIT_FOR_APPROVAL" && (
            <div className="d-flex submit-box mt-2">
              <Button
                className="btn-info w-100"
                onClick={() => dispatch(managerSubmit(logs.id))}
                disabled={
                  (role == "manager" && isAllApprove) ||
                  (role == "manager" && isHavePending) ||
                  (role == "manager" && isHaveRevisionData && isHavePending)
                }
                loading={loading}
              >
                {((role == "manager" && isHaveRevisionData && !isHavePending) ||
                  (role == "manager" && isHavePending) ||
                  (role == "manager" && isHaveRevisionData)) &&
                  "Kirim Ke Operator / Worker"}
                {role == "manager" &&
                  !isHaveRevisionData &&
                  !isHavePending &&
                  "Approve"}
              </Button>
            </div>
          )}
      </div>

      <ModalEdit
        ref={modalRef}
        initData={logs?.productions[selectedItem]}
        totalForm={logs?.totalMemberValue}
        data={logs?.itemAdditionalInformations}
        handleSubmit={handleSubmit}
        parentData={logs}
        onValueChange={(formValue: any) => {
          setAdditionalPayload(formValue);
        }}
        onTotalChange={(totalValue: any) => setTotalUom(totalValue)}
        onClose={() => {
          onCollapsed(selectedItem);
          getData();
        }}
        initialId={idList}
        loading={loading}
      />

      <Dialog isOpen={memberModal} className="h-auto">
        <div
          className="text-right close-icon-modal"
          onClick={() => setMemberModal(false)}
        >
          &times;
        </div>
        <h4 className="mb-0 mt-0">Member</h4>
        <ul className="member-group-list">
          {logs?.totalMemberValue?.map((x: any, i: number) => {
            return (
              <li className="d-flex" key={i}>
                <p>{x.name}</p>
                <p>{x.value}</p>
              </li>
            );
          })}
        </ul>
      </Dialog>

      <Dialog className="h-auto" isOpen={rejectModal}>
        <div>
          <h4>Alasan Revisi</h4>
          <div>
            <small>Tulis Alasan Disini</small>
            <input
              onChange={(e) => setRejectReason(e.target.value)}
              value={rejectReason}
              type="text"
              className="form-control w-100"
            />
          </div>
        </div>
        <div className="d-flex">
          <Button className="mr-2" onClick={() => setRejectModal(false)}>
            Batal
          </Button>
          <Button className="btn-info" onClick={handleReject} loading={loading}>
            Kirim
          </Button>
        </div>
      </Dialog>

      <Dialog className="h-auto" isOpen={modalConfirm}>
        <div>
          <h4>Apakah anda yakin ingin menghapus?</h4>
        </div>
        <div className="d-flex">
          <Button
            className="mr-2"
            onClick={() => {
              setModalConfirm(false);
              setIdInput(null);
            }}
          >
            Batal
          </Button>
          <Button
            className="btn-danger"
            onClick={() => dispatch(deleteInput(idInput))}
            loading={loading}
          >
            Yakin
          </Button>
        </div>
      </Dialog>
    </>
  );

  function onCollapsed(idx: number) {
    const cloneCollapse = [...collapsibleData];
    const newCollapsible = cloneCollapse?.map((i, index) => {
      if (index === idx) {
        i = !i;
      }
      return i;
    });
    setCollapsibleData(newCollapsible);
  }
};

export default CreateProduct;
