import HistoryActionTypes from "./history.types";

interface HistoryState {
  // eslint-disable-next-line @typescript-eslint/ban-types
  wageRequest: any[];
  failure: boolean;
  loading: boolean;
  pagination: any;
}

const INITIAL_STATE: HistoryState = {
  wageRequest: [],
  failure: false,
  loading: false,
  pagination: {},
};

const HistoryReducer = (state = INITIAL_STATE, action: any): HistoryState => {
  switch (action.type) {
    case HistoryActionTypes.HISTORY:
      return {
        ...state,
        loading: true,
      };
    case HistoryActionTypes.HISTORY_SUCCESS:
      return {
        ...state,
        wageRequest: action.payload.wageRequests,
        pagination: action.payload.pagination,
        loading: false,
        failure: false,
      };
    case HistoryActionTypes.HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
      };
    default:
      return state;
  }
};

export default HistoryReducer;
