import { Intent } from "@blueprintjs/core";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import { getOvertimeList } from "../../../../../../redux/overtime/overtime.actions";
import { OverTimeInterface } from "../../../../../../_interfaces";
import { OvertimeServices } from "../../../../../../_services/overtime.services";

const useOvertime = (): [
  (id: number) => void,
  (data: OverTimeInterface) => void,
] => {
  const dispatch = useDispatch();

  const cancelOvertime = async (id: number) => {
    try {
      dispatch(showLoading());
      const cancel = await OvertimeServices.cancelOvertime(id);
      dispatch(showAlert("Cancel overtime success", Intent.SUCCESS));
      dispatch(getOvertimeList());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert("Cancel overtime failure", Intent.DANGER));
    }
  };

  const createOvertime = async (data: OverTimeInterface) => {
    try {
      dispatch(showLoading());
      const { scheduleId, startAt, endAt, description, photos, overtimeType } =
        data;
      const payload: { [key: string]: any } = {};
      payload.startAt = `${startAt}:00.000+07:00`;
      payload.endAt = `${endAt}:00.000+07:00`;
      payload.description = description;
      payload.photos = photos;
      if (overtimeType === "workday") {
        payload.scheduleId = scheduleId;
      }
      const createOvertime = await OvertimeServices.createOvertime(
        payload,
        overtimeType,
      );
      dispatch(hideLoading());
      dispatch(showAlert("Berhasil mengajukan lembur", Intent.SUCCESS));
      window.history.back();
    } catch (error) {
      dispatch(hideLoading());
      dispatch(
        showAlert("Terjadi kesalahan saat mengirim data", Intent.DANGER),
      );
    }
  };

  return [cancelOvertime, createOvertime];
};

export default useOvertime;
