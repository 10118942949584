import { Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../redux/global/global.actions";
import { uploadServices } from "../_services/upload.services";

const useUpload = (): [
  string,
  (image: any, location: string, fromBase64?: boolean) => void,
] => {
  const dispatch = useDispatch();
  const [fileUrl, setFileUrl] = useState("");

  const upload = async (image: any, location: string, fromBase64 = false) => {
    try {
      dispatch(showLoading());
      if (fromBase64) {
        const action = await uploadServices.uploadImageFromB64(image, location);
        setFileUrl(action.data.url);
      } else {
        const action = await uploadServices.uploadImage(image, location);
        setFileUrl(action.data.url);
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("Failure to upload file", Intent.DANGER));
    }
  };

  return [fileUrl, upload];
};

export default useUpload;
