/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import Approval from "../pages/approval/Approval";
import TakePicture from "../pages/camera/TakePicture";
import ClockIn from "../pages/clockIn/ClockIn";
import ClockOut from "../pages/clockOut/ClockOut";
import Home from "../pages/home/Home";
import Maps from "../pages/maps/Maps";
import OutstationForm from "../pages/outstation/Outstation.form";
import PermitForm from "../pages/permit/Permit.form";
import ScanQR from "../pages/scan-qr/ScanQR";
import ChooseClock from "../pages/clockOption/ClockOption";
import RequestShift from "../pages/shiftRequest/ShiftRequests";
import { getMasterData } from "../../../../../redux/clock-in/clockin.actions";
import { useAppSelector } from "../../../../../redux/hooks";
import "./header.style.scss";
import Overtime from "../pages/overtime/Overtime";
import OvertimeRequest from "../pages/overtime/Overtime.form";
import { useDispatch } from "react-redux";
import CutiPage from "../pages/cuti/Cuti";
import EmployeePresencePage from "../pages/employee-presence/Employee-Presence";
import CutiRequest from "../pages/cuti/Cuti.form";
import PresenceHistoryPage from "../pages/presence-history/PresenceHistory";
import PermitPage from "../pages/permit/Permit";

const UserRoutes = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { title, hasBack } = useAppSelector((state) => state.globalState);

  useEffect(() => {
    // dispatch(getMasterData());
  }, []);

  return (
    <div>
      <div className="header">
        {hasBack && (
          <div
            className="back-button"
            onClick={() => {
              if (
                history.location.pathname === "/attendance" ||
                history.location.pathname === "/attendance/"
              )
                history.replace("/dashboard/0");
              else history.goBack();
            }}
          >
            <Icon icon="arrow-left" />
          </div>
        )}
        <div className="title">{title}</div>
      </div>
      <div className="white-space" />
      <div className="body-attendance">
        <Switch>
          <Route exact component={Home} path={`${path}`} />
          <Route exact component={ClockIn} path={`${path}clockIn`} />
          <Route exact component={ClockOut} path={`${path}clockOut`} />
          <Route exact component={PermitForm} path={`${path}permit-request`} />
          <Route exact component={PermitPage} path={`${path}permit`} />
          <Route exact component={CutiPage} path={`${path}cuti`} />
          <Route exact component={CutiRequest} path={`${path}cuti-request`} />
          <Route exact component={OutstationForm} path={`${path}outstation`} />
          <Route exact component={Maps} path={`/attendance/maps`} />
          <Route exact component={TakePicture} path="/attendance/selfie" />
          <Route exact component={Approval} path="/attendance/approval" />
          <Route exact component={ScanQR} path="/attendance/scan-qr" />
          <Route
            exact
            component={EmployeePresencePage}
            path="/attendance/employee-presence"
          />
          <Route
            exact
            component={ChooseClock}
            path="/attendance/choose-option"
          />
          <Route
            exact
            component={RequestShift}
            path="/attendance/shift-request"
          />
          <Route exact component={Overtime} path="/attendance/overtime" />
          <Route
            exact
            component={OvertimeRequest}
            path="/attendance/overtime-request/:date"
          />
          <Route
            exact
            component={PresenceHistoryPage}
            path="/attendance/presence-history"
          />
          <Redirect exact from="**" to={`/attendance/`} />
        </Switch>
      </div>
    </div>
  );
};

export default UserRoutes;
