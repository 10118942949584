import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AnchorButton,
  Button,
  Card,
  Dialog,
  Elevation,
  Icon,
  Intent,
} from "@blueprintjs/core";
import Logo from "../../../assets/logo.svg";
import "./login.css";
import { useHistory } from "react-router";
import { useToastHandler } from "../../../utils/toaster";
import { generateEnterKeyWatcher } from "../../../utils/keys";
import { useLogin } from "../../../data/login/api";
import { useAppSelector } from "../../../redux/hooks";
import { loginRedux } from "../../../redux/auth/auth.actions";
import { useDispatch } from "react-redux";

const LoginPage: FunctionComponent<{
  baseRoute: string;
}> = ({ baseRoute }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const canSubmit = !(username === "" || password === "");

  const toastHandler = useToastHandler();
  const hist = useHistory();
  const [login] = useLogin();
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(loginRedux(username.replace(/^0+/, ""), password));
  };

  const { loading } = useAppSelector((state) => ({
    ...state.globalState,
  }));

  const submitKeyPress = generateEnterKeyWatcher(submit);
  return (
    <div className="login-page">
      <div className="card">
        <Card elevation={Elevation.FOUR}>
          <div className="container logo">
            <img src={Logo} alt="gajiku-logo" />
            <div className="title">Gajiku</div>
          </div>
          <div className="container fill-form">
            <div className="input-container">
              <label htmlFor={"username"}>Nomor Telepon</label>
              <input
                id={"username"}
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                onKeyPress={submitKeyPress}
              />
            </div>
            <div className="input-container">
              <label htmlFor={"password"}>Password</label>
              <input
                id={"password"}
                type={"password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyPress={submitKeyPress}
              />
            </div>
          </div>
          <div className="container button">
            <Button
              className="btn"
              disabled={!canSubmit}
              text="Submit"
              onClick={submit}
              loading={loading}
            />
            {/* <a href="/iforgotmypassword">Lupa Password?</a> */}
          </div>
          <div className="container enquiry">
            <div
              className="question"
              // onClick={() => hist.push(`${baseRoute}/forget-password/1`)}
            >
              {/* Lupa password? */}
            </div>
            <div onClick={() => hist.push(`${baseRoute}/register/1`)}>
              Daftar
              <Icon icon={"arrow-right"} iconSize={10} />
            </div>
          </div>
          {/* <div className="container youtube">
            <AnchorButton
              text="Cara mendaftar Gajiku"
              href="https://youtu.be/Q6HJNR_v0Fw"
              icon={"video"}
              minimal={true}
            />
          </div> */}
          {/* <div className={"version"}>
            {process.env.REACT_APP_VERSION || "dev"}
          </div> */}
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
