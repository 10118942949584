import {
  ClockInBySpvI,
  ClockInSelfieI,
  ClockOutSelfieI,
  PermissionI,
  PresenceCheckReq,
} from "../_interfaces";
import request from "../_network/request";
const attendanceUrl = "attendance";

const requestRemote = (payload: any) => {
  return request({
    url: `${attendanceUrl}/presence/remote/request`,
    method: "POST",
    data: payload,
  });
};

const checkHasPresence = (payload: PresenceCheckReq) => {
  return request({
    url: `${attendanceUrl}/presence/check`,
    method: "POST",
    data: payload,
  });
};

const getSubordinates = () => {
  return request({
    url: "/employee/profile/subordinate/direct",
    method: "GET",
  });
};

const getPresenceHistory = () => {
  return request({
    url: `${attendanceUrl}/presence/history/my`,
    method: "GET",
  });
};

const cancelRemote = (id: number) => {
  return request({
    url: `${attendanceUrl}/presence/remote/request/${id}/cancel`,
    method: "POST",
  });
};

const permission = (payload: PermissionI) => {
  return request({
    url: `/permission/request`,
    method: "POST",
    data: payload,
  });
};

const permissionList = () => {
  return request({
    url: "/permission/history/my",
    method: "GET",
  });
};

const cancelPermission = (id: number) => {
  return request({
    url: `/permission/request/${id}/cancel`,
    method: "POST",
  });
};

const clockInSelfie = (payload: ClockInSelfieI) => {
  return request({
    url: `${attendanceUrl}/presence/clock-in`,
    method: "POST",
    data: payload,
  });
};

const clockOutSelfie = (payload: ClockOutSelfieI) => {
  return request({
    url: `${attendanceUrl}/presence/clock-out`,
    method: "POST",
    data: payload,
  });
};

const presenceBySpv = (
  payload: ClockInBySpvI,
  type: string,
  presenceAt: string,
) => {
  let data: any;
  if (type === "clock-in") {
    data = {
      ...payload,
      clockIn: presenceAt,
    };
  } else {
    data = {
      subordinateId: payload.subordinateId,
      scheduleId: payload.scheduleId,
      latitude: payload.latitude,
      longitude: payload.longitude,
      clockOut: presenceAt,
    };
  }
  return request({
    url: `${attendanceUrl}/presence/${type}/subordinate`,
    method: "POST",
    data: data,
  });
};

const getSubordinatePresence = () => {
  return request({
    url: `${attendanceUrl}/presence/history/direct-subordinate?presenceTypes=manual`,
    method: "GET",
  });
};

const approvePresence = (id: number) => {
  return request({
    url: `${attendanceUrl}/presence/${id}/approve`,
    method: "POST",
  });
};

const rejectPresence = (id: number, rejectReason: string) => {
  return request({
    url: `${attendanceUrl}/presence/${id}/reject`,
    method: "POST",
    data: {
      rejectReason,
    },
  });
};

const approveBulkPresence = (ids: Array<number>) => {
  return request({
    url: `${attendanceUrl}/presence/approve-bulk`,
    method: "POST",
    data: {
      id: ids,
    },
  });
};

const getSubordinatePermissions = () => {
  return request({
    url: `/permission/history/subordinate`,
    method: "GET",
  });
};

const approvePermission = (id: number) => {
  return request({
    url: `/permission/request/${id}/approve`,
    method: "POST",
  });
};

const rejectPermission = (id: number, reason: string) => {
  return request({
    url: `/permission/request/${id}/reject`,
    method: "POST",
    data: {
      rejectReason: reason,
    },
  });
};

const approveBulkPermission = (ids: Array<number>) => {
  return request({
    url: `/permission/request/bulk/approve`,
    method: "POST",
    data: {
      id: ids,
    },
  });
};

const getCheckListPresence = () => {
  return request({
    url: `${attendanceUrl}/presence/check-list`,
    method: "GET",
  });
};

export const PresenceServices = {
  requestRemote,
  checkHasPresence,
  getSubordinates,
  getPresenceHistory,
  permission,
  clockInSelfie,
  clockOutSelfie,
  presenceBySpv,
  getSubordinatePresence,
  approvePresence,
  rejectPresence,
  approveBulkPresence,
  getSubordinatePermissions,
  approvePermission,
  rejectPermission,
  approveBulkPermission,
  permissionList,
  cancelPermission,
  cancelRemote,
  getCheckListPresence,
};
