/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import Datepicker from "../../components/date-picker";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import {
  createLeaveRecord,
  getOptions,
} from "../../../../../../redux/permit/permit.actions";
import { LeaveRecord } from "../../../../../../_interfaces";
import "./cuti.style.scss";
import { useDispatch } from "react-redux";
import useUpload from "../../../../../../_helper/useUpload";
import { from } from "@apollo/client";
import usePermissions from "../../../../../../_helper/usePermissions";

const CutiRequest = () => {
  const dispatch = useDispatch();
  const { leaveOptions, filePermission } = useAppSelector((state) => ({
    ...state.permit,
    ...state.globalState,
  }));
  const { reqFilePermission } = usePermissions();
  const [form, setForm] = useState<LeaveRecord>({
    leaveCategory: "",
    description: "",
    startAt: "",
    endAt: "",
    photos: "",
  });
  const [fileUrl, upload] = useUpload();

  useEffect(() => {
    dispatch(setHeader({ title: "Pengajuan Cuti", hasBack: true }));
    dispatch(getOptions());
    // reqFilePermission();
  }, []);

  useEffect(() => {
    setForm((temp) => ({ ...temp, photos: fileUrl }));
  }, [fileUrl]);

  const handleChange = (e: any) => {
    setForm((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    dispatch(createLeaveRecord(form));
  };

  const handleUploadFiles = (files: any) => {
    if (files.length > 0) {
      upload(files[0], "cuti");
    }
  };

  return (
    <div className="cuti">
      <select className="select" name="leaveCategory" onChange={handleChange}>
        <option hidden value="">
          Jenis Izin
        </option>
        {leaveOptions.map((opt: any, i: number) => (
          <option key={i} value={opt.code}>
            {opt.name}
          </option>
        ))}
      </select>
      <input
        type="date"
        name="startAt"
        id="startAt"
        placeholder="Tanggal Mulai"
        value={form.startAt}
        onChange={handleChange}
      />
      <input
        type="date"
        name="endAt"
        id="endAt"
        placeholder="Tanggal Mulai"
        value={form.endAt}
        onChange={handleChange}
      />
      <div className="upload-photo">
        <input
          type="file"
          id="file-izin"
          accept="*"
          onChange={(e: any) => handleUploadFiles(e.target.files)}
        />
        <div className="label">Unggah File</div>
        <label htmlFor="file-izin" className="file-box">
          <p className="text">
            {form.photos !== "" ? form.photos : "Pilih File"}
          </p>
          <div className="btn">Browse</div>
        </label>
      </div>
      <textarea
        placeholder="Keterangan"
        name="description"
        onChange={handleChange}
      />
      {!filePermission && (
        <p className="error">Izinkan akses file untuk melanjutkan</p>
      )}
      <button
        onClick={() => handleSubmit()}
        className="submit-btn"
        disabled={form.description === "" || !filePermission}
      >
        Submit
      </button>
    </div>
  );
};

export default CutiRequest;
