import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSpawnRender, useTokens } from "./index";
import { If, Then } from "react-if";
import { addMonths, months, titleCase } from "./util";
import { useHistory } from "react-router";
import {
  InterestFee,
  LoanPaymentPeriods,
  LoanRender,
  ResponseFetch,
  SpawnRender,
} from "./interface";
import { useLogin } from "../../../../data/login/api";
import dict from "./dicts.json";
import { useDispatch } from "react-redux";
import {
  cancelLoanRequest,
  getLoanHDetail,
  updateLoanAttachment,
} from "../../../../redux/loan/loan.actions";
import { useAppSelector } from "../../../../redux/hooks";
import { Button, Dialog } from "@blueprintjs/core";
import useUpload from "../../../../_helper/useUpload";

const dicts = dict as any;
const xhr = new XMLHttpRequest();

type LoanPaymentPeriodsType = {
  data: {
    loan_payment_periods: LoanPaymentPeriods[];
  };
};
const ListOfInstallment = ({
  render,
  interestFee,
  installmentNo,
  amount,
  loanPaymentPeriods,
}: {
  render: SpawnRender;
  interestFee: InterestFee | undefined;
  installmentNo: number;
  amount: number | string;
  loanPaymentPeriods: LoanPaymentPeriodsType | any;
}) => {
  const month = new Date(loanPaymentPeriods[installmentNo]?.dueDate).getMonth();
  const date = new Date(
    loanPaymentPeriods ? loanPaymentPeriods[installmentNo]?.dueDate : "",
  ).getDate();
  const year = new Date(
    loanPaymentPeriods ? loanPaymentPeriods[installmentNo]?.dueDate : "",
  ).getFullYear();
  const createdMonth = new Date(
    loanPaymentPeriods[installmentNo]?.createdAt
      ? loanPaymentPeriods[installmentNo]?.createdAt
      : "",
  ).getMonth();
  return (
    <div style={{ paddingBottom: "2rem" }} key={installmentNo}>
      <h3 style={{ marginBottom: "0px" }}>
        {
          months[
            dicts[createdMonth + installmentNo]
              ? createdMonth +
                installmentNo -
                dicts[createdMonth + installmentNo]
              : createdMonth + installmentNo
          ]
        }
      </h3>
      <div className="d-flex justify-between" style={{ height: "1.5rem" }}>
        <div className="desc">
          <p style={{ color: "#DEB789", fontSize: "12px" }}>
            {loanPaymentPeriods[installmentNo]?.isPaid
              ? titleCase("Approve")
              : "Pending"}
          </p>
        </div>
        <h4 style={{ marginBottom: "0px", color: "grey" }}>
          {`Rp. ${Number(amount).toLocaleString()}`}
        </h4>
      </div>
      <p style={{ fontSize: "12px" }}>
        Jatuh Tempo:{" "}
        {loanPaymentPeriods[installmentNo]?.dueDate
          ? `${date} ${months[month]} ${year}`
          : "Loading..."}
      </p>
    </div>
  );
};
const LoanDetail = () => {
  const [interestFee, setInterestFee] = useState<InterestFee>();
  const [, loginAuth] = useLogin();
  const [render] = useSpawnRender({
    shouldCompare() {
      return true;
    },
  });
  const [loanPaymentPeriods, setLoanPaymentPeriods] =
    useState<LoanPaymentPeriodsType>();
  const [token] = useTokens({
    shouldCompare() {
      return true;
    },
  });

  const [confirmModal, setConfirmModal] = useState(false);

  const dispatch = useDispatch();
  const { loanDetailData, loading } = useAppSelector((state) => ({
    ...state.loanState,
    ...state.globalState,
  }));

  useEffect(() => {
    dispatch(getLoanHDetail(render.index));
  }, [render.index]);

  const cancelLoan = () => {
    dispatch(cancelLoanRequest(render.index));
  };

  const { statusRequest } = useAppSelector((state) => state.loanState);

  useEffect(() => {
    if (statusRequest == "success") {
      setConfirmModal(false);
      dispatch(getLoanHDetail(render.index));
    }
  }, [statusRequest]);

  return (
    <>
      <Dialog isOpen={confirmModal} className={"custom-modal-confirm"}>
        <div>
          <h4>Apakah anda yakin ingin membatalkan pengajuan?</h4>
          <div className="d-flex">
            <Button
              className="confirm-btn"
              loading={loading}
              onClick={cancelLoan}
            >
              Yakin
            </Button>
            <Button onClick={() => setConfirmModal(false)}>Tutup</Button>
          </div>
        </div>
      </Dialog>
      <div className="card card-visible" style={{ height: "auto" }}>
        <div className="card-body">
          <div
            className="d-flex justify-between"
            style={{ marginBottom: "1rem" }}
          >
            <div className="desc">
              <p style={{ marginBottom: "0px" }}>
                {new Date(loanDetailData?.createdAt).toLocaleDateString(
                  "id-ID",
                  {
                    day: "2-digit",
                    year: "2-digit",
                    month: "long",
                  },
                )}
                ,{" "}
                {new Date(loanDetailData?.createdAt).toLocaleTimeString(
                  "id-ID",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                    timeZone: "Asia/Jakarta",
                  },
                )}
              </p>
            </div>
            <div className={"btn-inside-card-wrapper"}>
              <div
                className={`btn-inside-card-right badge-${loanDetailData?.status}`}
              >
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  {titleCase(loanDetailData?.status)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex justify-between">
              <div className="desc">
                <p>Jumlah Pinjaman (Rp)</p>
              </div>
              <h4
                style={{ marginBottom: "0px", color: "grey", marginTop: "0" }}
              >
                {Number(loanDetailData?.amount).toLocaleString()}
              </h4>
            </div>
            <div
              className="d-flex justify-between"
              style={{ marginTop: "1em", marginBottom: "1em" }}
            >
              <div className="desc">
                <p>
                  {loanDetailData?.interestType == "tenor"
                    ? "Biaya Bunga ( 2% / tenor )"
                    : "Biaya Bunga ( 2% )"}
                </p>
              </div>
              <h4
                style={{
                  marginBottom: "0px",
                  color: "lightcoral",
                  marginTop: "0",
                }}
              >
                {Number(loanDetailData?.totalInterestFee).toLocaleString()}
              </h4>
            </div>
            {loanDetailData?.adminFee !== 0 && (
              <div
                className="d-flex justify-between"
                style={{ marginBottom: "1em" }}
              >
                <div className="desc">
                  <p>Biaya Admin (Rp)</p>
                </div>
                <h4
                  style={{
                    marginBottom: "0px",
                    color: "lightcoral",
                    marginTop: "0",
                  }}
                >
                  {Number(loanDetailData?.adminFee).toLocaleString()}
                </h4>
              </div>
            )}
            <div style={{ borderTop: "1px solid lightgrey" }}>
              <div
                className="d-flex justify-between"
                style={{ paddingTop: "1rem" }}
              >
                <div className="desc">
                  <p>Pinjaman perlu dibayar (Rp)</p>
                </div>
                <h3 style={{ marginBottom: "0px" }}>
                  {Number(loanDetailData?.principal).toLocaleString()}
                  {/* {render.data ? `${amount.toLocaleString()}` : "Loading..."} */}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card card-visible"
        style={{ height: "auto", marginBottom: "1rem" }}
      >
        <div className="card-body">
          <p style={{ marginBottom: "1rem", color: "grey" }}>Tenor</p>
          <h4 style={{ marginBottom: "0px" }}>
            {loanDetailData?.installment_length} Bulan (
            {months[new Date(loanDetailData?.createdAt).getMonth()]}{" "}
            {new Date(loanDetailData?.createdAt).getUTCFullYear()} -{" "}
            {
              months[
                String(
                  addMonths(
                    new Date(loanDetailData?.createdAt),
                    Number(loanDetailData?.installment_length),
                  ).getMonth(),
                ) as any
              ]
            }{" "}
            {addMonths(
              new Date(loanDetailData?.createdAt),
              Number(loanDetailData?.installment_length),
            ).getUTCFullYear()}
            )
          </h4>
        </div>
      </div>
      <div className="card card-visible" style={{ height: "auto" }}>
        <div className="card-body">
          <p style={{ color: "grey" }}>Rincian Tagihan</p>
          <div style={{ paddingTop: "2rem" }} className="installment-list">
            {loanDetailData &&
              loanDetailData?.installments?.map((item: any, i: number) => {
                let temp;
                for (let a = 0; a < loanDetailData?.installments?.length; a++) {
                  temp = loanDetailData?.installments[a]?.amount;
                }
                return (
                  <ListOfInstallment
                    key={i}
                    render={render}
                    loanPaymentPeriods={loanDetailData?.installments}
                    installmentNo={i}
                    amount={temp}
                    interestFee={loanDetailData?.totalInterestFee}
                  />
                );
              })}
            {loanDetailData?.status == "pending" && (
              <div>
                <button
                  className="btn-cancel"
                  onClick={() => setConfirmModal(true)}
                >
                  Batalkan Pinjaman
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const CardDetail = () => {
  const [tab, setTab] = useState("1");
  const [render] = useSpawnRender({
    shouldCompare() {
      return true;
    },
  });

  const dispatch = useDispatch();
  const [fileUrl, upload] = useUpload();

  const handleUploadFiles = (files: any) => {
    if (files.length > 0) {
      upload(files[0], "file-request");
    }
  };

  const submitForm = () => {
    dispatch(updateLoanAttachment(render?.index, fileUrl));
  };

  return (
    <div style={{ paddingTop: "1rem" }}>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => {
            setTab("1");
          }}
          style={{
            borderBottom: `${tab === "1" ? "3px solid #18B8BA" : "none"}`,
            width: "100%",
            textAlign: "center",
            paddingBottom: "0.5rem",
            color: `${tab === "1" ? "#18B8BA" : ""}`,
          }}
        >
          <p
            style={
              tab === "1"
                ? { fontWeight: "bold" }
                : { fontWeight: "normal", color: "grey" }
            }
          >
            Rincian Pinjaman
          </p>
        </div>
        <div
          onClick={() => {
            setTab("2");
          }}
          style={{
            borderBottom: `${tab === "2" ? "3px solid #18B8BA" : "none"}`,
            width: "100%",
            textAlign: "center",
            paddingBottom: "0.5rem",
            color: `${tab === "2" ? "#18B8BA" : ""}`,
          }}
        >
          <p
            style={
              tab === "2"
                ? { fontWeight: "bold" }
                : { fontWeight: "normal", color: "grey" }
            }
          >
            Upload Formulir
          </p>
        </div>
      </div>
      <If condition={tab === "1"}>
        <Then>
          <LoanDetail />
        </Then>
      </If>
      <If condition={tab === "2"}>
        <Then>
          <div className="p-4 request-upload">
            <p style={{ marginBottom: "1rem", fontWeight: "bold" }}>
              Upload Formulir yang sudah di tanda tangan
            </p>
            <label className="file">
              <input
                type="file"
                id="file"
                accept="image/*, application/pdf"
                className="w-100 mb-2"
                onChange={(e) => {
                  if (e.target.files) {
                    if (
                      !e.target.files[0].type.includes("image") &&
                      !e.target.files[0].type.includes("pdf")
                    ) {
                      alert("File harus berupa gambar atau pdf");
                      return;
                    }
                    handleUploadFiles(e.target.files);
                  }
                }}
              />
              <span
                className="file-custom"
                style={{ border: "none", marginTop: "0.5rem" }}
              ></span>
            </label>

            <button onClick={submitForm} className="submit-btn">
              Ajukan Sekarang
            </button>
          </div>
          {/* <UploadForm /> */}
        </Then>
      </If>
    </div>
  );
};
CardDetail.displayName = "CardDetail";
export default CardDetail;
