import {
  CreateLog,
  GenerateProduction,
  UpdateLog,
} from "../_interfaces/productionTracking.interfaces";
import request from "../_network/request";
const prefix = "production-tracking";

const getMyProjects = () => {
  return request({
    url: `${prefix}/project/my`,
    method: "GET",
  });
};

const getGroups = (id: number, date: string, status?: string) => {
  let url = ``;
  if (status == "submitted") {
    url = `${prefix}/production-log/group/${id}/${date}?status=WAIT_FOR_APPROVAL&status=CHECKING&status=APPROVED`;
  } else if (status == "checked") {
    url = `${prefix}/production-log/group/${id}/${date}?status=APPROVED&status=WAIT_FOR_APPROVAL`;
  } else if (status == "pending_manager") {
    url = `${prefix}/production-log/group/${id}/${date}?status=PENDING&status=CHECKING&status=NEED_REVISION`;
  } else if (status == "pending_checker") {
    url = `${prefix}/production-log/group/${id}/${date}?status=PENDING&status=NEED_REVISION`;
  } else if (!status) {
    url = `${prefix}/production-log/group/${id}/${date}`;
  } else {
    url = `${prefix}/production-log/group/${id}/${date}?status=${status.toUpperCase()}`;
  }

  return request({
    url: url,
    method: "GET",
  });
};

const generateProduction = (payload: GenerateProduction) => {
  return request({
    url: `${prefix}/production-log/create`,
    method: "POST",
    data: payload,
  });
};

const getLogItem = (
  projectId: any,
  date: string,
  itemId: any,
  groupName: string,
) => {
  return request({
    url: `${prefix}/production-log/group/${projectId}/${date}/${itemId}/${groupName}`,
    method: "GET",
  });
};

const createLog = (payload: CreateLog) => {
  return request({
    url: `${prefix}/production-log/input/create`,
    method: "POST",
    data: payload,
  });
};

const updateLog = (id: number, payload: UpdateLog) => {
  console.log("payload", payload);
  return request({
    url: `${prefix}/production-log/input/${id}/update`,
    method: "PUT",
    data: payload,
  });
};

const sendToChecker = (id: number) => {
  return request({
    url: `${prefix}/production-log/${id}/submit-to-checker`,
    method: "POST",
  });
};

const approveInput = (id: number) => {
  return request({
    url: `${prefix}/production-log/input/${id}/approve`,
    method: "PUT",
  });
};

const rejectInput = (id: number, rejectReason: string) => {
  return request({
    url: `${prefix}/production-log/input/${id}/reject`,
    method: "PUT",
    data: { rejectReason: rejectReason },
  });
};

const deleteInput = (id: number) => {
  return request({
    url: `${prefix}/production-log/input/${id}/delete`,
    method: "POST",
  });
};

const sendToManager = (id: number | null) => {
  return request({
    url: `${prefix}/production-log/${id}/submit-to-manager`,
    method: "POST",
  });
};

const managerSubmit = (id: number | null) => {
  return request({
    url: `${prefix}/production-log/${id}/submit-to-hr`,
    method: "POST",
  });
};

export const ProductionTrackingServices = {
  getMyProjects,
  getGroups,
  generateProduction,
  getLogItem,
  createLog,
  updateLog,
  sendToChecker,
  approveInput,
  rejectInput,
  sendToManager,
  deleteInput,
  managerSubmit,
};
