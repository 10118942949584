import axios, { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { logoutRedux } from "../redux/auth/auth.actions";
import AuthActionTypes from "../redux/auth/auth.types";
import { store } from "../redux/store";

store.subscribe(listener);

function select(state: any) {
  return state.auth.token;
}

function listener() {
  const token = select(store.getState());
  axios.defaults.headers.common["Authorization"] = `bearer ${token}`;
}

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  maxRedirects: 5,
});

const request = (options: any) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = function (error: any) {
    if (error.response.data.http_status == 401) {
      store.dispatch({
        type: AuthActionTypes.LOGOUT,
      });

      return Promise.reject("Anda tidak bisa mengakses aplikasi");
    }

    if (error.response.data.http_status == 403) {
      if (window.location.pathname == "/login") {
        return Promise.reject("Akun tidak terdaftar di perusahaan manapun");
      } else {
        return Promise.reject("Anda tidak memiliki akses untuk fitur ini.");
      }
    }
    return Promise.reject(error.response.data.message || error);
  };
  const token = select(store.getState());

  return client({
    headers: {
      Authorization: `bearer ${token}`,
    },
    ...options,
  })
    .then(onSuccess)
    .catch(onError);
};

export default request;
