/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ButtonImage from "../../components/button-selfie";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import "./clockin.style.scss";
import { useDispatch } from "react-redux";
import { ClockInSelfieI, KEYS } from "../../../../../../_interfaces";
import Geocode from "react-geocode";
import useClockIn from "./useClockIn";
import useUpload from "../../../../../../_helper/useUpload";
import usePermissions from "../../../../../../_helper/usePermissions";

const ClockIn = () => {
  Geocode.setApiKey(KEYS.GEOCODE);
  Geocode.setLanguage("ID");
  const dispatch = useDispatch();
  const [submitClockIn] = useClockIn();
  const { todaySchedule, image, locationPermission } = useAppSelector(
    (state) => ({
      ...state.shift,
      ...state.globalState,
    }),
  );
  const { reqLocationPermission } = usePermissions();
  const [address, setAddress] = useState("");
  const [url, upload] = useUpload();
  const [form, setForm] = useState<ClockInSelfieI>({
    scheduleId: todaySchedule?.id,
    latitude: 0,
    longitude: 0,
    photo: "",
  });

  useEffect(() => {
    setForm((temp) => ({ ...temp, scheduleId: todaySchedule?.id }));
  }, [todaySchedule]);

  useEffect(() => {
    if (image !== "") upload(image, "presence", true);
  }, [image]);

  useEffect(() => {
    setForm((temp) => ({ ...temp, photo: url }));
  }, [url]);

  useEffect(() => {
    dispatch(setHeader({ title: "Absen Masuk", hasBack: true }));
    reqLocationPermission();
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setForm((state) => ({
          ...state,
          longitude: pos.coords.longitude,
          latitude: pos.coords.latitude,
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      (err) => {
        console.log(err.message);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  }, [locationPermission]);

  useEffect(() => {
    if (form.latitude !== 0) {
      Geocode.fromLatLng(
        form.latitude.toString(),
        form.longitude.toString(),
      ).then((response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      });
    }
  }, [form.latitude, form.longitude]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitClockIn(form);
    // if (image && image !== "") {
    // }
  };

  return (
    <form onSubmit={handleSubmit} className="clock-in">
      <div className="form-map">
        <label htmlFor="location">Lokasi anda saat ini</label>
        <textarea
          id="location"
          placeholder="&#61442; Tuliskan lokasi absen anda"
          value={address}
          required
          disabled
        />
        {!locationPermission && (
          <p className="error">Izinkan lokasi untuk melanjutkan absen</p>
        )}
      </div>
      <ButtonImage />
      {/* <div className="container">
        <textarea
          className="reason"
          placeholder="Keterangan"
          onChange={(e: any) => console.log(e.target.value)}
        />
      </div> */}
      <button
        type="submit"
        className="submit-btn"
        disabled={image === "" || !locationPermission}
      >
        Absen Masuk
      </button>
    </form>
  );
};

export default ClockIn;
