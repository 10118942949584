import React, { FunctionComponent, useEffect, useRef, useState } from "react";

const TncBahasa = () => {
  return (
    <div>
      Selamat datang di Gajiku! <br />
      <br />
      <br />
      <p>
        Syarat dan Ketentuan yang ditetapkan di bawah ini mengatur pemakaian
        jasa yang ditawarkan oleh PT Vara Technologies Inc terkait penggunaan
        situs www.gajikuapp.com dan aplikasi Gajiku. Pengguna disarankan membaca
        dengan seksama karena dapat berdampak kepada hak dan kewajiban Pengguna
        di bawah hukum yang berlaku.
      </p>
      <br />
      <p>
        Dengan mengunduh, memasang, menggunakan dan/atau menikmati layanan kami
        pada situs dan/atau Platform Gajiku, Pengguna telah membaca, memahami,
        mengetahui, menerima, dan menyetujui seluruh syarat-syarat,
        ketentuan-ketentuan dan seluruh informasi yang terdapat dalam situs dan
        Platform Gajiku ini.
      </p>
      <br />
      <p>
        Syarat dan Ketentuan ini merupakan suatu perjanjian yang secara sah
        mengikat yang memuat mengenai tata cara dan persyaratan situs dan
        Platform Gajiku antara anda sebagai Pengguna dengan pengelola Platform
        Gajiku yaitu PT Vara Technologies Inc. Ketentuan penggunaan dan
        kebijakan privasi dapat diperbahurui oleh Gajiku dari waktu ke waktu
        untuk mengubah, menghapus dan/atau menerapkan syarat dan ketentuan baru.
        Penggunaan Platform Gajiku oleh Pengguna lebih lanjut akan dianggap
        sebagai persetujuan untuk tunduk kepada perubahan atas Syarat dan
        Ketentuan ini. KETENTUAN UMUM Gajiku adalah platform aplikasi layanan
        berbasis teknologi yang menyediakan fasilitas pembayaran gaji di muka
        bagi karyawan Pengguna.
      </p>
      <br />
      <p>
        Gajiku dimiliki oleh PT Vara Technologies Inc. Platform Gajiku adalah
        aplikasi versi Android dengan nama “Gajiku” dan situs yang beralamat di
        www.gajikuapp.com. Untuk menggunakan Platform Gajiku diperlukan koneksi
        internet serta perangkat Android, dan segala biaya yang timbul atas
        penggunaan koneksi perangkat elektronik tersebut merupakan tanggung
        jawab Pengguna sepenuhnya. Pengguna adalah pihak yang menggunakan
        dan/atau menikmati layanan Gajiku, termasuk namun tidak terbatas pada
        perusahaan mitra dan karyawan. Gajiku berhak untuk tidak memproses
        registrasi, membatasi, menunda atau menghentikan Pengguna untuk
        menggunakan Platform Gajiku, apabila terdapat alasan-alasan bahwa
        Pengguna telah melakukan pelanggaran terhadap syarat dan ketentuan yang
        sebagaimana diatur dalam Syarat dan Ketentuan Pengguna ini dan/atau
        peraturan perundang-undangan yang berlaku.
      </p>
      <br />
      <p>
        Syarat & Ketentuan adalah perjanjian antara Pengguna dan Gajiku yang
        berisikan seperangkat peraturan yang mengatur hak, kewajiban, tanggung
        jawab pengguna dan Gajiku, serta tata cara penggunaan sistem layanan
        Gajiku. KETENTUAN PENGGUNA Karyawan yang ingin menggunakan layanan
        Gajiku dan untuk mengakses Platform Gajiku harus terlebih dahulu
        melakukan pendaftaran yang disertai dengan pemberian informasi berupa
        nomor ponsel yang dimiliki oleh karyawan. Perusahaan yang telah
        bekerjasama dengan Gajiku dapat menggunakan layanan Gajiku dengan
        mendapatkan akses online dashbor Platform Gajiku untuk dapat melihat
        karyawan yang telah melakukan penarikan gaji diawal.
      </p>
      <br />
      <p></p>
      <br />
      <p>
        Setelah mendaftarkan diri pada Platform Gajiku, Pengguna akan
        mendapatkan akun pribadi di Gajiku yang dapat diakses dengan One Time
        Password (OTP) yang akan dikirimkan ke nomor ponsel Pengguna yang telah
        terdaftar dan terverifikasi oleh Gajiku. Kata sandi atas akun Pengguna
        yang diberikan melalui One Time Password (OTP) wajib dijaga
        kerahasiaannya. Pengguna kemudian dapat mendaftarkan kode Personal
        Identification Number (PIN) untuk login selanjutnya. Segala perubahan
        data dan informasi Pengguna wajib diberitahukan kepada Gajiku. Informasi
        terkait dengan data pribadi yang diberikan oleh Pengguna kepada Gajiku
        hanya akan digunakan untuk memberikan pelayanan-pelayanan dalam Platform
        Gajiku dan untuk tujuan lain sebagaimana yang diatur dalam Syarat dan
        Ketentuan, kebijakan privasi yang telah disetujui oleh Pengguna serta
        sesuai dengan ketentuan perundang-undangan yang berlaku.
      </p>
      <br />
      <p>
        Pengguna juga wajib memberikan informasi yang benar, akurat, lengkap,
        dan terbaru serta dapat melampirkan semua bukti identitas atau bukti
        lain yang diperlukan untuk pendaftaran Platform Gajiku. Dalam setiap
        perubahan informasi rekening bank Pengguna yang tercantum di dalam
        database Platform Gajiku, maka Gajiku akan melakukan verifikasi ganda,
        yang pertama adalah verifikasi kepada perusahaan Pengguna dan verifikasi
        melalui nomor ponsel yang telah terverifikasi dengan mengirimkan
        one-time password (OTP). Karyawan Pengguna wajib untuk memastikan bahwa
        nama yang terdapat pada rekening bank sesuai dengan nama yang diberikan
        oleh perusahaan Pengguna kepada Gajiku. Setiap perubahan informasi
        rekening bank akan mengakibatkan tes verifikasi lanjutan dan dapat
        menunda waktu proses.
      </p>
      <br />
      <p>
        Dalam hal terjadi pengungkapan kata sandi ataupun akun Pengguna telah
        disusupi yang dapat mengakibatkan kerugian bagi Pengguna akibat
        penggunaan yang tidak sah (termasuk diantaranya peretasan akun
        Pengguna), tanpa kewenangan dan melawan hukum atas akun Pengguna yang
        terjadi bukan karena kesalahan dari Gajiku, maka transaksi penarikan
        gaji atas layanan yang dilakukan melalui Platform Gajiku dianggap
        sebagai transaksi yang sah, kecuali Pengguna telah memberitahukannya
        terlebih dahulu kepada pihak Gajiku mengenai hal tersebut.
      </p>
      <br />
      <p>
        Pengguna wajib melaporkan kepada pihak Gajiku dalam hal terdapat
        penyalahgunaan akun Pengguna dalam Platform Gajiku, Pengguna bertanggung
        jawab atas setiap penggunaan akun Pengguna, meskipun akun tersebut telah
        disalahgunakan oleh orang lain. Pengguna tidak diperkenankan untuk
        membahayakan, memanipulasi, menyalahgunakan atau memodifikasi Platform
        Gajiku dengan cara apapun, pihak Gajiku berhak untuk menghentikan,
        menutup, membatalkan dan melarang Pengguna untuk menggunakan Platform
        Gajiku karena telah melanggar ketentuan Syarat dan Ketentuan ini.
      </p>
      <br />
      <p>
        Pengguna telah setuju dan memahami akan menggunakan Platform Gajiku
        hanya untuk tujuan sebagaimana yang dimaksud dalam layanan, dan tidak
        diperbolehkan untuk menyalahgunakan atau menggunakan Platform Gajiku
        untuk aktivitas yang bertentangan dengan hukum, termasuk namun tidak
        terbatas pada tindakan pidana penggelapan maupun penipuan. Perusahaan
        Pengguna wajib secara untuk melakukan pembaharuan data karyawan secara
        tepat waktu, terhadap setiap perubahan gaji karyawan, pengunduran diri
        karyawan, dan/atau penambahan karyawan melalui Platform Gajiku.
      </p>
      <br />
      <p>
        Dengan memberikan informasi data pribadi kepada Gajiku, Pengguna telah
        memahami dan setuju terhadap syarat dan ketentuan penggunaan Platform
        Gajiku. KEAMANAN AKUN Gajiku berkomitmen untuk melakukan segala upaya
        yang wajar untuk menjaga agar Platform Gajiku ini dapat berfungsi dengan
        baik, aman, nyaman dan berjalan lancar sesuai dengan peruntukannya, tapi
        Gajiku tidak dapat menjamin secara terus-menerus akses layanan yang kami
        berikan dapat selalu sempurna.
      </p>
      <br />
      <p>
        Pengguna telah setuju dan mengetahui layanan yang diberikan oleh Gajiku
        kepada Pengguna adalah SEBAGAIMANA ADANYA dan SEBAGAIMANA TERSEDIA dalam
        arti Pengguna Platform Gajiku telah setuju bahwa pemanfaatan layanan
        Gajiku atas risiko Pengguna itu sendiri, dan Gajiku tidak dapat menjamin
        sebagai berikut: Gajiku tidak menjamin bahwa layanan yang diberikan akan
        bebas dari kesalahan dan/atau kecacatan saat beroperasi dengan kombinasi
        dengan perangkat keras, perangkat lunak, sistem atau data lainnya.
      </p>
      <br />
      <p>
        Bahwa ketersediaan dan kehandalan, maupun server-server pendukung yang
        menyediakan Platform Gajiku akan terbebas dari virus dan komponen bahaya
        lainnya. Bahwa kualitas produk, fitur, dan informasi yang tersedia di
        Platform Gajiku akan memenuhi harapan dan kebutuhan Pengguna. Bahwa
        Pengguna mengetahui dan setuju bahwa penggunaan akses internet tidak
        selamanya menciptakan suatu yang aman dan pribadi, dan karenanya setiap
        pesan atau informasi yang Pengguna kirimkan atau gunakan dalam Platform
        Gajiku mungkin dapat diretas atau diambil oleh Pihak Ketiga yang tidak
        bertanggung jawab.
      </p>
      <br />
      <p>
        KEBIJAKAN PRIVASI Kebijakan privasi sebagaimana diatur dalam Syarat dan
        Ketentuan ini akan menjelaskan bagaimana Gajiku mengelola, mengolah,
        memperoleh, mengumpulkan, menggunakan, mengungkapkan dan memproses,
        membuka akses, dan melindungi informasi dan data pribadi yang telah
        diberikan oleh Pengguna pada saat melakukan registrasi untuk menggunakan
        Platform Gajiku. Perlu diketahui bahwa data pribadi di sini tidak
        termasuk dalam data pribadi yang telah tersedia di domain publik.
      </p>
      <br />
      <p>
        Pengguna Platform Gajiku perlu membaca kebijakan privasi ini dengan
        sepenuhnya sebelum melakukan pendaftaran dan/atau mengakses layanan
        Platform Gajiku, karena kebijakan privasi ini merupakan bagian dari
        Syarat dan Ketentuan penggunaan Platform Gajiku. Dengan menggunakan
        Platform Gajiku, Pengguna dianggap telah membaca dan menyetujui
        kebijakan privasi serta pemanfaatan data pribadi yang akan digunakan
        untuk keperluan pemberian layanan sesuai dengan kebijakan privasi ini.
      </p>
      <br />
      <p>
        Registrasi Informasi yang diberikan mengenai identitas diri yang wajib
        diisi oleh Pengguna akun di Platform memuat antara lain:
        <ul>
          <li>
            Nama lengkap sesuai dengan kartu identitas yang berlaku (Kartu Tanda
            Penduduk atau Paspor);
          </li>
          <li>
            Nomor Handphone yang akan dipergunakan untuk mengirimkan one-time
            password (OTP); dan Gaji pokok bulanan.
          </li>
        </ul>{" "}
        Pengguna telah mengetahui dan menyetujui bahwa Gajiku dapat menerima
        informasi Pengguna, termasuk namun tidak terbatas pada lokasi/kordinat
        GPS (Jika Pengguna mengaktifkan fitur tersebut), alamat intenet protocol
        (ip) (Jika Pengguna menggunakan browser), system operasi, jenis browser,
        alamat platform.
      </p>
      <br />
      <p>
        Pengguna menyetujui bahwa Gajiku akan melakukan verifikasi langsung
        kepada Pengguna, termasuk perusahaan tempat dimana karyawan bekerja
        mengenai data diri yang telah disampaikan oleh karyawan sebagai Pengguna
        melalui Platform Gajiku. Pengguna menjamin bahwa informasi yang
        diberikan adalah benar, akurat, dan terbaru. Dalam hal ternyata
        informasi yang diberikan tidak benar, maka Gajiku tidak bertanggung
        jawab atas segala akibat yang mungkin dapat timbul sehubungan dengan
        pemberian dan penggunaan informasi yang tidak benar tersebut.
      </p>
      <br />
      <p>
        Penggunaan Data Pribadi Pengguna dengan ini memberikan persetujuan
        secara tegas kepada Gajiku untuk melakukan segala pemanfaatan data
        pribadi Pengguna untuk kegiatan bisnis dan operasional maupun keperluan
        lainnya yang dibutuhkan sesuai dengan tujuan diberikannya Data Pribadi
        tersebut, termasuk namun tidak terbatas untuk melakukan sebagai berikut:
        Gajiku menyimpan informasi pribadi Pengguna selama informasi tersebut
        terkait dengan tujuan-tujuan yang dibutuhkan untuk kepentingan pelayanan
        Gajiku.
      </p>
      <br />
      <p>
        Kerahasiaan informasi pribadi yang Pengguna berikan tidak akan
        disebarluaskan kecuali apabila telah disetujui oleh Pengguna berdasarkan
        kebijakan privasi dalam Syarat dan Ketentuan atau diwajibkan oleh
        peraturan perundang-undangan yang berlaku. Bahwa dengan menggunakan
        Platform Gajiku,
      </p>
      <br />
      <p>
        Pengguna menyetujui dan mengizinkan pihak ketiga tertentu untuk dapat
        menggunakan data pribadi Pengguna yang diperlukan sehubungan dengan
        pelayanan Gajiku, termasuk namun tidak terbatas pada: Perusahaan
        penyedia layanan terkait teknologi informasi (TI) seperti layanan
        infrastruktur, komputasi awan (cloud computing), perangkat lunak,
        pengembangan analisa “Big Data” dan “Machine Learning”, termasuk
        didalamnya layanan untuk melakukan “Cleansing Data”, “Data Insight”,
        “Credit Scoring” dan lain-lain.
      </p>
      <br />
      <p>
        Pihak jasa penyedia pemrosesan transaksi pembayaran transfer dana dari
        Pihak Gajiku kepada Pengguna. Badan-badan atau lembaga Pemerintah
        (apabila dibutuhkan).
      </p>
      <br />
      <p>
        Pihak-Pihak lainnya selama dilakukan sesuai dengan peraturan
        perundang-undangan yang berlaku. Upaya Pengamanan Gajiku senantiasa
        berkomitmen dam memberikan layanan terbaiknya dengan memastikan bahwa
        informasi yang Pengguna berikan kepada Gajiku aman dan tidak dapat
        digunakan oleh pihak-pihak yang tidak bertanggungjawab.
      </p>
      <br />
      <p>
        Dengan menggunakan Platform Gajiku, Pengguna mengizinkan Gajiku untuk
        dapat melakukan segala tindakan pengamanan yang dianggap perlu untuk
        melindungi informasi Pengguna. Namun demikian, gajiku tidak dapat
        menjamin hal tersebut secara sempurna dan tidak bertanggung jawab atas
        segala kerugian, kehilangan data yang terjadi dari insiden di luar
        kendali Gajiku.
      </p>
      <br />
      <p>
        Oleh karenanya Gajiku sangat menyarankan agar Pengguna tetap menjaga
        kerahasiaan kata sandi dan/atau tidak memberikan akses penggunaan
        Platform Gajiku kepada pihak manapun dan selalu melakukan pembaharuan
        terhadap Platform Gajiku. Pengguna dengan ini memberikan persetujuannya
        kepada Gajiku untuk dapat mengirimkan, menyimpan, menggunakan dan
        mengolah data pribadi pada server yang telah ditentukan oleh pihak
        Gajiku.
      </p>
      <br />
      <p>
        Server tersebut dapat dikelola oleh Gajiku dengan bekerjasama dengan
        pihak ketiga lainnya. Meskipun demikian, Pihak Gajiku akan mengatur
        penggunaan dan pemanfaatan informasi dan data pribadi tersebut
        berdasarkan kebijakan privasi ini sesuai dengan ketentuan peraturan
        perundang-undangan yang berlaku.
      </p>
      <br />
      <p>
        FITUR LAYANAN Gajiku adalah platform berbasis teknologi yang menawarkan
        fasilitas bagi karyawan Pengguna agar dapat mendapatkan gaji yang sudah
        dihasilkan sebelum waktu gajian dengan ketentuan bahwa gaji yang dapat
        ditarik oleh Pengguna adalah maksimal 15 (lima belas) hari kerja.
      </p>
      <br />
      <p>
        Dengan menggunakan layanan Platform Gajiku, Pengguna dapat melakukan
        penarikan gaji kapan saja dan dimana saja tanpa harus menunggu waktu
        gajian tiba. Karyawan Pengguna telah mengetahui dan setuju bahwa
        perusahaan Pengguna akan melakukan pemotongan terhadap gaji yang akan
        diterima oleh karyawan Pengguna pada periode penggajian terhadap jumlah
        penarikan gaji diawal yang dilakukan oleh karyawan Pengguna melalui
        Platform Gajiku.
      </p>
      <br />
      <p>
        Karyawan Pengguna telah setuju dan mengizinkan perusahaan Pengguna untuk
        melakukan pemotongan gaji pada periode waktu pembayaran gaji Pengguna
        atas penarikan gaji diawal yang dilakukan oleh karyawan Pengguna. Sesuai
        dengan standar operasional Gajiku, proses pencairan dana hanya
        memerlukan maksimal 1 (satu) jam sejak adanya permintaan pencairan gaji
        dari karyawan Pengguna.
      </p>
      <br />
      <p>
        Dengan melakukan penarikan gaji melalui Platform Gajiku, karyawan
        Pengguna telah setuju untuk dikenakan biaya transaksi yang ditetapkan
        oleh Gajiku atas jumlah penarikan gaji dimuka. Apabila karyawan Pengguna
        tidak setuju atas jumlah biaya transaksi yang dikenakan, karyawan
        Pengguna wajib untuk menghentikan pemakaian layanan Platform Gajiku.
      </p>
      <br />
      <p>
        Gajiku akan menerbitkan laporan kepada perusahaan Pengguna dan/atau
        karyawan Pengguna sehubungan dengan penarikan gaji yang dilakukan oleh
        karyawan, yang diantaranya memuat rincian penarikan gaji di muka yang
        dilakukan oleh karyawan Pengguna.
      </p>
      <br />
      <p>
        BIAYA TRANSAKSI Gajiku tidak akan membebankan biaya dan/atau bunga
        apapun kecuali biaya transaksi kepada karyawan Pengguna atas setiap
        penarikan gaji dimuka yang dilakukan oleh karyawan Pengguna.
      </p>
      <br />
      <p>
        Biaya transaksi disini adalah adalah biaya yang dikenakan kepada
        karyawan Pengguna sebesar 3% (tiga persen) dari total jumlah gaji yang
        diambil dimuka untuk tujuan pemeliharaan sistem dan peningkatan layanan
        Gajiku. Contoh: Gaji = Rp. 1.000.000,- dikurang biaya transaksi 3% =
        maka Gaji yang akan diterima karyawan Pengguna adalah sebesar Rp.
        970.000,- Biaya transaksi yang dikenakan Gajiku bersifat tetap,
        berapapun gaji yang ditarik dimuka oleh karyawan Pengguna.
      </p>
      <br />
      <p>
        Gajiku berhak sewaktu-waktu tanpa pemberitahuan sebelumnya untuk
        melakukan perubahan atas biaya transaksi yang terdapat pada Syarat dan
        Ketentuan ini. Dengan menggunakan Platform Gajiku, Pengguna dianggap
        memahami dan menyetujui Syarat dan Ketentuan atas biaya transaksi yang
        berlaku.
      </p>
      <br />
      <p>
        PEMBATASAN TANGGUNG JAWAB Dalam melakukan penyediaan layanan, Gajiku
        akan melaksanakan dengan penuh kehati-hatian, namun demikian Gajiku
        tidak bertanggung jawab terhadap segala tindakan yang dilakukan oleh
        Pengguna sehubungan dengan pemanfaatan layanan Gajiku yang dilakukan
        dengan itikad buruk, kesalahan yang disengaja, kelalaian Pengguna.
      </p>
      <br />
      <p>
        KLAIM Pihak Pengguna dapat melakukan pengaduan dan pemberitahuan melalui
        Platform Gajiku dengan mengisi form dalam aplikasi atau dapat dikirimkan
        melalui surat elektronik (e-mail) di nadia@gajikuapp.com, sejak
        diketahui ada tindakan-tindakan yang mecurigakan diluar dari aktifitas
        yang dilakukan oleh Pengguna dalam Platform Gajiku.
      </p>
      <br />
      <p>
        Gajiku akan segera memproses pengaduan atau pemberitahuan dalam jangka
        waktu 3 (tiga) hari setelah pengaduan tersebut diterima oleh Gajiku dan
        akan segera melakukan konfirmasi kepada Pengguna. Dalam hal terdapat
        klaim penipuan sehubungan dengan pencairan gaji karyawan Perusahaan,
        Gajiku akan melakukan investigasi atas klaim tersebut berdasarkan
        prosedur operasi standar yang berlaku dalam internal Gajiku.
      </p>
      <br />
      <p>
        Gajiku akan bertindak dengan penuh kehati-hatian dan ketekunan serta
        menggunakan keterampilan dan perhatian yang sama dalam menyediakan
        layanan, tetapi tidak akan bertanggung jawab atas tindakan apa pun yang
        diambil atau dihilangkan olehnya dalam pelaksanaan layanan yang
        diberikan di sini jika tidak ada itikad buruk, kesalahan yang disengaja,
        kelalaian yang parah atau pengabaian yang ceroboh terhadap
        tugas-tugasnya.
      </p>
      <br />
      <p>
        Gajiku tidak bertanggung jawab terhadap klaim penipuan yang diajukan
        Pengguna sebagai akibat karena kelalaiannya sendiri dengan tidak menjaga
        keamanan akun Pengguna yang mengakibatkan penggunaan yang tidak sah.
        Pengguna dengan ini melepaskan tanggung jawab Gajiku terhadap ganti
        kerugian atas setiap klaim yang diakibatkan oleh kelalaian Pengguna.
      </p>
      <br />
      <p>
        PAJAK Seluruh perpajakan yang mungkin timbul sehubungan dengan
        penggunaan layanan Platform Gajiku wajib ditanggung oleh masing-masing
        pihak sehubungan dengan peraturan perundang-undangan yang berlaku.
      </p>
      <br />
      <p>
        HAK ATAS KEKAYAAN INTELEKTUAL Gajiku adalah pemilik tunggal atas nama,
        ikon, dan logo, serta segala hak kekayaan intelektual yang terkait
        dengan Situs, sistem, dan tampilan antarmuka, yang mana semua itu
        merupakan hak cipta dan merek dagang yang dilindungi oleh ketentuan
        perundang-undangan.
      </p>
      <br />
      <p>
        Pengguna dilarang keras tanpa persetujuan tertulis dari Gajiku untuk
        menggunakan, memodifikasi, atau memasang nama, ikon, logo atau merek
        tersebut. Seluruh hak atas kekayaan intelektual yang terdapat dalam
        Platform Gajiku, termasuk namun tidak terbatas pada source code platform
        dilarang keras untuk dilakukan modifikasi, karya turunan, mengadaptasi,
        menduplikasi, menyalin, menjual, membuat ulang, meretas, menjual,
        dan/atau mengeksploitasi Platform Gajiku, penggunaan Platform Gajiku
        atas akses yang tidak sah, meluncurkan program otomatis atau script,
        atau segala program apapun yang mungkin menghambat operasi dan/atau
        kinerja Platform Gajiku, atau dengan cara apapun memperbanyak atau
        menghindari struktur navigasi atau presentasi dari Platform Gajiku atau
        isinya. Pengguna hanya diperbolehkan untuk menggunakan Platform Gajiku
        untuk kebutuhan pribadi non-komersial, tidak dapat dialihkan dan tidak
        disublisensikan.
      </p>
      <br />
      <p>
        Gajiku memiliki hak sepenuhnya untuk melakukan segala tindakan hukum
        kepada Pengguna terhadap setiap pelanggaran Hak Kekayaan Intelektual
        terkait dengan Platform Gajiku.
      </p>
      <br />
      <p>
        PEMBERITAHUAN Semua pemberitahuan atau permintaan informasi kepada
        dan/atau tentang Platform Gajiku akan diproses jika dibuat secara
        tertulis dan dikirimkan ke alamat Gajiku di Jl Mandor Demong No. 07 Kel.
        Mustikasari, Kec. Mustika Jaya Bekasi 17157, atau dapat dikirimkan
        melalui surat elektronik (e-mail) di nadia@gajikuapp.com, atau dapat
        menghubungi layanan call center kami di +6281231747236.
      </p>
      <br />
      <p>
        Setiap pemberitahuan yang diberikan oleh pihak Gajiku akan dianggap
        sudah diterima oleh Pengguna tersebut, apabila: Pihak Gajiku dapat
        menunjukkan bahwa pemberitahuan tersebut, baik secara fisik maupun
        elektronik telah dikirimkan kepada Pengguna tersebut; atau Pihak Gajiku
        telah melakukan pemberitahuan tersebut di Platform Gajiku yang dapat
        diakses oleh umum tanpa dikenakan biaya apapun.
      </p>
      <br />
      <p>
        PENYELESAIAN SENGKETA Segala perselisihan dan perbedaan pendapat yang
        mungkin timbul dalam pelaksanaan Syarat dan Ketentuan ini akan
        diselesaikan secara musyawarah, dan apabila timbul persoalan yang tidak
        dapat diselesaikan secara musyawarah, dalam jangka waktu 30 (tiga puluh)
        hari setelah terjadinya perselisihan, maka perselisihan pengaduan
        Pengguna wajib diselesaikan terlebih dahulu melalui forum dengan Gajiku.
      </p>
      <br />
      <p>
        Dalam hal penyelesaian pengaduan Pengguna tidak tercapai setelah 30
        (tiga puluh) hari, maka Pengguna dan Gajiku sepakat untuk menyelesaikan
        sengketa tersebut melalui Pengadilan Negeri Jakarta Selatan.
      </p>
      <br />
      <p>
        HUKUM YANG BERLAKU Syarat dan Ketentuan ini diatur dan ditafsirkan serta
        dilaksanakan berdasarkan hukum yang berlaku di Negara Republik Indonesia
        dan KEADAAN MEMAKSA Gajiku tidak akan bertanggung jawab atas
        keterlambatan atau kegagalan untuk melakukan kewajibannya berdasarkan
        Syarat dan Ketentuan ini, jika penundaan atau kegagalan tersebut
        diakibatkan oleh peristiwa atau keadaan di luar kendali yang wajar,
        termasuk namun tidak terbatas pada peristiwa-peristiwa kebakaran,
        bencana alam (seperti gempa bumi, banjir, angin topan, petir), pemogokan
        massal, huru-hara, peperangan, kerusakan server, adanya perubahan
        terhadap peraturan perundang-undangan, dan kondisi di bidang ekonomi,
        keuangan, kerusakan sistem transaksi pada Platform Gajiku, serta
        terganggunya sistem penyelesaian transaksi pada Platform Gajiku.
      </p>
      <br />
      <p>
        LAIN-LAIN Pengguna dengan tegas menyetujui bahwa ketentuan Pasal 1266
        Kitab Undang-Undang Hukum Perdata Indonesia yang mewajibkan adanya
        pengesahan atau persetujuan Pengadilan untuk dapat mengakhiri Syarat dan
        Ketentuan ini tidak berlaku.
      </p>
      <br />
      <p>
        Gajiku tanpa pemberitahuan sebelumnya berhak untuk melakukan
        tindakan-tindakan yang diperlukan, apabila ditemukan adanya tindakan
        kecurangan/manipulasi, pencucian uang atau pelanggaran yang dilakukan
        oleh Pengguna dan setiap pelanggaran yang dilakukan oleh Pengguna akan
        diproses berdasarkan ketentuan hukum yang berlaku di Indonesia.
      </p>
      <br />
      <p>
        Gajiku memiliki hak untuk menginvestigasi dan menuntut hak Gajiku atas
        setiap pelanggaran terhadap perjanjian Syarat dan Ketentuan ini
        sepanjang dimungkinkan dan diperkenankan oleh hukum yang berlaku di
        Indonesia.
      </p>
      <br />
      <p>
        Dengan ini Pengguna mengakui dan menyetujui bahwa Gajiku memiliki hak
        untuk memonitor setiap akses penggunaan Platform Gajiku, untuk
        memastikan Pengguna mematuhi syarat dan ketentuan Penggunaan ini, serta
        mematuhi ketentuan peraturan perundang-undangan yang berlaku di
        Indonesia.
      </p>
      <br />
      <p>
        Syarat dan Ketentuan ini dibuat dalam Bahasa Indonesia dan Bahasa
        Inggris. Bila terdapat inkonsistensi atau perbedaan interpretasi antara
        Bahasa Indonesia dan Bahasa Inggris, maka versi Bahasa Indonesia yang
        akan berlaku.
      </p>
      <br />
      <p>
        Dengan ini, Pengguna Platform Gajiku menyatakan dan menjamin bahwa semua
        informasi yang diberikan oleh Pengguna kepada Gajiku adalah benar dan
        akurat, dalam hal Pengguna menyampaikan informasi yang tidak benar, maka
        Gajiku berhak untuk menghentikan, menutup akses Pengguna ke Platform
        Gajiku, menutup dan/atau membatalkan akun Pengguna dalam Platform
        Gajiku.
      </p>
      <br />
      <p>
        Gajiku mencadangkan semua hak yang tidak secara tegas diberikan di sini.
        Gajiku dapat mengubah Syarat dan Ketentuan ini setiap saat dengan
        mengumumkan Syarat dan Ketentuan yang direvisi di Platform Gajiku.
        Penggunaan Platform Gajiku oleh Pengguna setelah perubahan tersebut
        diumumkan dianggap sebagai penerimaan Pengguna terhadap Syarat dan
        Ketentuan yang direvisi tersebut. Dalam hal Pengguna tidak setuju
        terhadap Syarat dan Ketentuan ini, harap tidak mengakses, menelusuri
        ataupun menggunakan Platform Gajiku. Syarat dan Ketentuan ini terakhir
        kali diubah: 4 November, 2020.
      </p>
    </div>
  );
};

export default TncBahasa;
