import { GlobalLoginTokens, Login, Register } from "../_interfaces";
import request from "../_network/request";
import { ApiSuccessResponse } from "../_network/response";
const authUrl = "/auth";

const login = (payload: Login) => {
  return request({
    url: `${authUrl}/login`,
    method: "POST",
    data: payload,
  });
};

const login_v2 = (
  payload: Login,
): Promise<ApiSuccessResponse<GlobalLoginTokens>> => {
  return request({
    url:
      process.env.REACT_APP_BASE_URL_V2 +
      `${authUrl}/login/global/employee/phone`,
    method: "POST",
    data: {
      phoneNumber: "+62" + payload.username,
      password: payload.password,
    },
  });
};

const register = (payload: Register) => {
  return request({
    url: `${authUrl}/signup`,
    method: "POST",
    data: payload,
  });
};

const claimAccountSendOTP = (phoneNumber: string) => {
  return request({
    url: `${authUrl}/claim-account/send-otp`,
    method: "POST",
    data: {
      phoneNumber: phoneNumber,
    },
  });
};

const claimAccountVerifyOTP = (phoneNumber: string, otpCode: string) => {
  return request({
    url: `${authUrl}/claim-account/verify-otp`,
    method: "POST",
    data: {
      phoneNumber: phoneNumber,
      otpCode: otpCode,
    },
  });
};

const resetPasswordSubmitByToken = (token: string, newPassword: string) => {
  return request({
    url: `${authUrl}/reset-password/` + token,
    method: "POST",
    data: {
      newPassword: newPassword,
    },
  });
};

export const AuthServices = {
  login,
  register,
  claimAccountSendOTP,
  claimAccountVerifyOTP,
  resetPasswordSubmitByToken,
  login_v2,
};
