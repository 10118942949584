import { Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import {
  PresenceDataFormatted,
  PresenceDataRes,
} from "../../../../../../_interfaces";
import { PresenceServices } from "../../../../../../_services";

const usePermission = (
  initialValue: Array<any>,
): [
  Array<any>,
  () => void,
  (ids: Array<number>) => void,
  (id: number) => void,
  (id: number, reason: string) => void,
] => {
  const dispatch = useDispatch();
  const [data, setData] = useState(initialValue);

  const getData = async () => {
    try {
      dispatch(showLoading());
      const permissions = await PresenceServices.getSubordinatePermissions();
      const formatted: Array<PresenceDataFormatted> =
        permissions.data.permissions.map(
          (item: PresenceDataRes, i: number): PresenceDataFormatted => {
            const startAt = new Date(item.clockIn);
            const endAt = new Date(item.clockOut);
            return {
              ...item,
              startAt: startAt
                .toLocaleDateString("id-ID", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .split(" ")[1],
              endAt: endAt
                .toLocaleDateString("id-ID", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .split(" ")[1],
              date: item.scheduleDate,
              description: item.description,
              type: "Permission",
            };
          },
        );
      setData(formatted);
      dispatch(hideLoading());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const bulk = async (ids: Array<number>) => {
    try {
      dispatch(showLoading());
      const bulk = await PresenceServices.approveBulkPermission(ids);
      dispatch(hideLoading());
      dispatch(showAlert("Success approve", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const approve = async (id: number) => {
    try {
      dispatch(showLoading());
      const action = await PresenceServices.approvePermission(id);
      dispatch(hideLoading());
      dispatch(showAlert("Success approve", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const reject = async (id: number, reason: string) => {
    try {
      dispatch(showLoading());
      const action = await PresenceServices.rejectPermission(id, reason);
      dispatch(hideLoading());
      dispatch(showAlert("Success reject", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  return [data, getData, bulk, approve, reject];
};

export default usePermission;
