import RequestActionTypes from "./request.types";

interface RequestState {
  attendanceAllowed: boolean;
  bankAccountNumber: string;
  bankCode: string;
  bankVerificationStatus: string | undefined;
  companyID: string;
  ewaAllowed: boolean;
  ewaCurrentLimit: number;
  ewaMinimumRequestAmount: number;
  ewaMaximumRequestAmount: number;
  ewaMonthlyLimit: number;
  hierarchy: string;
  id: string;
  isBankSetup: boolean;
  isSupervisor: boolean;
  locationCode: string;
  locationName: string;
  name: string;
  nip: string;
  phoneNumber: string;
  pkID: number | null;
  userID: string;
  employeeID: string;
  failure: boolean;
  loading: boolean;
  fee: number;
  amountWage: number;
  principal: number;
  statusRequest: string;
  transactionCap: number;
  wageRequestCount: number;
  ewaConfirmationMessage: string;
  bankAccountName: string;
  ewaWalletBalance: number;
  companyName: string;
}

const INITIAL_STATE: RequestState = {
  attendanceAllowed: false,
  bankAccountNumber: "",
  bankCode: "",
  bankVerificationStatus: "",
  companyID: "",
  ewaAllowed: false,
  ewaCurrentLimit: 0,
  ewaMinimumRequestAmount: 0,
  ewaMaximumRequestAmount: 0,
  ewaMonthlyLimit: 0,
  hierarchy: "",
  id: "",
  isBankSetup: false,
  isSupervisor: false,
  locationCode: "",
  locationName: "",
  name: "",
  nip: "",
  phoneNumber: "",
  pkID: null,
  userID: "",
  employeeID: "",
  failure: false,
  loading: false,
  fee: 0,
  amountWage: 0,
  principal: 0,
  statusRequest: "",
  transactionCap: 0,
  wageRequestCount: 0,
  ewaConfirmationMessage: "",
  bankAccountName: "",
  ewaWalletBalance: 0,
  companyName: "",
};

const RequestReducer = (state = INITIAL_STATE, action: any): RequestState => {
  switch (action.type) {
    case RequestActionTypes.REQUEST:
      return {
        ...state,
        loading: true,
        failure: false,
      };
    case RequestActionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        pkID: action.payload.pkID,
        userID: action.payload.userID,
        employeeID: action.payload.id,
        companyID: action.payload.companyID,
        ewaAllowed: action.payload.ewaAllowed,
        ewaCurrentLimit: action.payload.ewaCurrentLimit,
        ewaMonthlyLimit: action.payload.ewaMonthlyLimit,
        ewaMinimumRequestAmount: action.payload.ewaMinimumRequestAmount,
        ewaMaximumRequestAmount: action.payload.ewaMaximumRequestAmount,
        name: action.payload.name,
        phoneNumber: action.payload.phoneNumber,
        nip: action.payload.nip,
        locationCode: action.payload.locationCode,
        locationName: action.payload.locationName,
        isBankSetup: action.payload.isBankSetup,
        bankAccountNumber: action.payload.bankAccountNumber,
        bankVerificationStatus: action.payload.bankVerificationStatus,
        bankCode: action.payload.bankCode,
        attendanceAllowed: action.payload.attendanceAllowed,
        isSupervisor: action.payload.isSupervisor,
        hierarchy: action.payload.hierarchy,
        transactionCap: action.payload.transactionCap,
        wageRequestCount: action.payload.wageRequestCount,
        loading: false,
        statusRequest: "",
        ewaConfirmationMessage: action.payload.ewaConfirmationMessage,
        bankAccountName: action.payload.bankAccountName,
        ewaWalletBalance: action.payload.ewaWalletBalance,
        companyName: action.payload.companyName,
      };
    case RequestActionTypes.REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
      };
    case RequestActionTypes.BANK_UPDATE:
      return {
        ...state,
        loading: true,
        failure: false,
      };
    case RequestActionTypes.BANK_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        statusRequest: "success",
      };
    case RequestActionTypes.BANK_UPDATE_FAILURE:
      return {
        ...state,
        failure: true,
        loading: false,
      };
    case RequestActionTypes.WAGE_REQUEST:
      return {
        ...state,
        failure: false,
        loading: true,
      };
    case RequestActionTypes.WAGE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        statusRequest: "success",
      };
    case RequestActionTypes.WAGE_REQUEST_FAILURE:
      return {
        ...state,
        failure: true,
        loading: false,
      };
    case RequestActionTypes.WAGE_CHECK:
      return {
        ...state,
        failure: false,
        loading: true,
      };
    case RequestActionTypes.WAGE_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        fee: action.payload.fee,
        amountWage: action.payload.amount,
        principal: action.payload.principal,
      };
    case RequestActionTypes.WAGE_CHECK_FAILURE:
      return {
        ...state,
        fee: 0,
        amountWage: 0,
        principal: 0,
        failure: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default RequestReducer;
