import { Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import {
  PresenceDataFormatted,
  PresenceDataRes,
} from "../../../../../../_interfaces";
import { PresenceServices } from "../../../../../../_services";

const usePresence = (
  initialValue: Array<any>,
): [
  Array<any>,
  () => void,
  (ids: Array<number>) => void,
  (id: number) => void,
  (id: number, reason: string) => void,
] => {
  const dispatch = useDispatch();
  const [data, setData] = useState(initialValue);

  const getData = async () => {
    try {
      dispatch(showLoading());
      const presences = await PresenceServices.getSubordinatePresence();
      const formatted: Array<PresenceDataFormatted> =
        presences.data.presence.map(
          (item: PresenceDataRes, i: number): PresenceDataFormatted => {
            const startAt = new Date(item.clockIn);
            const endAt = new Date(item.clockOut);
            return {
              ...item,
              startAt: startAt.toLocaleDateString("id-ID", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }),
              endAt: endAt.toLocaleDateString("id-ID", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }),
              date: item.scheduleDate,
              description: item.description,
              type: item.presenceType,
            };
          },
        );
      setData(formatted);
      dispatch(hideLoading());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const bulk = async (ids: Array<number>) => {
    try {
      dispatch(showLoading());
      const bulk = await PresenceServices.approveBulkPresence(ids);
      dispatch(hideLoading());
      dispatch(showAlert("Success approve", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const approve = async (id: number) => {
    try {
      dispatch(showLoading());
      const action = await PresenceServices.approvePresence(id);
      dispatch(hideLoading());
      dispatch(showAlert("Success approve", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const reject = async (id: number, reason: string) => {
    try {
      dispatch(showLoading());
      const action = await PresenceServices.rejectPresence(id, reason);
      dispatch(hideLoading());
      dispatch(showAlert("Success reject", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  return [data, getData, bulk, approve, reject];
};

export default usePresence;
