import React, { FunctionComponent, useEffect, useState } from "react";
import "./App.css";
import { Login } from "./pages/Login";
import { Redirect, Route, Switch } from "react-router-dom";
import { Dashboard } from "./pages/DashboardV2";
import { useFullScreenHandle } from "react-full-screen";
import { useAppSelector } from "./redux/hooks";
import { useToaster } from "./utils/toaster";
import { useDispatch } from "react-redux";
import {
  hideAlert,
  setFCMToken,
  setPermissions,
} from "./redux/global/global.actions";
import { Modal } from "./components/Modal/Index";

const App: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { token, alertMessage, alertType, showAlert } = useAppSelector(
    (state) => ({
      ...state.auth,
      ...state.globalState,
    }),
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const toaster = useToaster();
  const fullScreenHandle = useFullScreenHandle();

  const media = async () => {
    console.log(navigator.userAgent);
  };

  useEffect(() => {
    window.addEventListener("flutterInAppWebViewPlatformReady", () => {
      (window as any).flutter_inappwebview
        .callHandler("communicationFlutter", "getFirebaseUserToken")
        .then((result: any) => {
          dispatch(setFCMToken(result));
        });
    });
    if (navigator.userAgent.includes("wv")) {
      window.addEventListener("flutterInAppWebViewPlatformReady", () => {
        (window as any).flutter_inappwebview
          .callHandler("communicationFlutter", "getStatusPermission")
          .then((result: any) => {
            dispatch(setPermissions(result));
          });
      });
    } else {
      dispatch(
        setPermissions({
          location: true,
          camera: true,
        }),
      );
    }
  }, []);

  useEffect(() => {
    media();
    fullScreenHandle.enter();
  }, []);

  useEffect(() => {
    setIsLoggedIn(token !== null);
  }, [token]);

  useEffect(() => {
    // dirty code for handle toaster, need improvement for change toaster behavior
    if (alertType && showAlert) {
      toaster?.current?.show({
        message: alertMessage,
        intent: alertType,
      });
      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
    }
  }, [alertMessage, alertType, showAlert]);

  return (
    <>
      <Switch>
        {!isLoggedIn ? (
          <>
            <Route path="/login">
              <Login baseRoute={"/login"} loggedInRoute={"/dashboard"} />
              {/* <Help /> */}
            </Route>
            <Route path={"/"}>
              <Redirect to={"/login"} />
            </Route>
          </>
        ) : (
          <>
            <Route path="/">
              <Dashboard baseUrl={"/dashboard"} />
              {/* <Help /> */}
            </Route>
          </>
        )}
      </Switch>
      <Modal />
    </>
  );
};

export default App;
