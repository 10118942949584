const presenceActionTypes = {
  GET_PRESENCE_HISTORY: "GET_PRESENCE_HISTORY",
  GET_PRESENCE_HISTORY_SUCCESS: "GET_PRESENCE_HISTORY_SUCCESS",
  GET_PRESENCE_HISTORY_FAILURE: "GET_PRESENCE_HISTORY_FAILURE",
  REQUEST_REMOTE: "REQUEST_REMOTE",
  REQUEST_REMOTE_SUCCESS: "REQUEST_REMOTE_SUCCESS",
  REQUEST_REMOTE_FAILURE: "REQUEST_REMOTE_FAILURE",
  GET_SUBORDINATES: "GET_SUBORDINATES",
  GET_SUBORDINATES_SUCCESS: "GET_SUBORDINATES_SUCCESS",
  GET_SUBORDINATES_FAILURE: "GET_SUBORDINATES_FAILURE",
};

export default presenceActionTypes;
