import { useDispatch } from "react-redux";
import {
  setCameraPermission,
  setLocationPermission,
} from "../redux/global/global.actions";

const usePermissions = (): {
  reqCameraPermission: () => void;
  reqLocationPermission: () => void;
  reqFilePermission: () => void;
} => {
  const dispatch = useDispatch();
  const reqCameraPermission = () => {
    if (navigator.userAgent.includes("wv")) {
      (window as any).flutter_inappwebview
        .callHandler("communicationFlutter", "reqCameraPermission")
        .then((result: any) => {
          dispatch(setCameraPermission(result));
        });
    }
  };

  const reqLocationPermission = () => {
    if (navigator.userAgent.includes("wv")) {
      (window as any).flutter_inappwebview
        .callHandler("communicationFlutter", "reqLocationPermission")
        .then((result: any) => {
          dispatch(setLocationPermission(result));
        });
    }
  };

  const reqFilePermission = () => {
    (window as any).communicationFlutter.postMessage("reqFilePermission");
  };

  return { reqCameraPermission, reqLocationPermission, reqFilePermission };
};

export default usePermissions;
