import { Intent } from "@blueprintjs/core";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import { getCutiList } from "../../../../../../redux/permit/permit.actions";
import { LeaveServices } from "../../../../../../_services/leave.services";

const useCuti = () => {
  const dispatch = useDispatch();

  const cancelCuti = async (id: number) => {
    try {
      dispatch(showLoading());
      const cancel = await LeaveServices.cancelCuti(id);
      dispatch(getCutiList());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert("Cancel overtime failure", Intent.DANGER));
    }
  };

  return [cancelCuti];
};

export default useCuti;
