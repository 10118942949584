import { OverTimeInterface } from "../_interfaces";
import request from "../_network/request";

const createOvertime = (data: any, overtimeType: "offday" | "workday") => {
  return request({
    url: `overtime/request/create/${overtimeType}`,
    method: "POST",
    data: data,
  });
};

const getOvertime = () => {
  return request({
    url: `overtime/history/my`,
    method: "GET",
  });
};

const cancelOvertime = (id: number) => {
  return request({
    url: `overtime/request/cancel/${id}`,
    method: "POST",
  });
};

const getSubordinateOvertime = () => {
  return request({
    url: `overtime/history/subordinate`,
    method: "GET",
  });
};

const approveOvertime = (id: number) => {
  return request({
    url: `overtime/request/approve/${id}`,
    method: "POST",
  });
};

const rejectOvertime = (id: number, rejectReason: string) => {
  return request({
    url: `overtime/request/reject/${id}`,
    method: "POST",
    data: { rejectReason },
  });
};

const approveBulkOvertime = (ids: Array<number>) => {
  return request({
    url: `overtime/request/approve-bulk`,
    method: "POST",
    data: {
      requestIds: ids,
    },
  });
};

export const OvertimeServices = {
  createOvertime,
  getOvertime,
  cancelOvertime,
  getSubordinateOvertime,
  approveOvertime,
  rejectOvertime,
  approveBulkOvertime,
};
