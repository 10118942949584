/* eslint-disable react-hooks/exhaustive-deps */
import { Icon, Intent } from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  setHeader,
  showAlert,
} from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import "./clockoption.style.scss";
import QRCode from "react-qr-code";
import useQRParser, { ActionType } from "../../../../../../_helper/QRParser";
import { useDispatch } from "react-redux";
import useCheckPresence from "./useCheckPresence";

const ClockOption = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { todaySchedule, name, employeeID } = useAppSelector((state) => ({
    ...state.shift,
    ...state.requestState,
  }));
  const [formattedText, fromText, QRData, fromPicture] = useQRParser();
  const [position, setPosition] = useState({
    longitude: 0,
    latitude: 0,
  });
  const [checkList, getList] = useCheckPresence();
  const [checkbox, setCheckbox] = useState<{ [attr: string]: any }>({});

  useEffect(() => {
    dispatch(
      setHeader({
        title: todaySchedule?.hasClockIn ? "Clock Out" : "Clock In",
        hasBack: true,
      }),
    );
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setPosition((state) => ({
          ...state,
          longitude: pos.coords.longitude,
          latitude: pos.coords.latitude,
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
    if (!todaySchedule?.hasClockIn) getList();
  }, [todaySchedule?.hasClockIn, employeeID]);

  useEffect(() => {
    fromText({
      scheduleId: todaySchedule?.id,
      subOrdinateId: employeeID,
      latitude: position.latitude,
      longitude: position.longitude,
      employeeName: name,
      shiftName: todaySchedule?.shiftName,
      type: todaySchedule?.hasClockIn ? "clock-out" : "clock-in",
    });
  }, [position, todaySchedule]);

  useEffect(() => {
    if (formattedText !== "") fromPicture(formattedText);
  }, [formattedText]);

  const isChecked = (): boolean => {
    for (const key in checkList) {
      if (Object.prototype.hasOwnProperty.call(checkList, key)) {
        if (!checkList[key]) return false;
      }
    }

    return true;
  };

  return (
    <div className="choose-page">
      {todaySchedule?.hasClockIn && (
        <div className="card mb-3">
          <div className="card-body">
            <p className="text-info">Office hour</p>
            <h3>{"09:00"}</h3>
            <small className="text-secondary">{todaySchedule.shiftName}</small>
            <div className="row-custom mt-2">
              <div className="flex">
                <p className="text-info">Check In</p>
                <h3>{"09:00:00"}</h3>
              </div>
              <div className="flex">
                <p className="text-info">Check Out</p>
                <h3>--:--:--</h3>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <QRCode value={formattedText} />
      </div>
      {!todaySchedule?.hasClockIn && checkList.length > 0 && (
        <div className="reminder-group">
          {checkList.map((item, i) => (
            <div className="form-group" key={i}>
              <input
                type="checkbox"
                name={`reminder${i}`}
                id={`reminder${i}`}
                checked={checkbox[`reminder${i}`] || false}
                onChange={(e) =>
                  setCheckbox((temp) => ({
                    ...temp,
                    [`reminder${i}`]: e.target.checked,
                  }))
                }
              />
              <label htmlFor={`reminder${i}`}>{item}</label>
            </div>
          ))}
        </div>
      )}
      {true && (
        <div
          onClick={() => {
            if (isChecked() || todaySchedule?.hasClockIn) {
              history.push(
                todaySchedule?.hasClockIn
                  ? `/attendance/clockOut`
                  : `/attendance/clockIn`,
              );
            } else {
              dispatch(
                showAlert(
                  "Silahkan check kesiapan terlebih dahulu",
                  Intent.DANGER,
                ),
              );
            }
          }}
        >
          <div className="row-custom btn-clock">
            <div>
              <p>Self clock in / clock out</p>
              <small className="text-secondary">
                Supervisor not around? Use your location and take selfie to
                clock in
              </small>
            </div>
            <div>
              <h3 className="icon">
                <Icon icon="chevron-right" iconSize={30} />
              </h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClockOption;
