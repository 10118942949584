import AuthActionTypes from "./auth.types";
import { batch } from "react-redux";
import GlobalActionTypes from "../global/global.types";
import { AuthServices } from "../../_services";
import { Intent } from "@blueprintjs/core";

export const loginRedux = (username: string, password: string) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const login_resp = await AuthServices.login_v2({ username, password });
      if (login_resp.data.loginToken.length <= 0) {
        dispatch({
          type: AuthActionTypes.LOGIN_FAILURE,
          payload: "Password salah",
        });
        return;
      }
      console.log(login_resp);
      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: login_resp.data.loginToken[0].authToken,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: "Login Success",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN_FAILURE, payload: error });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const logoutRedux = () => {
  return async (dispatch: any) => {
    dispatch({ type: AuthActionTypes.LOGOUT });
  };
};
