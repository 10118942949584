import { TodayScheduleI } from "../../_interfaces";
import ShiftActionTypes from "./shift.types";

interface currentShift {
  companyId: string;
  employeeCount: number;
  endTime: string;
  id: number;
  scheduleDate: string;
  shiftDetailId: number;
  shiftId: number;
  startTime: string;
  status: true;
  timezone: string;
}

interface ShiftState {
  currentShift: currentShift | undefined;
  todaySchedule: TodayScheduleI | undefined;
}

const INITIAL_STATE: ShiftState = {
  currentShift: undefined,
  todaySchedule: undefined,
};

const ShiftReducer = (state = INITIAL_STATE, action: any): ShiftState => {
  switch (action.type) {
    case ShiftActionTypes.GET_INCOMING_SHIFT:
      return {
        ...state,
        todaySchedule: undefined,
      };
    case ShiftActionTypes.GET_INCOMING_SHIFT_SUCCESS:
      return {
        ...state,
        todaySchedule: action.payload,
      };
    case ShiftActionTypes.GET_INCOMING_SHIFT_FAILURE:
      return {
        ...state,
        todaySchedule: undefined,
      };
    case ShiftActionTypes.GET_CURRENT_SHIFT:
      return {
        ...state,
        currentShift: undefined,
      };
    case ShiftActionTypes.GET_CURRENT_SHIFT_SUCCESS:
      return {
        ...state,
        currentShift: action.payload,
      };
    case ShiftActionTypes.GET_CURRENT_SHIFT_FAILURE:
      return {
        ...state,
        currentShift: undefined,
      };
    case ShiftActionTypes.DEFAULT_CURRENT_SHIFT:
      return {
        ...state,
        currentShift: undefined,
      };
    default:
      return state;
  }
};

export default ShiftReducer;
