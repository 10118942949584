import React, { useEffect, useState } from "react";
import PresenceCard from "../../components/calendar-card/PresenceCard";
import OvertimeCard from "../../components/calendar-card/OvertimeCard";
import LeaveCard from "../../components/calendar-card/LeaveCard";
import "./approval.style.scss";
import { Dialog } from "@blueprintjs/core";

const CardContent = ({
  item,
  i,
  tab,
  approveOvertime,
  rejectOvertime,
  approveLeave,
  rejectLeave,
  approvePresence,
  rejectPresence,
  approvePermission,
  rejectPermission,
}: {
  item: any;
  i: number;
  tab: number;
  approveOvertime: (id: number) => void;
  rejectOvertime: (id: number, reason: string) => void;
  approvePresence: (id: number) => void;
  rejectPresence: (id: number, reason: string) => void;
  approveLeave: (id: number) => void;
  rejectLeave: (id: number, reason: string) => void;
  approvePermission: (id: number) => void;
  rejectPermission: (id: number, reason: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const approve = (id: number) => {
    switch (tab) {
      case 2:
        approveOvertime(id);
        break;
      case 0:
        approvePresence(id);
        break;
      case 3:
        approvePermission(id);
        break;
      case 1:
        approveLeave(id);
        break;
      default:
        break;
    }
  };

  const reject = (id: number, reason: string) => {
    switch (tab) {
      case 2:
        rejectOvertime(id, reason);
        setIsOpen(false);
        break;
      case 0:
        rejectPresence(id, reason);
        setIsOpen(false);
        break;
      case 3:
        rejectPermission(id, reason);
        setIsOpen(false);
        break;
      case 1:
        rejectLeave(id, reason);
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  switch (tab) {
    case 2:
      return (
        <>
          <OvertimeCard
            htmlFor={`${i}`}
            title={item.name}
            date={item.date}
            clockIn={item.startAt}
            clockOut={item.endAt}
            status={item.status}
            type={item.type}
            photo={item.photo}
            hasAction
            approve={() => approve(item.id)}
            reject={() => setIsOpen(true)}
            description={item?.description}
          />
          <Dialog
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isOpen={isOpen}
            onClose={onClose}
          >
            <Popup handleSubmit={(reason) => reject(item.id, reason)} />
          </Dialog>
        </>
      );
    case 0:
      return (
        <>
          <PresenceCard
            htmlFor={`${i}`}
            title={item.name}
            date={item.date}
            clockIn={item.startAt}
            clockOut={item.endAt}
            status={item.status}
            type={item.type}
            photo={item.photo}
            hasAction
            approve={() => approve(item.id)}
            reject={() => setIsOpen(true)}
            description={item?.description}
          />
          <Dialog
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isOpen={isOpen}
            onClose={onClose}
          >
            <Popup handleSubmit={(reason) => reject(item.id, reason)} />
          </Dialog>
        </>
      );
    case 3:
      return (
        <>
          <OvertimeCard
            htmlFor={`${i}`}
            title={item.employeeName}
            date={item.date}
            clockIn={item.startAt}
            clockOut={item.endAt}
            status={item.status}
            photo={item.photo}
            type={item.type}
            hasAction
            approve={() => approve(item.id)}
            reject={() => setIsOpen(true)}
            description={item?.description}
          />
          <Dialog
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isOpen={isOpen}
            onClose={onClose}
          >
            <Popup handleSubmit={(reason) => reject(item.id, reason)} />
          </Dialog>
        </>
      );
    case 1:
      return (
        <>
          <LeaveCard
            htmlFor={`${i}`}
            title={item.name}
            date={item.date}
            clockIn={item.startAt}
            clockOut={item.endAt}
            status={item.status}
            type={item.type}
            photo={item.photo}
            hasAction
            approve={() => approve(item.id)}
            reject={() => setIsOpen(true)}
            description={item?.description}
          />
          <Dialog
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isOpen={isOpen}
            onClose={onClose}
          >
            <Popup handleSubmit={(reason) => reject(item.id, reason)} />
          </Dialog>
        </>
      );
    default:
      return <></>;
  }
};

const Popup = ({ handleSubmit }: { handleSubmit: (rs: string) => void }) => {
  const [reason, setReason] = useState("");

  return (
    <div className="popup-reject">
      <div className="title">
        <h3>Reject Form</h3>
      </div>
      <div className="form-group">
        <label htmlFor="reject">Reason</label>
        <textarea
          name="rejectReason"
          id="reject"
          onChange={(e) => setReason(e.target.value)}
        ></textarea>
      </div>
      <button className="btn" onClick={() => handleSubmit(reason)}>
        Reject
      </button>
    </div>
  );
};

export default CardContent;
