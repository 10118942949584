import { Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import {
  OvertimeDataFormatted,
  OvertimeDataRes,
} from "../../../../../../_interfaces";
import { OvertimeServices } from "../../../../../../_services/overtime.services";

const useOvertime = (
  initialValue: Array<any>,
): [
  Array<any>,
  () => void,
  (ids: Array<number>) => void,
  (id: number) => void,
  (id: number, reason: string) => void,
] => {
  const dispatch = useDispatch();
  const [data, setData] = useState(initialValue);

  const getData = async () => {
    try {
      dispatch(showLoading());
      const overtimes = await OvertimeServices.getSubordinateOvertime();
      const formatted: Array<OvertimeDataFormatted> =
        overtimes.data.overtimeRequests.map(
          (item: OvertimeDataRes): OvertimeDataFormatted => {
            const startAt = new Date(item.startAt);
            const endAt = new Date(item.endAt);
            return {
              ...item,
              startAt: startAt.toLocaleDateString("id-ID", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }),
              endAt: endAt.toLocaleDateString("id-ID", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }),
              date: startAt.toISOString().split("T")[0],
              description: item.description,
              type: item.overtimeType,
            };
          },
        );
      setData(formatted);
      dispatch(hideLoading());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const bulk = async (ids: Array<number>) => {
    try {
      dispatch(showLoading());
      const presence = await OvertimeServices.approveBulkOvertime(ids);
      dispatch(hideLoading());
      dispatch(showAlert("Success approve", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const approve = async (id: number) => {
    try {
      dispatch(showLoading());
      const action = await OvertimeServices.approveOvertime(id);
      dispatch(hideLoading());
      dispatch(showAlert("Success approve", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  const reject = async (id: number, reason: string) => {
    try {
      dispatch(showLoading());
      const action = await OvertimeServices.rejectOvertime(id, reason);
      dispatch(hideLoading());
      dispatch(showAlert("Success reject", Intent.SUCCESS));
      getData();
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  return [data, getData, bulk, approve, reject];
};

export default useOvertime;
