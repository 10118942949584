import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import { getPresenceHistory } from "../../../../../../redux/presence/presence.actions";
import CalendarCard from "../../components/calendar-card/HistoryCard";
import "./approval.style.scss";
import usePresence from "./usePresence";

const PresenceHistoryPage = () => {
  const dispatch = useDispatch();
  const { presences } = useAppSelector((state) => state.presence);
  const [cancelRemote] = usePresence();

  useEffect(() => {
    dispatch(setHeader({ title: "Presence History", hasBack: true }));
    dispatch(getPresenceHistory());
  }, []);

  return (
    <div className="presence-history">
      <div className="list-view">
        {presences?.length === 0 && (
          <div style={{ textAlign: "center" }}>Belum ada data</div>
        )}
        {presences?.map((item, i) => (
          <CalendarCard
            key={i}
            title="Presence History"
            date={item.scheduleDate}
            clockIn={new Date(item.clockIn).toLocaleDateString("id-ID", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
            clockOut={new Date(item.clockOut).toLocaleDateString("id-ID", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
            type="presence"
            photo={item.photo}
            status={item.status}
            description={item.presenceType}
            hasAction={item.status === "pending"}
            reject={() => cancelRemote(item.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default PresenceHistoryPage;
