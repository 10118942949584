import React, { FunctionComponent } from "react";

import "./style.scss";

export const Modal: FunctionComponent = () => {
  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <p className="promotion-message">
            For better experience we recommend to update your application
          </p>
          <div className="promotion">
            <a
              href="https://play.google.com/store/apps/details?id=com.gajiku.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Get it on Google Play"
                className="playstore"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </div>
          <div className="promotion">
            <a
              href="https://apps.apple.com/id/app/gajiku/id6444016967?itsct=apps_box_badge&amp;itscg=30200"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Download on the App Store"
                className="appstore"
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1670457600"
              />
            </a>
          </div>
          <div className="promotion">
            <p>Or go to our new website at</p>
            <p>
              <a
                href="https://employee.gajiku.app?utm_source=pwa"
                target="_blank"
                rel="noreferrer"
              >
                https://employee.gajiku.app
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
