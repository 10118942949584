import React from "react";
import list_checked from "../../../../assets/list_checked.svg";
import { useHistory } from "react-router";
import { useSpawnRender } from "./index";
import { LoanRender } from "./interface";

const DownloadingForm = () => {
  const history = useHistory();
  const [, setRender] = useSpawnRender();
  return (
    <>
      <div
        style={{
          marginTop: " 3rem",
          position: "absolute",
          left: "0",
          right: "0",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          fontWeight: "bold",
        }}
      >
        <p>Berhasil</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          top: "50%",
          margin: "0",
          transform: "translateY(-50%)",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
        }}
      >
        <div
          style={{ paddingLeft: "3rem", paddingRight: "3rem", margin: "1rem" }}
        >
          <div className="icon-card">
            <img src={list_checked} />
          </div>
          <h3>Selamat, formulir pengajuan berhasil dibuat</h3>
        </div>
        <div
          style={{
            paddingLeft: "3rem",
            paddingRight: "3rem",
            marginTop: " 1rem",
            marginBottom: "3rem",
          }}
        >
          <p style={{ fontWeight: "lighter", color: "grey" }}>
            Silahkan upload kembali formulir yang sudah di tanda tangan lalu
            upload kembali di
          </p>
          <p
            style={{ textDecoration: "underline", color: "#1bb8ba" }}
            onClick={() => setRender({ spawn: LoanRender.HOME })}
          >
            Halaman Riwayat
          </p>
        </div>
        <div
          className={"btn-next-wrapper"}
          style={{
            position: "relative",
            bottom: "unset",
            marginBottom: "1rem",
          }}
        >
          <button
            className={"btn-next"}
            style={{ boxShadow: "none" }}
            onClick={() => {
              const w = window.open(
                "https://storage.googleapis.com/gajiku-assets/TEST.pdf",
                "_blank",
              );
              if (w) {
                w.focus();
              }
            }}
          >
            <p>Download Formulir</p>
          </button>
        </div>
        <p
          style={{
            textDecoration: "underline",
            color: "#1bb8ba",
            fontWeight: "bold",
          }}
          onClick={() => history.push("/dashboard/0")}
        >
          Kembali ke Home
        </p>
      </div>
    </>
  );
};
export default DownloadingForm;
