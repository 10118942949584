import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import { PresenceServices } from "../../../../../../_services";

const useCheckPresence = (): [Array<any>, () => void] => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const getList = async () => {
    try {
      dispatch(showLoading());
      const checkList = await PresenceServices.getCheckListPresence();
      setData(checkList.data.preparation);
      dispatch(hideLoading());
    } catch (error: any) {
      dispatch(hideLoading());
    }
  };

  return [data, getList];
};

export default useCheckPresence;
