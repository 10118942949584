export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function addMonths(date: Date, number: number) {
  const newDate = new Date(date);
  return new Date(newDate.setMonth(newDate.getMonth() + number));
}

import { useCallback, useLayoutEffect, useState } from "react";

export function useForceUpdate() {
  const [, updateComponent] = useState<any>({});

  return useCallback(() => {
    updateComponent({});
  }, []);
}

export function removeIt<T>(arr: T[], elem: T) {
  const index = arr.indexOf(elem);

  if (index === -1) {
    return;
  }

  arr.splice(index, 1);
}
interface StoreListeningComponentData<T> {
  update: () => void;
  options?: UseStoreStateOptions<T>;
}

export interface UseStoreStateOptions<T> {
  shouldCompare?: (oldState: T, newState: T) => boolean;
}
export function createStore<T>(ins: T) {
  let stok = ins;
  const listens: StoreListeningComponentData<T>[] = [];

  function up(newStoreState: T) {
    const oldStoreState = stok;
    stok = newStoreState;
    listens.forEach(({ update, options }) => {
      const shouldUpdate =
        !options ||
        !options.shouldCompare ||
        !options.shouldCompare(oldStoreState, newStoreState);

      if (!shouldUpdate) {
        return;
      }

      update();
    });
  }

  function useState(options?: UseStoreStateOptions<T>) {
    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {
      const a: StoreListeningComponentData<T> = {
        options,
        update: forceUpdate,
      };

      listens.push(a);

      return () => {
        removeIt(listens, a);
      };
    });

    return [stok, up] as const;
  }

  return [useState, up] as const;
}
export function titleCase(str: string) {
  const splitStr = str?.toLowerCase().split(" ");
  if (splitStr) {
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
}
