import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Icon, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import { RequestPage } from "./Pages/Request";
import { ProfilePage } from "./Pages/Profile";
import { HistoryPage } from "./Pages/History";
import "./dashboard.css";
import Logo from "../../assets/ewa-gajiku.png";

import { IPage } from "./lib";
import { HomePage } from "./Pages/Home";
import Loan from "./Pages/Loan";
import UserRoutes from "./Pages/Attendance/_routes/UserRoutes";
import { useAppSelector } from "../../redux/hooks";
import UserRoutesProducts from "./Pages/ProductionTracking/_routes/UserRoutesProducts";

export const Dashboard: FunctionComponent<{
  baseUrl: string;
}> = ({ baseUrl }) => {
  const hist = useHistory();
  const location = useLocation();
  const { attendanceAllowed } = useAppSelector((state) => state.requestState);

  const pages = [
    {
      component: HomePage,
      navIcon: <Icon icon={IconNames.HOME} iconSize={25} />,
      label: "Home",
      topIcon: IconNames.REFRESH,
      showHeader: false,
    },
    {
      component: HistoryPage,
      navIcon: <Icon icon={IconNames.HISTORY} iconSize={25} />,
      label: "Riwayat",
      topIcon: IconNames.REFRESH,
      showHeader: true,
    },
    {
      component: RequestPage,
      navIcon: <img src={Logo} alt={"gajiku-logo"} style={{ width: "25px" }} />,
      label: "Tarik Gaji",
      topIcon: IconNames.REFRESH,
      showHeader: true,
    },
    {
      component: ProfilePage,
      navIcon: <Icon icon={IconNames.PERSON} iconSize={25} />,
      label: "Profil",
      topIcon: IconNames.REFRESH,
      showHeader: true,
    },
    {
      component: ProfilePage,
      navIcon: <Icon icon={IconNames.PERSON} iconSize={25} />,
      label: "Profil",
      topIcon: IconNames.REFRESH,
      showHeader: true,
    },
  ] as IPage[];

  const linkWidth = 100 / pages.length;
  const defaultPageIndex = 0;
  const [selectedPage, setSelectedPage] = useState<number>(defaultPageIndex);
  const { loading } = useAppSelector((state) => state.globalState);

  useEffect(() => {
    if (attendanceAllowed) setSelectedPage(0);
    else {
      const pagePath = `${baseUrl}/2`;
      setSelectedPage(2);
      hist.push(pagePath);
    }
  }, [attendanceAllowed]);

  useEffect(() => {
    const currentTab = parseInt(location.pathname.split("/")[2]);
    setSelectedPage(currentTab);
  }, [location]);

  const navButtons = pages.map((page, index) => {
    const pagePath = `${baseUrl}/${index}`;
    const navButtonPress = () => {
      setSelectedPage(index);
      hist.push(pagePath);
    };

    const isSelectedLink = selectedPage === index;

    return (
      <div
        key={`nav-link-${page.label}-${index}`}
        className={`link ${isSelectedLink ? "link-selected" : ""}`}
        onClick={navButtonPress}
        style={{ width: `${linkWidth} vw` }}
      >
        <div className="footer-container-icon">
          <div className={"nav-icon"}>{page.navIcon}</div>
          <div className={"nav-label"}>{page.label}</div>
        </div>
      </div>
    );
  });

  const topIconDisabledStates = pages.map(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [topIconIsDisabled, setTopIconIsDisabled] = useState<boolean>(false);
    return { topIconIsDisabled, setTopIconIsDisabled };
  });

  const bodies = pages.map((page, index) => {
    const pagePath = `${baseUrl}/${index}`;
    // eslint-disable-next-line react-hooks/rules-of-hooks,@typescript-eslint/no-empty-function
    const onTopIconClickRef = useRef<() => void>(() => {});
    const { topIconIsDisabled, setTopIconIsDisabled } =
      topIconDisabledStates[index];
    return (
      <Route key={`page-path-${page.label}-${index}`} exact path={pagePath}>
        {page.showHeader && (
          <div
            className={`dashboard-header ${
              window.location.pathname.split("/")[2] == "3"
                ? "profile-page"
                : ""
            }`}
          >
            <div className={"dashboard-title"}>{page.label}</div>
            <div
              className={"dashboard-reload"}
              onClick={() => onTopIconClickRef.current()}
            >
              {topIconIsDisabled ? (
                <Icon icon={page.topIcon} iconSize={24} />
              ) : (
                <Icon icon={page.topIcon} color={"#555555"} iconSize={24} />
              )}
            </div>
          </div>
        )}
        <div className={`dashboard-body ${!page.showHeader ? "hide" : ""}`}>
          {page.component({
            baseUrl: pagePath,
            onTopIconClickRef,
            topIconIsDisabled,
            setTopIconIsDisabled,
          })}
        </div>
      </Route>
    );
  });
  return (
    <div className={"dashboard-container"}>
      {loading && (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
      <Switch>
        {bodies}
        <Route path={"/attendance/"} component={UserRoutes} />
        <Route path={"/loan/"} exact component={Loan} />
        <Route path={"/production-tracking/"} component={UserRoutesProducts} />
        <Route path={"/"}>
          <Redirect to={`${baseUrl}/${defaultPageIndex}`} />
        </Route>
      </Switch>
      <div className={"dashboard-footer"}>{navButtons}</div>
    </div>
  );
};
