import { Intent } from "@blueprintjs/core";
import { batch } from "react-redux";
import { OverTimeInterface } from "../../_interfaces";
import { OvertimeServices } from "../../_services/overtime.services";
import GlobalActionTypes from "../global/global.types";
import OvertimeActionTypes from "./overtime.types";

export const getOvertimeList = () => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: OvertimeActionTypes.GET_OVERTIME });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const overtimeData = await OvertimeServices.getOvertime();

      batch(() => {
        dispatch({
          type: OvertimeActionTypes.GET_OVERTIME_SUCCESS,
          payload: overtimeData.data.overtimeRequests,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({
          type: OvertimeActionTypes.GET_OVERTIME_FAILURE,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const createOvertime = (data: OverTimeInterface) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: OvertimeActionTypes.CREATE_OVERTIME });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      // data.startAt = `${data.startAt}:00.000+07:00`;
      // data.endAt = `${data.endAt}:00.000+07:00`;
      // const createOvertime = await OvertimeServices.createOvertime(data);

      batch(() => {
        dispatch({ type: OvertimeActionTypes.CREATE_OVERTIME_SUCCESS });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Berhasil mengajukan lembur",
            alertType: Intent.SUCCESS,
          },
        });
      });
      window.history.back();
    } catch (error: any) {
      batch(() => {
        dispatch({ type: OvertimeActionTypes.CREATE_OVERTIME_SUCCESS });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};
