import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { PageComponent } from "../../lib";
import {
  Button,
  Card,
  Elevation,
  FormGroup,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import "./index.css";
import { UploadKTPDialog } from "./UploadKTPDialog";
import { UpdatePasswordDialog } from "./UpdatePasswordDialog";
import { useLogin } from "../../../../data/login/api";
import { useDispatch } from "react-redux";
import { logoutRedux } from "../../../../redux/auth/auth.actions";
import { useAppSelector } from "../../../../redux/hooks";
import { requestGet } from "../../../../redux/request/request.actions";
import avatar from "../../../../assets/avatar.png";
import userIcon from "../../../../assets/user.svg";
import phoneIcon from "../../../../assets/smartphone.svg";
import homeIcon from "../../../../assets/home_icon.svg";
import bankIcon from "../../../../assets/bank.svg";
import passwordIcon from "../../../../assets/password.svg";

export const ProfileCard: FunctionComponent<
  PropsWithChildren<{
    title: string;
  }>
> = ({ children, title }) => {
  return (
    <div className={"profile-card"}>
      <Card elevation={Elevation.TWO}>
        <div className={"profile-card-header"}>{title}</div>
        <hr />
        <div className={"profile-card-body"}>{children}</div>
      </Card>
    </div>
  );
};

export const VerificationSpan: FunctionComponent<{
  value?: string;
}> = ({ value }) => {
  let color: any;
  let text: any;

  if (value == "VALID") {
    color = "var(--theme-green)";
    text = "Valid";
  } else if (value == "PENDING") {
    color = "orange";
    text = "Pending";
  } else {
    color = "var(--theme-red)";
    text = "Invalid";
  }

  return <span style={{ color }}>{text}</span>;
};

export const ProfilePage: PageComponent = ({ onTopIconClickRef }) => {
  const [, loginAuth] = useLogin();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logoutRedux());
  };

  const {
    phoneNumber,
    nip,
    bankCode,
    bankAccountNumber,
    bankVerificationStatus,
    loading,
    name,
    companyName,
  } = useAppSelector((state) => state.requestState);

  useEffect(() => {
    dispatch(requestGet());
  }, []);

  onTopIconClickRef.current = () => {
    dispatch(requestGet());
  };

  const [isPasswordDialogOpen, setIsPasswordDialogOpen] =
    useState<boolean>(false);

  return (
    <div className={"profile-container mt-0"}>
      {/* <UploadKTPDialog
        url={ktpUrl}
        isOpen={ktpDialogIsOpen}
        onClose={() => {
          setKtpDialogIsOpen(false);
        }}
      /> */}
      <UpdatePasswordDialog
        isOpen={isPasswordDialogOpen}
        onClose={() => setIsPasswordDialogOpen(false)}
      />
      {loading ? (
        <Spinner />
      ) : (
        <div className={"profile-body"}>
          <div>
            <div className="header-profile">
              <h4>Hi, {name}</h4>
            </div>
            <div className="body-profile">
              <ul>
                <li>
                  <div className="d-flex">
                    <div className="icon-info">
                      <img src={homeIcon} alt="icon" />
                    </div>
                    <div>
                      <small className="text-secondary">Perusahaan</small>
                      <span>
                        <b>{companyName}</b>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <div className="icon-info">
                      <img src={userIcon} alt="icon" />
                    </div>
                    <div>
                      <small className="text-secondary">Nama</small>
                      <span>
                        <b>{name}</b>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <div className="icon-info">
                      <img src={phoneIcon} alt="icon" />
                    </div>
                    <div>
                      <small className="text-secondary">Telepon</small>
                      <span>
                        <b>{phoneNumber}</b>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <div className="icon-info">
                      <img src={bankIcon} alt="icon" />
                    </div>
                    <div>
                      <small className="text-secondary">
                        <b>{bankCode}</b>
                      </small>
                      <span>
                        <b>{bankAccountNumber}</b>
                      </span>
                    </div>
                    <div className="ml-auto pt-1">
                      <b>
                        <VerificationSpan value={bankVerificationStatus} />
                      </b>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <div className="icon-info">
                      <img src={passwordIcon} alt="icon" />
                    </div>
                    <div>
                      <small className="text-secondary">Keamanan</small>
                      <span
                        className="text-info"
                        onClick={() => {
                          setIsPasswordDialogOpen(true);
                        }}
                      >
                        Ganti Password
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <Button
                className={"profile-card-button"}
                intent={Intent.DANGER}
                onClick={logout}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
