import LoanActionTypes from "./loan.types";

interface LoanState {
  // eslint-disable-next-line @typescript-eslint/ban-types
  loanRequestData: any;
  loanHistoryData: [];
  loanDetailData: any;
  failure: boolean;
  statusRequest: string;
}

const INITIAL_STATE: LoanState = {
  loanRequestData: {},
  loanHistoryData: [],
  loanDetailData: {},
  failure: false,
  statusRequest: "",
};

const LoanReducer = (state = INITIAL_STATE, action: any): LoanState => {
  switch (action.type) {
    case LoanActionTypes.GET_LOAN_REQUEST:
      return {
        ...state,
        failure: false,
        statusRequest: "",
      };
    case LoanActionTypes.GET_LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        loanRequestData: action.payload,
      };
    case LoanActionTypes.GET_LOAN_REQUEST_FAILURE:
      return {
        ...state,
        failure: true,
      };
    case LoanActionTypes.CREATE_LOAN_REQUEST:
      return {
        ...state,
        statusRequest: "",
      };
    case LoanActionTypes.CREATE_LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        statusRequest: "success",
      };
    case LoanActionTypes.CREATE_LOAN_REQUEST_FAILURE:
      return {
        ...state,
        statusRequest: "failure",
      };
    case LoanActionTypes.GET_LOAN_REQUEST:
      return {
        ...state,
        failure: false,
      };
    case LoanActionTypes.GET_LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        loanRequestData: action.payload,
      };
    case LoanActionTypes.GET_LOAN_REQUEST_FAILURE:
      return {
        ...state,
        failure: true,
      };
    case LoanActionTypes.GET_LOAN_HISTORY:
      return {
        ...state,
        failure: false,
      };
    case LoanActionTypes.GET_LOAN_HISTORY_SUCCESS:
      return {
        ...state,
        loanHistoryData: action.payload,
      };
    case LoanActionTypes.GET_LOAN_HISTORY_FAILURE:
      return {
        ...state,
        failure: true,
      };
    case LoanActionTypes.GET_LOAN_DETAIL:
      return {
        ...state,
        failure: false,
      };
    case LoanActionTypes.GET_LOAN_DETAIL_SUCCESS:
      return {
        ...state,
        loanDetailData: action.payload,
      };
    case LoanActionTypes.GET_LOAN_DETAIL_FAILURE:
      return {
        ...state,
        failure: true,
      };
    case LoanActionTypes.UPDATE_LOAN_ATTACHMENT:
      return {
        ...state,
        statusRequest: "",
      };
    case LoanActionTypes.UPDATE_LOAN_ATTACHMENT_SUCCESS:
      return {
        ...state,
        statusRequest: "success",
      };
    case LoanActionTypes.UPDATE_LOAN_ATTACHMENT_FAILURE:
      return {
        ...state,
        statusRequest: "failure",
      };
    case LoanActionTypes.CANCEL_LOAN_REQUEST:
      return {
        ...state,
        statusRequest: "",
      };
    case LoanActionTypes.CANCEL_LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        statusRequest: "success",
      };
    case LoanActionTypes.CANCEL_LOAN_REQUEST_FAILURE:
      return {
        ...state,
        statusRequest: "failure",
      };
    default:
      return state;
  }
};

export default LoanReducer;
