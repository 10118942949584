import request from "../_network/request";
const authUrl = "/company";

const get = () => {
  return request({
    url: `${authUrl}/settings`,
    method: "GET",
  });
};

export const CompanySettingsServices = {
  get,
};
