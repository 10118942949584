import ChangePasswordActionTypes from "./changePassword.types";
import { batch } from "react-redux";
import GlobalActionTypes from "../global/global.types";
import { ChangePasswordServices } from "../../_services";
import { Intent } from "@blueprintjs/core";
import AuthActionTypes from "../auth/auth.types";

export const update = (oldPassword: string, newPassword: string) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: ChangePasswordActionTypes.CHANGE_PASSWORD });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const update = await ChangePasswordServices.update({
        newPassword,
        oldPassword,
      });
      batch(() => {
        dispatch({
          type: ChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS,
          payload: update.data.ChangePasswordToken,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: "Update Success",
            alertType: Intent.SUCCESS,
          },
        });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({
          type: ChangePasswordActionTypes.CHANGE_PASSWORD_FAILURE,
          payload: error,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};
