import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLogin } from "../../../../data/login/api";
import { useAppSelector } from "../../../../redux/hooks";
import {
  createLoanRequest,
  getLoanRequest,
} from "../../../../redux/loan/loan.actions";
import { requestGet } from "../../../../redux/request/request.actions";
import { useSpawnRender, useTokens } from "./index";
import {
  BankInfo,
  DataInput,
  EmployeeProfile,
  FormInput,
  ImperativeHandler,
  InterestFee,
  LoanRender,
  LoanRenderForm,
  ResponseFetch,
} from "./interface";
import { addMonths, months, titleCase } from "./util";

const Stepper = () => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [render] = useSpawnRender({
    shouldCompare(oldUser, newUser) {
      return true;
    },
  });
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          width: "100%",
          padding: "1rem",
        }}
      >
        <svg
          className="sp"
          ref={svgRef}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 200 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            data-dot="1"
            className="sp__dot"
            cx="12"
            cy="12"
            r="0"
            fill="#000"
          />
          <line
            className="sp__bar"
            x1="22"
            y1="12"
            x2="62"
            y2="12"
            fill="none"
            stroke="#000"
            strokeWidth="4"
          />
          <circle
            data-hub="1"
            className="sp__hub"
            cx="12"
            cy="12"
            r="2"
            fill="none"
            stroke="#37c4c6"
            strokeWidth="4"
          />
          <line
            data-bar="1"
            className="sp__bar-fill"
            x1="22"
            y1="12"
            x2="62"
            y2="12"
            fill="none"
            stroke="#37c4c6"
            strokeWidth="4"
          />
          <circle
            data-hub-fill="1"
            className="sp__hub-fill"
            cx="12"
            cy="12"
            r="10"
            fill="none"
            strokeWidth="4"
            transform="rotate(180,12,12)"
          />
          <circle
            data-dot="2"
            className="sp__dot"
            cx="72"
            cy="12"
            r="0"
            fill="#000"
          />
          <line
            className="sp__bar"
            x1="82"
            y1="12"
            x2="122"
            y2="12"
            fill="none"
            stroke="#000"
            strokeWidth="4"
          />
          <circle
            data-hub="2"
            className="sp__hub"
            cx="72"
            cy="12"
            r="2"
            fill="none"
            stroke={render.spawn == "CONFIRM" ? "#37c4c6" : "#ddd"}
            strokeWidth="4"
          />
          <line
            data-bar="2"
            className="sp__bar-fill"
            x1="82"
            y1="12"
            x2="122"
            y2="12"
            fill="none"
            stroke={render.spawn == "CONFIRM" ? "#37c4c6" : "#ddd"}
            strokeWidth="4"
          />
          <circle
            data-hub-fill="2"
            className="sp__hub-fill"
            cx="72"
            cy="12"
            r="10"
            fill="none"
            strokeWidth="4"
            transform="rotate(180,72,12)"
          />
          <circle
            data-dot="3"
            className="sp__dot"
            cx="132"
            cy="12"
            r="0"
            fill="#000"
          />
          <circle
            data-hub="3"
            className="sp__hub"
            cx="132"
            cy="12"
            r="2"
            fill="none"
            stroke={render.spawn == "CONFIRM" ? "#37c4c6" : "#ddd"}
            strokeWidth="4"
          />

          <circle
            data-hub-fill="3"
            className="sp__hub-fill"
            cx="132"
            cy="12"
            r="10"
            fill="none"
            strokeWidth="4"
            transform="rotate(180,132,12)"
          />
        </svg>
      </div>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          width: "100%",
          padding: "1rem",
          position: "relative",
          top: "-2rem",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <p
          style={
            (
              [LoanRenderForm.FILL, LoanRenderForm.CONFIRM] as string[]
            ).includes(render.spawn)
              ? { color: "black" }
              : { color: "lightgrey" }
          }
        >
          Isi Formulir
        </p>
        <p
          style={
            ([LoanRenderForm.CONFIRM] as string[]).includes(render.spawn)
              ? { color: "black" }
              : { color: "lightgrey" }
          }
        >
          Konfirmasi
        </p>
      </div>
    </>
  );
};
const LoanRequestForm_2 = ({ data }: { data: DataInput }) => {
  const [, setRender] = useSpawnRender();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const {
    phoneNumber,
    nip,
    bankCode,
    bankAccountNumber,
    bankVerificationStatus,
    loading,
    name,
  } = useAppSelector((state) => state.requestState);

  useEffect(() => {
    dispatch(requestGet());
    setAmount(0);
    data.amount = 0;
    data.bankAccountNumber = bankAccountNumber;
    data.bankCode = bankCode;
  }, []);

  const loanRequestHandler = () => {
    dispatch(getLoanRequest(data.type, data.amount, data.mentLength));
  };

  return (
    <form
      action="#"
      autoComplete="off"
      className="form-loan"
      onSubmit={(e) => {
        e.preventDefault();
        setRender({ spawn: LoanRenderForm.CONFIRM });
        loanRequestHandler();
      }}
    >
      <div className={"loan-form-container"}>
        <h3 style={{ marginBottom: "1rem" }}>Ajukan Pinjaman</h3>
        <div className={"radio-context"}>
          <div className="radio">
            <input
              id="radio-1"
              name="radio"
              type="radio"
              checked={true}
              onChange={(e) => console.log("")}
            />
            <label
              htmlFor="radio-1"
              className="radio-label"
              style={{ fontWeight: "bold" }}
            >
              Pinjaman Uang
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Masukan Nominal Pinjaman</label>
          <input
            className="form-control mb-0"
            type="text"
            defaultValue={Number(amount).toLocaleString()}
            value={Number(amount).toLocaleString()}
            name="amount"
            onChange={(e) => {
              const temp = e.currentTarget.value.replace(/,/g, "");
              if (!isNaN(Number(temp)) && temp?.length > 0) {
                data.amount = Number(temp);
                e.currentTarget.value = Number(temp).toLocaleString();
                setAmount(Number(temp));
              }
            }}
            required
            pattern={`^[0-9,.]*$`}
            placeholder={"Masukan Nominal Uang"}
          />
        </div>
        <div className="form-group">
          <label>Masukan Durasi Tenor</label>
          <input
            className="form-control"
            type="text"
            defaultValue={data?.mentLength}
            name="mentLength"
            onChange={(e) => {
              data.mentLength = Number(e.target.value);
            }}
            required
            pattern={`^[0-9]*$`}
            placeholder={"Masukan Tenor (1-12)"}
          />
        </div>
        <>
          <div style={{ textAlign: "left" }}>
            <p>Kirim Ke</p>
          </div>
          <div className="bank-box">
            <h4>{bankCode}</h4>
            <p>
              {bankAccountNumber} a/n {name}
            </p>
          </div>
        </>

        <div className={"btn-next-wrapper"}>
          <button
            className={"btn-next"}
            type={"submit"}
            disabled={amount === 0 || data.amount === 0}
            style={{
              backgroundColor: amount > 0 ? "" : "grey",
              boxShadow: "none",
            }}
            onClick={() => console.log("")}
          >
            <p>Lanjutkan</p>
          </button>
        </div>
      </div>
    </form>
  );
};
const LoanRequestForm_3 = ({ data }: { data: DataInput }) => {
  const [, loginAuth] = useLogin();
  const [interestFee, setInterestFee] = useState<InterestFee>();
  const [employeeName, setEmployeeName] = useState("");
  const [loanData, setLoanData] = useState<any>({});
  const [token] = useTokens({
    shouldCompare(oldUser, newUser) {
      return true;
    },
  });

  const [, setRender] = useSpawnRender();
  const [render] = useSpawnRender({
    shouldCompare(oldUser, newUser) {
      return true;
    },
  });
  const dispatch = useDispatch();
  const { loanRequestData, statusRequest } = useAppSelector(
    (state) => state.loanState,
  );

  useEffect(() => {
    setLoanData(loanRequestData);
  }, [loanRequestData]);

  const submitLoan = () => {
    const param: any = {
      loanType: "cash",
      amount: data.amount,
      bankAccountNumber: data.bankAccountNumber,
      bankCode: data.bankCode,
      installmentLength: data.mentLength,
    };

    dispatch(createLoanRequest(param));
  };

  useEffect(() => {
    if (statusRequest === "success") {
      setRender({ spawn: LoanRender.HOME });
      window.scrollTo({ top: 0 });
    }
  }, [statusRequest]);

  const collapsibleParentRef = useRef<HTMLDivElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <div className={"loan-form-container"}>
        <h3 style={{ marginBottom: "1rem" }}>Konfirmasi Pinjaman</h3>
        <div className={"radio-context"}>
          <div className="radio">
            <input
              id="radio-1"
              name="radio"
              type="radio"
              checked={true}
              onChange={() => console.log("")}
            />
            <label
              htmlFor="radio-1"
              className="radio-label"
              style={{ fontWeight: "bold" }}
            >
              Pinjaman Uang
            </label>
          </div>
        </div>
        <div className={"receipt-like-style"} style={{ borderBottom: "none" }}>
          <p>Nominal</p>
          <p style={{ color: "grey" }}>
            Rp. {Number(loanData?.amount).toLocaleString()}
          </p>
        </div>
        <div
          className={"receipt-like-style"}
          style={{ borderBottom: "none", paddingBottom: "0rem" }}
        >
          <p>
            {loanData?.interestType == "tenor"
              ? "Biaya Bunga ( 2% / tenor )"
              : "Biaya Bunga ( 2% )"}
          </p>
          <p style={{ color: "lightcoral" }}>
            +Rp. {Number(loanData?.totalInterestFee).toLocaleString()}
          </p>
        </div>
        {loanData?.adminFee !== 0 && (
          <div
            className={"receipt-like-style"}
            style={{ borderBottom: "none", paddingBottom: "0rem" }}
          >
            <p>Biaya Admin</p>
            <p style={{ color: "lightcoral" }}>
              +Rp. {Number(loanData?.adminFee).toLocaleString()}
            </p>
          </div>
        )}
        <div className={"receipt-like-style"}>
          <p>Jumlah Total</p>
          <p className={"bold"}>
            Rp. {Number(loanData?.principal).toLocaleString()}
          </p>
        </div>
        <div style={{ padding: "1rem" }}>
          <p
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            Tenor
          </p>
          <div
            className="tab"
            ref={collapsibleParentRef}
            onClick={(e) => {
              e.preventDefault();
              if (collapsibleParentRef.current && checkboxRef.current) {
                collapsibleParentRef.current.classList.toggle("active");
                checkboxRef.current.toggleAttribute("checked");
              }
            }}
          >
            <input
              type="checkbox"
              id="chck1"
              ref={checkboxRef}
              className={"checkbox-collapsible"}
            />
            <label className="tab-label" htmlFor="chck1">
              {loanData?.installmentLength} Bulan (
              {months[new Date().getMonth()]} {new Date().getUTCFullYear()} -{" "}
              {
                months[
                  String(
                    addMonths(
                      new Date(),
                      Number(loanData?.installmentLength),
                    ).getMonth(),
                  ) as any
                ]
              }{" "}
              {addMonths(
                new Date(),
                Number(loanData?.installmentLength),
              ).getUTCFullYear()}
              )
            </label>
            <div className="tab-content">
              {loanData?.installments?.map((item: any, i: number) => {
                return (
                  <div key={i} className={"actual-content"}>
                    <div style={{ margin: "0px", fontWeight: "bold" }}>
                      <p style={{ textAlign: "left" }}>
                        Pembayaran ke {item.installmentNo}
                      </p>
                      <p>
                        pada:{" "}
                        {new Date(item?.dueDate).toLocaleDateString("id-ID", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </p>
                    </div>
                    <p style={{ margin: "0px" }}>
                      Rp. {Number(item.amount).toLocaleString()}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "grid", placeItems: "center", marginBottom: "10rem" }}
      >
        <button
          className="btn btn-submit"
          type="button"
          disabled={loanData?.totalInterestFee === 0}
          onClick={submitLoan}
        >
          <span
            className="btn__text"
            style={{
              backgroundColor: loanData.totalInterestFee > 0 ? "" : "grey",
            }}
          >
            Submit
          </span>
          <svg
            className="btn__progress"
            viewBox="0 0 48 48"
            width="48px"
            height="48px"
          >
            <circle
              className="btn__progress-track"
              r="20"
              cx="24"
              cy="24"
              fill="none"
              stroke="#c7cad1"
              strokeWidth="8"
            />
            <circle
              className="btn__progress-fill"
              r="20"
              cx="24"
              cy="24"
              fill="none"
              stroke="#000000"
              strokeWidth="8"
              transform="rotate(-90,24,24)"
              strokeDasharray="125.66 125.66"
              strokeDashoffset="125.66"
            />
            <polyline
              className="btn__progress-check"
              points="12,24 20,32 36,16"
              fill="none"
              stroke="#fff"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="34 34"
              strokeDashoffset="34"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export const LoanRequestForm = React.forwardRef<ImperativeHandler>(
  (props, ref) => {
    const data = useRef<DataInput>({
      amount: 0,
      type: "cash",
      mentLength: 1,
      bankAccountNumber: "",
      bankCode: "",
    });

    const [render] = useSpawnRender({
      shouldCompare(oldUser, newUser) {
        return true;
      },
    });
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundColor: "white",
          height: `${
            ([LoanRender.FORM] as string[]).includes(render.spawn)
              ? "100vh"
              : "auto"
          }`,
        }}
      >
        <Stepper />
        {render.spawn === LoanRenderForm.FILL && (
          <LoanRequestForm_2 data={data.current} />
        )}
        {render.spawn === LoanRenderForm.CONFIRM && (
          <LoanRequestForm_3 data={data.current} />
        )}
      </div>
    );
  },
);
