import React, { useEffect, useState } from "react";
import "./calendar-card.style.scss";
import { Dialog } from "@blueprintjs/core";

export interface CalendarCardI {
  date: string;
  title: string;
  clockIn: string;
  clockOut: string;
  status: string;
  type: string;
  hasAction?: boolean;
  description?: string;
  htmlFor?: string;
  photo: string;
  approve?: () => void;
  reject?: () => void;
}

const CalendarCard = ({
  date,
  title,
  clockIn,
  clockOut,
  status,
  type,
  hasAction,
  description,
  htmlFor,
  photo,
  approve,
  reject,
}: CalendarCardI) => {
  const [dateLocal, setDateLocal] = useState<Date>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setDateLocal(new Date(date));
  }, [date]);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <label className={`card-calendar`} htmlFor={htmlFor}>
        <div className={`calendar-card overtime`}>
          <div className={`calendar-header overtime`}>
            {dateLocal?.toLocaleDateString("id-ID", {
              month: "short",
            })}
          </div>
          <div className="calendar-body">{dateLocal?.getDate()}</div>
        </div>
        <div>
          <h3>{title}</h3>
          <div className="description-request">
            <table>
              <tbody>
                {/* <tr>
                  <td>Day</td>
                  <td>:</td>
                  <td className="detail-data">
                    {dateLocal?.toLocaleDateString("id-ID", {
                      weekday: "long",
                    })}
                  </td>
                </tr>
                <tr>
                  <td>Start</td>
                  <td>:</td>
                  <td className="detail-data">{clockIn}</td>
                </tr>
                <tr>
                  <td>End</td>
                  <td>:</td>
                  <td className="detail-data">{clockOut}</td>
                </tr> */}
                <tr>
                  <td>Type</td>
                  <td>:</td>
                  <td className="detail-data">{type}</td>
                </tr>
                <tr>
                  <td>Desc</td>
                  <td>:</td>
                  <td className="detail-data">{description}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="button-group">
          {hasAction && (
            <>
              <button className="btn approve" onClick={approve}>
                Approve
              </button>
              <button className="btn reject" onClick={reject}>
                Reject
              </button>
            </>
          )}
          <button className="btn info" onClick={() => setIsOpen(true)}>
            Detail
          </button>
        </div>
      </label>
      <Dialog
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="popup-calendar">
          <div className="title overtime">
            <h3>{title}</h3>
          </div>
          <table>
            <tbody>
              <tr>
                <td>Tanggal</td>
                <td>:</td>
                <td className="detail-data">
                  {dateLocal?.toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </td>
              </tr>
              <tr>
                <td>Day</td>
                <td>:</td>
                <td className="detail-data">
                  {dateLocal?.toLocaleDateString("id-ID", {
                    weekday: "long",
                  })}
                </td>
              </tr>
              <tr>
                <td>Start</td>
                <td>:</td>
                <td className="detail-data">{clockIn}</td>
              </tr>
              <tr>
                <td>End</td>
                <td>:</td>
                <td className="detail-data">{clockOut}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>:</td>
                <td className="detail-data">{type}</td>
              </tr>
              <tr>
                <td>Desc</td>
                <td>:</td>
                <td className="detail-data">{description}</td>
              </tr>
            </tbody>
          </table>
          <div className="documents">
            <h2>Document</h2>
            {photo !== "" ? (
              <img src={photo} alt="attendance document" />
            ) : (
              <div>No document</div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CalendarCard;
