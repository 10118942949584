import request from "../_network/request";
import { CreateLoan } from "../_interfaces";
const loanUrl = "/loan";

const getLoanRequest = (type: string, amount: number, mentLength: number) => {
  return request({
    url: `${loanUrl}/request/draft?loanType=${type}&amount=${amount}&installmentLength=${mentLength}`,
    method: "GET",
  });
};

const createLoanRequest = (payload: CreateLoan) => {
  return request({
    url: `${loanUrl}/request`,
    method: "POST",
    data: payload,
  });
};

const getLoanHistory = () => {
  return request({
    url: `${loanUrl}/request/history?page=1&size=10`,
    method: "GET",
  });
};

const getLoanDetail = (id: number) => {
  return request({
    url: `${loanUrl}/request/${id}/view`,
    method: "GET",
  });
};

const updateLoanAttachment = (id: number | undefined, url: string) => {
  return request({
    url: `${loanUrl}/request/${id}/update-attachment`,
    method: "POST",
    data: {
      attachment: url,
    },
  });
};

const cancelLoanRequest = (id: number | undefined) => {
  return request({
    url: `${loanUrl}/request/${id}/cancel`,
    method: "POST",
    data: {},
  });
};

export const LoanServices = {
  getLoanRequest,
  createLoanRequest,
  getLoanHistory,
  getLoanDetail,
  updateLoanAttachment,
  cancelLoanRequest,
};
