import { Icon } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import "./permit.style.scss";
import usePermit from "./usePermit";

const PermitPage = () => {
  const dispatch = useDispatch();
  const [, getPermissionList, permissionList, cancelPermit] = usePermit();
  const history = useHistory();

  useEffect(() => {
    dispatch(setHeader({ title: "Izin", hasBack: true }));
    getPermissionList();
  }, []);

  const generateIcon = (status: string) => {
    switch (status) {
      case "pending":
        return <Icon color="#979757" icon="time" iconSize={20} />;
      case "approved":
        return <Icon color="#6eb96e" icon="tick-circle" iconSize={20} />;
      case "rejected":
        return <Icon color="#c06262" icon="delete" iconSize={20} />;
      case "canceled":
        return <Icon color="#808080" icon="ban-circle" iconSize={20} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="overtime-page">
      <button
        className="submit-btn"
        onClick={() => history.push(`/attendance/permit-request`)}
      >
        Request Izin
      </button>
      <div className="overtime-list">
        <div className="header-list">Izin Activity</div>
        {permissionList?.map((item: any) => (
          <div className="list-group" key={item.id}>
            <div className="left">
              <div>
                {`${new Date(item.clockIn).toLocaleDateString("id-ID", {
                  day: "numeric",
                  year: "numeric",
                  month: "short",
                  hour: "numeric",
                  minute: "numeric",
                  timeZone: "Asia/Jakarta",
                })} - 
              ${new Date(item.clockOut).toLocaleDateString("id-ID", {
                day: "numeric",
                year: "numeric",
                month: "short",
                hour: "numeric",
                minute: "numeric",
                timeZone: "Asia/Jakarta",
              })}`}
              </div>
              <div>{item.description}</div>
              {item.status === "rejected" && (
                <div>Reject reason: {item.reject_reason}</div>
              )}
              <div></div>
            </div>
            <div className="right">
              {generateIcon(item.status)}
              {item.status === "pending" && (
                <button className="btn" onClick={() => cancelPermit(item.id)}>
                  Cancel
                </button>
              )}
            </div>
          </div>
        ))}
        {permissionList?.length === 0 && (
          <div style={{ textAlign: "center" }}>Belum ada data</div>
        )}
      </div>
    </div>
  );
};

export default PermitPage;
