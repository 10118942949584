/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import {
  setHeader,
  setLocation,
} from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import "./maps.style.scss";
import Geocode from "react-geocode";
import { useHistory } from "react-router";
import { iconBuildings } from "./IconBuilding";
import { KEYS } from "../../_interfaces";
import { useDispatch } from "react-redux";

const Maps = () => {
  Geocode.setApiKey(KEYS.GEOCODE);
  Geocode.setLanguage("ID");
  const dispatch = useDispatch();
  const [loc, setLoc] = useState({
    long: "",
    lat: "",
  });
  const { location } = useAppSelector((state) => state.clockin.masterData);
  const [address, setAddress] = useState("");
  const [isNearby, setIsNearby] = useState(false);
  const history = useHistory();

  useEffect(() => {
    dispatch(setHeader({ title: "Pilih lewat peta", hasBack: true }));
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setLoc({
          long: pos.coords.longitude.toString(),
          lat: pos.coords.latitude.toString(),
        });
      },
      () => {},
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  }, []);

  useEffect(() => {
    if (loc.lat !== "") {
      location.forEach((item: any) => {
        const distance = getDistance(
          parseFloat(loc.lat),
          parseFloat(loc.long),
          parseFloat(item.lat),
          parseFloat(item.long),
        );
        if (distance <= 1) setIsNearby(true);
      });
      Geocode.fromLatLng(loc.lat, loc.long).then((response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      });
    }
  }, [loc, location]);

  const getDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ) => {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  };

  const deg2rad = (deg: any) => {
    return deg * (Math.PI / 180);
  };

  const chooseLocation = () => {
    dispatch(setLocation(loc.lat, loc.long, address, isNearby));
    history.goBack();
  };

  return (
    <div className="map-page">
      {loc.long !== "" && (
        <MapContainer
          center={[parseFloat(loc.lat), parseFloat(loc.long)]}
          zoom={14}
          scrollWheelZoom={false}
        >
          <MapComponent
            setLatLong={(lat: string, long: string) =>
              setLoc({ lat: lat, long: long })
            }
          />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {location.map((item: any, i: number) => (
            <Marker
              key={i}
              position={[item.lat, item.long]}
              icon={iconBuildings}
            ></Marker>
          ))}
          <Marker
            position={[parseFloat(loc.lat), parseFloat(loc.long)]}
          ></Marker>
        </MapContainer>
      )}
      <div className="form-container">
        <textarea
          id="location"
          value={address}
          placeholder="&#61442; Lokasi absen"
          disabled
        />
        <Button onClick={() => chooseLocation()} className="btn">
          Pilih lokasi
        </Button>
      </div>
    </div>
  );
};

const MapComponent = ({ setLatLong }: { setLatLong: Function }) => {
  const map = useMapEvents({});
  return null;
};

export default Maps;
