import React, { FunctionComponent, useEffect, useState } from "react";

import Select from "react-select";

const GenerateForm = ({
  onValueChange,
  onTotalUomChange,
  data,
  totalForm,
  fromModal,
  initialValue,
  initialId,
  parentData,
}: {
  onValueChange?: (formValue: any) => void;
  onTotalUomChange?: (memberValue: any) => void;
  data: Array<any>;
  totalForm?: any;
  fromModal: boolean;
  initialValue?: any | null;
  initialId?: number | undefined | null;
  parentData?: any;
}) => {
  const [additionalInfoData, setAdditionalInfoData] = useState<any>({});
  const [memberInfo, setMemberInfo] = useState<Array<any>>([
    { id: 0, memberId: "", name: "", value: null },
  ]);

  useEffect(() => {
    const keys = Object.keys(additionalInfoData);
    const additional = keys.map((key) => ({
      code: key,
      selectedValue: additionalInfoData[key] ?? "",
    }));
    onValueChange?.(additional);
    if (!initialValue) {
      const arrMember: any[] = [];
      totalForm?.map((d: any, i: number) => {
        arrMember.push({
          id: d.id,
          memberId: d.memberId,
          name: d.name,
          value: null,
        });
      });
      setMemberInfo(arrMember);
    }
  }, []);

  useEffect(() => {
    if (initialValue) {
      const dataFilter = { ...initialValue };

      const formattedInitialValue: { [key: string]: any } = {};
      dataFilter?.additionalInfo?.forEach((item: any) => {
        formattedInitialValue[item.code] = item.selectedValue;
      });
      setAdditionalInfoData(formattedInitialValue);
      const keys = Object.keys(formattedInitialValue);
      const additional = keys.map((key) => ({
        code: key,
        selectedValue: formattedInitialValue[key] ?? "",
      }));
      onValueChange?.(additional);

      if (dataFilter) {
        setMemberInfo(dataFilter?.memberValue);
      }
    } else {
      data?.map((item: any) => {
        if (item.isChoice) {
          if (item?.values?.length > 0) {
            const formValue: any = additionalInfoData;
            formValue[item.code] = item?.values[0];
            setAdditionalInfoData(formValue);
            const keys = Object.keys(additionalInfoData);
            const additional = keys.map((key) => ({
              code: key,
              selectedValue: additionalInfoData[key],
            }));
            onValueChange?.(additional);
          }
        }
      });
    }
  }, [initialValue]);

  useEffect(() => {
    onTotalUomChange?.(memberInfo);
  }, [memberInfo]);

  const handleChangeInfo = (e: any) => {
    const { name, value } = e.target;

    const formValue: any = additionalInfoData;
    formValue[name] = value;
    setAdditionalInfoData(formValue);
    const keys = Object.keys(additionalInfoData);
    const additional = keys.map((key) => ({
      code: key,
      selectedValue: additionalInfoData[key],
    }));
    onValueChange?.(additional);
  };

  const handleMemberChange = (e: any, i: number) => {
    const { name, value } = e.target;
    const list = [...memberInfo];
    list[i]["value"] = value.replace(/^(0*)/, "");

    setMemberInfo(list);
    onTotalUomChange?.(list);
  };

  const generateElement = (item: any, type: string, isChoice: boolean) => {
    const options: any = [];

    item?.values?.map((datas: any) => {
      const list = {
        value: datas,
        label: datas,
      };

      options.push(list);
    });

    const selectInput = (
      <div className="mt-2">
        <label>{item.name}</label>
        <div>
          <select
            className="custom-select mt-2"
            required={item.isMandatory}
            value={additionalInfoData[item.code] || null || item.selectedValue}
            name={item.code}
            onChange={(e) => handleChangeInfo(e)}
          >
            <option value="">Pilih Data</option>
            {item.values?.map((x: string, i: number) => (
              <option selected={i == 0} key={i} value={x}>
                {x}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
    switch (type) {
      case "text":
        if (isChoice) {
          return selectInput;
        } else {
          return (
            <div className="mt-2">
              <label>{item.name}</label>
              <input
                type="text"
                name={item.code}
                required={item.isMandatory}
                value={
                  additionalInfoData[item.code] || null || item.selectedValue
                }
                onChange={(e) => handleChangeInfo(e)}
                disabled={!fromModal}
                className="form-control w-100"
              />
            </div>
          );
        }
      case "number":
        if (isChoice) {
          return selectInput;
        } else {
          return (
            <div>
              <label>{item.name}</label>
              <input
                type="number"
                name={item.code}
                required={item.isMandatory}
                value={
                  parseFloat(additionalInfoData[item.code]) ||
                  null ||
                  parseFloat(item.selectedValue)
                }
                onChange={(e) => handleChangeInfo(e)}
                disabled={!fromModal}
                className="form-control w-100"
              />
            </div>
          );
        }
      default:
        return <></>;
    }
  };

  if (!data) return <div></div>;

  return (
    <>
      {data.map((item: any, i: number) => (
        <div key={i}>{generateElement(item, item.type, item.isChoice)}</div>
      ))}
      {fromModal ? (
        <>
          <h4>JUMLAH PRODUKSI</h4>
          {memberInfo?.map((x: any, i: number) => (
            <div key={i}>
              <label>{x.name}</label>
              <div className="d-flex">
                <div className="mr-2 w-100">
                  <input
                    type="number"
                    onChange={(e) => handleMemberChange(e, i)}
                    value={x.value}
                    pattern="[0-9]+([\,|\.][0-9]+)?"
                    step="0.01"
                    className="form-control w-100"
                  />
                </div>
                <div className="text-right w-50">
                  <input
                    type="text"
                    disabled
                    value={parentData?.uom}
                    className="form-control w-100"
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default GenerateForm;
