import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { PageComponent } from "../../lib";
import {
  AnchorButton,
  Button,
  Card,
  Elevation,
  Icon,
  Intent,
  Spinner,
  Switch,
} from "@blueprintjs/core";
import { HistoryDetailPopup } from "./HistoryDetailPopup";
import {
  checkIfRequestsOnly,
  checkIfWithinRange,
  formatTransactionDetails,
} from "./lib";

import "./index.css";
import { dateFormatter, rupiahFormatter } from "../../../../utils/formatters";
import { useLogin } from "../../../../data/login/api";
import { DateRangeInput } from "@blueprintjs/datetime";
import { useToaster } from "../../../../utils/toaster";
import { useAppSelector } from "../../../../redux/hooks";
import { historyGet } from "../../../../redux/history/history.actions";
import { useDispatch } from "react-redux";
import useListHistory from "./useListHistory";
import { useHistory, useLocation } from "react-router";
import wallet from "../../../../assets/wallet.svg";
import ewaGajiku from "../../../../assets/ewa-gajiku.png";
import { requestGet } from "../../../../redux/request/request.actions";

const styles = {
  currencyStats: { fontSize: "1.2em", color: "var(--theme-green)" },
  subheader: {
    fontSize: "1.2rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center" as const,
    color: "var(--theme-medium-gray)",
  },
};
export const HistoryPage: PageComponent = ({ onTopIconClickRef, baseUrl }) => {
  const [, loginAuth] = useLogin();
  const toaster = useToaster();

  const [selectedTransaction, setDialogTransaction] =
    useState<any | undefined>();

  onTopIconClickRef.current = () => {
    setHistoryData([]);
    setPage(1);
    getWageRequest(1);
    dispatch(requestGet());
  };

  const [wageRequest, getWageRequest] = useListHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [startDateSelected, setStartDateSelected] =
    useState<Date | undefined>();
  const [endDateSelected, setEndDateSelected] = useState<Date | undefined>();

  const [showRequestsOnly, setShowRequestsOnly] = useState<boolean>(true);

  const { ewaCurrentLimit, ewaWalletBalance } = useAppSelector(
    (state) => state.requestState,
  );
  const { wallet_enabled } = useAppSelector(
    (state) => state.companySettingState,
  );
  const [page, setPage] = useState(1);
  const [historyData, setHistoryData] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const { loading } = useAppSelector((state) => state.historyState);

  const location = useLocation();

  useEffect(() => {
    setHistoryData([]);
    setPage(1);
    getWageRequest(1);
  }, [location]);

  useEffect(() => {
    setHistoryData((item: any) => [...item, ...wageRequest]);
  }, [wageRequest]);

  const loadMore = () => {
    getWageRequest(page + 1);
    setPage(page + 1);
  };

  const [showFilterOptions, setShowFilterOptions] = useState<boolean>(true);
  return (
    <>
      <HistoryDetailPopup
        isOpen={isDialogOpen}
        tx={selectedTransaction}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
      <div className="history-page">
        <>
          <Card className="balance-card">
            <div className="d-flex balance-box">
              <div
                className={`ewa-balance ${
                  !wallet_enabled ? "border-0 text-center" : ""
                }`}
              >
                <small>
                  <img src={ewaGajiku} alt="wallet" className="wallet-icon" />{" "}
                  EWA
                </small>
                <div>
                  <h5>Rp. {rupiahFormatter(ewaCurrentLimit)}</h5>
                </div>
              </div>
              {wallet_enabled && (
                <div className="wallet-balance">
                  <small>
                    <img src={wallet} alt="wallet" className="wallet-icon" />{" "}
                    Wallet{" "}
                  </small>
                  <div>
                    <h5>Rp. {rupiahFormatter(ewaWalletBalance)}</h5>
                  </div>
                </div>
              )}
            </div>
          </Card>
          <div className="container logs">
            <Card
              style={{
                background: "var(--theme-light-gray)",
                padding: "1%",
                marginBottom: "-1%",
                display: "none",
              }}
            >
              <Icon
                icon={"filter"}
                onClick={() => {
                  setShowFilterOptions(!showFilterOptions);
                }}
              />
              {showFilterOptions && (
                <div
                  style={{
                    marginTop: "1%",
                    textAlign: "center",
                  }}
                >
                  <DateRangeInput
                    className="date-range-input"
                    singleMonthOnly={true}
                    formatDate={(date) => date.toLocaleDateString()}
                    parseDate={(str) => new Date(str)}
                    onChange={(e) => {
                      setStartDateSelected(e[0] || undefined);
                      setEndDateSelected(e[1] || undefined);
                    }}
                    value={[startDateSelected || null, endDateSelected || null]}
                  />
                  <Switch
                    defaultChecked={true}
                    alignIndicator={"right"}
                    style={{
                      textAlign: "right",
                      fontSize: "0.8em",
                      margin: "2%",
                      fontStyle: "italic",
                    }}
                    onClick={() => {
                      setShowRequestsOnly(!showRequestsOnly);
                    }}
                    label={showRequestsOnly ? "Permintaan" : "Semua Aktivitas"}
                  />
                </div>
              )}
              <div style={{ textAlign: "center" }}>
                Jumlah Transaksi Ditampilkan:
                <div
                  style={{
                    ...styles.currencyStats,
                    color: "var(--theme-purple)",
                  }}
                >
                  {/* Rp. {rupiahFormatter(filteredTransactionsTotalAmount)} */}
                </div>
              </div>
            </Card>
            {loading ? (
              <Spinner />
            ) : (
              <div className="h-100">
                {historyData?.map((data: any, idx) => (
                  <>
                    <Card className="list-history-card">
                      <div>
                        <div className="d-flex align-center mb-1">
                          <div className="chip danger">Penarikan</div>
                          <small className="ml-auto">
                            {new Date(data.created_at).toLocaleDateString(
                              "id-ID",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              },
                            )}
                          </small>
                        </div>
                        <div className="d-flex mb-1 align-center">
                          <h4 className="m-0">
                            -Rp. {rupiahFormatter(data.amount)}
                          </h4>
                          <div
                            className={`ml-auto badge ${data.status.toLowerCase()}`}
                          >
                            {data.status}
                          </div>
                        </div>
                        <div className="d-flex">
                          <small>{data.description}</small>
                          <small
                            className="ml-auto text-info"
                            onClick={() => {
                              setIsDialogOpen(true);
                              setDialogTransaction(data);
                            }}
                          >
                            Detail
                          </small>
                        </div>
                      </div>
                    </Card>
                  </>
                ))}
                <Button
                  loading={loading}
                  className={`load ${wageRequest?.length > 0 ? "" : "d-none"}`}
                  onClick={() => loadMore()}
                >
                  Muat data lainnya
                </Button>
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
};
