import React, { FunctionComponent, useEffect, useState } from "react";
import "./index.css";
import { PageComponent } from "../../lib";
import { Button, Dialog, Spinner } from "@blueprintjs/core";
import { generateEnterKeyWatcher } from "../../../../utils/keys";
import { rupiahFormatter } from "../../../../utils/formatters";
import { useAppSelector } from "../../../../redux/hooks";
import {
  requestGet,
  updateBank,
  wageCheck,
  wageRequest,
} from "../../../../redux/request/request.actions";
import { useDispatch } from "react-redux";
import TncBahasa from "../../components/tncBahasa";
import usePermissions from "../../../../_helper/usePermissions";

interface IRequestDialog {
  isOpen: boolean;
  onClose: () => void;
  setDescription: (description: string) => void;
  description: string;
  amount: number;
  fee: number;
  principal: number;
  onSubmit: () => void;
  tncMessage: string;
  requestType: string;
}
const RequestDialog: FunctionComponent<IRequestDialog> = ({
  isOpen,
  onClose,
  setDescription,
  description,
  amount,
  onSubmit,
  fee,
  principal,
  tncMessage,
  requestType,
}) => {
  const {
    loading,
    bankCode,
    bankAccountName,
    bankAccountNumber,
    name,
    statusRequest,
  } = useAppSelector((state) => state.requestState);
  const [isTnc, setIsTnc] = useState(false);
  const isDisabled = description.length < 5;
  const [isAgree, setIsAgree] = useState(false);
  const onKeyPress = generateEnterKeyWatcher(onSubmit);
  document.addEventListener("click", function (e: any) {
    if (e.target && e.target.id == "tncId") {
      setIsTnc(true);
    }
  });

  useEffect(() => {
    if (statusRequest == "success") {
      setIsAgree(false);
    }
  }, [statusRequest]);

  return (
    <Dialog
      isOpen={isOpen}
      canOutsideClickClose={false}
      className="h-auto"
      onClose={onClose}
    >
      <div className={"request-page-confirmation-container title"}>
        <div className={"confirmation-title"}>
          {!isTnc ? "Konfirmasi" : "Syarat & Ketentuan"}
        </div>
        <hr className="dialog-hr" />
      </div>
      {!isTnc ? (
        <>
          <div className={"body"}>
            {!loading ? (
              <>
                <div className="d-flex mb-2">
                  <small>Sumber penarikan:</small>
                  <h5 className="amount-text m-0 ml-auto">
                    {requestType == "gajiku-ewa"
                      ? "Gajiku EWA"
                      : "Gajiku Wallet"}
                  </h5>
                </div>
                <div className="amount-box">
                  <div className="d-flex">
                    <small>Nominal yang ditarik</small>
                    <h4 className="amount-text font-weight-normal">
                      Rp. {rupiahFormatter(amount)}
                    </h4>
                  </div>
                  <div className="d-flex">
                    <small>Biaya penarikan</small>
                    <h4 className="amount-text font-weight-normal">
                      Rp. {rupiahFormatter(fee)}
                    </h4>
                  </div>
                  <hr />
                  <div className="d-flex">
                    <small>Nominal yang anda dapat</small>
                    <h3 className="amount-text">
                      Rp. {rupiahFormatter(principal)}
                    </h3>
                  </div>
                </div>
                <div className="request-desc-box">
                  <h5>Nominal akan ditransfer ke*</h5>
                  <div className="d-flex">
                    <small>Bank</small>
                    <h4 className="amount-text">{bankCode}</h4>
                  </div>
                  <div className="d-flex">
                    <small>No. Rekening</small>
                    <h4 className="amount-text">{bankAccountNumber}</h4>
                  </div>
                  <div className="d-flex">
                    <small>Nama</small>
                    <h4 className="amount-text">
                      {bankAccountName ? bankAccountName : name}
                    </h4>
                  </div>
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </div>
          <div className={"mt-0 message-input"}>
            <h5 className="mb-1 mt-0">
              Alasan Penarikan<sup className="text-danger">*</sup>
            </h5>
            <textarea
              onKeyPress={onKeyPress}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              className={`request-reason ${
                isAgree && isDisabled ? "error" : ""
              }`}
            />
          </div>
          <div className={"request-page-confirmation-container footer"}>
            {tncMessage != "" && tncMessage && (
              <div className="d-flex tnc-box">
                <div className="box-checkbox">
                  <input
                    type="checkbox"
                    checked={isAgree}
                    onChange={() => setIsAgree(!isAgree)}
                    className="tnc-checkbox"
                  />
                </div>
                <div className="approve-text">
                  <small
                    dangerouslySetInnerHTML={{
                      __html: tncMessage?.replace(
                        "{tnc}",
                        `<u id="tncId">Syarat & Ketentuan</u>`,
                      ),
                    }}
                  />
                </div>
              </div>
            )}
            <hr className="dialog-hr" />
            <div className="d-flex">
              <Button
                className="cancel"
                text="Batal"
                onClick={() => {
                  setIsAgree(false);
                  onClose();
                }}
              />
              <Button
                className={
                  (tncMessage != "" && !isAgree) || isDisabled || principal <= 0
                    ? "disabled"
                    : "confirm"
                }
                text="Konfirmasi"
                disabled={
                  (tncMessage != "" && !isAgree) || isDisabled || principal <= 0
                }
                onClick={onSubmit}
                loading={loading}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-tnc">
            <TncBahasa />
          </div>
          <div className={"request-page-confirmation-container footer"}>
            <hr className="dialog-hr" />
            <div className="text-center">
              <Button
                className="btn-info"
                onClick={() => {
                  setIsTnc(false);
                  setIsAgree(true);
                }}
              >
                Mengerti
              </Button>
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
};

const WarningLabel: FunctionComponent<{ message: string }> = ({ message }) => {
  if (message !== "") {
    return <div className="label">{message}</div>;
  }
  return null;
};

const AllowanceSlider: FunctionComponent<{
  amount: number;
  minimumRequestAmount: number;
  maximumRequestAmount: number;
  retrievedAvailableAllowance: number;
  setAmount: (value: ((prevState: number) => number) | number) => void;
}> = ({
  amount,
  minimumRequestAmount,
  maximumRequestAmount,
  retrievedAvailableAllowance,
  setAmount,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const maxAmount =
    maximumRequestAmount === 0
      ? retrievedAvailableAllowance
      : retrievedAvailableAllowance > maximumRequestAmount
      ? maximumRequestAmount
      : retrievedAvailableAllowance;

  return (
    <>
      <div className="label">Jumlah penarikan yang bisa diterima (Rp)</div>
      <div className="input-field-amount">
        {isEditing ? (
          <input
            type="text"
            name="real"
            pattern="[0-9]"
            min={
              minimumRequestAmount > retrievedAvailableAllowance
                ? retrievedAvailableAllowance
                : minimumRequestAmount
            }
            inputMode="numeric"
            max={maxAmount}
            value={amount}
            onChange={(e) => {
              if (e.target.value === "") {
                setAmount(0);
              } else if (parseInt(e.target.value) >= maxAmount) {
                setAmount(maxAmount);
              } else {
                setAmount(parseInt(e.target.value));
              }
            }}
            onBlur={() => setIsEditing(false)}
            placeholder="RP."
          />
        ) : (
          <input
            type="text"
            name="temp"
            inputMode="numeric"
            value={`Rp. ${rupiahFormatter(amount)}`}
            onFocus={() => setIsEditing(true)}
            placeholder="RP."
          />
        )}
      </div>
      <input
        className="slider"
        type="range"
        min={minimumRequestAmount}
        max={maxAmount}
        step={10000}
        value={amount}
        onChange={(e) => {
          setAmount(parseInt(e.target.value));
        }}
      />
    </>
  );
};

export const RequestPage: PageComponent = ({ onTopIconClickRef }) => {
  const dispatch = useDispatch();
  const {
    ewaCurrentLimit,
    ewaAllowed,
    loading,
    bankVerificationStatus,
    isBankSetup,
    ewaMinimumRequestAmount,
    ewaMaximumRequestAmount,
    fee,
    principal,
    failure,
    statusRequest,
    transactionCap,
    wageRequestCount,
    ewaConfirmationMessage,
    ewaWalletBalance,
  } = useAppSelector((state) => state.requestState);
  const { wallet_enabled } = useAppSelector(
    (state) => state.companySettingState,
  );
  const { reqCameraPermission } = usePermissions();

  const [amount, setAmount] = useState<number>(ewaMinimumRequestAmount || 0);
  const [description, setDescription] = useState<string>("");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [bankName, setBankName] = useState("MANDIRI");
  const [bankNumber, setBankNumber] = useState("");
  const [requestType, setRequestType] = useState("gajiku-ewa");

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTopIconClickRef.current = () => {
    dispatch(requestGet());
  };

  const [isOpenBank, setIsOpenBank] = useState(false);
  useEffect(() => {
    if (ewaCurrentLimit <= ewaMinimumRequestAmount) {
      setRequestType("gajiku-wallet");
    } else {
      setRequestType("gajiku-ewa");
    }
  }, [ewaCurrentLimit]);

  const createAdvanceRequest = () => {
    if (!isBankSetup) {
      setIsOpenBank(true);
      dispatch(wageCheck(amount));
    }

    if (isBankSetup) {
      if (
        bankVerificationStatus != "VALID" &&
        bankVerificationStatus != "PENDING"
      ) {
        setIsOpenBank(true);
      } else {
        setShowDialog(true);
        dispatch(wageCheck(amount));
      }
    }
  };

  useEffect(() => {
    if (statusRequest == "success") {
      dispatch(requestGet());
    }
  }, [statusRequest]);

  const submitAdvanceRequest = () => {
    dispatch(wageRequest(amount, description, requestType));
    if (failure) {
      setShowDialog(true);
    } else {
      setAmount(
        ewaCurrentLimit < ewaMinimumRequestAmount ? 0 : ewaMinimumRequestAmount,
      );
      setShowDialog(false);
    }
    setDescription("");
  };

  let warningMessage = "";
  if (ewaCurrentLimit <= ewaMinimumRequestAmount) {
    warningMessage = "tidak ada gaji yang bisa ditarik";
  }

  if (!ewaAllowed) {
    warningMessage = "anda tidak memiliki akses untuk tarik gaji";
  }

  const submitBankAccount = () => {
    dispatch(updateBank(bankName, bankNumber));
    if (failure) {
      setIsOpenBank(true);
    } else {
      setIsOpenBank(false);
      setBankNumber("");
      setBankName("");
    }
  };

  const handleBankName = (e: any) => {
    setBankName(e.target.value);
  };

  const handleBankNumber = (e: any) => {
    const word_With_Numbers = e.target.value;
    const word_Without_Numbers = word_With_Numbers.replace(/\D/g, "");
    setBankNumber(word_Without_Numbers);
  };

  const handleChangeRadio = (e: any) => {
    console.log("e.", e.target.value);
    setRequestType(e.target.value);
    setAmount(ewaMinimumRequestAmount);
  };

  return (
    <div className="request-page-container">
      <RequestDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        amount={amount}
        description={description || ""}
        setDescription={setDescription}
        onSubmit={submitAdvanceRequest}
        fee={fee}
        tncMessage={ewaConfirmationMessage}
        principal={principal}
        requestType={requestType}
      />
      <Dialog isOpen={isOpenBank} onClose={() => setIsOpenBank(false)}>
        <div className="container">
          {bankVerificationStatus == "PENDING" && isBankSetup ? (
            <p>
              We are currently verify your bank account, please retry again
              later
            </p>
          ) : (
            <>
              <label>Pilih Bank</label>
              <select
                name="bankCode"
                className="form-control"
                onChange={(e) => handleBankName(e)}
                value={bankName}
              >
                <option value="MANDIRI">MANDIRI</option>
                <option value="BRI">BRI</option>
                <option value="BNI">BNI</option>
                <option value="BCA">BCA</option>
                <option value="BSM">BSM</option>
                <option value="CIMB">CIMB</option>
                <option value="MUAMALAT">MUAMALAT</option>
                <option value="DANAMON">DANAMON</option>
                <option value="PERMATA">PERMATA</option>
                <option value="BII">BII</option>
                <option value="PANIN">PANIN</option>
                <option value="UOB">UOB</option>
                <option value="OCBC">OCBC</option>
                <option value="CITIBANK">CITIBANK</option>
                <option value="ARTHA">ARTHA</option>
                <option value="TOKYO">TOKYO</option>
                <option value="DBS">DBS</option>
                <option value="STANDARD_CHARTERED">STANDARD_CHARTERED</option>
                <option value="CAPITAL">CAPITAL</option>
                <option value="ANZ">ANZ</option>
                <option value="BOC">BOC</option>
                <option value="BUMI_ARTA">BUMI_ARTA</option>
                <option value="HSBC">HSBC</option>
                <option value="RABOBANK">RABOBANK</option>
                <option value="MAYAPADA">MAYAPADA</option>
                <option value="BJB">BJB</option>
                <option value="DKI">DKI</option>
                <option value="DAERAH_ISTIMEWA">DAERAH_ISTIMEWA</option>
                <option value="JAWA_TENGAH">JAWA_TENGAH</option>
                <option value="JAWA_TIMUR">JAWA_TIMUR</option>
                <option value="JAMBI">JAMBI</option>
                <option value="SUMUT">SUMUT</option>
                <option value="SUMATERA_BARAT">SUMATERA_BARAT</option>
                <option value="RIAU_DAN_KEPRI">RIAU_DAN_KEPRI</option>
                <option value="SUMSEL_DAN_BABEL">SUMSEL_DAN_BABEL</option>
                <option value="LAMPUNG">LAMPUNG</option>
                <option value="KALIMANTAN_SELATAN">KALIMANTAN_SELATAN</option>
                <option value="KALIMANTAN_BARAT">KALIMANTAN_BARAT</option>
                <option value="KALIMANTAN_TIMUR">KALIMANTAN_TIMUR</option>
                <option value="KALIMANTAN_TENGAH">KALIMANTAN_TENGAH</option>
                <option value="SULSELBAR">SULSELBAR</option>
                <option value="SULUT">SULUT</option>
                <option value="NUSA_TENGGARA_BARAT">NUSA_TENGGARA_BARAT</option>
                <option value="BALI">BALI</option>
                <option value="NUSA_TENGGARA_TIMUR">NUSA_TENGGARA_TIMUR</option>
                <option value="MALUKU">MALUKU</option>
                <option value="PAPUA">PAPUA</option>
                <option value="BENGKULU">BENGKULU</option>
                <option value="SULAWESI">SULAWESI</option>
                <option value="SULAWESI_TENGGARA">SULAWESI_TENGGARA</option>
                <option value="NUSANTARA_PARAHYANGAN">
                  NUSANTARA_PARAHYANGAN
                </option>
                <option value="INDIA">INDIA</option>
                <option value="MESTIKA_DHARMA">MESTIKA_DHARMA</option>
                <option value="SINARMAS">SINARMAS</option>
                <option value="MASPION">MASPION</option>
                <option value="GANESHA">GANESHA</option>
                <option value="ICBC">ICBC</option>
                <option value="QNB_KESAWAN">QNB_KESAWAN</option>
                <option value="BTN">BTN</option>
                <option value="WOORI">WOORI</option>
                <option value="TABUNGAN_PENSIUNAN_NASIONAL">
                  TABUNGAN_PENSIUNAN_NASIONAL
                </option>
                <option value="BRI_SYR">BRI_SYR</option>
                <option value="BJB_SYR">BJB_SYR</option>
                <option value="MEGA">MEGA</option>
                <option value="BUKOPIN">BUKOPIN</option>
                <option value="JASA_JAKARTA">JASA_JAKARTA</option>
                <option value="HANA">HANA</option>
                <option value="MNC_INTERNASIONAL">MNC_INTERNASIONAL</option>
                <option value="AGRONIAGA">AGRONIAGA</option>
                <option value="SBI_INDONESIA">SBI_INDONESIA</option>
                <option value="ROYAL">ROYAL</option>
                <option value="NATIONALNOBU">NATIONALNOBU</option>
                <option value="MEGA_SYR">MEGA_SYR</option>
                <option value="INA_PERDANA">INA_PERDANA</option>
                <option value="SAHABAT_SAMPOERNA">SAHABAT_SAMPOERNA</option>
                <option value="KESEJAHTERAAN_EKONOMI">
                  KESEJAHTERAAN_EKONOMI
                </option>
                <option value="BCA_SYR">BCA_SYR</option>
                <option value="ARTOS">ARTOS</option>
                <option value="MAYORA">MAYORA</option>
                <option value="INDEX_SELINDO">INDEX_SELINDO</option>
                <option value="VICTORIA_INTERNASIONAL">
                  VICTORIA_INTERNASIONAL
                </option>
                <option value="AGRIS">AGRIS</option>
                <option value="CHINATRUST">CHINATRUST</option>
                <option value="COMMONWEALTH">COMMONWEALTH</option>
                <option value="VICTORIA_SYR">VICTORIA_SYR</option>
                <option value="BANTEN">BANTEN</option>
                <option value="DOKU">DOKU</option>
                <option value="MUTIARA">MUTIARA</option>
                <option value="PANIN_SYR">PANIN_SYR</option>
              </select>
              <label>Nomer Rekening</label>
              <input
                className="form-control"
                value={bankNumber}
                type="text"
                name="bankAccountNumber"
                onChange={(e) => handleBankNumber(e)}
              />
              <div className="container submit-button btn-info">
                <Button
                  text="Update Akun Bank"
                  // disabled={ewaAllowed}
                  onClick={submitBankAccount}
                  loading={loading}
                />
              </div>
            </>
          )}
        </div>
        <div className="container submit-button btn-info">
          <div>
            <button
              className="btn btn-close"
              onClick={() => setIsOpenBank(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="container request-page-total-allowance mb-0">
            <div className="label mb-2 text-left">Pilih sumber penarikan: </div>
            <div className="amount-choice-box">
              <label
                htmlFor="gajiku-ewa"
                className={`${requestType == "gajiku-ewa" ? "selected" : ""} ${
                  ewaCurrentLimit < ewaMinimumRequestAmount ||
                  ewaCurrentLimit === 0
                    ? "disabled-card"
                    : ""
                }`}
              >
                <small className="text-secondary">Gajiku EWA Limit:</small>
                <div className="amount-wrap">
                  <input
                    type="radio"
                    id="gajiku-ewa"
                    className={!wallet_enabled ? "d-none" : ""}
                    onChange={(e) => handleChangeRadio(e)}
                    name="ewaAmountChoice"
                    value="gajiku-ewa"
                    checked={
                      requestType == "gajiku-ewa" &&
                      !(ewaCurrentLimit < ewaMinimumRequestAmount) &&
                      !(ewaCurrentLimit === 0)
                    }
                  />
                  <h3>Rp. {rupiahFormatter(ewaCurrentLimit)}</h3>
                </div>
              </label>
              {wallet_enabled && (
                <>
                  <label
                    htmlFor="gajiku-wallet"
                    className={`${
                      requestType == "gajiku-wallet" ? "selected" : ""
                    } ${
                      ewaWalletBalance < ewaMinimumRequestAmount ||
                      ewaWalletBalance === 0
                        ? "disabled-card"
                        : ""
                    }`}
                  >
                    <small className="text-secondary">
                      Gajiku Wallet Limit:
                    </small>
                    <div className="amount-wrap">
                      <input
                        type="radio"
                        id="gajiku-wallet"
                        name="ewaAmountChoice"
                        onChange={(e) => handleChangeRadio(e)}
                        value="gajiku-wallet"
                        checked={
                          requestType == "gajiku-wallet" &&
                          !(ewaWalletBalance < ewaMinimumRequestAmount) &&
                          !(ewaWalletBalance === 0)
                        }
                      />
                      <h3>Rp. {rupiahFormatter(ewaWalletBalance)}</h3>
                    </div>
                  </label>
                </>
              )}
            </div>
          </div>
          <hr className="hr" />
          <div className="container request-page-withdrawal-amount">
            <AllowanceSlider
              amount={amount}
              minimumRequestAmount={ewaMinimumRequestAmount}
              maximumRequestAmount={ewaMaximumRequestAmount}
              retrievedAvailableAllowance={
                ewaCurrentLimit <= ewaMinimumRequestAmount ||
                requestType == "gajiku-wallet"
                  ? ewaWalletBalance
                  : ewaCurrentLimit
              }
              setAmount={setAmount}
            />
            <WarningLabel message={warningMessage} />
          </div>

          {ewaMaximumRequestAmount > 0 && (
            <div className="cap-container font-black">
              Maksimum jumlah per penarikan: Rp{" "}
              {rupiahFormatter(ewaMaximumRequestAmount)}
            </div>
          )}
          {ewaMinimumRequestAmount > 0 && (
            <div className="cap-container font-black">
              Minimum Penarikan: Rp {rupiahFormatter(ewaMinimumRequestAmount)}
            </div>
          )}
          {transactionCap > 0 && (
            <div className="cap-container font-red">
              Total Penarikan Periode Ini: {wageRequestCount} / {transactionCap}
            </div>
          )}
          <hr className="hr" />
          <div className="container submit-button">
            <Button
              text={
                wageRequestCount >= transactionCap && transactionCap > 0
                  ? "Sudah Melampaui Limit Transaksi"
                  : "Tarik Gaji"
              }
              disabled={
                (wageRequestCount >= transactionCap &&
                  transactionCap > 0 &&
                  requestType == "gajiku-ewa") ||
                (ewaCurrentLimit < 1 && requestType == "gajiku-ewa") ||
                !ewaAllowed ||
                amount < ewaMinimumRequestAmount ||
                amount <= 0 ||
                (requestType == "gajiku-wallet" &&
                  ewaWalletBalance < ewaMinimumRequestAmount)
              }
              onClick={createAdvanceRequest}
            />
            <button
              onClick={() => {
                reqCameraPermission();
              }}
            >
              Test request permission
            </button>
          </div>
        </>
      )}
    </div>
  );
};
