import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import countryCodes from "../../../../backend/countryCodes.json";
import "./index.css";
import {
  usePasswordResetSendOtpMutation,
  useRegistrationSendOtpMutation,
} from "../../../../backend/app";
import { RegistrationComponentProps } from "../../lib";

const cleanPhoneNumber = (number: string) => {
  return number.replace(/[\W_]+/g, "");
};

export const ValidateHandphone: FunctionComponent<RegistrationComponentProps> =
  ({ setNextIsDisabled, setRegistrationToken, onNextClickRef }) => {
    const MIN_PHONE_NUMBER_LENGTH = 7;
    const MAX_PHONE_NUMBER_LENGTH = 15;

    const countryCodeSelections = countryCodes.map((countryCode, index) => {
      return (
        <option key={`country-code-option-${index}`}>{countryCode.dial}</option>
      );
    });

    const [handphone, setHandphone] = useState<string>("");
    const [countryCodeSelection, setCountryCodeSelection] =
      useState<string>("+62");
    // const [businessCode, setBusinessCode] = useState<string>();

    const cleanHandphone = cleanPhoneNumber(handphone);
    const fullPhoneNumber = cleanPhoneNumber(
      countryCodeSelection + cleanHandphone,
    );

    const [sendOTP] = usePasswordResetSendOtpMutation();

    useEffect(() => {
      setNextIsDisabled(
        !(
          fullPhoneNumber.length >= MIN_PHONE_NUMBER_LENGTH &&
          fullPhoneNumber.length <= MAX_PHONE_NUMBER_LENGTH
        ),
      );
    }, [fullPhoneNumber, setNextIsDisabled]);

    const submit = useCallback(async () => {
      const claim = await sendOTP({
        variables: {
          countryCode: parseInt(cleanPhoneNumber(countryCodeSelection)),
          phoneNumber: parseInt(cleanHandphone),
        },
        fetchPolicy: "no-cache",
      });
      setRegistrationToken(claim.data?.PasswordResetSendOtp);
    }, [setRegistrationToken, sendOTP, countryCodeSelection, cleanHandphone]);

    onNextClickRef.current = useCallback(async () => {
      try {
        await submit();
      } catch (e) {
        throw e;
      }
    }, [submit]);

    return (
      <div className="hp">
        <div className="company-tag">Gajiku Inc</div>
        <div className="header">
          <div>Halo! Harap masukan nomor Whatsapp.</div>
        </div>
        <div className="container hp-number">
          <div>Nomor Whatsapp</div>
          <div className="hp-field">
            <select onChange={(e) => setCountryCodeSelection(e.target.value)}>
              {countryCodeSelections}
            </select>
            <input
              value={handphone}
              onChange={(e) => setHandphone(e.target.value)}
              placeholder="Masuk Nomor Hp"
            />
          </div>
        </div>
        {/*<div className="container biz-code">*/}
        {/*  <div>Kode Perusahaan (Opsional)</div>*/}
        {/*  <input placeholder="Masuk Kode Bisnis"/>*/}
        {/*</div>*/}
      </div>
    );
  };
