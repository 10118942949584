import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSpawnRender } from "./index";
import { LoanRender, LoanRenderForm, LoanRequest } from "./interface";
import { If, Then } from "react-if";
import { createStore, titleCase } from "./util";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { getLoanHistory } from "../../../../redux/loan/loan.actions";

export const LoanHistory = () => {
  const [message] = useState("");
  const [, setRender] = useSpawnRender();
  const cardWrapperRef = useRef<HTMLDivElement>(null);
  const { loanHistoryData } = useAppSelector((state) => state.loanState);
  useEffect(() => {
    if (cardWrapperRef.current && loanHistoryData.length > 0) {
      const clickListener = (e: MouseEvent) => {
        if (e.target && e.target instanceof Element) {
          const ja = e.target.closest(".card");
          if (ja === null) {
            return;
          }
          const index = Number(ja.getAttribute("data-index"));
          setRender({
            spawn: LoanRender.DETAIL,
            index,
          });
        }
      };
      cardWrapperRef.current.addEventListener("click", clickListener);
    }
  }, [cardWrapperRef.current, loanHistoryData]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoanHistory());
  }, []);

  return (
    <>
      <div className={"card-wrapper"} ref={cardWrapperRef}>
        {loanHistoryData?.map((item: any, i: number) => {
          return (
            <div className="card" key={i} data-index={item.id}>
              <div className="card-body">
                <div
                  className="d-flex justify-between"
                  style={{ marginBottom: "1rem" }}
                >
                  <div className="desc">
                    <strong>
                      <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                        {`${new Date(item.createdAt).toLocaleDateString(
                          "id-ID",
                          {
                            day: "2-digit",
                            year: "2-digit",
                            month: "long",
                          },
                        )}, ${new Date(item.createdAt).toLocaleTimeString(
                          "id-ID",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            timeZone: "Asia/Jakarta",
                          },
                        )}`}
                      </p>
                    </strong>
                  </div>
                  <div className={"btn-inside-card-wrapper"}>
                    <div
                      className={`btn-inside-card-right badge-${item.status}`}
                    >
                      <p style={{ fontSize: "12px" }}>
                        {titleCase(item.status)}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-between">
                    <div className="desc">
                      <p>Peminjaman Senilai</p>
                    </div>
                    <h3 style={{ marginBottom: "0px" }}>
                      Rp. {Number(item.amount).toLocaleString()}
                    </h3>
                  </div>
                  <small>Durasi: {item.installment_length} Bulan</small>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={"btn-next-wrapper add"}>
        <button
          className={"btn-next add"}
          onClick={() => setRender({ spawn: LoanRenderForm.FILL })}
        >
          <p>Create Request</p>
        </button>
      </div>
    </>
  );
};
