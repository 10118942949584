import { changePasswordI } from "../_interfaces";
import request from "../_network/request";
const baseUrl = "/auth";

const update = (payload: changePasswordI) => {
  return request({
    url: `${baseUrl}/change-password`,
    method: "POST",
    data: payload,
  });
};

export const ChangePasswordServices = {
  update,
};
