import React, { useEffect, useState } from "react";
import {
  setHeader,
  showAlert,
} from "../../../../../../redux/global/global.actions";
import QrReader from "react-qr-reader";
import "./scan-qr.style.scss";
import useQRParser from "../../../../../../_helper/QRParser";
import { useDispatch } from "react-redux";
import usePresence from "../employee-presence/usePresence";
import { Intent } from "@blueprintjs/core";
import { useAppSelector } from "../../../../../../redux/hooks";
import { getSubordinates } from "../../../../../../redux/presence/presence.actions";

const ScanQR = () => {
  const dispatch = useDispatch();
  const [showScanner, setShowScanner] = useState<boolean>(true);
  const [, , QRData, fromPicture] = useQRParser();
  const [doPresence] = usePresence();
  const { employees } = useAppSelector((state) => state.presence);

  useEffect(() => {
    dispatch(setHeader({ title: "Scan QR", hasBack: true }));
    dispatch(getSubordinates());
  }, []);

  const handleScan = (e: string | null) => {
    if (e) fromPicture(e);
  };

  const handleError = (err: any) => {
    console.log(err);
  };

  const handleSubmit = () => {
    if (QRData) {
      const found = employees.find((item) => item.id === QRData.subOrdinateId);
      const currentDate = new Date().toISOString().split("T")[0];
      const currentTime = new Date()
        .toLocaleDateString("id-ID", {
          hourCycle: "h24",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .split(" ")[1];
      const formattedDate = `${currentDate}T${currentTime.replaceAll(
        ".",
        ":",
      )}.000+07:00`;
      doPresence(
        {
          scheduleId: QRData.scheduleId,
          subordinateId: found?.id,
          latitude: QRData.latitude,
          longitude: QRData.longitude,
          photo: "",
        },
        QRData.type,
        formattedDate,
      );
    } else dispatch(showAlert("QR Data not valid", Intent.DANGER));
  };

  return (
    <div className="scan-qr">
      {showScanner ? (
        <>
          <QrReader
            delay={1000}
            onScan={handleScan}
            onError={handleError}
            showViewFinder={true}
            style={{ width: "100%" }}
          />
          {QRData && (
            <>
              <div className="employee-section">
                <div className="employee-header">Presence for :</div>
                <div className="employee-data">
                  <label htmlFor="employee">{QRData?.employeeName}</label>
                  <div className="employee-name" id="employee">
                    {QRData?.shiftName}
                  </div>
                </div>
              </div>
              <button className="submit-presence" onClick={handleSubmit}>
                Submit
              </button>
            </>
          )}
        </>
      ) : (
        <div className="employee-data">employeeData</div>
      )}
    </div>
  );
};

export default ScanQR;
