import { Intent } from "@blueprintjs/core";
import { batch } from "react-redux";
import { PresenceServices, ShiftServices } from "../../_services";
import GlobalActionTypes from "../global/global.types";
import PresenceActionTypes from "./presence.types";

export const createRemoteRequest = (data: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: PresenceActionTypes.REQUEST_REMOTE });
      });

      const requestRemote = await PresenceServices.requestRemote(data);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PresenceActionTypes.REQUEST_REMOTE_SUCCESS,
          payload: requestRemote,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: "Request Remote Success",
            alertType: Intent.SUCCESS,
          },
        });
      });
      window.history.back();
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PresenceActionTypes.REQUEST_REMOTE_FAILURE,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: "Request Remote Failure",
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};

export const getSubordinates = () => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: PresenceActionTypes.GET_SUBORDINATES });
      });
      const subordinate = await PresenceServices.getSubordinates();
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PresenceActionTypes.GET_SUBORDINATES_SUCCESS,
          payload: subordinate.data.employees,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({ type: PresenceActionTypes.GET_SUBORDINATES_FAILURE });
      });
    }
  };
};

export const getPresenceHistory = () => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: PresenceActionTypes.GET_PRESENCE_HISTORY });
      });

      const presenceHistory = await PresenceServices.getPresenceHistory();

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: PresenceActionTypes.GET_PRESENCE_HISTORY_SUCCESS,
          payload: presenceHistory.data.presence,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({ type: PresenceActionTypes.GET_PRESENCE_HISTORY_FAILURE });
      });
    }
  };
};
