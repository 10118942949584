import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RegistrationComponentProps } from "../../lib";
import "./index.css";
import { useRegistrationSubmitPasswordMutation } from "../../../../backend/app";
import { useLogin } from "../../../../data/login/api";
import { useDispatch } from "react-redux";
import { loginRedux } from "../../../../redux/auth/auth.actions";
import { AuthServices } from "../../../../_services";

export const SetSecurityCode: FunctionComponent<RegistrationComponentProps> = ({
  setNextIsDisabled,
  registrationToken,
  onNextClickRef,
  invitationPhone,
  invitationName,
  invitationCompanyName,
}) => {
  const [initialSecurityCode, setInitialSecurityCode] = useState<string>("");
  const [securityCode, setSecurityCode] = useState<string>("");

  const [login] = useLogin();

  useEffect(() => {
    setNextIsDisabled(
      securityCode === "" ||
        securityCode.length < 4 ||
        securityCode !== initialSecurityCode,
    );
  }, [securityCode, setNextIsDisabled]);

  const [registerSecurityCode] = useRegistrationSubmitPasswordMutation();
  const dispatch = useDispatch();

  onNextClickRef.current = useCallback(async () => {
    await AuthServices.resetPasswordSubmitByToken(
      registrationToken,
      securityCode,
    );
    // const loginAuthBackendResponse = await registerSecurityCode({
    //   variables: {
    //     password: securityCode,
    //     token: registrationToken,
    //   },
    //   fetchPolicy: "no-cache",
    // });
    // await login({
    //   username:
    //     loginAuthBackendResponse.data!.RegistrationSubmitPassword.username,
    //   password: securityCode,
    // });

    dispatch(loginRedux(invitationPhone.replace("+62", ""), securityCode));
  }, [registerSecurityCode, registrationToken, securityCode]);

  return (
    <div className="hp">
      <div className="company-tag">Gajiku Inc</div>
      <div className="header">
        <div>Setup Password</div>
      </div>
      <div className="container full-name">
        Perusahaan
        <input
          placeholder=""
          defaultValue={invitationCompanyName}
          disabled={true}
        />
      </div>
      <div className="container full-name">
        Nama
        <input placeholder="" defaultValue={invitationName} disabled={true} />
      </div>
      <div className="container password">
        Set Password
        <div className="password-field">
          <input
            type="password"
            value={initialSecurityCode}
            onChange={(e) => setInitialSecurityCode(e.target.value)}
            placeholder="Masukan Kata Sandi..."
          />
          <input
            type="password"
            value={securityCode}
            onChange={(e) => setSecurityCode(e.target.value)}
            placeholder="Masukan Kata Sandi Lagi..."
          />
        </div>
      </div>
    </div>
  );
};
