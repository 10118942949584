import CompanySettingsTypes from "./companySetting.types";

interface CompanySettingsState {
  // eslint-disable-next-line @typescript-eslint/ban-types
  attendance_enabled: boolean;
  payroll_enabled: boolean;
  wage_request_enabled: boolean;
  loan_management_enabled: boolean;
  production_tracking_enabled: boolean;
  wallet_enabled: boolean;
  failure: boolean;
  loading: boolean;
}

const INITIAL_STATE: CompanySettingsState = {
  attendance_enabled: false,
  payroll_enabled: false,
  wage_request_enabled: false,
  loan_management_enabled: false,
  production_tracking_enabled: false,
  wallet_enabled: false,
  failure: false,
  loading: false,
};

const CompanySettingReducer = (
  state = INITIAL_STATE,
  action: any,
): CompanySettingsState => {
  switch (action.type) {
    case CompanySettingsTypes.COMPANY_SETTINGS_GET:
      return {
        ...state,
        failure: false,
      };
    case CompanySettingsTypes.COMPANY_SETTINGS_GET_SUCCESS:
      return {
        ...state,
        attendance_enabled: action.payload.attendance_enabled,
        payroll_enabled: action.payload.payroll_enabled,
        wage_request_enabled: action.payload.wage_request_enabled,
        loan_management_enabled: action.payload.loan_management_enabled,
        production_tracking_enabled: action.payload.production_tracking_enabled,
        wallet_enabled: action.payload.wallet_enabled,
      };
    case CompanySettingsTypes.COMPANY_SETTINGS_GET_FAILURE:
      return {
        ...state,
        failure: true,
      };
    default:
      return state;
  }
};

export default CompanySettingReducer;
