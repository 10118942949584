import request from "../_network/request";
// const authUrl = "/employee/profile";

const get = (page: number) => {
  return request({
    url: `ewa/wage-request/history?page=${page}&size=10`,
    method: "GET",
  });
};

export const HistoryServices = {
  get,
};
