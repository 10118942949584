export interface LoanRequest {
  id: number;
  code: string;
  company_id: string;
  member_id: string;
  employee_id: string;
  loan_type: string;
  installment_length: number;
  amount: string;
  interest_value: string;
  principal: number;
  interest_type: string;
  interest_percent: number;
  bank_code: string;
  bank_account_number: string;
  status: string;
  reject_reason: string;
  created_at: string;
  updated_at: string;
  duration: string;
  requested_at: string;
}
export interface LoanPaymentPeriods {
  id: number;
  amount: number;
  dueDate: string;
  isPaid: boolean;
  paidAt: string;
  installmentNo: number;
  createdAt: string;
}
export interface InterestFee {
  id: number;
  code: string;
  company_id: string;
  member_id: string;
  interest_type: string;
  interest_percent: number;
  interest_fee: number;
  created_at: string;
  updated_at: string;
}
export interface LoanHistoryInterface {
  loan_requests: LoanRequest[];
}
export interface EmployeeProfile {
  pkID: number;
  id: string;
  userID: string;
  companyID: string;
  name: string;
  phoneNumber: string;
  nip: string;
  locationCode: string;
  locationName: string;
  isBankSetup: boolean;
  bankCode: string;
  bankAccountNumber: string;
  bankVerificationStatus: string;
  ewaAllowed: boolean;
  ewaCurrentLimit: string;
  ewaMonthlyLimit: string;
  ewaMinimumRequestAmount: string;
  attendanceAllowed: boolean;
  hierarchy: string;
  isSupervisor: boolean;
}
export interface BankInfo {
  id: number;
  user_id: string;
  bank_code: string;
  number: string;
  created_at: null | string;
  updated_at: null | string;
}
export interface ResponseFetch {
  http_status: number;
  process_time: string;
  data: any;
}
export type ImperativeHandler = {
  getForm: () => Partial<FormInput>;
  getRenderForm: () => {
    spawn: keyof typeof LoanRender | keyof typeof LoanRenderForm;
  };
};
export enum LoanRender {
  HOME = "HOME",
  FORM = "FORM",
  DOWNLOAD = "DOWNLOAD",
  DETAIL = "DETAIL",
}
export enum LoanRenderForm {
  FILL = "FILL",
  CONFIRM = "CONFIRM",
}
export interface SpawnRender {
  spawn: keyof typeof LoanRender | keyof typeof LoanRenderForm;
  data?: LoanRequest;
  index?: number;
}
export interface Token {
  authToken: string;
  refreshToken: string;
}
export interface FormInput {
  code: string;
  company_id: string;
  member_id: string;
  employee_id: string;
  amount: number;
  loan_type: string;
  installment_length: number;
  principal: number;
  interest_type: string;
  interest_percent: number;
  interest_value: number;
  bank_code: string;
  bank_account_number: string;
  status: string;
  duration: number;
  user_id: string;
}

export interface DataInput {
  amount: number;
  mentLength: number;
  type: string;
  bankAccountNumber: string;
  bankCode: string;
}
