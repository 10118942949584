/* eslint-disable react/no-unescaped-entities */
import React, { FunctionComponent, useEffect, useState } from "react";
import TncBahasaIndo from "../../../DashboardV2/components/tncBahasa";
import TncWithEnglish from "../../../DashboardV2/components/tncEnglish";
import { RegistrationComponentProps } from "../../lib";
import "./index.css";

export const AgreeTnc: FunctionComponent<RegistrationComponentProps> = ({
  onNextClickRef,
  setNextIsDisabled,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isTncBahasa, setIsTncBahasa] = useState<boolean>(true);

  onNextClickRef.current = async () => {
    return { data: "ok" };
  };

  useEffect(() => {
    setNextIsDisabled(!isChecked);
  }, [isChecked, setNextIsDisabled]);

  const TncBahasa = () => {
    return (
      <div className="language">
        <TncBahasaIndo />
      </div>
    );
  };

  const TncEnglish = () => {
    return (
      <div className="language">
        <TncWithEnglish />
      </div>
    );
  };

  return (
    <div className="hp">
      <div className="company-tag">Gajiku Inc</div>
      <div className="header">
        <div>Silakan baca dan setuju dengan T&Cs di bawah.</div>
      </div>
      <div className="container language-switch">
        <button
          className="bahasa"
          onClick={() => {
            setIsTncBahasa(true);
          }}
        >
          Bahasa
        </button>
        <button
          className="english"
          onClick={() => {
            setIsTncBahasa(false);
          }}
        >
          English
        </button>
      </div>
      <div className="container tnc">
        {!!isTncBahasa && <TncBahasa />}
        {!isTncBahasa && <TncEnglish />}
      </div>
      <div className="agreement">
        <input
          type="checkbox"
          onChange={(e) => {
            setIsChecked(e.target.checked);
          }}
        />
        <div className="message">Saya setuju dengan T&Cs tersebut.</div>
      </div>
    </div>
  );
};
