import { useState } from "react";
import CryptoJS from "crypto-js";
import { showAlert } from "../redux/global/global.actions";
import { useDispatch } from "react-redux";
import { Intent } from "@blueprintjs/core";

export enum ActionType {
  ClockIn = 0,
  ClockOut = 1,
}

interface CurrentSchedule {
  id: string;
  companyId: string;
  locationId: string;
  divisionId: string;
  startDate: Date;
  endDate: Date;
  dataShiftId: string;
  assignTo: string[];
  restrictLocation: boolean;
  backupGPS: boolean;
  createdAt: Date;
}

export interface QRData {
  subOrdinateId?: string;
  scheduleId?: number;
  shiftName?: string;
  latitude: number;
  longitude: number;
  employeeName?: string;
  type: "clock-in" | "clock-out";
}

const useQRParser = () => {
  const dispatch = useDispatch();
  const [formattedText, setFormattedText] = useState<string>("");
  const [data, setData] = useState<QRData>();

  const enc = (raw: string): string =>
    CryptoJS.AES.encrypt(raw, process.env.REACT_APP_AES || "").toString();

  const dec = (encrypted: string): string =>
    CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_AES || "").toString(
      CryptoJS.enc.Utf8,
    );

  const fromText = (qrData: QRData) => {
    const encrypted = enc(JSON.stringify(qrData));
    setFormattedText(encrypted);
  };

  const fromPicture = (text: string) => {
    try {
      const decrypted = dec(text);
      const extractText: QRData = JSON.parse(decrypted);
      if (extractText.scheduleId) setData(extractText);
      else throw "error";
    } catch (error) {
      dispatch(showAlert("QR code is not valid!", Intent.DANGER));
    }
  };

  return [formattedText, fromText, data, fromPicture] as const;
};

export default useQRParser;
