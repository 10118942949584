/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ButtonImage from "../../components/button-selfie";
import MapSection from "../../components/maps-section";
import { sendClockOutAttendance } from "../../../../../../redux/clock-in/clockin.actions";
import {
  setHeader,
  showAlert,
} from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import "./clockout.style.scss";
import { useDispatch } from "react-redux";
import { Intent } from "@blueprintjs/core";
import { ClockOutSelfieI, KEYS } from "../../../../../../_interfaces";
import Geocode from "react-geocode";
import useClockOut from "./useClockOut";

const ClockOut = () => {
  Geocode.setApiKey(KEYS.GEOCODE);
  Geocode.setLanguage("ID");
  const dispatch = useDispatch();
  const { todaySchedule } = useAppSelector((state) => ({
    ...state.shift,
  }));
  const [address, setAddress] = useState("");
  const [form, setForm] = useState<ClockOutSelfieI>({
    presenceId: todaySchedule?.presenceId,
    latitude: 0,
    longitude: 0,
  });
  const [submitClockOut] = useClockOut();

  useEffect(() => {
    dispatch(setHeader({ title: "Absen Keluar", hasBack: true }));
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setForm((state) => ({
          ...state,
          longitude: pos.coords.longitude,
          latitude: pos.coords.latitude,
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  }, []);

  useEffect(() => {
    if (form.latitude !== 0) {
      Geocode.fromLatLng(
        form.latitude.toString(),
        form.longitude.toString(),
      ).then((response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      });
    }
  }, [form.latitude, form.longitude]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitClockOut(form);
  };

  return (
    <form onSubmit={handleSubmit} className="clock-out">
      <div className="form-map">
        <label htmlFor="location">Lokasi anda saat ini</label>
        <textarea
          id="location"
          placeholder="&#61442; Tuliskan lokasi absen anda"
          value={address}
          required
          disabled
        />
      </div>
      {/* <ButtonImage /> */}
      <button type="submit" className="submit-btn">
        Absen Keluar
      </button>
    </form>
  );
};

export default ClockOut;
