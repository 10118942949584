import { Intent } from "@blueprintjs/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../../../../../redux/global/global.actions";
import { ClockOutSelfieI } from "../../../../../../_interfaces";
import { PresenceServices } from "../../../../../../_services";

const useClockOut = () => {
  const dispatch = useDispatch();
  const hist = useHistory();

  const doPresence = async (payload: ClockOutSelfieI) => {
    try {
      dispatch(showLoading());
      const presence = await PresenceServices.clockOutSelfie(payload);
      dispatch(hideLoading());
      dispatch(showAlert("Absen keluar berhasil", Intent.SUCCESS));
      hist.push("/attendance");
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(showAlert(error, Intent.DANGER));
    }
  };

  return [doPresence];
};

export default useClockOut;
