import CompanySettingsTypes from "./companySetting.types";
import { batch } from "react-redux";
import GlobalActionTypes from "../global/global.types";
import { CompanySettingsServices } from "../../_services";
import { Intent } from "@blueprintjs/core";

export const getCompanySettings = () => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: CompanySettingsTypes.COMPANY_SETTINGS_GET });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const get = await CompanySettingsServices.get();
      batch(() => {
        dispatch({
          type: CompanySettingsTypes.COMPANY_SETTINGS_GET_SUCCESS,
          payload: get.data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({
          type: CompanySettingsTypes.COMPANY_SETTINGS_GET_FAILURE,
          payload: error,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: error,
            alertType: Intent.DANGER,
          },
        });
      });
    }
  };
};
