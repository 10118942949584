import ChangePasswordActionTypes from "./changePassword.types";

interface ChangePasswordState {
  // eslint-disable-next-line @typescript-eslint/ban-types
  failure: boolean;
  loading: boolean;
}

const INITIAL_STATE: ChangePasswordState = {
  failure: false,
  loading: false,
};

const ChangePasswordReducer = (
  state = INITIAL_STATE,
  action: any,
): ChangePasswordState => {
  switch (action.type) {
    case ChangePasswordActionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        failure: false,
        loading: true,
      };
    case ChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ChangePasswordActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        failure: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default ChangePasswordReducer;
