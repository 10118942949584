const ProductionTrackingActionTypes = {
  GET_MY_PROJECT: "GET_MY_PROJECT",
  GET_MY_PROJECT_SUCCESS: "GET_MY_PROJECT_SUCCESS",
  GET_MY_PROJECT_FAILURE: "GET_MY_PROJECT_FAILURE",

  GET_GROUP_BY_PROJECT: "GET_GROUP_BY_PROJECT",
  GET_GROUP_BY_PROJECT_SUCCESS: "GET_GROUP_BY_PROJECT_SUCCESS",
  GET_GROUP_BY_PROJECT_FAILURE: "GET_GROUP_BY_PROJECT_FAILURE",

  GENERATE_PRODUCTION: "GENERATE_PRODUCTION",
  GENERATE_PRODUCTION_SUCCESS: "GENERATE_PRODUCTION_SUCCESS",
  GENERATE_PRODUCTION_FAILURE: "GENERATE_PRODUCTION_FAILURE",

  GET_LOG_ITEM: "GET_LOG_ITEM",
  GET_LOG_ITEM_SUCCESS: "GET_LOG_ITEM_SUCCESS",
  GET_LOG_ITEM_FAILURE: "GET_LOG_ITEM_FAILURE",

  CREATE_LOG_ITEM: "CREATE_LOG_ITEM",
  CREATE_LOG_ITEM_SUCCESS: "CREATE_LOG_ITEM_SUCCESS",
  CREATE_LOG_ITEM_FAILURE: "CREATE_LOG_ITEM_FAILURE",

  UPDATE_LOG_ITEM: "UPDATE_LOG_ITEM",
  UPDATE_LOG_ITEM_SUCCESS: "UPDATE_LOG_ITEM_SUCCESS",
  UPDATE_LOG_ITEM_FAILURE: "UPDATE_LOG_ITEM_FAILURE",

  SEND_TO_CHECKER: "SEND_TO_CHECKER",
  SEND_TO_CHECKER_SUCCESS: "SEND_TO_CHECKER_SUCCESS",
  SEND_TO_CHECKER_FAILURE: "SEND_TO_CHECKER_FAILURE",

  APPROVE_INPUT: "APPROVE_INPUT",
  APPROVE_INPUT_SUCCESS: "APPROVE_INPUT_SUCCESS",
  APPROVE_INPUT_FAILURE: "APPROVE_INPUT_FAILURE",

  REJECT_INPUT: "REJECT_INPUT",
  REJECT_INPUT_SUCCESS: "REJECT_INPUT_SUCCESS",
  REJECT_INPUT_FAILURE: "REJECT_INPUT_FAILURE",

  DELETE_INPUT: "DELETE_INPUT",
  DELETE_INPUT_SUCCESS: "DELETE_INPUT_SUCCESS",
  DELETE_INPUT_FAILURE: "DELETE_INPUT_FAILURE",

  SEND_TO_MANAGER: "SEND_TO_MANAGER",
  SEND_TO_MANAGER_SUCCESS: "SEND_TO_MANAGER_SUCCESS",
  SEND_TO_MANAGER_FAILURE: "SEND_TO_MANAGER_FAILURE",

  MANAGER_SUBMIT: "MANAGER_SUBMIT",
  MANAGER_SUBMIT_SUCCESS: "MANAGER_SUBMIT_SUCCESS",
  MANAGER_SUBMIT_FAILURE: "MANAGER_SUBMIT_FAILURE",

  SET_FILTER: "SET_FILTER",
  RESET_FILTER: "RESET_FILTER",
};

export default ProductionTrackingActionTypes;
