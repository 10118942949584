import PermitActionTypes from "./permit.types";

interface ClockinState {
  leaveOptions: Array<any>;
  leaveList: Array<any>;
}

const INITIAL_STATE: ClockinState = {
  leaveOptions: [],
  leaveList: [],
};

const PermitReducer = (state = INITIAL_STATE, action: any): ClockinState => {
  switch (action.type) {
    case PermitActionTypes.GET_OPTIONS:
      return {
        ...state,
        leaveOptions: [],
      };
    case PermitActionTypes.GET_OPTIONS_SUCCESS:
      return {
        ...state,
        leaveOptions: action.payload,
      };
    case PermitActionTypes.GET_OPTIONS_FAILURE:
      return {
        ...state,
        leaveOptions: [],
      };
    case PermitActionTypes.GET_CUTI_LIST:
      return {
        ...state,
        leaveList: [],
      };
    case PermitActionTypes.GET_CUTI_LIST_SUCCESS:
      return {
        ...state,
        leaveList: action.payload,
      };
    case PermitActionTypes.GET_CUTI_LIST_FAILURE:
      return {
        ...state,
        leaveList: [],
      };
    case PermitActionTypes.SUBMIT_LEAVE:
      return {
        ...state,
      };
    case PermitActionTypes.SUBMIT_LEAVE_SUCCESS:
      return {
        ...state,
      };
    case PermitActionTypes.SUBMIT_LEAVE_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default PermitReducer;
