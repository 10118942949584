/* GRAPHQL_VERSION: bcddeb7 */
import BankCode from "./BankCode";
import PCFS from "./PCFS";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  AuthRoleLevel: any;
  BankCode: BankCode;
  Bool: any;
  Context: any;
  Historical: any;
  JSON: any;
  Map: any;
  PCFS: PCFS;
  Time: Date;
  Upload: File;
  Void: any;
};

export type AdditionInsert = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  date: Scalars["Time"];
};

export type AllowanceRequest = {
  __typename?: "AllowanceRequest";
  requestID: Scalars["ID"];
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  amount: Scalars["Int"];
  fee: Scalars["Int"];
  principal: Scalars["Int"];
  idempotencyKey: Scalars["String"];
  status: Scalars["PCFS"];
  createdAt?: Maybe<Scalars["Time"]>;
};

export type AuthRole = {
  __typename?: "AuthRole";
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  level: Scalars["AuthRoleLevel"];
};

export type BankAccount = {
  __typename?: "BankAccount";
  bankKey: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  verificationStatus: BankAccountVerificationStatus;
};

export enum BankAccountVerificationStatus {
  Pending = "PENDING",
  Valid = "VALID",
  InvalidAccountNumber = "INVALID_ACCOUNT_NUMBER",
  InvalidSuspectedAccount = "INVALID_SUSPECTED_ACCOUNT",
  InvalidBlackListed = "INVALID_BLACK_LISTED",
  InvalidUnknown = "INVALID_UNKNOWN",
}

export type CompanyEmployee = {
  __typename?: "CompanyEmployee";
  userID: Scalars["ID"];
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  username: Scalars["String"];
  phoneNumber: Scalars["Int"];
  monthlyLimit: Scalars["Int"];
  birthday?: Maybe<Scalars["Time"]>;
  name: Scalars["String"];
  ktpUrl: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  bankVerificationStatus: BankAccountVerificationStatus;
  wageRequestAllowed: Scalars["Boolean"];
  startDate?: Maybe<Scalars["Time"]>;
  endDate?: Maybe<Scalars["Time"]>;
  customData: Scalars["Map"];
};

export type CompanyEmployeeInput = {
  companyID: Scalars["ID"];
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
  monthlyLimit: Scalars["Int"];
  birthday?: Maybe<Scalars["Time"]>;
  name: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  password: Scalars["String"];
  wageRequestAllowed: Scalars["Boolean"];
  customData: Scalars["Map"];
};

export type CompanyEmployeeUpdate = {
  userID: Scalars["ID"];
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  phoneNumber?: Maybe<Scalars["Int"]>;
  monthlyLimit?: Maybe<Scalars["Int"]>;
  birthday?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  bankCode?: Maybe<Scalars["BankCode"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  wageRequestAllowed?: Maybe<Scalars["Boolean"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  customData?: Maybe<Scalars["Map"]>;
};

export type CompanyInfo = {
  __typename?: "CompanyInfo";
  companyID: Scalars["ID"];
  name: Scalars["String"];
  ewaFee: Scalars["String"];
  startPayPeriodDay: Scalars["Int"];
  endPayPeriodDay: Scalars["Int"];
  wageAllowanceAutoAccruingDaily: Scalars["Boolean"];
  /** Employees can't get access */
  phoneAppEnabled?: Maybe<Scalars["Boolean"]>;
  /** Admin dashboard enablement */
  dashboardEnabled?: Maybe<Scalars["Boolean"]>;
  /** Employees can't request allowance */
  wageRequestEnabled?: Maybe<Scalars["Boolean"]>;
  /** allowance requests are automatically getting disbursed immediately */
  allowanceRequestAutoDisbursing: Scalars["Boolean"];
  /** minimumRequestAmount is the minimum amount that an employee is allowed to request */
  minimumRequestAmount?: Maybe<Scalars["Int"]>;
  /** wageImbursementPercentLimit is the percent limit an employee is allocated */
  wageImbursementPercentLimit: Scalars["Int"];
};

export type CompanySettingsInput = {
  name?: Maybe<Scalars["String"]>;
  /** Inclusive start of pay period */
  startPayPeriodDay?: Maybe<Scalars["Int"]>;
  /**
   * Exclusive end of pay period.
   * If the end falls on a day greater than 26, will attempt to compensate by
   * calculating the closest date
   */
  endPayPeriodDay?: Maybe<Scalars["Int"]>;
  wageAllowanceAutoAccruingDaily?: Maybe<Scalars["Boolean"]>;
  ewaFee?: Maybe<Scalars["String"]>;
  /** Employees can't get access */
  phoneAppEnabled?: Maybe<Scalars["Boolean"]>;
  /** Admin dashboard enablement */
  dashboardEnabled?: Maybe<Scalars["Boolean"]>;
  /** Employees can't request allowance */
  wageRequestEnabled?: Maybe<Scalars["Boolean"]>;
  allowanceRequestAutoDisbursing?: Maybe<Scalars["Boolean"]>;
  minimumRequestAmount?: Maybe<Scalars["Int"]>;
  wageImbursementPercentLimit?: Maybe<Scalars["Int"]>;
};

export enum DashboardType {
  Disbursals = "DISBURSALS",
  Payroll = "PAYROLL",
  Transactions = "TRANSACTIONS",
  Employees = "EMPLOYEES",
  Companies = "COMPANIES",
}

export type DeductionInsert = {
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  date: Scalars["Time"];
};

export type EmployeePeriodAddition = {
  __typename?: "EmployeePeriodAddition";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  date: Scalars["Time"];
};

export type EmployeePeriodDaysWorked = {
  __typename?: "EmployeePeriodDaysWorked";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  daysWorked: Scalars["Int"];
};

export type EmployeePeriodDeduction = {
  __typename?: "EmployeePeriodDeduction";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  amount: Scalars["Int"];
  reason: Scalars["String"];
  date: Scalars["Time"];
};

export type Membership = {
  __typename?: "Membership";
  memberID: Scalars["ID"];
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
  startDate?: Maybe<Scalars["Time"]>;
  endDate?: Maybe<Scalars["Time"]>;
  isManager: Scalars["Boolean"];
};

export type Mutation = {
  __typename?: "Mutation";
  AdminCreate: UserResponse;
  AllowanceAdd: Scalars["Int"];
  AllowanceRequestApprove: AllowanceRequest;
  AllowanceRequestCreate: Scalars["Int"];
  AllowanceRequestDeny: AllowanceRequest;
  CompanyCreate: CompanyInfo;
  CompanyEmployeeCreate: CompanyEmployee;
  CompanyEmployeeUpdate: CompanyEmployee;
  CompanyManagerSet?: Maybe<Scalars["Void"]>;
  CompanyMembershipCreate: Membership;
  CompanyUpdate: CompanyInfo;
  HelloWorldM: Scalars["String"];
  PasswordReset?: Maybe<Scalars["Void"]>;
  PasswordResetSendOtp: Scalars["String"];
  PasswordResetVerifyOtp: Scalars["String"];
  PasswordUpdate?: Maybe<Scalars["Void"]>;
  PayrollAdditionCreate?: Maybe<Scalars["Void"]>;
  PayrollDeductionCreate?: Maybe<Scalars["Void"]>;
  PayrollEmployeeCreate: PayrollEmployee;
  PayrollEmployeeUpdate: PayrollEmployee;
  PayrollPeriodMemberExecute?: Maybe<Scalars["Void"]>;
  PayrollTopUpAdd: Scalars["Int"];
  /** PhoneInvitation will idempotently create an invitation for a phone number */
  PhoneInvitationCreate: PhoneInvitation;
  PhoneInvitationDelete?: Maybe<Scalars["Void"]>;
  /** PhoneOtpCreate will create a manual otp for the phone number. Must include country code */
  PhoneOtpCreate?: Maybe<Scalars["Void"]>;
  PhoneOtpDelete?: Maybe<Scalars["Void"]>;
  RegistrationSendOTP: Scalars["String"];
  RegistrationSubmitBank: Scalars["String"];
  RegistrationSubmitInfo: Scalars["String"];
  RegistrationSubmitPassword: RegistrationInfo;
  RegistrationVerifyOTP: RegistrationInfo;
  UserCreate: UserResponse;
  UserProfileUpdate?: Maybe<Scalars["Void"]>;
  UserUpdatePassword?: Maybe<Scalars["Void"]>;
};

export type MutationAdminCreateArgs = {
  username: Scalars["String"];
  password: Scalars["String"];
};

export type MutationAllowanceAddArgs = {
  userID: Scalars["String"];
  amount: Scalars["Int"];
};

export type MutationAllowanceRequestApproveArgs = {
  idempotencyKey: Scalars["String"];
};

export type MutationAllowanceRequestCreateArgs = {
  userID: Scalars["String"];
  amount: Scalars["Int"];
  description: Scalars["String"];
};

export type MutationAllowanceRequestDenyArgs = {
  idempotencyKey: Scalars["String"];
};

export type MutationCompanyCreateArgs = {
  inp: CompanySettingsInput;
};

export type MutationCompanyEmployeeCreateArgs = {
  inp: CompanyEmployeeInput;
};

export type MutationCompanyEmployeeUpdateArgs = {
  companyID: Scalars["ID"];
  update: CompanyEmployeeUpdate;
};

export type MutationCompanyManagerSetArgs = {
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  isManager: Scalars["Boolean"];
};

export type MutationCompanyMembershipCreateArgs = {
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
};

export type MutationCompanyUpdateArgs = {
  companyID: Scalars["ID"];
  inp: CompanySettingsInput;
};

export type MutationHelloWorldMArgs = {
  companyID: Scalars["ID"];
};

export type MutationPasswordResetArgs = {
  token: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationPasswordResetSendOtpArgs = {
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
};

export type MutationPasswordResetVerifyOtpArgs = {
  token: Scalars["String"];
  otpCode: Scalars["String"];
};

export type MutationPasswordUpdateArgs = {
  userID: Scalars["ID"];
  newPassword: Scalars["String"];
};

export type MutationPayrollAdditionCreateArgs = {
  inp: AdditionInsert;
};

export type MutationPayrollDeductionCreateArgs = {
  inp: DeductionInsert;
};

export type MutationPayrollEmployeeCreateArgs = {
  inp: PayrollEmployeeCreate;
};

export type MutationPayrollEmployeeUpdateArgs = {
  memberID: Scalars["ID"];
  inp: PayrollEmployeeCreate;
};

export type MutationPayrollPeriodMemberExecuteArgs = {
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  periodID: Scalars["ID"];
};

export type MutationPayrollTopUpAddArgs = {
  companyID: Scalars["ID"];
  amount: Scalars["Int"];
};

export type MutationPhoneInvitationCreateArgs = {
  companyID: Scalars["ID"];
  insert: PhoneInvitationInsert;
};

export type MutationPhoneInvitationDeleteArgs = {
  inviteID: Scalars["ID"];
};

export type MutationPhoneOtpCreateArgs = {
  phoneNumber: Scalars["Int"];
  otpCode: Scalars["String"];
};

export type MutationPhoneOtpDeleteArgs = {
  phoneNumber: Scalars["Int"];
};

export type MutationRegistrationSendOtpArgs = {
  token: Scalars["String"];
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
};

export type MutationRegistrationSubmitBankArgs = {
  token: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
};

export type MutationRegistrationSubmitInfoArgs = {
  token: Scalars["String"];
  ktp: Scalars["Upload"];
  birthday: Scalars["Time"];
};

export type MutationRegistrationSubmitPasswordArgs = {
  token: Scalars["String"];
  password: Scalars["String"];
};

export type MutationRegistrationVerifyOtpArgs = {
  token: Scalars["String"];
  otpCode: Scalars["String"];
};

export type MutationUserCreateArgs = {
  username: Scalars["String"];
  password: Scalars["String"];
};

export type MutationUserProfileUpdateArgs = {
  userID: Scalars["ID"];
  update: UserProfileUpdate;
};

export type MutationUserUpdatePasswordArgs = {
  userID: Scalars["String"];
  newPassword: Scalars["String"];
};

export type PayrollEmployee = {
  __typename?: "PayrollEmployee";
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  name: Scalars["String"];
  taxTkRate: Scalars["Int"];
  taxKesRate: Scalars["Int"];
  salaryType: PayrollSalaryType;
  salary: Scalars["Int"];
  dailyRateOverride?: Maybe<Scalars["Int"]>;
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
  customData: Scalars["Map"];
};

export type PayrollEmployeeCreate = {
  companyID: Scalars["ID"];
  name: Scalars["String"];
  taxTkRate?: Maybe<Scalars["Int"]>;
  taxKesRate?: Maybe<Scalars["Int"]>;
  salaryType?: Maybe<PayrollSalaryType>;
  salary?: Maybe<Scalars["Int"]>;
  dailyRateOverride?: Maybe<Scalars["Int"]>;
  bankCode?: Maybe<Scalars["BankCode"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  customData?: Maybe<Scalars["Map"]>;
};

export enum PayrollSalaryType {
  Daily = "DAILY",
  Monthly = "MONTHLY",
}

export type Period = {
  __typename?: "Period";
  periodID: Scalars["ID"];
  companyID: Scalars["ID"];
  startTime: Scalars["Time"];
  endTime: Scalars["Time"];
};

export type PeriodData = {
  __typename?: "PeriodData";
  periodID: Scalars["ID"];
  startTime: Scalars["Time"];
  endTime: Scalars["Time"];
};

export type PhoneInvitation = {
  __typename?: "PhoneInvitation";
  inviteID: Scalars["ID"];
  memberID: Scalars["ID"];
  companyID: Scalars["ID"];
  name: Scalars["String"];
  phoneNumber: Scalars["Int"];
  monthlyLimit: Scalars["Int"];
  bankCode?: Maybe<Scalars["BankCode"]>;
  bankNumber?: Maybe<Scalars["String"]>;
  accepted: Scalars["Boolean"];
  customData: Scalars["Map"];
};

export type PhoneInvitationInsert = {
  companyID: Scalars["ID"];
  name: Scalars["String"];
  phoneNumber: Scalars["Int"];
  monthlyLimit: Scalars["Int"];
  bankCode?: Maybe<Scalars["BankCode"]>;
  bankNumber?: Maybe<Scalars["String"]>;
  customData?: Maybe<Scalars["Map"]>;
};

export type Query = {
  __typename?: "Query";
  AllowanceRequestFee: Scalars["Int"];
  AllowanceRequestMinimum: Scalars["Int"];
  AllowanceRequestRetrieveUnapproved: Array<Maybe<AllowanceRequest>>;
  AllowanceRetrieve: Scalars["Int"];
  AuthRoleRetrieve: AuthRole;
  BankAccountRetrieveDefault: BankAccount;
  CompanyEmployeeRetrieve: CompanyEmployee;
  CompanyEmployeesRetrieve: Array<Maybe<CompanyEmployee>>;
  CompanyInfoRetrieve: CompanyInfo;
  CompanyInfoRetrieveAll: Array<Maybe<CompanyInfo>>;
  CompanyMembershipRetrieve: Membership;
  CompanyMembershipRetrieveAll: Array<Maybe<Membership>>;
  CompanyMembershipsReadAll: Array<Maybe<Membership>>;
  DashboardsVisibleRetrieve: Array<DashboardType>;
  HelloWorldQ: Scalars["String"];
  PayrollAdditionsReadAll: Array<Maybe<EmployeePeriodAddition>>;
  PayrollDaysWorkedReadAll: Array<Maybe<EmployeePeriodDaysWorked>>;
  PayrollDeductionsReadAll: Array<Maybe<EmployeePeriodDeduction>>;
  PayrollEmployeesReadAll: Array<Maybe<PayrollEmployee>>;
  PayrollPeriodDataRead: Array<Maybe<PeriodData>>;
  PayrollTopUpRetrieve: Scalars["Int"];
  PeriodRetrieve: Period;
  PeriodsRetrieveAll: Array<Maybe<Period>>;
  PhoneInvitationsRetrieve: Array<PhoneInvitation>;
  TransactionEventRetrieveByCompanyID: Array<Maybe<TransactionEvent>>;
  TransactionEventRetrieveByUserID: Array<Maybe<TransactionEvent>>;
  UserProfileRetrieve: UserProfile;
  UserRetrieveByUsername: UserResponse;
};

export type QueryAllowanceRequestFeeArgs = {
  userID: Scalars["String"];
  amount: Scalars["Int"];
};

export type QueryAllowanceRequestMinimumArgs = {
  userID: Scalars["String"];
};

export type QueryAllowanceRetrieveArgs = {
  userID: Scalars["String"];
};

export type QueryBankAccountRetrieveDefaultArgs = {
  userID: Scalars["ID"];
};

export type QueryCompanyEmployeeRetrieveArgs = {
  userID: Scalars["ID"];
};

export type QueryCompanyEmployeesRetrieveArgs = {
  companyID: Scalars["ID"];
};

export type QueryCompanyInfoRetrieveArgs = {
  companyID: Scalars["ID"];
};

export type QueryCompanyMembershipRetrieveArgs = {
  userID: Scalars["ID"];
};

export type QueryCompanyMembershipRetrieveAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryDashboardsVisibleRetrieveArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollAdditionsReadAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollDaysWorkedReadAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollDeductionsReadAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollEmployeesReadAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollPeriodDataReadArgs = {
  companyID: Scalars["ID"];
};

export type QueryPayrollTopUpRetrieveArgs = {
  companyID: Scalars["ID"];
};

export type QueryPeriodRetrieveArgs = {
  periodID: Scalars["ID"];
};

export type QueryPeriodsRetrieveAllArgs = {
  companyID: Scalars["ID"];
};

export type QueryPhoneInvitationsRetrieveArgs = {
  companyID: Scalars["ID"];
  accepted?: Maybe<Scalars["Boolean"]>;
};

export type QueryTransactionEventRetrieveByCompanyIdArgs = {
  companyID: Scalars["String"];
};

export type QueryTransactionEventRetrieveByUserIdArgs = {
  userID: Scalars["String"];
};

export type QueryUserProfileRetrieveArgs = {
  userID: Scalars["ID"];
};

export type QueryUserRetrieveByUsernameArgs = {
  username: Scalars["String"];
};

export type RegistrationInfo = {
  __typename?: "RegistrationInfo";
  name: Scalars["String"];
  username: Scalars["String"];
  token: Scalars["String"];
};

export type TransactionEvent = {
  __typename?: "TransactionEvent";
  eventID: Scalars["ID"];
  companyID: Scalars["ID"];
  periodID: Scalars["ID"];
  memberID: Scalars["ID"];
  amount: Scalars["Int"];
  fee: Scalars["Int"];
  principal: Scalars["Int"];
  description: Scalars["String"];
  status: Scalars["PCFS"];
  idempotencyKey: Scalars["String"];
  typ: TransactionEventType;
  allocatedAt?: Maybe<Scalars["Time"]>;
  createdAt?: Maybe<Scalars["Time"]>;
};

export enum TransactionEventType {
  Request = "REQUEST",
  Allowance = "ALLOWANCE",
}

export type UserProfile = {
  __typename?: "UserProfile";
  username: Scalars["String"];
  ktpUrl: Scalars["String"];
  name: Scalars["String"];
};

export type UserProfileUpdate = {
  ktp?: Maybe<Scalars["Upload"]>;
};

export type UserResponse = {
  __typename?: "UserResponse";
  userID: Scalars["ID"];
  username: Scalars["String"];
};

export type AdminCreateMutationVariables = Exact<{
  username: Scalars["String"];
  password: Scalars["String"];
}>;

export type AdminCreateMutation = { __typename?: "Mutation" } & {
  AdminCreate: { __typename?: "UserResponse" } & Pick<
    UserResponse,
    "userID" | "username"
  >;
};

export type AllowanceAddMutationVariables = Exact<{
  userID: Scalars["String"];
  amount: Scalars["Int"];
}>;

export type AllowanceAddMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "AllowanceAdd"
>;

export type AllowanceRequestApproveMutationVariables = Exact<{
  idempotencyKey: Scalars["String"];
}>;

export type AllowanceRequestApproveMutation = { __typename?: "Mutation" } & {
  AllowanceRequestApprove: { __typename?: "AllowanceRequest" } & Pick<
    AllowanceRequest,
    | "requestID"
    | "memberID"
    | "companyID"
    | "periodID"
    | "bankCode"
    | "accountNumber"
    | "amount"
    | "fee"
    | "principal"
    | "idempotencyKey"
    | "status"
    | "createdAt"
  >;
};

export type AllowanceRequestCreateMutationVariables = Exact<{
  userID: Scalars["String"];
  amount: Scalars["Int"];
  description: Scalars["String"];
}>;

export type AllowanceRequestCreateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "AllowanceRequestCreate"
>;

export type AllowanceRequestDenyMutationVariables = Exact<{
  idempotencyKey: Scalars["String"];
}>;

export type AllowanceRequestDenyMutation = { __typename?: "Mutation" } & {
  AllowanceRequestDeny: { __typename?: "AllowanceRequest" } & Pick<
    AllowanceRequest,
    | "requestID"
    | "memberID"
    | "companyID"
    | "periodID"
    | "bankCode"
    | "accountNumber"
    | "amount"
    | "fee"
    | "principal"
    | "idempotencyKey"
    | "status"
    | "createdAt"
  >;
};

export type CompanyCreateMutationVariables = Exact<{
  inp: CompanySettingsInput;
}>;

export type CompanyCreateMutation = { __typename?: "Mutation" } & {
  CompanyCreate: { __typename?: "CompanyInfo" } & Pick<
    CompanyInfo,
    | "companyID"
    | "name"
    | "ewaFee"
    | "startPayPeriodDay"
    | "endPayPeriodDay"
    | "wageAllowanceAutoAccruingDaily"
    | "phoneAppEnabled"
    | "dashboardEnabled"
    | "wageRequestEnabled"
    | "allowanceRequestAutoDisbursing"
    | "minimumRequestAmount"
    | "wageImbursementPercentLimit"
  >;
};

export type CompanyEmployeeCreateMutationVariables = Exact<{
  inp: CompanyEmployeeInput;
}>;

export type CompanyEmployeeCreateMutation = { __typename?: "Mutation" } & {
  CompanyEmployeeCreate: { __typename?: "CompanyEmployee" } & Pick<
    CompanyEmployee,
    | "userID"
    | "memberID"
    | "companyID"
    | "username"
    | "phoneNumber"
    | "monthlyLimit"
    | "birthday"
    | "name"
    | "ktpUrl"
    | "bankCode"
    | "accountNumber"
    | "bankVerificationStatus"
    | "wageRequestAllowed"
    | "startDate"
    | "endDate"
    | "customData"
  >;
};

export type CompanyEmployeeUpdateMutationVariables = Exact<{
  companyID: Scalars["ID"];
  update: CompanyEmployeeUpdate;
}>;

export type CompanyEmployeeUpdateMutation = { __typename?: "Mutation" } & {
  CompanyEmployeeUpdate: { __typename?: "CompanyEmployee" } & Pick<
    CompanyEmployee,
    | "userID"
    | "memberID"
    | "companyID"
    | "username"
    | "phoneNumber"
    | "monthlyLimit"
    | "birthday"
    | "name"
    | "ktpUrl"
    | "bankCode"
    | "accountNumber"
    | "bankVerificationStatus"
    | "wageRequestAllowed"
    | "startDate"
    | "endDate"
    | "customData"
  >;
};

export type CompanyManagerSetMutationVariables = Exact<{
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  isManager: Scalars["Boolean"];
}>;

export type CompanyManagerSetMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "CompanyManagerSet"
>;

export type CompanyMembershipCreateMutationVariables = Exact<{
  userID: Scalars["ID"];
  companyID: Scalars["ID"];
}>;

export type CompanyMembershipCreateMutation = { __typename?: "Mutation" } & {
  CompanyMembershipCreate: { __typename?: "Membership" } & Pick<
    Membership,
    "memberID" | "userID" | "companyID" | "startDate" | "endDate" | "isManager"
  >;
};

export type CompanyUpdateMutationVariables = Exact<{
  companyID: Scalars["ID"];
  inp: CompanySettingsInput;
}>;

export type CompanyUpdateMutation = { __typename?: "Mutation" } & {
  CompanyUpdate: { __typename?: "CompanyInfo" } & Pick<
    CompanyInfo,
    | "companyID"
    | "name"
    | "ewaFee"
    | "startPayPeriodDay"
    | "endPayPeriodDay"
    | "wageAllowanceAutoAccruingDaily"
    | "phoneAppEnabled"
    | "dashboardEnabled"
    | "wageRequestEnabled"
    | "allowanceRequestAutoDisbursing"
    | "minimumRequestAmount"
    | "wageImbursementPercentLimit"
  >;
};

export type HelloWorldMMutationVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type HelloWorldMMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "HelloWorldM"
>;

export type PasswordResetMutationVariables = Exact<{
  token: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type PasswordResetMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PasswordReset"
>;

export type PasswordResetSendOtpMutationVariables = Exact<{
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
}>;

export type PasswordResetSendOtpMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PasswordResetSendOtp"
>;

export type PasswordResetVerifyOtpMutationVariables = Exact<{
  token: Scalars["String"];
  otpCode: Scalars["String"];
}>;

export type PasswordResetVerifyOtpMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PasswordResetVerifyOtp"
>;

export type PasswordUpdateMutationVariables = Exact<{
  userID: Scalars["ID"];
  newPassword: Scalars["String"];
}>;

export type PasswordUpdateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PasswordUpdate"
>;

export type PayrollAdditionCreateMutationVariables = Exact<{
  inp: AdditionInsert;
}>;

export type PayrollAdditionCreateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PayrollAdditionCreate"
>;

export type PayrollDeductionCreateMutationVariables = Exact<{
  inp: DeductionInsert;
}>;

export type PayrollDeductionCreateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PayrollDeductionCreate"
>;

export type PayrollEmployeeCreateMutationVariables = Exact<{
  inp: PayrollEmployeeCreate;
}>;

export type PayrollEmployeeCreateMutation = { __typename?: "Mutation" } & {
  PayrollEmployeeCreate: { __typename?: "PayrollEmployee" } & Pick<
    PayrollEmployee,
    | "memberID"
    | "companyID"
    | "name"
    | "taxTkRate"
    | "taxKesRate"
    | "salaryType"
    | "salary"
    | "dailyRateOverride"
    | "bankCode"
    | "accountNumber"
    | "customData"
  >;
};

export type PayrollEmployeeUpdateMutationVariables = Exact<{
  memberID: Scalars["ID"];
  inp: PayrollEmployeeCreate;
}>;

export type PayrollEmployeeUpdateMutation = { __typename?: "Mutation" } & {
  PayrollEmployeeUpdate: { __typename?: "PayrollEmployee" } & Pick<
    PayrollEmployee,
    | "memberID"
    | "companyID"
    | "name"
    | "taxTkRate"
    | "taxKesRate"
    | "salaryType"
    | "salary"
    | "dailyRateOverride"
    | "bankCode"
    | "accountNumber"
    | "customData"
  >;
};

export type PayrollPeriodMemberExecuteMutationVariables = Exact<{
  companyID: Scalars["ID"];
  memberID: Scalars["ID"];
  periodID: Scalars["ID"];
}>;

export type PayrollPeriodMemberExecuteMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "PayrollPeriodMemberExecute">;

export type PayrollTopUpAddMutationVariables = Exact<{
  companyID: Scalars["ID"];
  amount: Scalars["Int"];
}>;

export type PayrollTopUpAddMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PayrollTopUpAdd"
>;

export type PhoneInvitationCreateMutationVariables = Exact<{
  companyID: Scalars["ID"];
  insert: PhoneInvitationInsert;
}>;

export type PhoneInvitationCreateMutation = { __typename?: "Mutation" } & {
  PhoneInvitationCreate: { __typename?: "PhoneInvitation" } & Pick<
    PhoneInvitation,
    | "inviteID"
    | "memberID"
    | "companyID"
    | "name"
    | "phoneNumber"
    | "monthlyLimit"
    | "bankCode"
    | "bankNumber"
    | "accepted"
    | "customData"
  >;
};

export type PhoneInvitationDeleteMutationVariables = Exact<{
  inviteID: Scalars["ID"];
}>;

export type PhoneInvitationDeleteMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PhoneInvitationDelete"
>;

export type PhoneOtpCreateMutationVariables = Exact<{
  phoneNumber: Scalars["Int"];
  otpCode: Scalars["String"];
}>;

export type PhoneOtpCreateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PhoneOtpCreate"
>;

export type PhoneOtpDeleteMutationVariables = Exact<{
  phoneNumber: Scalars["Int"];
}>;

export type PhoneOtpDeleteMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "PhoneOtpDelete"
>;

export type RegistrationSendOtpMutationVariables = Exact<{
  token: Scalars["String"];
  countryCode: Scalars["Int"];
  phoneNumber: Scalars["Int"];
}>;

export type RegistrationSendOtpMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "RegistrationSendOTP"
>;

export type RegistrationSubmitBankMutationVariables = Exact<{
  token: Scalars["String"];
  bankCode: Scalars["BankCode"];
  accountNumber: Scalars["String"];
}>;

export type RegistrationSubmitBankMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "RegistrationSubmitBank"
>;

export type RegistrationSubmitInfoMutationVariables = Exact<{
  token: Scalars["String"];
  ktp: Scalars["Upload"];
  birthday: Scalars["Time"];
}>;

export type RegistrationSubmitInfoMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "RegistrationSubmitInfo"
>;

export type RegistrationSubmitPasswordMutationVariables = Exact<{
  token: Scalars["String"];
  password: Scalars["String"];
}>;

export type RegistrationSubmitPasswordMutation = { __typename?: "Mutation" } & {
  RegistrationSubmitPassword: { __typename?: "RegistrationInfo" } & Pick<
    RegistrationInfo,
    "name" | "username" | "token"
  >;
};

export type RegistrationVerifyOtpMutationVariables = Exact<{
  token: Scalars["String"];
  otpCode: Scalars["String"];
}>;

export type RegistrationVerifyOtpMutation = { __typename?: "Mutation" } & {
  RegistrationVerifyOTP: { __typename?: "RegistrationInfo" } & Pick<
    RegistrationInfo,
    "name" | "username" | "token"
  >;
};

export type UserCreateMutationVariables = Exact<{
  username: Scalars["String"];
  password: Scalars["String"];
}>;

export type UserCreateMutation = { __typename?: "Mutation" } & {
  UserCreate: { __typename?: "UserResponse" } & Pick<
    UserResponse,
    "userID" | "username"
  >;
};

export type UserProfileUpdateMutationVariables = Exact<{
  userID: Scalars["ID"];
  update: UserProfileUpdate;
}>;

export type UserProfileUpdateMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "UserProfileUpdate"
>;

export type UserUpdatePasswordMutationVariables = Exact<{
  userID: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type UserUpdatePasswordMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "UserUpdatePassword"
>;

export type AllowanceRequestFeeQueryVariables = Exact<{
  userID: Scalars["String"];
  amount: Scalars["Int"];
}>;

export type AllowanceRequestFeeQuery = { __typename?: "Query" } & Pick<
  Query,
  "AllowanceRequestFee"
>;

export type AllowanceRequestMinimumQueryVariables = Exact<{
  userID: Scalars["String"];
}>;

export type AllowanceRequestMinimumQuery = { __typename?: "Query" } & Pick<
  Query,
  "AllowanceRequestMinimum"
>;

export type AllowanceRequestRetrieveUnapprovedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllowanceRequestRetrieveUnapprovedQuery = {
  __typename?: "Query";
} & {
  AllowanceRequestRetrieveUnapproved: Array<
    Maybe<
      { __typename?: "AllowanceRequest" } & Pick<
        AllowanceRequest,
        | "requestID"
        | "memberID"
        | "companyID"
        | "periodID"
        | "bankCode"
        | "accountNumber"
        | "amount"
        | "fee"
        | "principal"
        | "idempotencyKey"
        | "status"
        | "createdAt"
      >
    >
  >;
};

export type AllowanceRetrieveQueryVariables = Exact<{
  userID: Scalars["String"];
}>;

export type AllowanceRetrieveQuery = { __typename?: "Query" } & Pick<
  Query,
  "AllowanceRetrieve"
>;

export type AuthRoleRetrieveQueryVariables = Exact<{ [key: string]: never }>;

export type AuthRoleRetrieveQuery = { __typename?: "Query" } & {
  AuthRoleRetrieve: { __typename?: "AuthRole" } & Pick<
    AuthRole,
    "userID" | "companyID" | "memberID" | "level"
  >;
};

export type BankAccountRetrieveDefaultQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type BankAccountRetrieveDefaultQuery = { __typename?: "Query" } & {
  BankAccountRetrieveDefault: { __typename?: "BankAccount" } & Pick<
    BankAccount,
    "bankKey" | "bankCode" | "accountNumber" | "verificationStatus"
  >;
};

export type CompanyEmployeeRetrieveQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type CompanyEmployeeRetrieveQuery = { __typename?: "Query" } & {
  CompanyEmployeeRetrieve: { __typename?: "CompanyEmployee" } & Pick<
    CompanyEmployee,
    | "userID"
    | "memberID"
    | "companyID"
    | "username"
    | "phoneNumber"
    | "monthlyLimit"
    | "birthday"
    | "name"
    | "ktpUrl"
    | "bankCode"
    | "accountNumber"
    | "bankVerificationStatus"
    | "wageRequestAllowed"
    | "startDate"
    | "endDate"
    | "customData"
  >;
};

export type CompanyEmployeesRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type CompanyEmployeesRetrieveQuery = { __typename?: "Query" } & {
  CompanyEmployeesRetrieve: Array<
    Maybe<
      { __typename?: "CompanyEmployee" } & Pick<
        CompanyEmployee,
        | "userID"
        | "memberID"
        | "companyID"
        | "username"
        | "phoneNumber"
        | "monthlyLimit"
        | "birthday"
        | "name"
        | "ktpUrl"
        | "bankCode"
        | "accountNumber"
        | "bankVerificationStatus"
        | "wageRequestAllowed"
        | "startDate"
        | "endDate"
        | "customData"
      >
    >
  >;
};

export type CompanyInfoRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type CompanyInfoRetrieveQuery = { __typename?: "Query" } & {
  CompanyInfoRetrieve: { __typename?: "CompanyInfo" } & Pick<
    CompanyInfo,
    | "companyID"
    | "name"
    | "ewaFee"
    | "startPayPeriodDay"
    | "endPayPeriodDay"
    | "wageAllowanceAutoAccruingDaily"
    | "phoneAppEnabled"
    | "dashboardEnabled"
    | "wageRequestEnabled"
    | "allowanceRequestAutoDisbursing"
    | "minimumRequestAmount"
    | "wageImbursementPercentLimit"
  >;
};

export type CompanyInfoRetrieveAllQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyInfoRetrieveAllQuery = { __typename?: "Query" } & {
  CompanyInfoRetrieveAll: Array<
    Maybe<
      { __typename?: "CompanyInfo" } & Pick<
        CompanyInfo,
        | "companyID"
        | "name"
        | "ewaFee"
        | "startPayPeriodDay"
        | "endPayPeriodDay"
        | "wageAllowanceAutoAccruingDaily"
        | "phoneAppEnabled"
        | "dashboardEnabled"
        | "wageRequestEnabled"
        | "allowanceRequestAutoDisbursing"
        | "minimumRequestAmount"
        | "wageImbursementPercentLimit"
      >
    >
  >;
};

export type CompanyMembershipRetrieveQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type CompanyMembershipRetrieveQuery = { __typename?: "Query" } & {
  CompanyMembershipRetrieve: { __typename?: "Membership" } & Pick<
    Membership,
    "memberID" | "userID" | "companyID" | "startDate" | "endDate" | "isManager"
  >;
};

export type CompanyMembershipRetrieveAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type CompanyMembershipRetrieveAllQuery = { __typename?: "Query" } & {
  CompanyMembershipRetrieveAll: Array<
    Maybe<
      { __typename?: "Membership" } & Pick<
        Membership,
        | "memberID"
        | "userID"
        | "companyID"
        | "startDate"
        | "endDate"
        | "isManager"
      >
    >
  >;
};

export type CompanyMembershipsReadAllQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyMembershipsReadAllQuery = { __typename?: "Query" } & {
  CompanyMembershipsReadAll: Array<
    Maybe<
      { __typename?: "Membership" } & Pick<
        Membership,
        | "memberID"
        | "userID"
        | "companyID"
        | "startDate"
        | "endDate"
        | "isManager"
      >
    >
  >;
};

export type DashboardsVisibleRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type DashboardsVisibleRetrieveQuery = { __typename?: "Query" } & Pick<
  Query,
  "DashboardsVisibleRetrieve"
>;

export type HelloWorldQQueryVariables = Exact<{ [key: string]: never }>;

export type HelloWorldQQuery = { __typename?: "Query" } & Pick<
  Query,
  "HelloWorldQ"
>;

export type PayrollAdditionsReadAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollAdditionsReadAllQuery = { __typename?: "Query" } & {
  PayrollAdditionsReadAll: Array<
    Maybe<
      { __typename?: "EmployeePeriodAddition" } & Pick<
        EmployeePeriodAddition,
        "memberID" | "companyID" | "periodID" | "amount" | "reason" | "date"
      >
    >
  >;
};

export type PayrollDaysWorkedReadAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollDaysWorkedReadAllQuery = { __typename?: "Query" } & {
  PayrollDaysWorkedReadAll: Array<
    Maybe<
      { __typename?: "EmployeePeriodDaysWorked" } & Pick<
        EmployeePeriodDaysWorked,
        "memberID" | "companyID" | "periodID" | "daysWorked"
      >
    >
  >;
};

export type PayrollDeductionsReadAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollDeductionsReadAllQuery = { __typename?: "Query" } & {
  PayrollDeductionsReadAll: Array<
    Maybe<
      { __typename?: "EmployeePeriodDeduction" } & Pick<
        EmployeePeriodDeduction,
        "memberID" | "companyID" | "periodID" | "amount" | "reason" | "date"
      >
    >
  >;
};

export type PayrollEmployeesReadAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollEmployeesReadAllQuery = { __typename?: "Query" } & {
  PayrollEmployeesReadAll: Array<
    Maybe<
      { __typename?: "PayrollEmployee" } & Pick<
        PayrollEmployee,
        | "memberID"
        | "companyID"
        | "name"
        | "taxTkRate"
        | "taxKesRate"
        | "salaryType"
        | "salary"
        | "dailyRateOverride"
        | "bankCode"
        | "accountNumber"
        | "customData"
      >
    >
  >;
};

export type PayrollPeriodDataReadQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollPeriodDataReadQuery = { __typename?: "Query" } & {
  PayrollPeriodDataRead: Array<
    Maybe<
      { __typename?: "PeriodData" } & Pick<
        PeriodData,
        "periodID" | "startTime" | "endTime"
      >
    >
  >;
};

export type PayrollTopUpRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PayrollTopUpRetrieveQuery = { __typename?: "Query" } & Pick<
  Query,
  "PayrollTopUpRetrieve"
>;

export type PeriodRetrieveQueryVariables = Exact<{
  periodID: Scalars["ID"];
}>;

export type PeriodRetrieveQuery = { __typename?: "Query" } & {
  PeriodRetrieve: { __typename?: "Period" } & Pick<
    Period,
    "periodID" | "companyID" | "startTime" | "endTime"
  >;
};

export type PeriodsRetrieveAllQueryVariables = Exact<{
  companyID: Scalars["ID"];
}>;

export type PeriodsRetrieveAllQuery = { __typename?: "Query" } & {
  PeriodsRetrieveAll: Array<
    Maybe<
      { __typename?: "Period" } & Pick<
        Period,
        "periodID" | "companyID" | "startTime" | "endTime"
      >
    >
  >;
};

export type PhoneInvitationsRetrieveQueryVariables = Exact<{
  companyID: Scalars["ID"];
  accepted?: Maybe<Scalars["Boolean"]>;
}>;

export type PhoneInvitationsRetrieveQuery = { __typename?: "Query" } & {
  PhoneInvitationsRetrieve: Array<
    { __typename?: "PhoneInvitation" } & Pick<
      PhoneInvitation,
      | "inviteID"
      | "memberID"
      | "companyID"
      | "name"
      | "phoneNumber"
      | "monthlyLimit"
      | "bankCode"
      | "bankNumber"
      | "accepted"
      | "customData"
    >
  >;
};

export type TransactionEventRetrieveByCompanyIdQueryVariables = Exact<{
  companyID: Scalars["String"];
}>;

export type TransactionEventRetrieveByCompanyIdQuery = {
  __typename?: "Query";
} & {
  TransactionEventRetrieveByCompanyID: Array<
    Maybe<
      { __typename?: "TransactionEvent" } & Pick<
        TransactionEvent,
        | "eventID"
        | "companyID"
        | "periodID"
        | "memberID"
        | "amount"
        | "fee"
        | "principal"
        | "description"
        | "status"
        | "idempotencyKey"
        | "typ"
        | "allocatedAt"
        | "createdAt"
      >
    >
  >;
};

export type TransactionEventRetrieveByUserIdQueryVariables = Exact<{
  userID: Scalars["String"];
}>;

export type TransactionEventRetrieveByUserIdQuery = { __typename?: "Query" } & {
  TransactionEventRetrieveByUserID: Array<
    Maybe<
      { __typename?: "TransactionEvent" } & Pick<
        TransactionEvent,
        | "eventID"
        | "companyID"
        | "periodID"
        | "memberID"
        | "amount"
        | "fee"
        | "principal"
        | "description"
        | "status"
        | "idempotencyKey"
        | "typ"
        | "allocatedAt"
        | "createdAt"
      >
    >
  >;
};

export type UserProfileRetrieveQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type UserProfileRetrieveQuery = { __typename?: "Query" } & {
  UserProfileRetrieve: { __typename?: "UserProfile" } & Pick<
    UserProfile,
    "username" | "ktpUrl" | "name"
  >;
};

export type UserRetrieveByUsernameQueryVariables = Exact<{
  username: Scalars["String"];
}>;

export type UserRetrieveByUsernameQuery = { __typename?: "Query" } & {
  UserRetrieveByUsername: { __typename?: "UserResponse" } & Pick<
    UserResponse,
    "userID" | "username"
  >;
};

export const AdminCreateDocument = gql`
  mutation AdminCreate($username: String!, $password: String!) {
    AdminCreate(username: $username, password: $password) {
      userID
      username
    }
  }
`;
export type AdminCreateMutationFn = Apollo.MutationFunction<
  AdminCreateMutation,
  AdminCreateMutationVariables
>;

/**
 * __useAdminCreateMutation__
 *
 * To run a mutation, you first call `useAdminCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateMutation, { data, loading, error }] = useAdminCreateMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateMutation,
    AdminCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateMutation, AdminCreateMutationVariables>(
    AdminCreateDocument,
    options,
  );
}
export type AdminCreateMutationHookResult = ReturnType<
  typeof useAdminCreateMutation
>;
export type AdminCreateMutationResult =
  Apollo.MutationResult<AdminCreateMutation>;
export type AdminCreateMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateMutation,
  AdminCreateMutationVariables
>;
export const AllowanceAddDocument = gql`
  mutation AllowanceAdd($userID: String!, $amount: Int!) {
    AllowanceAdd(userID: $userID, amount: $amount)
  }
`;
export type AllowanceAddMutationFn = Apollo.MutationFunction<
  AllowanceAddMutation,
  AllowanceAddMutationVariables
>;

/**
 * __useAllowanceAddMutation__
 *
 * To run a mutation, you first call `useAllowanceAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllowanceAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allowanceAddMutation, { data, loading, error }] = useAllowanceAddMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAllowanceAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AllowanceAddMutation,
    AllowanceAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AllowanceAddMutation,
    AllowanceAddMutationVariables
  >(AllowanceAddDocument, options);
}
export type AllowanceAddMutationHookResult = ReturnType<
  typeof useAllowanceAddMutation
>;
export type AllowanceAddMutationResult =
  Apollo.MutationResult<AllowanceAddMutation>;
export type AllowanceAddMutationOptions = Apollo.BaseMutationOptions<
  AllowanceAddMutation,
  AllowanceAddMutationVariables
>;
export const AllowanceRequestApproveDocument = gql`
  mutation AllowanceRequestApprove($idempotencyKey: String!) {
    AllowanceRequestApprove(idempotencyKey: $idempotencyKey) {
      requestID
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      amount
      fee
      principal
      idempotencyKey
      status
      createdAt
    }
  }
`;
export type AllowanceRequestApproveMutationFn = Apollo.MutationFunction<
  AllowanceRequestApproveMutation,
  AllowanceRequestApproveMutationVariables
>;

/**
 * __useAllowanceRequestApproveMutation__
 *
 * To run a mutation, you first call `useAllowanceRequestApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allowanceRequestApproveMutation, { data, loading, error }] = useAllowanceRequestApproveMutation({
 *   variables: {
 *      idempotencyKey: // value for 'idempotencyKey'
 *   },
 * });
 */
export function useAllowanceRequestApproveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AllowanceRequestApproveMutation,
    AllowanceRequestApproveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AllowanceRequestApproveMutation,
    AllowanceRequestApproveMutationVariables
  >(AllowanceRequestApproveDocument, options);
}
export type AllowanceRequestApproveMutationHookResult = ReturnType<
  typeof useAllowanceRequestApproveMutation
>;
export type AllowanceRequestApproveMutationResult =
  Apollo.MutationResult<AllowanceRequestApproveMutation>;
export type AllowanceRequestApproveMutationOptions = Apollo.BaseMutationOptions<
  AllowanceRequestApproveMutation,
  AllowanceRequestApproveMutationVariables
>;
export const AllowanceRequestCreateDocument = gql`
  mutation AllowanceRequestCreate(
    $userID: String!
    $amount: Int!
    $description: String!
  ) {
    AllowanceRequestCreate(
      userID: $userID
      amount: $amount
      description: $description
    )
  }
`;
export type AllowanceRequestCreateMutationFn = Apollo.MutationFunction<
  AllowanceRequestCreateMutation,
  AllowanceRequestCreateMutationVariables
>;

/**
 * __useAllowanceRequestCreateMutation__
 *
 * To run a mutation, you first call `useAllowanceRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allowanceRequestCreateMutation, { data, loading, error }] = useAllowanceRequestCreateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      amount: // value for 'amount'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAllowanceRequestCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AllowanceRequestCreateMutation,
    AllowanceRequestCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AllowanceRequestCreateMutation,
    AllowanceRequestCreateMutationVariables
  >(AllowanceRequestCreateDocument, options);
}
export type AllowanceRequestCreateMutationHookResult = ReturnType<
  typeof useAllowanceRequestCreateMutation
>;
export type AllowanceRequestCreateMutationResult =
  Apollo.MutationResult<AllowanceRequestCreateMutation>;
export type AllowanceRequestCreateMutationOptions = Apollo.BaseMutationOptions<
  AllowanceRequestCreateMutation,
  AllowanceRequestCreateMutationVariables
>;
export const AllowanceRequestDenyDocument = gql`
  mutation AllowanceRequestDeny($idempotencyKey: String!) {
    AllowanceRequestDeny(idempotencyKey: $idempotencyKey) {
      requestID
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      amount
      fee
      principal
      idempotencyKey
      status
      createdAt
    }
  }
`;
export type AllowanceRequestDenyMutationFn = Apollo.MutationFunction<
  AllowanceRequestDenyMutation,
  AllowanceRequestDenyMutationVariables
>;

/**
 * __useAllowanceRequestDenyMutation__
 *
 * To run a mutation, you first call `useAllowanceRequestDenyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestDenyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allowanceRequestDenyMutation, { data, loading, error }] = useAllowanceRequestDenyMutation({
 *   variables: {
 *      idempotencyKey: // value for 'idempotencyKey'
 *   },
 * });
 */
export function useAllowanceRequestDenyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AllowanceRequestDenyMutation,
    AllowanceRequestDenyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AllowanceRequestDenyMutation,
    AllowanceRequestDenyMutationVariables
  >(AllowanceRequestDenyDocument, options);
}
export type AllowanceRequestDenyMutationHookResult = ReturnType<
  typeof useAllowanceRequestDenyMutation
>;
export type AllowanceRequestDenyMutationResult =
  Apollo.MutationResult<AllowanceRequestDenyMutation>;
export type AllowanceRequestDenyMutationOptions = Apollo.BaseMutationOptions<
  AllowanceRequestDenyMutation,
  AllowanceRequestDenyMutationVariables
>;
export const CompanyCreateDocument = gql`
  mutation CompanyCreate($inp: CompanySettingsInput!) {
    CompanyCreate(inp: $inp) {
      companyID
      name
      ewaFee
      startPayPeriodDay
      endPayPeriodDay
      wageAllowanceAutoAccruingDaily
      phoneAppEnabled
      dashboardEnabled
      wageRequestEnabled
      allowanceRequestAutoDisbursing
      minimumRequestAmount
      wageImbursementPercentLimit
    }
  }
`;
export type CompanyCreateMutationFn = Apollo.MutationFunction<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>;

/**
 * __useCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateMutation, { data, loading, error }] = useCompanyCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useCompanyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >(CompanyCreateDocument, options);
}
export type CompanyCreateMutationHookResult = ReturnType<
  typeof useCompanyCreateMutation
>;
export type CompanyCreateMutationResult =
  Apollo.MutationResult<CompanyCreateMutation>;
export type CompanyCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>;
export const CompanyEmployeeCreateDocument = gql`
  mutation CompanyEmployeeCreate($inp: CompanyEmployeeInput!) {
    CompanyEmployeeCreate(inp: $inp) {
      userID
      memberID
      companyID
      username
      phoneNumber
      monthlyLimit
      birthday
      name
      ktpUrl
      bankCode
      accountNumber
      bankVerificationStatus
      wageRequestAllowed
      startDate
      endDate
      customData
    }
  }
`;
export type CompanyEmployeeCreateMutationFn = Apollo.MutationFunction<
  CompanyEmployeeCreateMutation,
  CompanyEmployeeCreateMutationVariables
>;

/**
 * __useCompanyEmployeeCreateMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeCreateMutation, { data, loading, error }] = useCompanyEmployeeCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useCompanyEmployeeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyEmployeeCreateMutation,
    CompanyEmployeeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyEmployeeCreateMutation,
    CompanyEmployeeCreateMutationVariables
  >(CompanyEmployeeCreateDocument, options);
}
export type CompanyEmployeeCreateMutationHookResult = ReturnType<
  typeof useCompanyEmployeeCreateMutation
>;
export type CompanyEmployeeCreateMutationResult =
  Apollo.MutationResult<CompanyEmployeeCreateMutation>;
export type CompanyEmployeeCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyEmployeeCreateMutation,
  CompanyEmployeeCreateMutationVariables
>;
export const CompanyEmployeeUpdateDocument = gql`
  mutation CompanyEmployeeUpdate(
    $companyID: ID!
    $update: CompanyEmployeeUpdate!
  ) {
    CompanyEmployeeUpdate(companyID: $companyID, update: $update) {
      userID
      memberID
      companyID
      username
      phoneNumber
      monthlyLimit
      birthday
      name
      ktpUrl
      bankCode
      accountNumber
      bankVerificationStatus
      wageRequestAllowed
      startDate
      endDate
      customData
    }
  }
`;
export type CompanyEmployeeUpdateMutationFn = Apollo.MutationFunction<
  CompanyEmployeeUpdateMutation,
  CompanyEmployeeUpdateMutationVariables
>;

/**
 * __useCompanyEmployeeUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeUpdateMutation, { data, loading, error }] = useCompanyEmployeeUpdateMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useCompanyEmployeeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyEmployeeUpdateMutation,
    CompanyEmployeeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyEmployeeUpdateMutation,
    CompanyEmployeeUpdateMutationVariables
  >(CompanyEmployeeUpdateDocument, options);
}
export type CompanyEmployeeUpdateMutationHookResult = ReturnType<
  typeof useCompanyEmployeeUpdateMutation
>;
export type CompanyEmployeeUpdateMutationResult =
  Apollo.MutationResult<CompanyEmployeeUpdateMutation>;
export type CompanyEmployeeUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyEmployeeUpdateMutation,
  CompanyEmployeeUpdateMutationVariables
>;
export const CompanyManagerSetDocument = gql`
  mutation CompanyManagerSet(
    $companyID: ID!
    $memberID: ID!
    $isManager: Boolean!
  ) {
    CompanyManagerSet(
      companyID: $companyID
      memberID: $memberID
      isManager: $isManager
    )
  }
`;
export type CompanyManagerSetMutationFn = Apollo.MutationFunction<
  CompanyManagerSetMutation,
  CompanyManagerSetMutationVariables
>;

/**
 * __useCompanyManagerSetMutation__
 *
 * To run a mutation, you first call `useCompanyManagerSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyManagerSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyManagerSetMutation, { data, loading, error }] = useCompanyManagerSetMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      memberID: // value for 'memberID'
 *      isManager: // value for 'isManager'
 *   },
 * });
 */
export function useCompanyManagerSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyManagerSetMutation,
    CompanyManagerSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyManagerSetMutation,
    CompanyManagerSetMutationVariables
  >(CompanyManagerSetDocument, options);
}
export type CompanyManagerSetMutationHookResult = ReturnType<
  typeof useCompanyManagerSetMutation
>;
export type CompanyManagerSetMutationResult =
  Apollo.MutationResult<CompanyManagerSetMutation>;
export type CompanyManagerSetMutationOptions = Apollo.BaseMutationOptions<
  CompanyManagerSetMutation,
  CompanyManagerSetMutationVariables
>;
export const CompanyMembershipCreateDocument = gql`
  mutation CompanyMembershipCreate($userID: ID!, $companyID: ID!) {
    CompanyMembershipCreate(userID: $userID, companyID: $companyID) {
      memberID
      userID
      companyID
      startDate
      endDate
      isManager
    }
  }
`;
export type CompanyMembershipCreateMutationFn = Apollo.MutationFunction<
  CompanyMembershipCreateMutation,
  CompanyMembershipCreateMutationVariables
>;

/**
 * __useCompanyMembershipCreateMutation__
 *
 * To run a mutation, you first call `useCompanyMembershipCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembershipCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyMembershipCreateMutation, { data, loading, error }] = useCompanyMembershipCreateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyMembershipCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyMembershipCreateMutation,
    CompanyMembershipCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyMembershipCreateMutation,
    CompanyMembershipCreateMutationVariables
  >(CompanyMembershipCreateDocument, options);
}
export type CompanyMembershipCreateMutationHookResult = ReturnType<
  typeof useCompanyMembershipCreateMutation
>;
export type CompanyMembershipCreateMutationResult =
  Apollo.MutationResult<CompanyMembershipCreateMutation>;
export type CompanyMembershipCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyMembershipCreateMutation,
  CompanyMembershipCreateMutationVariables
>;
export const CompanyUpdateDocument = gql`
  mutation CompanyUpdate($companyID: ID!, $inp: CompanySettingsInput!) {
    CompanyUpdate(companyID: $companyID, inp: $inp) {
      companyID
      name
      ewaFee
      startPayPeriodDay
      endPayPeriodDay
      wageAllowanceAutoAccruingDaily
      phoneAppEnabled
      dashboardEnabled
      wageRequestEnabled
      allowanceRequestAutoDisbursing
      minimumRequestAmount
      wageImbursementPercentLimit
    }
  }
`;
export type CompanyUpdateMutationFn = Apollo.MutationFunction<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function useCompanyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >(CompanyUpdateDocument, options);
}
export type CompanyUpdateMutationHookResult = ReturnType<
  typeof useCompanyUpdateMutation
>;
export type CompanyUpdateMutationResult =
  Apollo.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;
export const HelloWorldMDocument = gql`
  mutation HelloWorldM($companyID: ID!) {
    HelloWorldM(companyID: $companyID)
  }
`;
export type HelloWorldMMutationFn = Apollo.MutationFunction<
  HelloWorldMMutation,
  HelloWorldMMutationVariables
>;

/**
 * __useHelloWorldMMutation__
 *
 * To run a mutation, you first call `useHelloWorldMMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHelloWorldMMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [helloWorldMMutation, { data, loading, error }] = useHelloWorldMMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useHelloWorldMMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HelloWorldMMutation,
    HelloWorldMMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HelloWorldMMutation, HelloWorldMMutationVariables>(
    HelloWorldMDocument,
    options,
  );
}
export type HelloWorldMMutationHookResult = ReturnType<
  typeof useHelloWorldMMutation
>;
export type HelloWorldMMutationResult =
  Apollo.MutationResult<HelloWorldMMutation>;
export type HelloWorldMMutationOptions = Apollo.BaseMutationOptions<
  HelloWorldMMutation,
  HelloWorldMMutationVariables
>;
export const PasswordResetDocument = gql`
  mutation PasswordReset($token: String!, $newPassword: String!) {
    PasswordReset(token: $token, newPassword: $newPassword)
  }
`;
export type PasswordResetMutationFn = Apollo.MutationFunction<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function usePasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >(PasswordResetDocument, options);
}
export type PasswordResetMutationHookResult = ReturnType<
  typeof usePasswordResetMutation
>;
export type PasswordResetMutationResult =
  Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;
export const PasswordResetSendOtpDocument = gql`
  mutation PasswordResetSendOtp($countryCode: Int!, $phoneNumber: Int!) {
    PasswordResetSendOtp(countryCode: $countryCode, phoneNumber: $phoneNumber)
  }
`;
export type PasswordResetSendOtpMutationFn = Apollo.MutationFunction<
  PasswordResetSendOtpMutation,
  PasswordResetSendOtpMutationVariables
>;

/**
 * __usePasswordResetSendOtpMutation__
 *
 * To run a mutation, you first call `usePasswordResetSendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetSendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetSendOtpMutation, { data, loading, error }] = usePasswordResetSendOtpMutation({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function usePasswordResetSendOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetSendOtpMutation,
    PasswordResetSendOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordResetSendOtpMutation,
    PasswordResetSendOtpMutationVariables
  >(PasswordResetSendOtpDocument, options);
}
export type PasswordResetSendOtpMutationHookResult = ReturnType<
  typeof usePasswordResetSendOtpMutation
>;
export type PasswordResetSendOtpMutationResult =
  Apollo.MutationResult<PasswordResetSendOtpMutation>;
export type PasswordResetSendOtpMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetSendOtpMutation,
  PasswordResetSendOtpMutationVariables
>;
export const PasswordResetVerifyOtpDocument = gql`
  mutation PasswordResetVerifyOtp($token: String!, $otpCode: String!) {
    PasswordResetVerifyOtp(token: $token, otpCode: $otpCode)
  }
`;
export type PasswordResetVerifyOtpMutationFn = Apollo.MutationFunction<
  PasswordResetVerifyOtpMutation,
  PasswordResetVerifyOtpMutationVariables
>;

/**
 * __usePasswordResetVerifyOtpMutation__
 *
 * To run a mutation, you first call `usePasswordResetVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetVerifyOtpMutation, { data, loading, error }] = usePasswordResetVerifyOtpMutation({
 *   variables: {
 *      token: // value for 'token'
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function usePasswordResetVerifyOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetVerifyOtpMutation,
    PasswordResetVerifyOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordResetVerifyOtpMutation,
    PasswordResetVerifyOtpMutationVariables
  >(PasswordResetVerifyOtpDocument, options);
}
export type PasswordResetVerifyOtpMutationHookResult = ReturnType<
  typeof usePasswordResetVerifyOtpMutation
>;
export type PasswordResetVerifyOtpMutationResult =
  Apollo.MutationResult<PasswordResetVerifyOtpMutation>;
export type PasswordResetVerifyOtpMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetVerifyOtpMutation,
  PasswordResetVerifyOtpMutationVariables
>;
export const PasswordUpdateDocument = gql`
  mutation PasswordUpdate($userID: ID!, $newPassword: String!) {
    PasswordUpdate(userID: $userID, newPassword: $newPassword)
  }
`;
export type PasswordUpdateMutationFn = Apollo.MutationFunction<
  PasswordUpdateMutation,
  PasswordUpdateMutationVariables
>;

/**
 * __usePasswordUpdateMutation__
 *
 * To run a mutation, you first call `usePasswordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordUpdateMutation, { data, loading, error }] = usePasswordUpdateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function usePasswordUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordUpdateMutation,
    PasswordUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordUpdateMutation,
    PasswordUpdateMutationVariables
  >(PasswordUpdateDocument, options);
}
export type PasswordUpdateMutationHookResult = ReturnType<
  typeof usePasswordUpdateMutation
>;
export type PasswordUpdateMutationResult =
  Apollo.MutationResult<PasswordUpdateMutation>;
export type PasswordUpdateMutationOptions = Apollo.BaseMutationOptions<
  PasswordUpdateMutation,
  PasswordUpdateMutationVariables
>;
export const PayrollAdditionCreateDocument = gql`
  mutation PayrollAdditionCreate($inp: AdditionInsert!) {
    PayrollAdditionCreate(inp: $inp)
  }
`;
export type PayrollAdditionCreateMutationFn = Apollo.MutationFunction<
  PayrollAdditionCreateMutation,
  PayrollAdditionCreateMutationVariables
>;

/**
 * __usePayrollAdditionCreateMutation__
 *
 * To run a mutation, you first call `usePayrollAdditionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollAdditionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollAdditionCreateMutation, { data, loading, error }] = usePayrollAdditionCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollAdditionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollAdditionCreateMutation,
    PayrollAdditionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollAdditionCreateMutation,
    PayrollAdditionCreateMutationVariables
  >(PayrollAdditionCreateDocument, options);
}
export type PayrollAdditionCreateMutationHookResult = ReturnType<
  typeof usePayrollAdditionCreateMutation
>;
export type PayrollAdditionCreateMutationResult =
  Apollo.MutationResult<PayrollAdditionCreateMutation>;
export type PayrollAdditionCreateMutationOptions = Apollo.BaseMutationOptions<
  PayrollAdditionCreateMutation,
  PayrollAdditionCreateMutationVariables
>;
export const PayrollDeductionCreateDocument = gql`
  mutation PayrollDeductionCreate($inp: DeductionInsert!) {
    PayrollDeductionCreate(inp: $inp)
  }
`;
export type PayrollDeductionCreateMutationFn = Apollo.MutationFunction<
  PayrollDeductionCreateMutation,
  PayrollDeductionCreateMutationVariables
>;

/**
 * __usePayrollDeductionCreateMutation__
 *
 * To run a mutation, you first call `usePayrollDeductionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollDeductionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollDeductionCreateMutation, { data, loading, error }] = usePayrollDeductionCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollDeductionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollDeductionCreateMutation,
    PayrollDeductionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollDeductionCreateMutation,
    PayrollDeductionCreateMutationVariables
  >(PayrollDeductionCreateDocument, options);
}
export type PayrollDeductionCreateMutationHookResult = ReturnType<
  typeof usePayrollDeductionCreateMutation
>;
export type PayrollDeductionCreateMutationResult =
  Apollo.MutationResult<PayrollDeductionCreateMutation>;
export type PayrollDeductionCreateMutationOptions = Apollo.BaseMutationOptions<
  PayrollDeductionCreateMutation,
  PayrollDeductionCreateMutationVariables
>;
export const PayrollEmployeeCreateDocument = gql`
  mutation PayrollEmployeeCreate($inp: PayrollEmployeeCreate!) {
    PayrollEmployeeCreate(inp: $inp) {
      memberID
      companyID
      name
      taxTkRate
      taxKesRate
      salaryType
      salary
      dailyRateOverride
      bankCode
      accountNumber
      customData
    }
  }
`;
export type PayrollEmployeeCreateMutationFn = Apollo.MutationFunction<
  PayrollEmployeeCreateMutation,
  PayrollEmployeeCreateMutationVariables
>;

/**
 * __usePayrollEmployeeCreateMutation__
 *
 * To run a mutation, you first call `usePayrollEmployeeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollEmployeeCreateMutation, { data, loading, error }] = usePayrollEmployeeCreateMutation({
 *   variables: {
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollEmployeeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollEmployeeCreateMutation,
    PayrollEmployeeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollEmployeeCreateMutation,
    PayrollEmployeeCreateMutationVariables
  >(PayrollEmployeeCreateDocument, options);
}
export type PayrollEmployeeCreateMutationHookResult = ReturnType<
  typeof usePayrollEmployeeCreateMutation
>;
export type PayrollEmployeeCreateMutationResult =
  Apollo.MutationResult<PayrollEmployeeCreateMutation>;
export type PayrollEmployeeCreateMutationOptions = Apollo.BaseMutationOptions<
  PayrollEmployeeCreateMutation,
  PayrollEmployeeCreateMutationVariables
>;
export const PayrollEmployeeUpdateDocument = gql`
  mutation PayrollEmployeeUpdate($memberID: ID!, $inp: PayrollEmployeeCreate!) {
    PayrollEmployeeUpdate(memberID: $memberID, inp: $inp) {
      memberID
      companyID
      name
      taxTkRate
      taxKesRate
      salaryType
      salary
      dailyRateOverride
      bankCode
      accountNumber
      customData
    }
  }
`;
export type PayrollEmployeeUpdateMutationFn = Apollo.MutationFunction<
  PayrollEmployeeUpdateMutation,
  PayrollEmployeeUpdateMutationVariables
>;

/**
 * __usePayrollEmployeeUpdateMutation__
 *
 * To run a mutation, you first call `usePayrollEmployeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollEmployeeUpdateMutation, { data, loading, error }] = usePayrollEmployeeUpdateMutation({
 *   variables: {
 *      memberID: // value for 'memberID'
 *      inp: // value for 'inp'
 *   },
 * });
 */
export function usePayrollEmployeeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollEmployeeUpdateMutation,
    PayrollEmployeeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollEmployeeUpdateMutation,
    PayrollEmployeeUpdateMutationVariables
  >(PayrollEmployeeUpdateDocument, options);
}
export type PayrollEmployeeUpdateMutationHookResult = ReturnType<
  typeof usePayrollEmployeeUpdateMutation
>;
export type PayrollEmployeeUpdateMutationResult =
  Apollo.MutationResult<PayrollEmployeeUpdateMutation>;
export type PayrollEmployeeUpdateMutationOptions = Apollo.BaseMutationOptions<
  PayrollEmployeeUpdateMutation,
  PayrollEmployeeUpdateMutationVariables
>;
export const PayrollPeriodMemberExecuteDocument = gql`
  mutation PayrollPeriodMemberExecute(
    $companyID: ID!
    $memberID: ID!
    $periodID: ID!
  ) {
    PayrollPeriodMemberExecute(
      companyID: $companyID
      memberID: $memberID
      periodID: $periodID
    )
  }
`;
export type PayrollPeriodMemberExecuteMutationFn = Apollo.MutationFunction<
  PayrollPeriodMemberExecuteMutation,
  PayrollPeriodMemberExecuteMutationVariables
>;

/**
 * __usePayrollPeriodMemberExecuteMutation__
 *
 * To run a mutation, you first call `usePayrollPeriodMemberExecuteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollPeriodMemberExecuteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollPeriodMemberExecuteMutation, { data, loading, error }] = usePayrollPeriodMemberExecuteMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      memberID: // value for 'memberID'
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePayrollPeriodMemberExecuteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollPeriodMemberExecuteMutation,
    PayrollPeriodMemberExecuteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollPeriodMemberExecuteMutation,
    PayrollPeriodMemberExecuteMutationVariables
  >(PayrollPeriodMemberExecuteDocument, options);
}
export type PayrollPeriodMemberExecuteMutationHookResult = ReturnType<
  typeof usePayrollPeriodMemberExecuteMutation
>;
export type PayrollPeriodMemberExecuteMutationResult =
  Apollo.MutationResult<PayrollPeriodMemberExecuteMutation>;
export type PayrollPeriodMemberExecuteMutationOptions =
  Apollo.BaseMutationOptions<
    PayrollPeriodMemberExecuteMutation,
    PayrollPeriodMemberExecuteMutationVariables
  >;
export const PayrollTopUpAddDocument = gql`
  mutation PayrollTopUpAdd($companyID: ID!, $amount: Int!) {
    PayrollTopUpAdd(companyID: $companyID, amount: $amount)
  }
`;
export type PayrollTopUpAddMutationFn = Apollo.MutationFunction<
  PayrollTopUpAddMutation,
  PayrollTopUpAddMutationVariables
>;

/**
 * __usePayrollTopUpAddMutation__
 *
 * To run a mutation, you first call `usePayrollTopUpAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollTopUpAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollTopUpAddMutation, { data, loading, error }] = usePayrollTopUpAddMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function usePayrollTopUpAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayrollTopUpAddMutation,
    PayrollTopUpAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayrollTopUpAddMutation,
    PayrollTopUpAddMutationVariables
  >(PayrollTopUpAddDocument, options);
}
export type PayrollTopUpAddMutationHookResult = ReturnType<
  typeof usePayrollTopUpAddMutation
>;
export type PayrollTopUpAddMutationResult =
  Apollo.MutationResult<PayrollTopUpAddMutation>;
export type PayrollTopUpAddMutationOptions = Apollo.BaseMutationOptions<
  PayrollTopUpAddMutation,
  PayrollTopUpAddMutationVariables
>;
export const PhoneInvitationCreateDocument = gql`
  mutation PhoneInvitationCreate(
    $companyID: ID!
    $insert: PhoneInvitationInsert!
  ) {
    PhoneInvitationCreate(companyID: $companyID, insert: $insert) {
      inviteID
      memberID
      companyID
      name
      phoneNumber
      monthlyLimit
      bankCode
      bankNumber
      accepted
      customData
    }
  }
`;
export type PhoneInvitationCreateMutationFn = Apollo.MutationFunction<
  PhoneInvitationCreateMutation,
  PhoneInvitationCreateMutationVariables
>;

/**
 * __usePhoneInvitationCreateMutation__
 *
 * To run a mutation, you first call `usePhoneInvitationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneInvitationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneInvitationCreateMutation, { data, loading, error }] = usePhoneInvitationCreateMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      insert: // value for 'insert'
 *   },
 * });
 */
export function usePhoneInvitationCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneInvitationCreateMutation,
    PhoneInvitationCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneInvitationCreateMutation,
    PhoneInvitationCreateMutationVariables
  >(PhoneInvitationCreateDocument, options);
}
export type PhoneInvitationCreateMutationHookResult = ReturnType<
  typeof usePhoneInvitationCreateMutation
>;
export type PhoneInvitationCreateMutationResult =
  Apollo.MutationResult<PhoneInvitationCreateMutation>;
export type PhoneInvitationCreateMutationOptions = Apollo.BaseMutationOptions<
  PhoneInvitationCreateMutation,
  PhoneInvitationCreateMutationVariables
>;
export const PhoneInvitationDeleteDocument = gql`
  mutation PhoneInvitationDelete($inviteID: ID!) {
    PhoneInvitationDelete(inviteID: $inviteID)
  }
`;
export type PhoneInvitationDeleteMutationFn = Apollo.MutationFunction<
  PhoneInvitationDeleteMutation,
  PhoneInvitationDeleteMutationVariables
>;

/**
 * __usePhoneInvitationDeleteMutation__
 *
 * To run a mutation, you first call `usePhoneInvitationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneInvitationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneInvitationDeleteMutation, { data, loading, error }] = usePhoneInvitationDeleteMutation({
 *   variables: {
 *      inviteID: // value for 'inviteID'
 *   },
 * });
 */
export function usePhoneInvitationDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneInvitationDeleteMutation,
    PhoneInvitationDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneInvitationDeleteMutation,
    PhoneInvitationDeleteMutationVariables
  >(PhoneInvitationDeleteDocument, options);
}
export type PhoneInvitationDeleteMutationHookResult = ReturnType<
  typeof usePhoneInvitationDeleteMutation
>;
export type PhoneInvitationDeleteMutationResult =
  Apollo.MutationResult<PhoneInvitationDeleteMutation>;
export type PhoneInvitationDeleteMutationOptions = Apollo.BaseMutationOptions<
  PhoneInvitationDeleteMutation,
  PhoneInvitationDeleteMutationVariables
>;
export const PhoneOtpCreateDocument = gql`
  mutation PhoneOtpCreate($phoneNumber: Int!, $otpCode: String!) {
    PhoneOtpCreate(phoneNumber: $phoneNumber, otpCode: $otpCode)
  }
`;
export type PhoneOtpCreateMutationFn = Apollo.MutationFunction<
  PhoneOtpCreateMutation,
  PhoneOtpCreateMutationVariables
>;

/**
 * __usePhoneOtpCreateMutation__
 *
 * To run a mutation, you first call `usePhoneOtpCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneOtpCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneOtpCreateMutation, { data, loading, error }] = usePhoneOtpCreateMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function usePhoneOtpCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneOtpCreateMutation,
    PhoneOtpCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneOtpCreateMutation,
    PhoneOtpCreateMutationVariables
  >(PhoneOtpCreateDocument, options);
}
export type PhoneOtpCreateMutationHookResult = ReturnType<
  typeof usePhoneOtpCreateMutation
>;
export type PhoneOtpCreateMutationResult =
  Apollo.MutationResult<PhoneOtpCreateMutation>;
export type PhoneOtpCreateMutationOptions = Apollo.BaseMutationOptions<
  PhoneOtpCreateMutation,
  PhoneOtpCreateMutationVariables
>;
export const PhoneOtpDeleteDocument = gql`
  mutation PhoneOtpDelete($phoneNumber: Int!) {
    PhoneOtpDelete(phoneNumber: $phoneNumber)
  }
`;
export type PhoneOtpDeleteMutationFn = Apollo.MutationFunction<
  PhoneOtpDeleteMutation,
  PhoneOtpDeleteMutationVariables
>;

/**
 * __usePhoneOtpDeleteMutation__
 *
 * To run a mutation, you first call `usePhoneOtpDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneOtpDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneOtpDeleteMutation, { data, loading, error }] = usePhoneOtpDeleteMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function usePhoneOtpDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneOtpDeleteMutation,
    PhoneOtpDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneOtpDeleteMutation,
    PhoneOtpDeleteMutationVariables
  >(PhoneOtpDeleteDocument, options);
}
export type PhoneOtpDeleteMutationHookResult = ReturnType<
  typeof usePhoneOtpDeleteMutation
>;
export type PhoneOtpDeleteMutationResult =
  Apollo.MutationResult<PhoneOtpDeleteMutation>;
export type PhoneOtpDeleteMutationOptions = Apollo.BaseMutationOptions<
  PhoneOtpDeleteMutation,
  PhoneOtpDeleteMutationVariables
>;
export const RegistrationSendOtpDocument = gql`
  mutation RegistrationSendOTP(
    $token: String!
    $countryCode: Int!
    $phoneNumber: Int!
  ) {
    RegistrationSendOTP(
      token: $token
      countryCode: $countryCode
      phoneNumber: $phoneNumber
    )
  }
`;
export type RegistrationSendOtpMutationFn = Apollo.MutationFunction<
  RegistrationSendOtpMutation,
  RegistrationSendOtpMutationVariables
>;

/**
 * __useRegistrationSendOtpMutation__
 *
 * To run a mutation, you first call `useRegistrationSendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationSendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationSendOtpMutation, { data, loading, error }] = useRegistrationSendOtpMutation({
 *   variables: {
 *      token: // value for 'token'
 *      countryCode: // value for 'countryCode'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useRegistrationSendOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationSendOtpMutation,
    RegistrationSendOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationSendOtpMutation,
    RegistrationSendOtpMutationVariables
  >(RegistrationSendOtpDocument, options);
}
export type RegistrationSendOtpMutationHookResult = ReturnType<
  typeof useRegistrationSendOtpMutation
>;
export type RegistrationSendOtpMutationResult =
  Apollo.MutationResult<RegistrationSendOtpMutation>;
export type RegistrationSendOtpMutationOptions = Apollo.BaseMutationOptions<
  RegistrationSendOtpMutation,
  RegistrationSendOtpMutationVariables
>;
export const RegistrationSubmitBankDocument = gql`
  mutation RegistrationSubmitBank(
    $token: String!
    $bankCode: BankCode!
    $accountNumber: String!
  ) {
    RegistrationSubmitBank(
      token: $token
      bankCode: $bankCode
      accountNumber: $accountNumber
    )
  }
`;
export type RegistrationSubmitBankMutationFn = Apollo.MutationFunction<
  RegistrationSubmitBankMutation,
  RegistrationSubmitBankMutationVariables
>;

/**
 * __useRegistrationSubmitBankMutation__
 *
 * To run a mutation, you first call `useRegistrationSubmitBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationSubmitBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationSubmitBankMutation, { data, loading, error }] = useRegistrationSubmitBankMutation({
 *   variables: {
 *      token: // value for 'token'
 *      bankCode: // value for 'bankCode'
 *      accountNumber: // value for 'accountNumber'
 *   },
 * });
 */
export function useRegistrationSubmitBankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationSubmitBankMutation,
    RegistrationSubmitBankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationSubmitBankMutation,
    RegistrationSubmitBankMutationVariables
  >(RegistrationSubmitBankDocument, options);
}
export type RegistrationSubmitBankMutationHookResult = ReturnType<
  typeof useRegistrationSubmitBankMutation
>;
export type RegistrationSubmitBankMutationResult =
  Apollo.MutationResult<RegistrationSubmitBankMutation>;
export type RegistrationSubmitBankMutationOptions = Apollo.BaseMutationOptions<
  RegistrationSubmitBankMutation,
  RegistrationSubmitBankMutationVariables
>;
export const RegistrationSubmitInfoDocument = gql`
  mutation RegistrationSubmitInfo(
    $token: String!
    $ktp: Upload!
    $birthday: Time!
  ) {
    RegistrationSubmitInfo(token: $token, ktp: $ktp, birthday: $birthday)
  }
`;
export type RegistrationSubmitInfoMutationFn = Apollo.MutationFunction<
  RegistrationSubmitInfoMutation,
  RegistrationSubmitInfoMutationVariables
>;

/**
 * __useRegistrationSubmitInfoMutation__
 *
 * To run a mutation, you first call `useRegistrationSubmitInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationSubmitInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationSubmitInfoMutation, { data, loading, error }] = useRegistrationSubmitInfoMutation({
 *   variables: {
 *      token: // value for 'token'
 *      ktp: // value for 'ktp'
 *      birthday: // value for 'birthday'
 *   },
 * });
 */
export function useRegistrationSubmitInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationSubmitInfoMutation,
    RegistrationSubmitInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationSubmitInfoMutation,
    RegistrationSubmitInfoMutationVariables
  >(RegistrationSubmitInfoDocument, options);
}
export type RegistrationSubmitInfoMutationHookResult = ReturnType<
  typeof useRegistrationSubmitInfoMutation
>;
export type RegistrationSubmitInfoMutationResult =
  Apollo.MutationResult<RegistrationSubmitInfoMutation>;
export type RegistrationSubmitInfoMutationOptions = Apollo.BaseMutationOptions<
  RegistrationSubmitInfoMutation,
  RegistrationSubmitInfoMutationVariables
>;
export const RegistrationSubmitPasswordDocument = gql`
  mutation RegistrationSubmitPassword($token: String!, $password: String!) {
    RegistrationSubmitPassword(token: $token, password: $password) {
      name
      username
      token
    }
  }
`;
export type RegistrationSubmitPasswordMutationFn = Apollo.MutationFunction<
  RegistrationSubmitPasswordMutation,
  RegistrationSubmitPasswordMutationVariables
>;

/**
 * __useRegistrationSubmitPasswordMutation__
 *
 * To run a mutation, you first call `useRegistrationSubmitPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationSubmitPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationSubmitPasswordMutation, { data, loading, error }] = useRegistrationSubmitPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegistrationSubmitPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationSubmitPasswordMutation,
    RegistrationSubmitPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationSubmitPasswordMutation,
    RegistrationSubmitPasswordMutationVariables
  >(RegistrationSubmitPasswordDocument, options);
}
export type RegistrationSubmitPasswordMutationHookResult = ReturnType<
  typeof useRegistrationSubmitPasswordMutation
>;
export type RegistrationSubmitPasswordMutationResult =
  Apollo.MutationResult<RegistrationSubmitPasswordMutation>;
export type RegistrationSubmitPasswordMutationOptions =
  Apollo.BaseMutationOptions<
    RegistrationSubmitPasswordMutation,
    RegistrationSubmitPasswordMutationVariables
  >;
export const RegistrationVerifyOtpDocument = gql`
  mutation RegistrationVerifyOTP($token: String!, $otpCode: String!) {
    RegistrationVerifyOTP(token: $token, otpCode: $otpCode) {
      name
      username
      token
    }
  }
`;
export type RegistrationVerifyOtpMutationFn = Apollo.MutationFunction<
  RegistrationVerifyOtpMutation,
  RegistrationVerifyOtpMutationVariables
>;

/**
 * __useRegistrationVerifyOtpMutation__
 *
 * To run a mutation, you first call `useRegistrationVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationVerifyOtpMutation, { data, loading, error }] = useRegistrationVerifyOtpMutation({
 *   variables: {
 *      token: // value for 'token'
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function useRegistrationVerifyOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationVerifyOtpMutation,
    RegistrationVerifyOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationVerifyOtpMutation,
    RegistrationVerifyOtpMutationVariables
  >(RegistrationVerifyOtpDocument, options);
}
export type RegistrationVerifyOtpMutationHookResult = ReturnType<
  typeof useRegistrationVerifyOtpMutation
>;
export type RegistrationVerifyOtpMutationResult =
  Apollo.MutationResult<RegistrationVerifyOtpMutation>;
export type RegistrationVerifyOtpMutationOptions = Apollo.BaseMutationOptions<
  RegistrationVerifyOtpMutation,
  RegistrationVerifyOtpMutationVariables
>;
export const UserCreateDocument = gql`
  mutation UserCreate($username: String!, $password: String!) {
    UserCreate(username: $username, password: $password) {
      userID
      username
    }
  }
`;
export type UserCreateMutationFn = Apollo.MutationFunction<
  UserCreateMutation,
  UserCreateMutationVariables
>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCreateMutation,
    UserCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(
    UserCreateDocument,
    options,
  );
}
export type UserCreateMutationHookResult = ReturnType<
  typeof useUserCreateMutation
>;
export type UserCreateMutationResult =
  Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<
  UserCreateMutation,
  UserCreateMutationVariables
>;
export const UserProfileUpdateDocument = gql`
  mutation UserProfileUpdate($userID: ID!, $update: UserProfileUpdate!) {
    UserProfileUpdate(userID: $userID, update: $update)
  }
`;
export type UserProfileUpdateMutationFn = Apollo.MutationFunction<
  UserProfileUpdateMutation,
  UserProfileUpdateMutationVariables
>;

/**
 * __useUserProfileUpdateMutation__
 *
 * To run a mutation, you first call `useUserProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileUpdateMutation, { data, loading, error }] = useUserProfileUpdateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUserProfileUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserProfileUpdateMutation,
    UserProfileUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserProfileUpdateMutation,
    UserProfileUpdateMutationVariables
  >(UserProfileUpdateDocument, options);
}
export type UserProfileUpdateMutationHookResult = ReturnType<
  typeof useUserProfileUpdateMutation
>;
export type UserProfileUpdateMutationResult =
  Apollo.MutationResult<UserProfileUpdateMutation>;
export type UserProfileUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserProfileUpdateMutation,
  UserProfileUpdateMutationVariables
>;
export const UserUpdatePasswordDocument = gql`
  mutation UserUpdatePassword($userID: String!, $newPassword: String!) {
    UserUpdatePassword(userID: $userID, newPassword: $newPassword)
  }
`;
export type UserUpdatePasswordMutationFn = Apollo.MutationFunction<
  UserUpdatePasswordMutation,
  UserUpdatePasswordMutationVariables
>;

/**
 * __useUserUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUserUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatePasswordMutation, { data, loading, error }] = useUserUpdatePasswordMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUserUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpdatePasswordMutation,
    UserUpdatePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserUpdatePasswordMutation,
    UserUpdatePasswordMutationVariables
  >(UserUpdatePasswordDocument, options);
}
export type UserUpdatePasswordMutationHookResult = ReturnType<
  typeof useUserUpdatePasswordMutation
>;
export type UserUpdatePasswordMutationResult =
  Apollo.MutationResult<UserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UserUpdatePasswordMutation,
  UserUpdatePasswordMutationVariables
>;
export const AllowanceRequestFeeDocument = gql`
  query AllowanceRequestFee($userID: String!, $amount: Int!) {
    AllowanceRequestFee(userID: $userID, amount: $amount)
  }
`;

/**
 * __useAllowanceRequestFeeQuery__
 *
 * To run a query within a React component, call `useAllowanceRequestFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowanceRequestFeeQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAllowanceRequestFeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllowanceRequestFeeQuery,
    AllowanceRequestFeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowanceRequestFeeQuery,
    AllowanceRequestFeeQueryVariables
  >(AllowanceRequestFeeDocument, options);
}
export function useAllowanceRequestFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowanceRequestFeeQuery,
    AllowanceRequestFeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowanceRequestFeeQuery,
    AllowanceRequestFeeQueryVariables
  >(AllowanceRequestFeeDocument, options);
}
export type AllowanceRequestFeeQueryHookResult = ReturnType<
  typeof useAllowanceRequestFeeQuery
>;
export type AllowanceRequestFeeLazyQueryHookResult = ReturnType<
  typeof useAllowanceRequestFeeLazyQuery
>;
export type AllowanceRequestFeeQueryResult = Apollo.QueryResult<
  AllowanceRequestFeeQuery,
  AllowanceRequestFeeQueryVariables
>;
export const AllowanceRequestMinimumDocument = gql`
  query AllowanceRequestMinimum($userID: String!) {
    AllowanceRequestMinimum(userID: $userID)
  }
`;

/**
 * __useAllowanceRequestMinimumQuery__
 *
 * To run a query within a React component, call `useAllowanceRequestMinimumQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestMinimumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowanceRequestMinimumQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useAllowanceRequestMinimumQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllowanceRequestMinimumQuery,
    AllowanceRequestMinimumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowanceRequestMinimumQuery,
    AllowanceRequestMinimumQueryVariables
  >(AllowanceRequestMinimumDocument, options);
}
export function useAllowanceRequestMinimumLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowanceRequestMinimumQuery,
    AllowanceRequestMinimumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowanceRequestMinimumQuery,
    AllowanceRequestMinimumQueryVariables
  >(AllowanceRequestMinimumDocument, options);
}
export type AllowanceRequestMinimumQueryHookResult = ReturnType<
  typeof useAllowanceRequestMinimumQuery
>;
export type AllowanceRequestMinimumLazyQueryHookResult = ReturnType<
  typeof useAllowanceRequestMinimumLazyQuery
>;
export type AllowanceRequestMinimumQueryResult = Apollo.QueryResult<
  AllowanceRequestMinimumQuery,
  AllowanceRequestMinimumQueryVariables
>;
export const AllowanceRequestRetrieveUnapprovedDocument = gql`
  query AllowanceRequestRetrieveUnapproved {
    AllowanceRequestRetrieveUnapproved {
      requestID
      memberID
      companyID
      periodID
      bankCode
      accountNumber
      amount
      fee
      principal
      idempotencyKey
      status
      createdAt
    }
  }
`;

/**
 * __useAllowanceRequestRetrieveUnapprovedQuery__
 *
 * To run a query within a React component, call `useAllowanceRequestRetrieveUnapprovedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRequestRetrieveUnapprovedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowanceRequestRetrieveUnapprovedQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllowanceRequestRetrieveUnapprovedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllowanceRequestRetrieveUnapprovedQuery,
    AllowanceRequestRetrieveUnapprovedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowanceRequestRetrieveUnapprovedQuery,
    AllowanceRequestRetrieveUnapprovedQueryVariables
  >(AllowanceRequestRetrieveUnapprovedDocument, options);
}
export function useAllowanceRequestRetrieveUnapprovedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowanceRequestRetrieveUnapprovedQuery,
    AllowanceRequestRetrieveUnapprovedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowanceRequestRetrieveUnapprovedQuery,
    AllowanceRequestRetrieveUnapprovedQueryVariables
  >(AllowanceRequestRetrieveUnapprovedDocument, options);
}
export type AllowanceRequestRetrieveUnapprovedQueryHookResult = ReturnType<
  typeof useAllowanceRequestRetrieveUnapprovedQuery
>;
export type AllowanceRequestRetrieveUnapprovedLazyQueryHookResult = ReturnType<
  typeof useAllowanceRequestRetrieveUnapprovedLazyQuery
>;
export type AllowanceRequestRetrieveUnapprovedQueryResult = Apollo.QueryResult<
  AllowanceRequestRetrieveUnapprovedQuery,
  AllowanceRequestRetrieveUnapprovedQueryVariables
>;
export const AllowanceRetrieveDocument = gql`
  query AllowanceRetrieve($userID: String!) {
    AllowanceRetrieve(userID: $userID)
  }
`;

/**
 * __useAllowanceRetrieveQuery__
 *
 * To run a query within a React component, call `useAllowanceRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowanceRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowanceRetrieveQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useAllowanceRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllowanceRetrieveQuery,
    AllowanceRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowanceRetrieveQuery,
    AllowanceRetrieveQueryVariables
  >(AllowanceRetrieveDocument, options);
}
export function useAllowanceRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowanceRetrieveQuery,
    AllowanceRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowanceRetrieveQuery,
    AllowanceRetrieveQueryVariables
  >(AllowanceRetrieveDocument, options);
}
export type AllowanceRetrieveQueryHookResult = ReturnType<
  typeof useAllowanceRetrieveQuery
>;
export type AllowanceRetrieveLazyQueryHookResult = ReturnType<
  typeof useAllowanceRetrieveLazyQuery
>;
export type AllowanceRetrieveQueryResult = Apollo.QueryResult<
  AllowanceRetrieveQuery,
  AllowanceRetrieveQueryVariables
>;
export const AuthRoleRetrieveDocument = gql`
  query AuthRoleRetrieve {
    AuthRoleRetrieve {
      userID
      companyID
      memberID
      level
    }
  }
`;

/**
 * __useAuthRoleRetrieveQuery__
 *
 * To run a query within a React component, call `useAuthRoleRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthRoleRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthRoleRetrieveQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthRoleRetrieveQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthRoleRetrieveQuery,
    AuthRoleRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthRoleRetrieveQuery, AuthRoleRetrieveQueryVariables>(
    AuthRoleRetrieveDocument,
    options,
  );
}
export function useAuthRoleRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthRoleRetrieveQuery,
    AuthRoleRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AuthRoleRetrieveQuery,
    AuthRoleRetrieveQueryVariables
  >(AuthRoleRetrieveDocument, options);
}
export type AuthRoleRetrieveQueryHookResult = ReturnType<
  typeof useAuthRoleRetrieveQuery
>;
export type AuthRoleRetrieveLazyQueryHookResult = ReturnType<
  typeof useAuthRoleRetrieveLazyQuery
>;
export type AuthRoleRetrieveQueryResult = Apollo.QueryResult<
  AuthRoleRetrieveQuery,
  AuthRoleRetrieveQueryVariables
>;
export const BankAccountRetrieveDefaultDocument = gql`
  query BankAccountRetrieveDefault($userID: ID!) {
    BankAccountRetrieveDefault(userID: $userID) {
      bankKey
      bankCode
      accountNumber
      verificationStatus
    }
  }
`;

/**
 * __useBankAccountRetrieveDefaultQuery__
 *
 * To run a query within a React component, call `useBankAccountRetrieveDefaultQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankAccountRetrieveDefaultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankAccountRetrieveDefaultQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useBankAccountRetrieveDefaultQuery(
  baseOptions: Apollo.QueryHookOptions<
    BankAccountRetrieveDefaultQuery,
    BankAccountRetrieveDefaultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BankAccountRetrieveDefaultQuery,
    BankAccountRetrieveDefaultQueryVariables
  >(BankAccountRetrieveDefaultDocument, options);
}
export function useBankAccountRetrieveDefaultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BankAccountRetrieveDefaultQuery,
    BankAccountRetrieveDefaultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BankAccountRetrieveDefaultQuery,
    BankAccountRetrieveDefaultQueryVariables
  >(BankAccountRetrieveDefaultDocument, options);
}
export type BankAccountRetrieveDefaultQueryHookResult = ReturnType<
  typeof useBankAccountRetrieveDefaultQuery
>;
export type BankAccountRetrieveDefaultLazyQueryHookResult = ReturnType<
  typeof useBankAccountRetrieveDefaultLazyQuery
>;
export type BankAccountRetrieveDefaultQueryResult = Apollo.QueryResult<
  BankAccountRetrieveDefaultQuery,
  BankAccountRetrieveDefaultQueryVariables
>;
export const CompanyEmployeeRetrieveDocument = gql`
  query CompanyEmployeeRetrieve($userID: ID!) {
    CompanyEmployeeRetrieve(userID: $userID) {
      userID
      memberID
      companyID
      username
      phoneNumber
      monthlyLimit
      birthday
      name
      ktpUrl
      bankCode
      accountNumber
      bankVerificationStatus
      wageRequestAllowed
      startDate
      endDate
      customData
    }
  }
`;

/**
 * __useCompanyEmployeeRetrieveQuery__
 *
 * To run a query within a React component, call `useCompanyEmployeeRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyEmployeeRetrieveQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useCompanyEmployeeRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyEmployeeRetrieveQuery,
    CompanyEmployeeRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyEmployeeRetrieveQuery,
    CompanyEmployeeRetrieveQueryVariables
  >(CompanyEmployeeRetrieveDocument, options);
}
export function useCompanyEmployeeRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyEmployeeRetrieveQuery,
    CompanyEmployeeRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyEmployeeRetrieveQuery,
    CompanyEmployeeRetrieveQueryVariables
  >(CompanyEmployeeRetrieveDocument, options);
}
export type CompanyEmployeeRetrieveQueryHookResult = ReturnType<
  typeof useCompanyEmployeeRetrieveQuery
>;
export type CompanyEmployeeRetrieveLazyQueryHookResult = ReturnType<
  typeof useCompanyEmployeeRetrieveLazyQuery
>;
export type CompanyEmployeeRetrieveQueryResult = Apollo.QueryResult<
  CompanyEmployeeRetrieveQuery,
  CompanyEmployeeRetrieveQueryVariables
>;
export const CompanyEmployeesRetrieveDocument = gql`
  query CompanyEmployeesRetrieve($companyID: ID!) {
    CompanyEmployeesRetrieve(companyID: $companyID) {
      userID
      memberID
      companyID
      username
      phoneNumber
      monthlyLimit
      birthday
      name
      ktpUrl
      bankCode
      accountNumber
      bankVerificationStatus
      wageRequestAllowed
      startDate
      endDate
      customData
    }
  }
`;

/**
 * __useCompanyEmployeesRetrieveQuery__
 *
 * To run a query within a React component, call `useCompanyEmployeesRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeesRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyEmployeesRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyEmployeesRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyEmployeesRetrieveQuery,
    CompanyEmployeesRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyEmployeesRetrieveQuery,
    CompanyEmployeesRetrieveQueryVariables
  >(CompanyEmployeesRetrieveDocument, options);
}
export function useCompanyEmployeesRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyEmployeesRetrieveQuery,
    CompanyEmployeesRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyEmployeesRetrieveQuery,
    CompanyEmployeesRetrieveQueryVariables
  >(CompanyEmployeesRetrieveDocument, options);
}
export type CompanyEmployeesRetrieveQueryHookResult = ReturnType<
  typeof useCompanyEmployeesRetrieveQuery
>;
export type CompanyEmployeesRetrieveLazyQueryHookResult = ReturnType<
  typeof useCompanyEmployeesRetrieveLazyQuery
>;
export type CompanyEmployeesRetrieveQueryResult = Apollo.QueryResult<
  CompanyEmployeesRetrieveQuery,
  CompanyEmployeesRetrieveQueryVariables
>;
export const CompanyInfoRetrieveDocument = gql`
  query CompanyInfoRetrieve($companyID: ID!) {
    CompanyInfoRetrieve(companyID: $companyID) {
      companyID
      name
      ewaFee
      startPayPeriodDay
      endPayPeriodDay
      wageAllowanceAutoAccruingDaily
      phoneAppEnabled
      dashboardEnabled
      wageRequestEnabled
      allowanceRequestAutoDisbursing
      minimumRequestAmount
      wageImbursementPercentLimit
    }
  }
`;

/**
 * __useCompanyInfoRetrieveQuery__
 *
 * To run a query within a React component, call `useCompanyInfoRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyInfoRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyInfoRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyInfoRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyInfoRetrieveQuery,
    CompanyInfoRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyInfoRetrieveQuery,
    CompanyInfoRetrieveQueryVariables
  >(CompanyInfoRetrieveDocument, options);
}
export function useCompanyInfoRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyInfoRetrieveQuery,
    CompanyInfoRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyInfoRetrieveQuery,
    CompanyInfoRetrieveQueryVariables
  >(CompanyInfoRetrieveDocument, options);
}
export type CompanyInfoRetrieveQueryHookResult = ReturnType<
  typeof useCompanyInfoRetrieveQuery
>;
export type CompanyInfoRetrieveLazyQueryHookResult = ReturnType<
  typeof useCompanyInfoRetrieveLazyQuery
>;
export type CompanyInfoRetrieveQueryResult = Apollo.QueryResult<
  CompanyInfoRetrieveQuery,
  CompanyInfoRetrieveQueryVariables
>;
export const CompanyInfoRetrieveAllDocument = gql`
  query CompanyInfoRetrieveAll {
    CompanyInfoRetrieveAll {
      companyID
      name
      ewaFee
      startPayPeriodDay
      endPayPeriodDay
      wageAllowanceAutoAccruingDaily
      phoneAppEnabled
      dashboardEnabled
      wageRequestEnabled
      allowanceRequestAutoDisbursing
      minimumRequestAmount
      wageImbursementPercentLimit
    }
  }
`;

/**
 * __useCompanyInfoRetrieveAllQuery__
 *
 * To run a query within a React component, call `useCompanyInfoRetrieveAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyInfoRetrieveAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyInfoRetrieveAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyInfoRetrieveAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyInfoRetrieveAllQuery,
    CompanyInfoRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyInfoRetrieveAllQuery,
    CompanyInfoRetrieveAllQueryVariables
  >(CompanyInfoRetrieveAllDocument, options);
}
export function useCompanyInfoRetrieveAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyInfoRetrieveAllQuery,
    CompanyInfoRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyInfoRetrieveAllQuery,
    CompanyInfoRetrieveAllQueryVariables
  >(CompanyInfoRetrieveAllDocument, options);
}
export type CompanyInfoRetrieveAllQueryHookResult = ReturnType<
  typeof useCompanyInfoRetrieveAllQuery
>;
export type CompanyInfoRetrieveAllLazyQueryHookResult = ReturnType<
  typeof useCompanyInfoRetrieveAllLazyQuery
>;
export type CompanyInfoRetrieveAllQueryResult = Apollo.QueryResult<
  CompanyInfoRetrieveAllQuery,
  CompanyInfoRetrieveAllQueryVariables
>;
export const CompanyMembershipRetrieveDocument = gql`
  query CompanyMembershipRetrieve($userID: ID!) {
    CompanyMembershipRetrieve(userID: $userID) {
      memberID
      userID
      companyID
      startDate
      endDate
      isManager
    }
  }
`;

/**
 * __useCompanyMembershipRetrieveQuery__
 *
 * To run a query within a React component, call `useCompanyMembershipRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembershipRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembershipRetrieveQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useCompanyMembershipRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyMembershipRetrieveQuery,
    CompanyMembershipRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyMembershipRetrieveQuery,
    CompanyMembershipRetrieveQueryVariables
  >(CompanyMembershipRetrieveDocument, options);
}
export function useCompanyMembershipRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyMembershipRetrieveQuery,
    CompanyMembershipRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyMembershipRetrieveQuery,
    CompanyMembershipRetrieveQueryVariables
  >(CompanyMembershipRetrieveDocument, options);
}
export type CompanyMembershipRetrieveQueryHookResult = ReturnType<
  typeof useCompanyMembershipRetrieveQuery
>;
export type CompanyMembershipRetrieveLazyQueryHookResult = ReturnType<
  typeof useCompanyMembershipRetrieveLazyQuery
>;
export type CompanyMembershipRetrieveQueryResult = Apollo.QueryResult<
  CompanyMembershipRetrieveQuery,
  CompanyMembershipRetrieveQueryVariables
>;
export const CompanyMembershipRetrieveAllDocument = gql`
  query CompanyMembershipRetrieveAll($companyID: ID!) {
    CompanyMembershipRetrieveAll(companyID: $companyID) {
      memberID
      userID
      companyID
      startDate
      endDate
      isManager
    }
  }
`;

/**
 * __useCompanyMembershipRetrieveAllQuery__
 *
 * To run a query within a React component, call `useCompanyMembershipRetrieveAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembershipRetrieveAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembershipRetrieveAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useCompanyMembershipRetrieveAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyMembershipRetrieveAllQuery,
    CompanyMembershipRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyMembershipRetrieveAllQuery,
    CompanyMembershipRetrieveAllQueryVariables
  >(CompanyMembershipRetrieveAllDocument, options);
}
export function useCompanyMembershipRetrieveAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyMembershipRetrieveAllQuery,
    CompanyMembershipRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyMembershipRetrieveAllQuery,
    CompanyMembershipRetrieveAllQueryVariables
  >(CompanyMembershipRetrieveAllDocument, options);
}
export type CompanyMembershipRetrieveAllQueryHookResult = ReturnType<
  typeof useCompanyMembershipRetrieveAllQuery
>;
export type CompanyMembershipRetrieveAllLazyQueryHookResult = ReturnType<
  typeof useCompanyMembershipRetrieveAllLazyQuery
>;
export type CompanyMembershipRetrieveAllQueryResult = Apollo.QueryResult<
  CompanyMembershipRetrieveAllQuery,
  CompanyMembershipRetrieveAllQueryVariables
>;
export const CompanyMembershipsReadAllDocument = gql`
  query CompanyMembershipsReadAll {
    CompanyMembershipsReadAll {
      memberID
      userID
      companyID
      startDate
      endDate
      isManager
    }
  }
`;

/**
 * __useCompanyMembershipsReadAllQuery__
 *
 * To run a query within a React component, call `useCompanyMembershipsReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembershipsReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembershipsReadAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyMembershipsReadAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyMembershipsReadAllQuery,
    CompanyMembershipsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyMembershipsReadAllQuery,
    CompanyMembershipsReadAllQueryVariables
  >(CompanyMembershipsReadAllDocument, options);
}
export function useCompanyMembershipsReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyMembershipsReadAllQuery,
    CompanyMembershipsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyMembershipsReadAllQuery,
    CompanyMembershipsReadAllQueryVariables
  >(CompanyMembershipsReadAllDocument, options);
}
export type CompanyMembershipsReadAllQueryHookResult = ReturnType<
  typeof useCompanyMembershipsReadAllQuery
>;
export type CompanyMembershipsReadAllLazyQueryHookResult = ReturnType<
  typeof useCompanyMembershipsReadAllLazyQuery
>;
export type CompanyMembershipsReadAllQueryResult = Apollo.QueryResult<
  CompanyMembershipsReadAllQuery,
  CompanyMembershipsReadAllQueryVariables
>;
export const DashboardsVisibleRetrieveDocument = gql`
  query DashboardsVisibleRetrieve($companyID: ID!) {
    DashboardsVisibleRetrieve(companyID: $companyID)
  }
`;

/**
 * __useDashboardsVisibleRetrieveQuery__
 *
 * To run a query within a React component, call `useDashboardsVisibleRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsVisibleRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsVisibleRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useDashboardsVisibleRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardsVisibleRetrieveQuery,
    DashboardsVisibleRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardsVisibleRetrieveQuery,
    DashboardsVisibleRetrieveQueryVariables
  >(DashboardsVisibleRetrieveDocument, options);
}
export function useDashboardsVisibleRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardsVisibleRetrieveQuery,
    DashboardsVisibleRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardsVisibleRetrieveQuery,
    DashboardsVisibleRetrieveQueryVariables
  >(DashboardsVisibleRetrieveDocument, options);
}
export type DashboardsVisibleRetrieveQueryHookResult = ReturnType<
  typeof useDashboardsVisibleRetrieveQuery
>;
export type DashboardsVisibleRetrieveLazyQueryHookResult = ReturnType<
  typeof useDashboardsVisibleRetrieveLazyQuery
>;
export type DashboardsVisibleRetrieveQueryResult = Apollo.QueryResult<
  DashboardsVisibleRetrieveQuery,
  DashboardsVisibleRetrieveQueryVariables
>;
export const HelloWorldQDocument = gql`
  query HelloWorldQ {
    HelloWorldQ
  }
`;

/**
 * __useHelloWorldQQuery__
 *
 * To run a query within a React component, call `useHelloWorldQQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelloWorldQQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelloWorldQQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelloWorldQQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HelloWorldQQuery,
    HelloWorldQQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HelloWorldQQuery, HelloWorldQQueryVariables>(
    HelloWorldQDocument,
    options,
  );
}
export function useHelloWorldQLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HelloWorldQQuery,
    HelloWorldQQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HelloWorldQQuery, HelloWorldQQueryVariables>(
    HelloWorldQDocument,
    options,
  );
}
export type HelloWorldQQueryHookResult = ReturnType<typeof useHelloWorldQQuery>;
export type HelloWorldQLazyQueryHookResult = ReturnType<
  typeof useHelloWorldQLazyQuery
>;
export type HelloWorldQQueryResult = Apollo.QueryResult<
  HelloWorldQQuery,
  HelloWorldQQueryVariables
>;
export const PayrollAdditionsReadAllDocument = gql`
  query PayrollAdditionsReadAll($companyID: ID!) {
    PayrollAdditionsReadAll(companyID: $companyID) {
      memberID
      companyID
      periodID
      amount
      reason
      date
    }
  }
`;

/**
 * __usePayrollAdditionsReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollAdditionsReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollAdditionsReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollAdditionsReadAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollAdditionsReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollAdditionsReadAllQuery,
    PayrollAdditionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollAdditionsReadAllQuery,
    PayrollAdditionsReadAllQueryVariables
  >(PayrollAdditionsReadAllDocument, options);
}
export function usePayrollAdditionsReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollAdditionsReadAllQuery,
    PayrollAdditionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollAdditionsReadAllQuery,
    PayrollAdditionsReadAllQueryVariables
  >(PayrollAdditionsReadAllDocument, options);
}
export type PayrollAdditionsReadAllQueryHookResult = ReturnType<
  typeof usePayrollAdditionsReadAllQuery
>;
export type PayrollAdditionsReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollAdditionsReadAllLazyQuery
>;
export type PayrollAdditionsReadAllQueryResult = Apollo.QueryResult<
  PayrollAdditionsReadAllQuery,
  PayrollAdditionsReadAllQueryVariables
>;
export const PayrollDaysWorkedReadAllDocument = gql`
  query PayrollDaysWorkedReadAll($companyID: ID!) {
    PayrollDaysWorkedReadAll(companyID: $companyID) {
      memberID
      companyID
      periodID
      daysWorked
    }
  }
`;

/**
 * __usePayrollDaysWorkedReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollDaysWorkedReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollDaysWorkedReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollDaysWorkedReadAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollDaysWorkedReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollDaysWorkedReadAllQuery,
    PayrollDaysWorkedReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollDaysWorkedReadAllQuery,
    PayrollDaysWorkedReadAllQueryVariables
  >(PayrollDaysWorkedReadAllDocument, options);
}
export function usePayrollDaysWorkedReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollDaysWorkedReadAllQuery,
    PayrollDaysWorkedReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollDaysWorkedReadAllQuery,
    PayrollDaysWorkedReadAllQueryVariables
  >(PayrollDaysWorkedReadAllDocument, options);
}
export type PayrollDaysWorkedReadAllQueryHookResult = ReturnType<
  typeof usePayrollDaysWorkedReadAllQuery
>;
export type PayrollDaysWorkedReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollDaysWorkedReadAllLazyQuery
>;
export type PayrollDaysWorkedReadAllQueryResult = Apollo.QueryResult<
  PayrollDaysWorkedReadAllQuery,
  PayrollDaysWorkedReadAllQueryVariables
>;
export const PayrollDeductionsReadAllDocument = gql`
  query PayrollDeductionsReadAll($companyID: ID!) {
    PayrollDeductionsReadAll(companyID: $companyID) {
      memberID
      companyID
      periodID
      amount
      reason
      date
    }
  }
`;

/**
 * __usePayrollDeductionsReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollDeductionsReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollDeductionsReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollDeductionsReadAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollDeductionsReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollDeductionsReadAllQuery,
    PayrollDeductionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollDeductionsReadAllQuery,
    PayrollDeductionsReadAllQueryVariables
  >(PayrollDeductionsReadAllDocument, options);
}
export function usePayrollDeductionsReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollDeductionsReadAllQuery,
    PayrollDeductionsReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollDeductionsReadAllQuery,
    PayrollDeductionsReadAllQueryVariables
  >(PayrollDeductionsReadAllDocument, options);
}
export type PayrollDeductionsReadAllQueryHookResult = ReturnType<
  typeof usePayrollDeductionsReadAllQuery
>;
export type PayrollDeductionsReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollDeductionsReadAllLazyQuery
>;
export type PayrollDeductionsReadAllQueryResult = Apollo.QueryResult<
  PayrollDeductionsReadAllQuery,
  PayrollDeductionsReadAllQueryVariables
>;
export const PayrollEmployeesReadAllDocument = gql`
  query PayrollEmployeesReadAll($companyID: ID!) {
    PayrollEmployeesReadAll(companyID: $companyID) {
      memberID
      companyID
      name
      taxTkRate
      taxKesRate
      salaryType
      salary
      dailyRateOverride
      bankCode
      accountNumber
      customData
    }
  }
`;

/**
 * __usePayrollEmployeesReadAllQuery__
 *
 * To run a query within a React component, call `usePayrollEmployeesReadAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeesReadAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollEmployeesReadAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollEmployeesReadAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollEmployeesReadAllQuery,
    PayrollEmployeesReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollEmployeesReadAllQuery,
    PayrollEmployeesReadAllQueryVariables
  >(PayrollEmployeesReadAllDocument, options);
}
export function usePayrollEmployeesReadAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollEmployeesReadAllQuery,
    PayrollEmployeesReadAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollEmployeesReadAllQuery,
    PayrollEmployeesReadAllQueryVariables
  >(PayrollEmployeesReadAllDocument, options);
}
export type PayrollEmployeesReadAllQueryHookResult = ReturnType<
  typeof usePayrollEmployeesReadAllQuery
>;
export type PayrollEmployeesReadAllLazyQueryHookResult = ReturnType<
  typeof usePayrollEmployeesReadAllLazyQuery
>;
export type PayrollEmployeesReadAllQueryResult = Apollo.QueryResult<
  PayrollEmployeesReadAllQuery,
  PayrollEmployeesReadAllQueryVariables
>;
export const PayrollPeriodDataReadDocument = gql`
  query PayrollPeriodDataRead($companyID: ID!) {
    PayrollPeriodDataRead(companyID: $companyID) {
      periodID
      startTime
      endTime
    }
  }
`;

/**
 * __usePayrollPeriodDataReadQuery__
 *
 * To run a query within a React component, call `usePayrollPeriodDataReadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollPeriodDataReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollPeriodDataReadQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollPeriodDataReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollPeriodDataReadQuery,
    PayrollPeriodDataReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollPeriodDataReadQuery,
    PayrollPeriodDataReadQueryVariables
  >(PayrollPeriodDataReadDocument, options);
}
export function usePayrollPeriodDataReadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollPeriodDataReadQuery,
    PayrollPeriodDataReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollPeriodDataReadQuery,
    PayrollPeriodDataReadQueryVariables
  >(PayrollPeriodDataReadDocument, options);
}
export type PayrollPeriodDataReadQueryHookResult = ReturnType<
  typeof usePayrollPeriodDataReadQuery
>;
export type PayrollPeriodDataReadLazyQueryHookResult = ReturnType<
  typeof usePayrollPeriodDataReadLazyQuery
>;
export type PayrollPeriodDataReadQueryResult = Apollo.QueryResult<
  PayrollPeriodDataReadQuery,
  PayrollPeriodDataReadQueryVariables
>;
export const PayrollTopUpRetrieveDocument = gql`
  query PayrollTopUpRetrieve($companyID: ID!) {
    PayrollTopUpRetrieve(companyID: $companyID)
  }
`;

/**
 * __usePayrollTopUpRetrieveQuery__
 *
 * To run a query within a React component, call `usePayrollTopUpRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollTopUpRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollTopUpRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePayrollTopUpRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayrollTopUpRetrieveQuery,
    PayrollTopUpRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollTopUpRetrieveQuery,
    PayrollTopUpRetrieveQueryVariables
  >(PayrollTopUpRetrieveDocument, options);
}
export function usePayrollTopUpRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollTopUpRetrieveQuery,
    PayrollTopUpRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollTopUpRetrieveQuery,
    PayrollTopUpRetrieveQueryVariables
  >(PayrollTopUpRetrieveDocument, options);
}
export type PayrollTopUpRetrieveQueryHookResult = ReturnType<
  typeof usePayrollTopUpRetrieveQuery
>;
export type PayrollTopUpRetrieveLazyQueryHookResult = ReturnType<
  typeof usePayrollTopUpRetrieveLazyQuery
>;
export type PayrollTopUpRetrieveQueryResult = Apollo.QueryResult<
  PayrollTopUpRetrieveQuery,
  PayrollTopUpRetrieveQueryVariables
>;
export const PeriodRetrieveDocument = gql`
  query PeriodRetrieve($periodID: ID!) {
    PeriodRetrieve(periodID: $periodID) {
      periodID
      companyID
      startTime
      endTime
    }
  }
`;

/**
 * __usePeriodRetrieveQuery__
 *
 * To run a query within a React component, call `usePeriodRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodRetrieveQuery({
 *   variables: {
 *      periodID: // value for 'periodID'
 *   },
 * });
 */
export function usePeriodRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    PeriodRetrieveQuery,
    PeriodRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PeriodRetrieveQuery, PeriodRetrieveQueryVariables>(
    PeriodRetrieveDocument,
    options,
  );
}
export function usePeriodRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodRetrieveQuery,
    PeriodRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PeriodRetrieveQuery, PeriodRetrieveQueryVariables>(
    PeriodRetrieveDocument,
    options,
  );
}
export type PeriodRetrieveQueryHookResult = ReturnType<
  typeof usePeriodRetrieveQuery
>;
export type PeriodRetrieveLazyQueryHookResult = ReturnType<
  typeof usePeriodRetrieveLazyQuery
>;
export type PeriodRetrieveQueryResult = Apollo.QueryResult<
  PeriodRetrieveQuery,
  PeriodRetrieveQueryVariables
>;
export const PeriodsRetrieveAllDocument = gql`
  query PeriodsRetrieveAll($companyID: ID!) {
    PeriodsRetrieveAll(companyID: $companyID) {
      periodID
      companyID
      startTime
      endTime
    }
  }
`;

/**
 * __usePeriodsRetrieveAllQuery__
 *
 * To run a query within a React component, call `usePeriodsRetrieveAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodsRetrieveAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodsRetrieveAllQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function usePeriodsRetrieveAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    PeriodsRetrieveAllQuery,
    PeriodsRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PeriodsRetrieveAllQuery,
    PeriodsRetrieveAllQueryVariables
  >(PeriodsRetrieveAllDocument, options);
}
export function usePeriodsRetrieveAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodsRetrieveAllQuery,
    PeriodsRetrieveAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PeriodsRetrieveAllQuery,
    PeriodsRetrieveAllQueryVariables
  >(PeriodsRetrieveAllDocument, options);
}
export type PeriodsRetrieveAllQueryHookResult = ReturnType<
  typeof usePeriodsRetrieveAllQuery
>;
export type PeriodsRetrieveAllLazyQueryHookResult = ReturnType<
  typeof usePeriodsRetrieveAllLazyQuery
>;
export type PeriodsRetrieveAllQueryResult = Apollo.QueryResult<
  PeriodsRetrieveAllQuery,
  PeriodsRetrieveAllQueryVariables
>;
export const PhoneInvitationsRetrieveDocument = gql`
  query PhoneInvitationsRetrieve($companyID: ID!, $accepted: Boolean) {
    PhoneInvitationsRetrieve(companyID: $companyID, accepted: $accepted) {
      inviteID
      memberID
      companyID
      name
      phoneNumber
      monthlyLimit
      bankCode
      bankNumber
      accepted
      customData
    }
  }
`;

/**
 * __usePhoneInvitationsRetrieveQuery__
 *
 * To run a query within a React component, call `usePhoneInvitationsRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneInvitationsRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneInvitationsRetrieveQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      accepted: // value for 'accepted'
 *   },
 * });
 */
export function usePhoneInvitationsRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    PhoneInvitationsRetrieveQuery,
    PhoneInvitationsRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PhoneInvitationsRetrieveQuery,
    PhoneInvitationsRetrieveQueryVariables
  >(PhoneInvitationsRetrieveDocument, options);
}
export function usePhoneInvitationsRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PhoneInvitationsRetrieveQuery,
    PhoneInvitationsRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PhoneInvitationsRetrieveQuery,
    PhoneInvitationsRetrieveQueryVariables
  >(PhoneInvitationsRetrieveDocument, options);
}
export type PhoneInvitationsRetrieveQueryHookResult = ReturnType<
  typeof usePhoneInvitationsRetrieveQuery
>;
export type PhoneInvitationsRetrieveLazyQueryHookResult = ReturnType<
  typeof usePhoneInvitationsRetrieveLazyQuery
>;
export type PhoneInvitationsRetrieveQueryResult = Apollo.QueryResult<
  PhoneInvitationsRetrieveQuery,
  PhoneInvitationsRetrieveQueryVariables
>;
export const TransactionEventRetrieveByCompanyIdDocument = gql`
  query TransactionEventRetrieveByCompanyID($companyID: String!) {
    TransactionEventRetrieveByCompanyID(companyID: $companyID) {
      eventID
      companyID
      periodID
      memberID
      amount
      fee
      principal
      description
      status
      idempotencyKey
      typ
      allocatedAt
      createdAt
    }
  }
`;

/**
 * __useTransactionEventRetrieveByCompanyIdQuery__
 *
 * To run a query within a React component, call `useTransactionEventRetrieveByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionEventRetrieveByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionEventRetrieveByCompanyIdQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useTransactionEventRetrieveByCompanyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionEventRetrieveByCompanyIdQuery,
    TransactionEventRetrieveByCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransactionEventRetrieveByCompanyIdQuery,
    TransactionEventRetrieveByCompanyIdQueryVariables
  >(TransactionEventRetrieveByCompanyIdDocument, options);
}
export function useTransactionEventRetrieveByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionEventRetrieveByCompanyIdQuery,
    TransactionEventRetrieveByCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransactionEventRetrieveByCompanyIdQuery,
    TransactionEventRetrieveByCompanyIdQueryVariables
  >(TransactionEventRetrieveByCompanyIdDocument, options);
}
export type TransactionEventRetrieveByCompanyIdQueryHookResult = ReturnType<
  typeof useTransactionEventRetrieveByCompanyIdQuery
>;
export type TransactionEventRetrieveByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useTransactionEventRetrieveByCompanyIdLazyQuery
>;
export type TransactionEventRetrieveByCompanyIdQueryResult = Apollo.QueryResult<
  TransactionEventRetrieveByCompanyIdQuery,
  TransactionEventRetrieveByCompanyIdQueryVariables
>;
export const TransactionEventRetrieveByUserIdDocument = gql`
  query TransactionEventRetrieveByUserID($userID: String!) {
    TransactionEventRetrieveByUserID(userID: $userID) {
      eventID
      companyID
      periodID
      memberID
      amount
      fee
      principal
      description
      status
      idempotencyKey
      typ
      allocatedAt
      createdAt
    }
  }
`;

/**
 * __useTransactionEventRetrieveByUserIdQuery__
 *
 * To run a query within a React component, call `useTransactionEventRetrieveByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionEventRetrieveByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionEventRetrieveByUserIdQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useTransactionEventRetrieveByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionEventRetrieveByUserIdQuery,
    TransactionEventRetrieveByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransactionEventRetrieveByUserIdQuery,
    TransactionEventRetrieveByUserIdQueryVariables
  >(TransactionEventRetrieveByUserIdDocument, options);
}
export function useTransactionEventRetrieveByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionEventRetrieveByUserIdQuery,
    TransactionEventRetrieveByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransactionEventRetrieveByUserIdQuery,
    TransactionEventRetrieveByUserIdQueryVariables
  >(TransactionEventRetrieveByUserIdDocument, options);
}
export type TransactionEventRetrieveByUserIdQueryHookResult = ReturnType<
  typeof useTransactionEventRetrieveByUserIdQuery
>;
export type TransactionEventRetrieveByUserIdLazyQueryHookResult = ReturnType<
  typeof useTransactionEventRetrieveByUserIdLazyQuery
>;
export type TransactionEventRetrieveByUserIdQueryResult = Apollo.QueryResult<
  TransactionEventRetrieveByUserIdQuery,
  TransactionEventRetrieveByUserIdQueryVariables
>;
export const UserProfileRetrieveDocument = gql`
  query UserProfileRetrieve($userID: ID!) {
    UserProfileRetrieve(userID: $userID) {
      username
      ktpUrl
      name
    }
  }
`;

/**
 * __useUserProfileRetrieveQuery__
 *
 * To run a query within a React component, call `useUserProfileRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileRetrieveQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUserProfileRetrieveQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserProfileRetrieveQuery,
    UserProfileRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserProfileRetrieveQuery,
    UserProfileRetrieveQueryVariables
  >(UserProfileRetrieveDocument, options);
}
export function useUserProfileRetrieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfileRetrieveQuery,
    UserProfileRetrieveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserProfileRetrieveQuery,
    UserProfileRetrieveQueryVariables
  >(UserProfileRetrieveDocument, options);
}
export type UserProfileRetrieveQueryHookResult = ReturnType<
  typeof useUserProfileRetrieveQuery
>;
export type UserProfileRetrieveLazyQueryHookResult = ReturnType<
  typeof useUserProfileRetrieveLazyQuery
>;
export type UserProfileRetrieveQueryResult = Apollo.QueryResult<
  UserProfileRetrieveQuery,
  UserProfileRetrieveQueryVariables
>;
export const UserRetrieveByUsernameDocument = gql`
  query UserRetrieveByUsername($username: String!) {
    UserRetrieveByUsername(username: $username) {
      userID
      username
    }
  }
`;

/**
 * __useUserRetrieveByUsernameQuery__
 *
 * To run a query within a React component, call `useUserRetrieveByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRetrieveByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRetrieveByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserRetrieveByUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserRetrieveByUsernameQuery,
    UserRetrieveByUsernameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserRetrieveByUsernameQuery,
    UserRetrieveByUsernameQueryVariables
  >(UserRetrieveByUsernameDocument, options);
}
export function useUserRetrieveByUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserRetrieveByUsernameQuery,
    UserRetrieveByUsernameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserRetrieveByUsernameQuery,
    UserRetrieveByUsernameQueryVariables
  >(UserRetrieveByUsernameDocument, options);
}
export type UserRetrieveByUsernameQueryHookResult = ReturnType<
  typeof useUserRetrieveByUsernameQuery
>;
export type UserRetrieveByUsernameLazyQueryHookResult = ReturnType<
  typeof useUserRetrieveByUsernameLazyQuery
>;
export type UserRetrieveByUsernameQueryResult = Apollo.QueryResult<
  UserRetrieveByUsernameQuery,
  UserRetrieveByUsernameQueryVariables
>;
