/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { setHeader } from "../../../../../../redux/global/global.actions";
import { useAppSelector } from "../../../../../../redux/hooks";
import "./permit.style.scss";
import { useDispatch } from "react-redux";
import { PermissionI } from "../../../../../../_interfaces";
import { getCurrentShift } from "../../../../../../redux/shift/shift.actions";
import usePermit from "./usePermit";

const PermitForm = () => {
  const dispatch = useDispatch();
  const [submitPermission] = usePermit();
  const { currentShift } = useAppSelector((state) => ({
    ...state.shift,
  }));
  const currentDate = new Date().toISOString().split("T")[0];
  const [form, setForm] = useState<PermissionI>({
    scheduleId: 0,
    startTime: "",
    endTime: "",
    description: "",
    latitude: 0,
    longitude: 0,
    photo: "",
  });
  const [date, setDate] = useState(currentDate);
  const [time, setTime] = useState({
    from: "",
    to: "",
  });

  useEffect(() => {
    dispatch(setHeader({ title: "Pengajuan Izin", hasBack: true }));
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setForm((state) => ({
          ...state,
          longitude: pos.coords.longitude,
          latitude: pos.coords.latitude,
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  }, []);

  useEffect(() => {
    if (date !== "") {
      dispatch(getCurrentShift(date));
    }
  }, [date]);

  useEffect(() => {
    setForm((temp) => ({
      ...temp,
      scheduleId: currentShift?.id || -1,
    }));
    const from = new Date(currentShift?.startTime || "").toLocaleDateString(
      "id-ID",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: "Asia/Jakarta",
      },
    );
    const to = new Date(currentShift?.endTime || "").toLocaleDateString(
      "id-ID",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: "Asia/Jakarta",
      },
    );
    setTime({
      from: from,
      to: to,
    });
    setForm((temp) => ({
      ...temp,
      startTime: from.split(" ")[3]?.replaceAll(".", ":"),
      endTime: to.split(" ")[3]?.replaceAll(".", ":"),
    }));
  }, [currentShift]);

  const handleChange = (e: any) => {
    setForm((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    submitPermission(form);
  };

  return (
    <div className="permit">
      <div className="form-group">
        <label>Tanggal</label>
        <input
          type="date"
          name="requestedAt"
          id="requestedAt"
          value={date}
          placeholder="Tanggal"
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      {currentShift?.startTime ? (
        <div className="schedule">
          <label>Shift Anda</label>
          <div>
            {time.from} - {time.to}
          </div>
        </div>
      ) : (
        date !== "" && (
          <div className="schedule error">
            Tidak ada shift di tanggal {date}
          </div>
        )
      )}
      <div className="form-group">
        <label>Jam mulai</label>
        <input
          type="time"
          step="1"
          placeholder="Jam mulai"
          name="startTime"
          value={form.startTime}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Jam selesai</label>
        <input
          type="time"
          step={1}
          placeholder="Jam Selesai"
          name="endTime"
          value={form.endTime}
          onChange={handleChange}
        />
      </div>
      <textarea
        placeholder="Keterangan"
        name="description"
        onChange={handleChange}
      />
      <button
        onClick={() => handleSubmit()}
        className="submit-btn"
        disabled={form.description === ""}
      >
        Submit
      </button>
    </div>
  );
};

export default PermitForm;
